import actionType from '../actionTypes';
import _ from 'lodash';
import appEnvirontVariables from "../../../core_units/config/env-variables";
const pathUrl = `/api/admin`;
const coursePathUrl = '/api/course';
// const referralurl = 'https://fi6mrsg6n3.execute-api.ap-south-1.amazonaws.com';
const reducerName = actionType.reduxUpdateTeacherState;
// account actions

export default {

  list: (componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `${pathUrl}/teachers`
        },
        actionType: actionType.teachers.list,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },

  // Slots details 
  slotDetails: (componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `${pathUrl}/teacherslotdetails`
        },
        actionType: actionType.teachers.slotdetails,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
  // Slots creation 
  createSlots: (componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `${pathUrl}/insertteacherslot`
        },
        actionType: actionType.teachers.createslots,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
  //slot add for AE-teacher
  slotAdd:(componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `${coursePathUrl}/add/AirlineSlot`
        },
        actionType: actionType.teachers.slotAdd,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
  // Delete slots 
  deleteSlots: (componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `${pathUrl}/delete/teacherslot/${_.get(componentRequest, 'params.slotid')}`
        },
        actionType: actionType.teachers.deleteslots,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
  //AE Free slot delete
  deleteAeSlots:(componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'delete',
          url: `${coursePathUrl}/delete/airlineSlot`
        },
        actionType: actionType.teachers.deleteslots,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
  // Multiple Slots creation 
  createMultipleSlots: (componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `/api/course/admin/addslots/${_.get(componentRequest, 'params.teacherId')}&${_.get(componentRequest, 'params.clientId')}&${_.get(componentRequest, 'params.ishalfHourSlot')}`
        },
        actionType: actionType.teachers.multipleSlotsCreate,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
  // Edit profile 
  editteacherprofile: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/teacher/editprofile`,
        },
        actionType: actionType.teachers.editteacherprofile,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   view profile
  viewteacherprofile: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teacher/profile/${_.get(componentRequest, 'params.teacherId')}`,
        },
        actionType: actionType.teachers.viewteacherprofile,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  viewPreviousClass: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${coursePathUrl}/teacher/previousclassses`,
        },
        actionType: actionType.teachers.viewPreviousClass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  viewTeacherNoshowClass: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teacher/classes/noshow`,
        },
        actionType: actionType.teachers.viewTeacherNoshowClass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  deleteTeacherNoshowClass: (componentRequest) => {
    return (dispatch) => {
      const { teacherNoshowClassId } = componentRequest;
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/update/teacher/noshow/details`,
          data: {
            teacherNoshowClassId,
          },
        },
        actionType: actionType.teachers.deleteTeacherNoshowClass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  

  enablePaidClass: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `api/users/enable/paid`,
        },
        actionType: actionType.teachers.enablePaidClass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   booked slots cancel
  cancelbookedslots: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/teacher/cancelclass/${_.get(componentRequest, 'params.scheduleid')}`,
        },
        actionType: actionType.teachers.bookedslotcancel,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  cancelClassForComplete: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/admin/changeClassStatus`,
        },
        actionType: actionType.teachers.cancelClassForComplete,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   add profile
  addteacherprofile: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/teacher`,
        },
        actionType: actionType.teachers.addteacherprofile,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   class count list
  viewclasscount: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teacherClassesCount/${_.get(componentRequest, 'params.teacherId')}`,
        },
        actionType: actionType.teachers.classcount,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //addon class API 
  isAddOnClass: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'put',
                url: `/api/admin/student/addons`
            },
            actionType: actionType.teachers.isAddOnClass,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    },
  //   complete class 
  completeclass: (componentRequest) => {
    return (dispatch) => {
      const { st, csid, tid, cancelst, cancellationReasonByStudent } = componentRequest.body;
  
      const queryParams = new URLSearchParams({
        st,
        csid,
        tid,
        cancelst,
        cancellationReasonByStudent,
        pushNotification: true,
      }).toString();
  
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${coursePathUrl}/teacher/completeclass?${queryParams}`,
        },
        actionType: actionType.teachers.completeclass,
        reducerName,
        dispatch,
        componentRequest,
      };
  
      global.api.requestAPI(requestOptions);
    };
  },
  completeclassforpenalty: (componentRequest) => {
    return (dispatch) => {
      const { csid, tid, cancelst, cancellationReasonByStudent } = componentRequest.body;
  
      const queryParams = new URLSearchParams({
        
        csid,
        tid,
        cancelst,
        cancellationReasonByStudent,
        pushNotification: true,
        markForPenalty:true
      }).toString();
  
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${coursePathUrl}/teacher/completeclass?${queryParams}`,
        },
        actionType: actionType.teachers.completeclassforpenalty,
        reducerName,
        dispatch,
        componentRequest,
      };
  
      global.api.requestAPI(requestOptions);
    };
  },
  cancelClassFetch: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/api/admin/fetchClassesCancelledByTeacher`,
        },
        actionType: actionType.teachers.cancelClassFetch,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
 
  classNoShow: (componentRequest) => {
    return (dispatch) => {
      const { slotdate, studentClassId, status } = componentRequest.body;
  
      const queryParams = new URLSearchParams({
        
        slotdate,
        studentClassId,
        status
      }).toString();
  
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/api/course/change/classstatus`,
        },
        actionType: actionType.teachers.classNoShow,
        reducerName,
        dispatch,
        componentRequest,
      };
  
      global.api.requestAPI(requestOptions);
    };
  },
  markJoinClass: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/api/admin/mark/join`,
        },
        actionType: actionType.teachers.markJoinClass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   Schedule class 
  scheduleClass: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${coursePathUrl}/scheduleClass?status=prebook`,
        },
        actionType: actionType.teachers.scheduleclass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   Active Inactive  class 
  activeInactiveClass: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/teacher/students`,
        },
        actionType: actionType.teachers.activeInactiveClass,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   Course tags
  coursetags: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/api/course/teacher/course/tags`,
        },
        actionType: actionType.teachers.coursetags,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   students list
  studentslist: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teacherdashboard/${_.get(componentRequest, 'params.credid')}/enrolledStudents`,
        },
        actionType: actionType.teachers.classcount,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
   //   Cancel addons
   canceladdons: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `api/course/admin/addon/cancel`,
        },
        actionType: actionType.teachers.canceladdon,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
   //   teacher course
   courselist: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teachercourse`,
        },
        actionType: actionType.teachers.courselist,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  // Teacher activation 
  teacherstatus: (componentRequest) => {
    return dispatch => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: `${pathUrl}/disableTeacher/${_.get(componentRequest, 'params.teacherid')}/${_.get(componentRequest, 'params.status')}`
        },
        actionType: actionType.teachers.teacherstatus,
        reducerName,
        dispatch,
        componentRequest

      };
      global.api.requestAPI(requestOptions);
    }
  },
   //   check teacher status 
   checkteacherstatus: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/checkteacherdisabled/${_.get(componentRequest, 'params.teacherId')}`,
        },
        actionType: actionType.teachers.checkteacherstatus,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //   teacher list for students
  getavailableTeacher: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `api/admin/getavailableteacher`,
        },
        actionType: actionType.teachers.teacherlist,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //    agegroup
  getagroups: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teacherahegroup`,
        },
        actionType: actionType.teachers.teacherage,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  //    language
  getlanguages: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `${pathUrl}/teacherLanguage`,
        },
        actionType: actionType.teachers.teacherlanguage,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
    //    course update 
    updatecourselist: (componentRequest) => {
      return (dispatch) => {
        const requestOptions = {
          axiosParams: {
            method: "post",
            url: `/api/course/addTeacherCourseTagging`,
          },
          actionType: actionType.teachers.updateCourseteachers,
          reducerName,
          dispatch,
          componentRequest,
        };
        global.api.requestAPI(requestOptions);
      };
    },
     //    Teacher list for student course 
     listteacherforcourse: (componentRequest) => {
      return (dispatch) => {
        const requestOptions = {
          axiosParams: {
            method: "post",
            url: `${pathUrl}/teachers`,
          },
          actionType: actionType.teachers.listteacherforcourse,
          reducerName,
          dispatch,
          componentRequest,
        };
        global.api.requestAPI(requestOptions);
      };
    },

// plan code for student
planCodeForStudent: (planCode) => {
  console.log(planCode,"oooo")
  return (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: "get",
        url: `${pathUrl}/api/admin/plancode?planCode=${planCode.params}`,
      },
      actionType: actionType.teachers.planCodeForStudent,
      reducerName,
      dispatch,
     
    };
    global.api.requestAPI(requestOptions);
  };
  
},






       //    Change course for student  
       changecourseforstudent: (componentRequest) => {
        return (dispatch) => {
          const requestOptions = {
            axiosParams: {
              method: "post",
              url: `/api/admin/changecourseofstudent`,
            },
            actionType: actionType.teachers.changecourseforstudent,
            reducerName,
            dispatch,
            componentRequest,
          };
          global.api.requestAPI(requestOptions);
        };
      },
      //    Change teacher Block Status 
      teacherblockstatus: (componentRequest) => {
        return (dispatch) => {
          const requestOptions = {
            axiosParams: {
              method: "post",
              url: `/api/users/teacher/status`,
            },
            actionType: actionType.teachers.blockstatus,
            reducerName,
            dispatch,
            componentRequest,
          };
          global.api.requestAPI(requestOptions);
        };
      },
      // Course list
courseList: (componentRequest) => {
  return dispatch => {
      const requestOptions = {
          axiosParams: {
              baseURI:`https://api.artiumacademy.com`,
              method: 'get',
              url: `/config.json`
          },
          actionType: actionType.students.courselist,
          reducerName,
          dispatch,
          componentRequest
  
      };
      global.api.requestAPI(requestOptions);
  }
  },
  webinarLink: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `https://lxi2cxyob6.execute-api.ap-south-1.amazonaws.com/api/banner/create-banner`
            },
            actionType: actionType.teachers.webinarLink,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    },
    updateBanner: (componentRequest) => {
      return dispatch => {
          const requestOptions = {
              axiosParams: {
                  method: 'patch',
                  url: `https://lxi2cxyob6.execute-api.ap-south-1.amazonaws.com/api/banner/update-banner`
              },
              actionType: actionType.teachers.updateBanner,
              reducerName,
              dispatch,
              componentRequest
      
          };
          global.api.requestAPI(requestOptions);
      }
      },
    referral: (componentRequest) => {
      return dispatch => {
          const requestOptions = {
              axiosParams: {
                  method: 'get',
                  url: `${appEnvirontVariables.API.REFERRAL_URL}/api/referral/cashback-requests`
              },
              actionType: actionType.teachers.referral,
              reducerName,
              dispatch,
              componentRequest
      
          };
          global.api.requestAPI(requestOptions);
      }
      },
      approvereferral: (componentRequest) => {
        console.log(componentRequest,"componentRequest")
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${appEnvirontVariables.API.REFERRAL_URL}/api/referral/cashback-update/${_.get(componentRequest, 'body.userId')}`
                   
                  },
                actionType: actionType.teachers.approvereferral,
                reducerName,
                dispatch,
                componentRequest
        
            };
            global.api.requestAPI(requestOptions);
        }
        },
     
      
      fetchWebinar: (componentRequest) => {
      return dispatch => {
        const requestOptions = {
          axiosParams:{
            method: 'get',
            url:`https://lxi2cxyob6.execute-api.ap-south-1.amazonaws.com/api/banner/get-all-banners`
           
          },
          actionType: actionType.teachers.fetchWebinar,
            reducerName,
            dispatch,
            componentRequest
        };
        global.api.requestAPI(requestOptions);
      }
    },
    deleteWebinar: (componentRequest) => {
      return dispatch => {
        const requestOptions = {
          axiosParams:{
            method: 'get',
            url:`https://lxi2cxyob6.execute-api.ap-south-1.amazonaws.com/api/banner/delete-banner`
           
          },
          actionType: actionType.teachers.deleteWebinar,
            reducerName,
            dispatch,
            componentRequest
        };
        global.api.requestAPI(requestOptions);
      }
    }
}