import React, { Component, useState, useEffect, useCallback } from 'react';
import { Breadcrumb, Input, Menu, Dropdown, Empty, Button } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { AudioOutlined, MoreOutlined } from '@ant-design/icons';
import _ from 'lodash';
import './teachers.css'

import boy1 from '../../../assets/img/default-image.png'
import * as SecureLS from "secure-ls";
import logo from '../../../assets/gif/aa_loader.gif';


const { Search } = Input;

const TeachersScreen = (props) => {
	const [teacherslist, setTeachersList] = useState([]);
	const [teacherId, setTeacherId] = useState();
	const [pagenum, setPage] = useState();
	const [pageloading, setpageloading] = useState();
	const [alpha, setalpha ] = useState('All')
	const [queryvalue, setqueryvalue ] = useState();
	const [searchLoading, setSearchLoading] = useState(false); 
	const [searchValue, setSearchValue] = useState('');
	useEffect(() => {

		var ls = new SecureLS({ encodingType: 'aes' });
		if(_.has(_.get(props,'account.isLogin'),'authorization')){
			ls.set('isLogin', {...ls.get('isLogin'), ..._.get(props,'account.isLogin')})
		
		}
		apicall()
		setpageloading(true);
		prepareAlphabets();
	}, [])


	// Teacher list api call
	const apicall = (query = {}, page = 0) => {
		setqueryvalue(query)

		props.list({
			body: {
				page: page,
				size: 100,
				...query
			},
			callback: (response, data) => {
				setpageloading(false);

				if (_.isEqual(response, true)) {

					let arr = [];

					if (_.isEmpty(query) && !_.isEmpty(_.get(data, 'content'))) {
						arr = _.uniqBy([...teacherslist, ..._.get(data, 'content', [])], function (e) {
							return e.teacherId;
						});
					} else if (!_.isEmpty(query)) {
						if(_.size(teacherslist)<_.get(data, 'totalElements') && _.isEqual(queryvalue,query )) {
							arr = _.uniqBy([...teacherslist, ..._.get(data, 'content')], function (e) {
								return e.studentId;
							});	
						}else{
						arr = _.get(data, 'content')
						}					}
					setTeachersList(arr);
					
				}

			}
		})
		
	}

	useEffect(() => {
		if (_.isEqual(typeof (_.get(props, 'teachers.list.number')), 'number')) {
			setPage(_.get(props, 'teachers.list.number'))
		}
	}, [_.get(props, 'teachers.list.number')]);

	const handleNavigation = useCallback(
		(e) => {
			const window = e.target;
			if (window.scrollingElement.scrollHeight - window.scrollingElement.scrollTop === window.scrollingElement.clientHeight) {
				const page = _.get(props, 'teachers.list.pageable.pageNumber') ? _.parseInt(_.get(props, 'teachers.list.pageable.pageNumber')) + 1 : _.parseInt(pagenum) + 1
			if(page<=_.get(props, 'teachers.list.totalPages') && _.size(teacherslist)<_.get(props, 'teachers.list.totalElements')) {
					apicall({}, page);
				}
			}
		}
	);
	// window.onscroll = (ev) =>{
	// 	if ((window.innerHeight + Math.ceil(window.pageYOffset)) >= document.body.offsetHeight) {
	// 					const page =pagenum + 1
	// 					if(page<=_.get(props, 'teachers.list.totalPages') && _.size(teacherslist)<_.get(props, 'teachers.list.totalElements')) {
	// 						if(!_.isEmpty(alpha)&&!_.isEqual(alpha, "All")) {
	// 							apicall({name: alpha}, page);
			
	// 						}else{
	// 							apicall({}, page);
			
	// 						}						}
	// 	}
	// };

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleNavigation);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleNavigation);
	// 	};
	// }, [handleNavigation]);

	const onSearch = async value => {
		if (searchValue.trim() === '') {
			return;
		  }
		setSearchLoading(true);
		setSearchValue(value);
		let query = {};
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchValue)) {
      query = {
        emailId: searchValue
      };
    } else if (new RegExp(/^[0-9\b]+$/).test(searchValue)) {
      query = {
        mobileNumber: searchValue
      };
    } else {
      query = {
        name: searchValue
      };
    }

    await apicall(query);
    
    setSearchLoading(false);
    setSearchValue('');
  };
  const onInputChange = event => {
    setSearchValue(event.target.value);
  };
	const onSearchInputFocus = () => {
		
		setSearchLoading(false);
	  };
	const menuToggle = () => {
		document.body.classList.toggle('menu-close');
	}
	const onAlphabetClick = (value) => {
		let query = {}
	
		if (_.isEmpty(value)) {
			apicall(query)
	
		}else{
			query = {
				name: value
			}
			apicall(query)
	
		}
	}
	const prepareAlphabets = () => {
		let result = [
			<div  key={1} 
			className={`sort-btn ${alpha==='All' ? 'active':''} `}
			onClick={(value)=> {setalpha('All');onAlphabetClick()} }
			value={'All'} >{"All"}</div>
		];
		for(let i=65; i<91; i++) {
		  result.push(
			<div  key={i} 
			className={`sort-btn ${alpha===String.fromCharCode(i) && 'active'}  `}
			onClick={	()=>{setalpha(String.fromCharCode(i));onAlphabetClick(String.fromCharCode(i))	}	} 
			value={String.fromCharCode(i)} >{String.fromCharCode(i)}</div>
		  )
		}
		return result;
	  }
	const render = () => {
		if (_.isEqual(pageloading, false)) {
			return (
				<div className="DashboardScreen-container" >
					<div className="row">
						<div className="col ">
							
								<Button
									type="default"
									htmlType="reset"
									style={{background:'#1890ff',color:'white'}}
									id="kt_account_profile_details_submit"	
								>
									<Link to={`/add-teacher`}>
									Add Teacher
									</Link>
								</Button>
							
							{/* <Button
									type="default"
									style={{background:'#1890ff',color:'white',marginLeft:'7px'}}
									id="teacherSlot"	
								>
							<Link to={`/teacher-slot-search`}>
								Search Airline Slot
							</Link>
							</Button> */}
						</div>
						<div className="col ">

						</div>
						<div className="col align-self-end">
							<Search placeholder="Search by email/mobile number and press search button" allowClear onSearch={onSearch}  enterButton="Search" style={{ width: 300, float: 'right' }} 
						  loading={searchLoading}
						  disabled={searchLoading}
						  onChange={onInputChange} 
						  value={searchValue}
							/>
						</div>
					</div>
					<div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
					{prepareAlphabets()}
					</div>
					{_.size(teacherslist)>0 ?
						<div className="Dashboard-card-screen" onScroll={console.log('scroll works')}>
							<div className="row">
								{_.map(teacherslist, (teacher, index) => {

									return (
										<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" key={index} onClick={ () => {menuToggle(); localStorage.currentTeacherDetails=JSON.stringify(teacher)}}>
											
											<Link to={`/teacher-view?id=${_.get(teacher, 'teacherId')}&&credid=${_.get(teacher, 'credentialId')}`} state={ teacher }>
												<div className="card card-xl-stretch mb-xl-8 position-relative">
													<div className="card-body d-flex align-items-center ">
														<img id='profilePhoto' src={!_.isEmpty(_.get(teacher, 'profilePhoto')) ? _.get(teacher, 'profilePhoto', boy1) : boy1} alt="user" className="align-self-end h-100px w-100px mr-2 teacher-list-img fillImage" />
														<div className="d-flex flex-column flex-grow-1 text-truncate">
															<a href="" className="fw-bolder text-dark fs-4 mb-2 text-hover-primary" id='name'>{_.startCase(_.get(teacher, 'teacherName', ''))}</a>
															<span className="fw-bold text-muted fs-5 teacher-email" id='emailid'>{_.get(teacher, 'emailId', '')}</span>
															<span className="fw-bold text-muted fs-5 teacher-email" id='mobilenumber'>{_.get(teacher, 'mobileNumber', '')}</span>
														</div>
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						:
						<Empty />
					}
				</div>
			);
		} else {
			return <div className='loadingclass'> <img src={logo} alt="loading..." /></div>
		}

	}
	return render();
};
const mapStateToProps = state => (
	{
		teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
		account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),

	});

const mapDispatchToProps = dispatch => ({
	list: details => dispatch(global.redux.action.teachers.list(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(TeachersScreen)