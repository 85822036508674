import React, { useState, useEffect } from 'react';
import { Button, notification, Spin, DatePicker, Modal, Checkbox, Radio, Tabs } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import SecureLS from "secure-ls";
import axios from 'axios';
import appEnvirontVariables from "../../core_units/config/env-variables";
import envVariables from '../../core_units/config/env-variables';
import LeadsList from './leadsList';
import dayjs from "dayjs";

import {
  FilterOutlined,
  
} from "@ant-design/icons";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;


const AllLeads = (props, onDateChange, filter) => {
  console.log("props", props)
  const navigate = useNavigate();
  const location = useLocation();
  const [overallData, setOverallData] = useState([]);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false); 
  const [selectedButton, setSelectedButton] = useState('today');
  const [customDateRange, setCustomDateRange] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [notConductReason, setNotConductReason] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [demoCallSelectClicked, setDemoCallSelectClicked] = useState(false);
  const [demoCall2SelectClicked, setDemoCall2SelectClicked] = useState(false);
  const [commentClicked, setCommentClicked] = useState(false);
  const [notConductedClicked, setNotConductedClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filterMethod, setFilterMethod] = useState({ callStatus1: 'tags', callStatus2: 'tags' });
  const [applyDisabled, setApplyDisabled] = useState(true);
  const [todayCount, setTodayCount] = useState(null);
  const [weekCount, setWeekCount] = useState(null);
  const [customCount, setCustomCount] = useState(null);
  const [comment, setComment] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({});
  const [apiFilters, setApiFilters] = useState({ filter: "", filterOn: "" });


  // Pagination state variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);

  const ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");

  useEffect(() => {
    const savedButton = localStorage.getItem('selectedButton');
    if (savedButton) {
      if (savedButton === 'today') {
        handleTodayChange();
      } else if (savedButton === 'week') {
        handleWeekChange();
      } else if (savedButton === 'custom') {
        const savedCustomDateRange = JSON.parse(localStorage.getItem('customDateRange'));
        if (savedCustomDateRange) {
          const dateRange = [dayjs(savedCustomDateRange[0]), dayjs(savedCustomDateRange[1])];
          setCustomDateRange(dateRange);
          fetchCustomData(dateRange);
        }
      }
      setSelectedButton(savedButton);
    } else {
      handleTodayChange();
    }
  }, []);

  useEffect(() => {
    if (typeof onDateChange === 'function') {
      onDateChange(handleTodayChange);
    }
  }, [onDateChange]);

  const handleRowClick = (data) => {
    localStorage.setItem('selectedButton', selectedButton);
    localStorage.setItem('customDateRange', JSON.stringify(customDateRange));
    navigate('/DetailViewOps', {
      state: { ...data, ...{ selectedFromDate: selectedFromDate, selectedToDate: selectedToDate, "viewName": "", activeTab: props.activeTab, selectedButton: selectedButton, filter:filter,  } }
    });
  };

  const handleSaveChanges = (data, index) => {
    let status = "";
    let leadHistoryList = [];
    
    const studentData = overallData.find(e => e.studentId === data.studentId);
    const originalStudentData = originalData.find(e => e.studentId === data.studentId);
    console.log("original data", originalStudentData)

  
    
    console.log("original data", originalStudentData);
  
    if (studentData.demoCallStatus1 && demoCallSelectClicked) {
      status += `&latestDemoStatus=${studentData.demoCallStatus1}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Demo Call Status 1",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.demoCallStatus1,
        new_value: studentData.demoCallStatus1,
      });
    }

     
    if (studentData.demoCallStatus2 && demoCall2SelectClicked) {
      status += `&latestDemoStatus=${studentData.demoCallStatus2}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Demo Call Status 2",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.demoCallStatus2,
        new_value: studentData.demoCallStatus2,
      });
    }
  
    if (studentData.notConductedReason !== originalStudentData.notConductedReason && notConductedClicked) {
      status += `&notConductedReason=${studentData.notConductedReason}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Not Conduct Reason",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.notConductedReason,
        new_value: studentData.notConductedReason,
      });
    }
  
    if ( studentData.agentComments && commentClicked ) {
      status += `&comment=${studentData.agentComments}`;
      leadHistoryList.push({
        id: data.studentId.toString(),
        field: "Comment",
        user: token.studentName,
        user_id: token.uId,
        old_value: originalStudentData.agentComments,
        new_value: studentData.agentComments,
      });
    }
  
    if (demoCallSelectClicked || demoCall2SelectClicked||commentClicked || notConductedClicked|| studentData.notConductedReason !== originalStudentData.notConductedReason  ) {
      Modal.confirm({
        title: 'Are you sure you want to save changes?',
        onOk: () => {
          updateStatusAPI(data, status, () => {
            leadHistoryList.forEach(data => addLeadHistory(data));
            // Update the original data to reflect the saved changes
            setOriginalData(overallData.map(item => item.studentId === data.studentId ? { ...item } : item));
          });
        },
        onCancel: () => {
          console.log('Save changes cancelled');
        },
      });
    }
  };


  const updateStatusAPI = (leadData, status, cb) => {
    setLoading(true);
    const payload = {
      classId: leadData.classScheduleId,
      latestDemoStatus: demoCallSelectClicked ? leadData.demoCallStatus1 : leadData.demoCallStatus2,  
      DemoCallStatus1: leadData.demoCallStatus1,
      DemoCallStatus2: leadData.demoCallStatus2,
      notConductedReason: leadData.notConductedReason,
      comments: leadData.agentComments,
      agentId: `${leadData.agentId}`
    };
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/new/update/agent/lead`, payload, {
      headers: {
        Authorization: token["authorization"],
        "Content-Type": "application/json"
      }
    }).then(response => {
      notification.success({ message: "Status updated successfully!" });
      cb();
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  };

  const addLeadHistory = (data) => {
    const apiUrl = `${envVariables.API.DEMOOPS_HISTORY_URL}/api/lead-history`;

    axios.post(apiUrl, data, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then(response => {
        // Handle success as needed
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: "Failed to update lead history." });
      });
  };

  const resetCustomData = () => {
    setCustomCount(null);
    setOverallData([]);
  };

  const handleTodayChange = (page = 0, size = 50, filter = "", filterOn = "") => {
    
    resetCustomData();
    const dateFormat = dayjs().format("YYYY-MM-DD");
    setSelectedButton('today');
    setLoading(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`;
    setSelectedFromDate(dateFormat);
    setSelectedToDate(dateFormat);
    axios.post(apiUrl, {
      fromDate: dateFormat,
      toDate: dateFormat,
      page: page,
      size: size,
      filter: filter,
      filterOn: filterOn,
    }, {
      headers: {
        Authorization: token["authorization"]
      }
    })
    .then(response => {
      const dataLength = response.data.content.length || 0;
      setOverallData(response.data.content);
      setOriginalData(JSON.parse(JSON.stringify(response.data.content)));      
      setTodayCount(dataLength);
      setTotalLeads(response.data.totalElements);
      setLoading(false);
      onDateChange(response.data);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  };

  const handleWeekChange = (page = 0, size = 50, filter = "", filterOn = "") => {
    resetCustomData();
    setSelectedButton('week');
    setLoading(true);
    const fromDate = dayjs().subtract(6, 'days').format("YYYY-MM-DD");
    const toDate = dayjs().format("YYYY-MM-DD");
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`;
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
    axios.post(apiUrl, {
      fromDate: fromDate,
      toDate: toDate,
      page: page,
      size: size,
      filter: filter,
      filterOn: filterOn,
    }, {
      headers: {
        Authorization: token["authorization"]
      }
    })
    .then(response => {
      const dataLength = response.data.content.length || 0;
      setOverallData(response.data.content);
      setOriginalData(JSON.parse(JSON.stringify(response.data.content)));  
      setWeekCount(dataLength);
      setTotalLeads(response.data.totalElements);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  };

  

  const showCustomModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    if (customDateRange.length !== 2) {
      notification.error({
        message: 'Error',
        description: 'Please select a valid date range',
      });
      return;
    }
    fetchCustomData(customDateRange);
    setIsModalVisible(false);
    setSelectedButton('custom');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCommentChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId === data.studentId).agentComments = value;
    setOverallData(updatedData);
  };

  const handleStatusChange = (value, index, data) => {
    const updatedData = [...overallData,];
    updatedData.find(e => e.studentId == data.studentId).demoCallStatus1= value;
    setOverallData(updatedData);
  };

  const handleDemoStatusChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId == data.studentId).demoCallStatus2 = value;
    setOverallData(updatedData);
  };

  const handleConductedChange = (value, index, data) => {
    const updatedData = [...overallData];
    updatedData.find(e => e.studentId == data.studentId).notConductedReason = value;
    setOverallData(updatedData);
  };

  const handleCustomChange = (dates) => {
    if (dates) {
      setCustomDateRange(dates);
    }
  };

  const fetchCustomData = (dateRange, page = 0, size = 50, filter = "", filterOn = "") => {
    if (dateRange.length !== 2) {
      notification.error({
        message: 'Error',
        description: 'Please select a valid date range',
      });
      return;
    }
    const fromDate = dateRange[0].format("YYYY-MM-DD");
    const toDate = dateRange[1].format("YYYY-MM-DD");
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
    setLoading(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`;
    axios.post(apiUrl, {
      fromDate: fromDate,
      toDate: toDate,
      page: page,
      size: size,
      filter: filter,
      filterOn: filterOn,
    }, {
      headers: {
        Authorization: token["authorization"]
      }
    })
    .then(response => {
      const dataLength = response.data.content.length || 0;
      setOverallData(response.data.content);
      setOriginalData(JSON.parse(JSON.stringify(response.data.content)));
      setCustomCount(dataLength);
      setTotalLeads(response.data.totalElements);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  };

  const convertToCSV = (data) => {
    const headers = [
      "Agent Name", "Student Name", "Teacher Name", "Course Name",
      "Timezone", "Mobile Number", "Demo Status", "Demo Call Status1", "Demo Call Status2",
      "Not Conducted Reason", "comment", "Prediction", "class time"
    ];
    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(row => {
      const formattedSlotDate = dayjs(row.slotDate).format("YYYY-MM-DD");
      const formattedStartTime = dayjs(row.startTime, "HH:mm:ss").format("HH:mm:ss");
      const prediction = row.prediction ? `${row.prediction}%` : "No Prediction";

      // Determine demoStatus based on the provided logic
      const demoStatus = (row.demoStatus === "Class_Ready_For_Scheduling" && row.classDeleted)
        ? "RESCHEDULED"
        : (row.demoStatus === "SCHEDULED"
          ? row.actionPerformed === "teacher_demo_cancelclass"
            ? "NOT CONDUCTED"
            : row.actionPerformed === "student_trial_cancelclass"
              ? "CANCELLED"
              : row.demoStatus
          : row.demoStatus);

      const values = [
        row.agentName,
        row.firstName,
        row.teacherFirstName,
        row.courseName,
        row.studentTimezone,
        row.mobileNumber,
        demoStatus,
        row.demoCallStatus1,
        row.demoCallStatus2,
        row.notConductedReason,
        row.agentComments,
        prediction,
        `${row.slotDate}-${row.startTime}`,
      ];

      csvRows.push(values.join(','));
    });


    return csvRows.join('\n');
  };
  const fetchAllData = async (fromDate, toDate, size = 5000, filter = "", filterOn = "") => {
    let allData = [];
    let page = 0;
    let totalPages = 1;
    size = totalLeads;
  
    while (page < totalPages) {
      try {
        const response = await axios.post(
          `${appEnvirontVariables.API.BASE_URL}/api/admin/leads`,
          {
            fromDate,
            toDate,
            page,
            size,
            filter,
            filterOn,
          },
          {
            headers: {
              Authorization: token["authorization"],
            },
          }
        );
  
        const { content, totalPages: responseTotalPages } = response.data;
  
        allData = [...allData, ...content];
        totalPages = responseTotalPages;
        page += 1; 
      } catch (error) {
        console.error("Error fetching data: ", error);
        break;
      }
    }
  
    return allData;
  };
  
  const handleDownloadCsv = async () => {
    setLoading(true);
    let fromDate = selectedFromDate;
    let toDate = selectedToDate;
  
    const { filter, filterOn } = apiFilters; 
    
    
    const allData = await fetchAllData(fromDate, toDate, 5000, filter, filterOn);
  
    const csv = convertToCSV(allData);
    

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    
    a.download =
      selectedButton === "today"
        ? "Today_Leads.csv"
        : selectedButton === "week"
        ? "Last_7_Days_Leads.csv"
        : "Custom_Leads.csv";
  
    document.body.appendChild(a);
    setLoading(false);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  

 
    const showModal = () => {
      setSelectedFilters(appliedFilters); 
      setIsFilterModalVisible(true);
    };
    
  
    const handleCancel = () => {
      setIsFilterModalVisible(false);
      setSelectedFilters(true);
    };
  

    const handleFilterMethodChange = (status, method) => {
      setFilterMethod(prevState => ({ ...prevState, [status]: method }));
    };
  

    const handleFilterSelection = (status, type, value) => {
      setSelectedFilters(prevState => ({
        ...prevState,
        [status]: {
          ...prevState[status],
          [type]: value
        }
      }));
      setApplyDisabled(false); 
    };
  
    const handleClearFilters = () => {
      setSelectedFilters({
        callStatus1: {
          connected: false,
          notConnected: false,
          connectedReasons: [],
          notConnectedReasons: [],
        },
        callStatus2: {
          connected: false,
          notConnected: false,
          connectedReasons: [],
          notConnectedReasons: [],
        },
      });
    
     
      setApiFilters({ filter: "", filterOn: "" });
      
     
    };
    
   
  
    const handlePageChange = (page, size) => {
      setCurrentPage(page);
      setPageSize(size);
    
    
      const { filter, filterOn } = apiFilters;
      if (props.activeTab === '1') {
        props.getLeadDetails(page - 1, size, pageSize, filter, filterOn);
      }
      else if (selectedButton === 'today') {
        handleTodayChange(page - 1, size, filter, filterOn);
      } else if (selectedButton === 'week') {
        handleWeekChange(page - 1, size, filter, filterOn);
      } else if (selectedButton === 'custom') {
        fetchCustomData(customDateRange, page - 1, size, filter, filterOn);
      }
    };

  // const handleApplyFilters = () => {
  //   let filter = "";
  //   let filterOn = "";

  //   Object.keys(selectedFilters).forEach((status) => {
  //     filterOn = status;
  //     const selected = selectedFilters[status];
  //     Object.keys(selected).forEach((key) => {
  //       const value = selected[key];
  //       if (Array.isArray(value)) {
  //         filter += value.join(",") + ",";
  //       } else if (value) {
  //         filter += key + ",";
  //       }

  // else if (key === 'connected' && value) {
  //   // If "Connected" is selected, add all connected reasons to the filter
  //   const connectedReasons = ['Confirmed', 'Wishes to reschedule', 'Wishes to cancel', 'Call back later', 'Not interested'];
  //   filter += connectedReasons.join(",") + ",";
  // } else if (key === 'notConnected' && value) {
  //   // If "Not Connected" is selected, add all not connected reasons to the filter
  //   const notConnectedReasons = ['Unanswered', 'Not Contactable', 'Wrong Number', 'Invalid Number', 'Disconnected after intro', 'Voicemail', 'Language Barrier'];
  //   filter += notConnectedReasons.join(",") + ",";
  // }
  //     });
  //   });

  //   filter = filter.slice(0, -1); // Remove trailing comma

  //   if (selectedButton === 'today') {
  //     handleTodayChange(currentPage - 1, pageSize, filter, filterOn);
  //   } else if (selectedButton === 'week') {
  //     handleWeekChange(currentPage - 1, pageSize, filter, filterOn);
  //   } else if (selectedButton === 'custom') {
  //     fetchCustomData(customDateRange, currentPage - 1, pageSize, filter, filterOn);
  //   }

  //   setIsFilterModalVisible(false);
  // };

// ...



const handleApplyFilters = () => {
  const filters = [];

  Object.keys(selectedFilters).forEach((status) => {
    let filter = "";
    const selected = selectedFilters[status];

    // Handle 'Filter by Tags' for 'Connected' and 'Not Connected'
    if (filterMethod[status] === 'tags') {
      if (selected.connected) {
        const connectedReasons = ['Confirmed', 'Wishes to reschedule', 'Wishes to cancel', 'Call back later', 'Not interested'];
        filter += connectedReasons.join(",") + ",";
      }
      if (selected.notConnected) {
        const notConnectedReasons = ['Unanswered', 'Not Contactable', 'Wrong Number', 'Invalid Number', 'Disconnected after intro', 'Voicemail', 'Language Barrier'];
        filter += notConnectedReasons.join(",") + ",";
      }
    }

    
    if (filterMethod[status] === 'reasons') {
      if (selected.connectedReasons && selected.connectedReasons.length > 0) {
        filter += selected.connectedReasons.join(",") + ",";
      }
      if (selected.notConnectedReasons && selected.notConnectedReasons.length > 0) {
        filter += selected.notConnectedReasons.join(",") + ",";
      }
    }

    filter = filter.slice(0, -1); 

    if (filter) {
      filters.push({ filter, filterOn: status });
    }
  });
  if (filters.length > 0) {
    const { filter, filterOn } = filters[0]; // Handle multiple filters if necessary
    setApiFilters({ filter, filterOn });
  }

  if (filters.length === 0) {
    if (selectedButton === 'today') {
      handleTodayChange(currentPage - 1, pageSize, "", "");
    } else if (selectedButton === 'week') {
      handleWeekChange(currentPage - 1, pageSize, "", "");
    } else if (selectedButton === 'custom') {
      fetchCustomData(customDateRange, currentPage - 1, pageSize, "", "");
    }
  } else {
    // Send filters as separate requests based on each filterOn value
    filters.forEach(({ filter, filterOn }) => {
      if (selectedButton === 'today') {
        handleTodayChange(currentPage - 1, pageSize, filter, filterOn);
      } else if (selectedButton === 'week') {
        handleWeekChange(currentPage - 1, pageSize, filter, filterOn);
      } else if (selectedButton === 'custom') {
        fetchCustomData(customDateRange, currentPage - 1, pageSize, filter, filterOn);
      }
    });
  }

  // Store applied filters
  setAppliedFilters(selectedFilters);

  setIsFilterModalVisible(false);
};





return (
  <div className="container">
    <Button type={selectedButton === 'today' ? 'primary' : 'default'} onClick={() => handleTodayChange(currentPage - 1, pageSize)}>
      Today {selectedButton === 'today' && todayCount && `(${totalLeads})`}
    </Button>
    <Button type={selectedButton === 'week' ? 'primary' : 'default'} onClick={() => handleWeekChange(currentPage - 1, pageSize)} style={{ marginLeft: "10px" }}>
      Last 7 days {selectedButton === 'week' && weekCount && `(${totalLeads})`}
    </Button>
    <Button type={selectedButton === 'custom' ? 'primary' : 'default'} onClick={showCustomModal} style={{ marginLeft: "10px" }}>
      Custom Date {selectedButton === 'custom' && customCount && `(${totalLeads})`}
    </Button>

    <Modal
      title="Select Date Range"
      visible={isModalVisible}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      zIndex={1000}
    >
      <RangePicker
        onChange={handleCustomChange}
        value={customDateRange}
        style={{ zIndex: 99999 }}
      />
    </Modal>
    <Button style={{ marginLeft: "10px" }} onClick={handleDownloadCsv}>Download CSV</Button>

    <div style={{display:"flex",justifyContent:"flex-end", marginTop:"-33px"}}><Button id="filterButton"  style={{ marginLeft: "480px" }} className="filter-btn" onClick={showModal}>Filter   <FilterOutlined></FilterOutlined></Button></div>
  
    <Modal
      title="Filter Leads"
      visible={isFilterModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="clear" onClick={handleClearFilters}>Clear Filters</Button>,
        <Button key="apply" type="primary" disabled={applyDisabled} onClick={handleApplyFilters}>Apply Filters</Button>,
      ]}
    >
     <Tabs
  defaultActiveKey="1"
  className="filter-tabs"
  onChange={(key) => {
    if (key === "1") {
      setSelectedFilters((prevState) => ({
        ...prevState,
        callStatus2: { connected: false, notConnected: false, connectedReasons: [], notConnectedReasons: [] },
      }));
    } else if (key === "2") {
      setSelectedFilters((prevState) => ({
        ...prevState,
        callStatus1: { connected: false, notConnected: false, connectedReasons: [], notConnectedReasons: [] },
      }));
    }
  }}
>
        <TabPane tab="Call Status 1" key="1">
          <Radio.Group
            onChange={e => handleFilterMethodChange('callStatus1', e.target.value)}
            value={filterMethod.callStatus1}
            className="filter-radio-group"
          >
            <Radio value="tags">Filter by Tags</Radio>
            <Radio value="reasons">Filter by Reasons</Radio>
          </Radio.Group>

          {filterMethod.callStatus1 === 'tags' ? (
            <div className="filter-options">
              <Checkbox
                checked={selectedFilters.callStatus1?.connected}
                onChange={e => handleFilterSelection('callStatus1', 'connected', e.target.checked)}
              >
                Connected
              </Checkbox>
              <Checkbox
                checked={selectedFilters.callStatus1?.notConnected}
                onChange={e => handleFilterSelection('callStatus1', 'notConnected', e.target.checked)}
              >
                Not Connected
              </Checkbox>
            </div>
          ) : (
            <div className="filter-options">
              <b><label className="filter-label">Connected Reasons</label></b>
              <Checkbox.Group
                value={selectedFilters.callStatus1?.connectedReasons || []}
                onChange={checkedValues => handleFilterSelection('callStatus1', 'connectedReasons', checkedValues)}
                options={[
                  { label: 'Confirmed', value: 'Confirmed' },
                  { label: 'Wishes to reschedule', value: 'Wishes to reschedule' },
                  { label: 'Wishes to cancel', value: 'Wishes to cancel' },
                  { label: 'Call back later', value: 'Call back later' },
                  { label: 'Not interested', value: 'Not interested' },
                ]}
                className="checkbox-group"
              />
              <b><label className="filter-label">Not Connected Reasons</label></b>
              <Checkbox.Group
                value={selectedFilters.callStatus1?.notConnectedReasons || []}
                onChange={checkedValues => handleFilterSelection('callStatus1', 'notConnectedReasons', checkedValues)}
                options={[
                  { label: 'Unanswered', value: 'Unanswered' },
                  { label: 'Not Contactable', value: 'Not Contactable' },
                  { label: 'Wrong Number', value: 'Wrong Number' },
                  { label: 'Invalid Number', value: 'Invalid Number' },
                  { label: 'Disconnected after intro', value: 'Disconnected after intro' },
                  { label: 'Voicemail', value: 'Voicemail' },
                  { label: 'Language Barrier', value: 'Language Barrier' },
                ]}
                className="checkbox-group"
              />
            </div>
          )}
        </TabPane>

        <TabPane tab="Call Status 2" key="2">
          <Radio.Group
            onChange={e => handleFilterMethodChange('callStatus2', e.target.value)}
            value={filterMethod.callStatus2}
            className="filter-radio-group"
          >
            <Radio value="tags">Filter by Tags</Radio>
            <Radio value="reasons">Filter by Reasons</Radio>
          </Radio.Group>

          {filterMethod.callStatus2 === 'tags' ? (
            <div className="filter-options">
              <Checkbox
                checked={selectedFilters.callStatus2?.connected}
                onChange={e => handleFilterSelection('callStatus2', 'connected', e.target.checked)}
              >
                Connected
              </Checkbox>
              <Checkbox
                checked={selectedFilters.callStatus2?.notConnected}
                onChange={e => handleFilterSelection('callStatus2', 'notConnected', e.target.checked)}
              >
                Not Connected
              </Checkbox>
            </div>
          ) : (
            <div className="filter-options">
              <b><label className="filter-label">Connected Reasons</label></b>
              <Checkbox.Group
                value={selectedFilters.callStatus2?.connectedReasons || []}
                onChange={checkedValues => handleFilterSelection('callStatus2', 'connectedReasons', checkedValues)}
                options={[
                  { label: 'Confirmed', value: 'Confirmed' },
                  { label: 'Wishes to reschedule', value: 'Wishes to reschedule' },
                  { label: 'Wishes to cancel', value: 'Wishes to cancel' },
                  { label: 'Call back later', value: 'Call back later' },
                  { label: 'Not interested', value: 'Not interested' },
                ]}
                className="checkbox-group"
              />
              <b><label className="filter-label">Not Connected Reasons</label></b>
              <Checkbox.Group
                value={selectedFilters.callStatus2?.notConnectedReasons || []}
                onChange={checkedValues => handleFilterSelection('callStatus2', 'notConnectedReasons', checkedValues)}
                options={[
                  { label: 'Unanswered', value: 'Unanswered' },
                  { label: 'Not Contactable', value: 'Not Contactable' },
                  { label: 'Wrong Number', value: 'Wrong Number' },
                  { label: 'Invalid Number', value: 'Invalid Number' },
                  { label: 'Disconnected after intro', value: 'Disconnected after intro' },
                  { label: 'Voicemail', value: 'Voicemail' },
                  { label: 'Language Barrier', value: 'Language Barrier' },
                ]}
                className="checkbox-group"
              />
            </div>
          )}
        </TabPane>
      </Tabs>
    </Modal>
    <Spin tip="Loading..." spinning={loading}>
      {(selectedButton === 'today' || selectedButton === 'week' || selectedButton === 'custom') &&
        <LeadsList
          leadsList={overallData}
          handleStatusChange={handleStatusChange}
          handleDemoStatusChange={handleDemoStatusChange}
          handleRowClick={handleRowClick}
          handleConductedChange={handleConductedChange}
          viewName={"allleads"}
          handleSaveChanges={handleSaveChanges}
          setDemoCallSelectClicked={setDemoCallSelectClicked}
          setDemoCall2SelectClicked={setDemoCall2SelectClicked}
          setCommentClicked={setCommentClicked}
          setNotConductedClicked={setNotConductedClicked}
          fromDate={selectedFromDate}
          toDate={selectedToDate}
          switchActive={props.switchActive}
          handleCommentChange={handleCommentChange}
          comment={comment}
          currentPage={currentPage}
          pageSize={pageSize}
          totalLeads={totalLeads}
          handlePageChange={handlePageChange}
        />
      }
    </Spin>
  </div>
)};


export default AllLeads;