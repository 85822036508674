import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { useSearchParams } from "react-router-dom";
import moment from 'moment'
import { connect } from "react-redux";
import _ from 'lodash';
import DemoClassModal from './demo-modal';
import { Link } from "react-router-dom";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Alert, Modal, notification, Spin, Select, Radio } from 'antd';
import { ExclamationCircleOutlined, DiffOutlined } from '@ant-design/icons';
import Rescheduleteachers from './teacher-list-reschedule'
import ChangeStatusClass from './teacher-list-change-status'
import './style.css'
import axios from 'axios';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { constant } from '../../../core_units/constant';
import mixpanel from 'mixpanel-browser';
const propTypes = {}
const { confirm } = Modal;

const StudentCalendarPage = (props) => {
   console.log(props,"props====")
    var ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin')
  const loginDetails = ls.get('isLogin');
  //const mixpanel = require('mixpanel-browser');
  const { Option } = Select;
    const [teacherId] = useSearchParams();
    const [studentId] = useSearchParams();
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState({});
    const [datefrom, setdatefrom] = useState(moment().startOf('month').format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = useState(moment(datefrom).add(1, 'M').startOf('month').format('YYYY-MM-DD'));
    const [demoslot, setDemoslot] = useState(false);
    const [bookDemo, setBookDemo] = useState(false);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);
    const [focusDate, setFocusDate] = useState();
    const localizer = momentLocalizer(moment);
    const [pageloading, setpageloading] = useState();
    const [rescheduleslotteacher, setrescheduleslotteacher] = useState();
    const [demoslotteacher, setdemoslotteacher] = useState();
    // const[demoChangeStatus,setDemoChangeStatus]= useState();
    const [enrolledcourselist, setEnrolledcourselist] = useState([]);
    // const [classScheduleIds, setClassScheduleIds] = useState([]);
    const [classScheduleId, setClassScheduleId] = useState();
    const [csId,setCsId] = useState();
const [reassignAirlineTeacher, setReassignAirlineTeacher]= useState();
const[recordingLinks,setRecordingLinks]= useState([]);
const[demoStatusDetails,setDemoStatusDetails] = useState();
const [courseList, setCourseList] = useState();
    let allViews = Object.keys(Views).map((k) => Views[k]);

    const ColoredDateCellWrapper = ({ children }) =>
        React.cloneElement(React.Children.only(children), {
            style: {
                backgroundColor: "#CCE8F1",
            },
        });

    /**Component Did Mount  */
    // useEffect(() => {
    //     apiCall(datefrom, dateTo)
    // }, [])
useEffect(()=>{
    bookDemoClass()
},[])
    // useEffect(()=>{
    //     if(!demoChangeStatus){

    //         console.log('reload data')
    //         apiCall(datefrom, dateTo)
    //     }
    // },[demoChangeStatus])

    useEffect(() => {
        if(props.selectedcourse){
            apiCall(datefrom, dateTo)
        }
       

    }, [props.selectedcourse])
    useEffect(() => {
       // configjson();
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) {
              setCourseList(data);
            }
          },
        });
      }, []);
      const configjson = () => {
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) {
              console.log("courselist", data);
              setCourseList(data);
              
             
            }
          },
        });
      };

//    const bookDemoClass = () => {
//     props.bookDemoStatus({
//         params: {
//            studentId: props.profileDetails.studentId
//           },
//         callback: (res, data) => {
//             console.log(data,"@@@==")
//             setDemoStatusDetails(data);
//             console.log(data,"6688")
//         },
//       });
    
//    }
    
    /**slots list api call */
    const apiCall = (datefrom, dateTo) => {
     
        let params = {
            //studentId: teacherId.get("id")
              studentId: studentId.get("id")
        }
        if (!_.isEmpty(_.get(props, 'selectedcourse'))) {
            params.courseId = _.get(props, 'selectedcourse.courseId')
        }
        
      if( !_.isEmpty(_.get(props,'selectedcourse')) ){
           params.courseId=  _.get(props,'selectedcourse.courseId')
       }
       console.log('Calling enrolledcourselist API ');
      
    //    props.enrolledcourselist({
    //     params: {
    //         credid: teacherId.get("credid"),
    //     },
    //     callback: (response, data) => {
    //         // setselectedcourse(data[0])
    //         if(response){
    //             setEnrolledcourselist(data)
               
    //         }  
    //     },
    //    });
       if(_.get(props,'selectedcourse.courseId')){
        props.studentclass({
            params: {
                studentId: teacherId.get("id"),
                courseId:  _.get(props,'selectedcourse.courseId'),
            },
            body: {
                "page": 0,
                "size": 600
            },
            callback: (res, data) => {
                setpageloading(false);
               
                if (_.get(data, 'content')) {
                    setCsId(data.content)
                    const filteredData = data.content.find(item => item.isAirlineBooking === 'true') || {};
                    // const filteredClassScheduleIds = filteredData.map(item => item.classScheduleId);
                    props.setClassScheduleIds(filteredData);
                    console.log(filteredData, "Filtered class schedule IDs");
                    console.log(data.content,"data.content==")
                    props.setStudentClassSchedule(data.content[0] || []);
                   
               
                    // let arr = _.uniqBy([...events, ..._.get(data, 'data.content', [])], function (e) {
                    //     return e.teacherSlotInfoId;
                    // });
                    setEvents(_.get(data, 'content', []))
                    console.log(_.get(data, 'content', []),"138")
                    // console.log(_.get(data, 'content', []),"_.get(data, 'content', [])")
                }
            }
        })
       }

    }
    //
    const bookDemoClass = async (data) => {
       
        return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/studentdemocomplete/${studentId.get("id")}`,
        { headers: { Authorization: token["authorization"] } }).then(res => {
        setDemoStatusDetails(res.data)
          
        });
      }
    //
     //Api for get airline teacher
    const getTeacherforAirline = async (data, classScheduleId) => {
       
        return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/airline/teacher?classScheduleId=${data.classScheduleId}`,
        { headers: { Authorization: token["authorization"] } }
       ).then(res => {
            setReassignAirlineTeacher(res.data)
           
        });
      }
      // API for get RecordingLinks
const getRecordingLinkForStudent = async (event) => {
        let cScheduleId = (event.meetingCode || "").indexOf("batch") > -1 ? event.teacherSlotInfoId : event.classScheduleId;
        return axios
          .get(
            `${appEnvirontVariables.API.RECORDING_URL}/api/recordings/${event.teacherCredentialId}/${cScheduleId}`,
            { headers: { Authorization: token["authorization"] } }
          ).then(res => {
            setRecordingLinks(res.data)
           
            if (_.isEqual(res.data, "Success")) {
                notification.success({
                  message: "Recordings Successfully Done",
                });
              } else {
                
              }
            }
             )
             .catch((err)=> notification.error({
              message: "No Recordings Found",
            }));
        ;
      };
    /**New slot creation using calendar view */
    const handleSelect = ({ start, end }) => {
        const dur = Math.abs(new Date(start) - new Date(end));
        const hours = Math.floor(dur / 3600000);
        if (moment(start) > moment() && hours >= 1) {
            confirm({
                title: `Date  ${moment(start).format('YYYY-MM-DD')}`,
                icon: <DiffOutlined />,
                content: `Timing ${moment(start).format('HH:mm:ss')} - ${moment(end).format('HH:mm:ss')}`,
                onOk() {
                    setpageloading(true);
                    var newslot = {
                        "studentId": 271,
                        "courseId": 15,
                        "teacherId": 70,
                        "selectedSlots": [
                            {
                                "teacherSlotInfoId": 15267,
                                "slotDate": moment(start).format('DD-MM-YYYY'),
                                "slotStartTime": moment(start).format('HH:mm'),
                                "slotEndTime": moment(end).format('HH:mm')
                            }

                        ]
                    }
                    props.teachersslotcreate({
                        body: newslot,
                        callback: (res, data) => {
                            setpageloading(false);

                            if (_.isEqual(res, true)) {
                                apiCall(datefrom, dateTo);
                            
                                notification.success({
                                    message: 'Slot added successfully.',
                                });
                            } else {
                                notification.error({
                                    message: 'Teacher slot info already exist!',
                                });

                            }
                        }
                    })
                },
                onCancel() {
                },
            });
        }
    }
    
      
    /**Events list in calendar */
    const onCalendarEvents = (events) => {
      
        let datas = [];
        _.map(events, (event, id) => {
            datas.push({
                id: `${_.get(event, 'courseId')}`,
                key: `${_.get(event, 'courseId')}`,
                title: <div className="createEventBlock" onClick={() => {
                    setPreviewModalVisible(true);setClassScheduleId(_.get(event, 'classScheduleId'));setEvent(event); setFocusDate(_.get(event, 'classDate')); 
                    //if(_.isEqual(_.get(event, 'status'), "class_completed")){
                        getRecordingLinkForStudent(event);
                    //}
                }}>
                    <span >{_.get(event, 'classTitle') && !_.isEmpty(_.get(event, 'classTitle')) ? _.get(event, 'classTitle') : 'FS'}</span>
                    <span >{_.isEqual(_.get(event, 'isAddOn'),"true") ?' -Supplementary' : null}</span>
                </div>
                ,
                start: new Date(moment(
                    `${_.get(event, 'classDate')} ${_.get(event, 'classStartTime')}`,
                    'YYYY-MM-DD HH-mm-ss'
                ).format()),
                end: new Date(moment(
                    `${_.get(event, 'classDate')} ${_.get(event, 'classEndTime')}`,
                    'YYYY-MM-DD HH-mm-ss'
                ).format()),
                classBookingStatus: _.get(event, 'classBookingStatus'),
                status: _.get(event, 'status'),
                isAirlineBooking:_.get(event, 'isAirlineBooking')
            })
        })
        return datas
    }
    /**Multiple slots style */
    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor;
        console.log(event,"event")
        if (_.isEqual(_.get(event, 'classBookingStatus'), "deleted")) {
            backgroundColor = "#c0c0c0";
        } else
        if (_.isEqual(_.get(event, 'isAirlineBooking'), "true")) {
            backgroundColor = "#E82C86";
        } else if (_.isEqual(_.get(event, 'status'), "class_completed")) {
            backgroundColor = '#ec6363';
        } else if (_.isEqual(_.get(event, 'status'), "class_scheduled") || _.isEqual(_.get(event, 'status'), "class_rescheduled")) {
            backgroundColor = '#ffc306';
        } else {
            backgroundColor = '#68c0af';
        }
    
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            color: _.isEqual(_.get(event, 'status'), "class_completed") ? '#363D3F' : '#172714',
            border: '1px solid #fff',
            display: 'block'
        };
    
        return {
            style: style
        };
    }
    
    /**Next and previous function call */
    const calenderChange = (changeDate) => {
        // setFocusDate(changeDate)
        let date = moment(new Date(changeDate)).format('YYYY-MM')
        let monthInDays = moment(new Date(changeDate)).daysInMonth()
        let start_date = moment(`${date}-01`, "YYYY-MM-DD").format('YYYY-MM-DD')
        // let end_date = moment(`${date}-${monthInDays}`, "YYYY-MM-DD").format('YYYY-MM-DD')
        let end_date = moment(date).add(1, 'M').startOf('month').format('YYYY-MM-DD')

        setdatefrom(start_date);
        setDateTo(end_date);
        apiCall(start_date, end_date)
       
    }
    /**Free slots delete */
    const onslotdelete = (event) => {
        let selectedReason = '';
        let errorSpanRef = null;
        confirm({
            title: 'Select reasons for cancel class',
            // icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                <Radio.Group
                    onChange={(e) => {
                        selectedReason = e.target.value;
                    }}
                    required
                >
                    {courseList && courseList.classCancellationReasonsForAdmin.map((reason) => (
                      <Radio key={reason.id} value={reason.reason}>
                        {reason.reason}
                      </Radio>
                    ))}
                </Radio.Group>
                <span ref={(span) => (errorSpanRef = span)} style={{ color: 'red' }}></span>
                </div>
            ),
            onOk() {
              
                mixpanel.track(constant. MIXPANEL_CANCEL_CLASS, {
                    user:loginDetails?.studentName,
                    cancelledVia: "Student Side",
                    studentName: props.profileDetails.firstName,
                    reason: selectedReason, 
                   studentEmail: props.profileDetails.emailId,
                   studentMobile: props.profileDetails.mobileNumber,
                    classTitle: event.classTitle,
                    classTimings: `${event.classStartTime} - ${event.classEndTime}`,
                    courseName: event.courseName,
                    classScheduleId: event.classScheduleId,
                    slotDate: event.classDate,
                  
                  });
                if (!selectedReason) {
                    errorSpanRef.textContent = 'Please select an option'; 
                    return Promise.reject(); 
                  }
                confirm({
                    title: 'Confirm Class Cancellation',
        content: 'Are you sure you want to cancel the class?',
                    onOk(){
                        setpageloading(true);

                        props.scheduledcancel({
                            params: {
                                scheduleid: _.get(event, 'classScheduleId'),
                                pushNotification:"true",
                                cancellationReasonByStudent: selectedReason,
                            },
                            callback: (res, data) => {
                                setpageloading(false);
        
                                setPreviewModalVisible(false)
                                if (_.isEqual(res, true)) {
                                    apiCall(datefrom, dateTo);
                                  
                                    notification.success({
                                        message: 'Scheduled class cancelled successfully.',
                                    });
                                } else {
                                    notification.error({
                                        message: 'Something went wrong',
                                    });
        
                                }
                            }
                        })

                    },
                    onCancel() {
                        onslotdelete(event);
                      },
                })
               
            },
            onCancel() {
            },
        });


    }
    ////////
    const onChangeStatusClass = (event) => {
        confirm({
            title: 'Do you Want to do this action?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                setpageloading(true);

                props.scheduledcancel({
                    params: {
                        scheduleid: _.get(event, 'classScheduleId'),
                        pushNotification:"true"
                    },
                    callback: (res, data) => {
                        setpageloading(false);

                        setPreviewModalVisible(false)
                        if (_.isEqual(res, true)) {
                            apiCall(datefrom, dateTo);
                         
                            notification.success({
                                message: 'Scheduled class cancelled successfully.',
                            });
                        } else {
                            notification.error({
                                message: 'Something went wrong',
                            });

                        }
                    }
                })
            },
            onCancel() {
            },
        });
    }
    const classStatusChange = () => {
        confirm({
          title: "Do you Want to change status?",
          icon: <ExclamationCircleOutlined />,
          onOk() {
            if ("class_scheduled") {
                if(_.isEqual(_.get(event, 'classType'), "PAID")){
              props.ChangeStatusForStudentSlot({
                params: {
                    classScheduleId: (_.get(event, 'classScheduleId')),
                    classStatus: "class_scheduled",
                    teacherId: (_.get(event, 'teacherId'))
                },
                callback: (response, data) => {},
              });
              notification.success({
                message: "change status successfully done.",
              });
            }
        }else {
                notification.error({
                  message: "something went wrong.",
                });
              }
          },
        });
    
      };
    const onscheduletime = (slotdate) => {
        confirm({
            title: 'Do you Want to do this action?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                setpageloading(true);

                props.courseallocation({
                    body: {
                        "bookingClassEndTime": _.get(slotdate, 'classEndTime'),
                        "bookingClassStartTime": _.get(slotdate, 'classStartTime'),
                        "bookingDate": moment(_.get(slotdate, 'classDate')).format('DD/MM/YYYY'),
                        "bookingType": "",
                        "isDemoStudent": true,
                        "isFreeStudent": false,
                        "isPaidStudent": false,
                        "studentId": null,
                        "classTocancelled":"",
                        "teacherId": null,
                        "userId": teacherId.get("credid"),
                        "voucherCode": "",
                        'teacherid': _.get(slotdate, 'teacherId'),

                    },
                    callback: (res, data) => {
                        setpageloading(false);

                        setPreviewModalVisible(false)
                        if (_.isEqual(res, true)) {
                            apiCall(datefrom, dateTo);
                          
                            notification.success({
                                message: 'Slot allocated successfully.',
                            });
                        } else {
                            notification.error({
                                message: 'Teacher is not available',
                            });

                        }
                    }
                })
            },
            onCancel() {
            },
        });

    }
    function myFunction(item) {  
        var text = document.getElementById("myInput").innerHTML;
        
        navigator.clipboard.writeText(item).then(
            function(){
                alert("Copied successfully"); // success 
            })
          .catch(
             function() {
                alert("Error"); // error
          });
        
      }
    const ViewModel = () => {
       
        return (
            <div className={`modal-container ${_.isEqual(previewModalVisible, false) ? 'd-none' : ''}`}>
                <div className="cookiesContent" id="cookiesPopup">
                    <button className="close" onClick={() => setPreviewModalVisible(false)}>✖</button>

                    {_.isEqual(_.get(event, 'classType'), "PAID") ?

                        // ||_.isEqual(_.get(event, 'classType'), "DEMO") ?
                        (
                            <React.Fragment>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Course title: </b> {_.startCase(_.get(event, 'courseName'))}{_.isEqual(_.get(event, 'isAddOn'),"true") ?' -Supplementary' : null}
                                    </span>
                                </div>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Class title: </b> {_.startCase(_.get(event, 'classTitle'))}
                                    </span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Description: </b> {_.get(event, 'classDescription')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Session Number: </b> {_.get(event, 'classNumber')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Class Start Time: </b> {_.get(event, 'classStartTime')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Class End Time: </b> {_.get(event, 'classEndTime')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Teacher name: </b> {_.startCase(_.get(event, 'teacherName'))}</span>
                                </div>
                                <div className='viewmodalstatus mb-2'>
                                    <span><b>Call status: </b> {_.get(event, 'status')}</span>
                                </div>
                                <div className='viewmodalstatus mb-2'>
                                    <span><b>Student Feedback:</b> {_.get(event, 'studentFeedback')}</span>
                                </div>
                                <div className='viewmodalstatus mb-2'>
                                    <span><b>Teacher Feedback: </b> {_.get(event, 'teacherFeedback')}</span>
                                </div>

                                 {/* {(_.isEqual(_.get(event, 'status'), "class_completed")) &&
    recordingLinks?.length &&
                                <div className='viewmodalstatus mb-2'>
                                    <span type="text" ><b>Recording Link: </b>
                                 
{
    
    recordingLinks?.length && Array.isArray(recordingLinks) ? recordingLinks?.map((item) => (
        <span>
            <li id="myInput" className="recLink">{item}</li>
        <button id="myInput" className='copyBtn' onClick={()=> {myFunction(item)}}>Copy Link</button>  
        </span>
    ))
    :
    <div>No recordings found</div>
     
}                                  

                                    </span>
                                </div>
    } */}
    {(
  (_.get(event, 'recordingLink')) ? (
    <div className='viewmodalstatus mb-2'>
      <span type="text" ><b>Recording Link: </b>
        <li id="myInput" className="recLink">{event.recordingLink}</li>
        <button id="myInput" className='copyBtn' onClick={()=> {myFunction(event.recordingLink)}}>Copy Link</button>  
      </span>
    </div>
  ) : (
    recordingLinks?.length && (
      <div className='viewmodalstatus mb-2'>
        <span type="text" ><b>Recording Link: </b>
          {recordingLinks?.length && Array.isArray(recordingLinks) ? (
            recordingLinks.map((item) => (
              <span>
                <li id="myInput" className="recLink">{item}</li>
                <button id="myInput" className='copyBtn' onClick={()=> {myFunction(item)}}>Copy Link</button>  
              </span>
            ))
          ) : (
            <div>No recordings found</div>
          )}
        </span>
      </div>
    )
  )
)}

    
     
                                {(!_.isEqual(_.get(event, 'status'), "class_completed")) &&
                                    <button className="accept mt-2" onClick={() => onslotdelete(event)}>{_.get(props, 'students.bookedclasscancelLoadingInProgress') ? 'Loading...' : 'Cancel class'}</button>
                                }
                                {/* {!_.isEqual(_.get(event, 'status'), "class_scheduled") && !_.isEqual(_.get(event, 'classType'), "DEMO") &&
                                <button className="reschdeule mt-2" onClick={() => { classStatusChange(); }}>Change Status</button>
    } */}

                                {_.isEqual(_.get(event, 'classType'), "PAID") && !_.isEqual(_.get(event, 'status'), "class_completed") ?
                                    <button className="reschdeule mt-2" ><Link to={`/calendar?id=${_.get(event, 'teacherId')}&&from=student&&Schid=${_.get(event, 'classScheduleId')}`} state={{selectedCourse:event}}  >
                                        Reschedule </Link></button>
                                    :
                                    null}
                            </React.Fragment>
                        ) : _.isEqual(_.get(event, 'classType'), "DEMO") &&
                            !_.isEqual(_.get(event, 'status'), "class_completed") ? (
                            <React.Fragment>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Course title: </b> {_.startCase(_.get(event, 'courseName'))}{_.isEqual(_.get(event, 'isAddOn'),"true") ?' -Supplementary' : null}
                                    </span>
                                </div>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Class title: </b> {_.startCase(_.get(event, 'classTitle'))}
                                    </span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Description: </b> {_.get(event, 'classDescription')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Session Number: </b> {_.get(event, 'classNumber')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Class Date: </b> {_.upperCase(moment(_.get(event, 'classDate')).format('DD MMM YYYY'))}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                <span><b>Class Start-End Time: </b> {_.get(event, 'classStartTime')} - {_.get(event, 'classEndTime')}
                                        </span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Teacher name: </b> {_.startCase(_.get(event, 'teacherName'))}</span>
                                </div>
                               
                                <div className='viewmodalstatus mb-2'>
                                    <span><b>Call status: </b> {_.get(event, 'status')}</span>
                                </div>
                                
                                
                               
                                {!_.isEqual(_.get(event, 'status'), "class_completed") &&
                                    <button className="reschdeule mt-2" onClick={() =>setDemoslot(true)}>Reschedule</button>
                                }
                                {!_.isEqual(_.get(event, 'status'), "class_completed")  && !event.isAirlineBooking &&
                                    <button className="reschdeule mt-2" onClick={() => {
                                        setdemoslotteacher(true); setrescheduleslotteacher(event)
                                    }}>Reschedule with different teacher</button>
                                }
                              
                                {event?.isAirlineBooking=="true" &&
                                    <button className="reschdeule mt-2" onClick={() => {
                                        setdemoslotteacher(true); setrescheduleslotteacher(event); getTeacherforAirline(event)
                                        
                                    }}>Reassign with different teacher</button>
                                }
                                                                  { (
                                    (_.get(event, 'recordingLink')) ? (
                                        <div className='viewmodalstatus mb-2'>
                                        <span type="text" ><b>Recording Link: </b>
                                            <li id="myInput" className="recLink">{event.recordingLink}</li>
                                            <button id="myInput" className='copyBtn' onClick={()=> {myFunction(event.recordingLink)}}>Copy Link</button>  
                                        </span>
                                        </div>
                                    ) : (
                                        recordingLinks?.length && (
                                        <div className='viewmodalstatus mb-2'>
                                            <span type="text" ><b>Recording Link: </b>
                                            {recordingLinks?.length && Array.isArray(recordingLinks) ? (
                                                recordingLinks.map((item) => (
                                                <span>
                                                    <li id="myInput" className="recLink">{item}</li>
                                                    <button id="myInput" className='copyBtn' onClick={()=> {myFunction(item)}}>Copy Link</button>  
                                                </span>
                                                ))
                                            ) : (
                                                <div>No recordings found</div>
                                            )}
                                            </span>
                                        </div>
                                        )
                                    )
                                    )}
                            </React.Fragment>
                        ) :
                            (
                                <React.Fragment>
                                    <div className='viewmodaltitle mb-2'>
                                        <span><b>Timing: </b> {_.get(event, 'classStartTime')} - {_.get(event, 'classEndTime')}
                                        </span>
                                    </div>
                                    <div className='viewmodaltitle mb-2'>
                                        <span><b>Date: </b> {_.upperCase(moment(_.get(event, 'classDate')).format('DD MMM YYYY'))}
                                        </span>
                                    </div>
                                    { (
                                    (_.get(event, 'recordingLink')) ? (
                                        <div className='viewmodalstatus mb-2'>
                                        <span type="text" ><b>Recording Link: </b>
                                            <li id="myInput" className="recLink">{event.recordingLink}</li>
                                            <button id="myInput" className='copyBtn' onClick={()=> {myFunction(event.recordingLink)}}>Copy Link</button>  
                                        </span>
                                        </div>
                                    ) : (
                                        recordingLinks?.length && (
                                        <div className='viewmodalstatus mb-2'>
                                            <span type="text" ><b>Recording Link: </b>
                                            {recordingLinks?.length && Array.isArray(recordingLinks) ? (
                                                recordingLinks.map((item) => (
                                                <span>
                                                    <li id="myInput" className="recLink">{item}</li>
                                                    <button id="myInput" className='copyBtn' onClick={()=> {myFunction(item)}}>Copy Link</button>  
                                                </span>
                                                ))
                                            ) : (
                                                <div>No recordings found</div>
                                            )}
                                            </span>
                                        </div>
                                        )
                                    )
                                    )}
                                </React.Fragment>
                            )
                             }
                            
                            
                </div>
            </div>
        )

    }
    const showBookaDemo = () => {
        let trialCourse = (enrolledcourselist || []).filter( z => {
            if(z.demoCourse == 'true'){
                let studentCourse = (events || []).find(e => e.courseId == z.courseId)
                return (studentCourse && studentCourse.status != "class_completed")
            }else{
                return false;
            }
        });
        if(trialCourse.length || (events || []).filter(e => (e.classType == "DEMO" && e.status != "class_completed")).length){
           return false;
        }else{
           return true;
        }
    }

    const render = () => {
        return (
            <div className='teachers-slots-container'>
                <div className='calendar-legendset-conatiner'>
                    <div className='calendar-legend-set'>
                        <span className='legend-square' style={{ backgroundColor: '#ec5e63' }}>
                        </span>
                        <span className='legend-text'>
                            Completed &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: '#ffc306' }}>
                        </span>
                        <span className='legend-text'>
                            Booked  &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: '#c0c0c0' }}>

                        </span>
                        <span className='legend-text'>
                            Cancelled &emsp;
                        </span>

                        <span className='legend-square' style={{ backgroundColor: '#68c0af' }}>
                        </span>
                        <span className='legend-text'>
                            Free &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: '#E82C86' }}>
                        </span>
                        <span className='legend-text'>

                            Airline Slot &emsp;
                        </span>
                        {demoStatusDetails && demoStatusDetails.isEnrollNewCourseEligible == true &&
                        <span style={{"position": "absolute","marginTop": "-13px","right": "38px","cursor": "pointer","background": "#0078d4","padding": "5px 10px","color": "white","borderRadius": "5px"}} onClick={() => {
                        setBookDemo(true);    
                        setDemoslot(true);
                         }}>
                        Book a Demo
                        </span>}
                    </div>
                </div>
                <div className='row'>
                    {_.isEqual(pageloading, true) ? <div className="SpinBoolean">
                        <Spin size="large" spinning={!pageloading} />
                    </div> : ''
                    }
                    <div className='col-12'>
                        <Spin spinning={pageloading}>

                            <Calendar
                                selectable
                                tooltipAccessor={null}
                                onNavigate={(focusDate, flipUnit, prevOrNext) => calenderChange(focusDate)}
                                localizer={localizer}
                                events={onCalendarEvents(events)}
                                views={['month', 'week', 'day']}
                                showMultiDayTimes
                                // onSelectEvent={viewFreeSlot} 
                                // onSelectSlot={handleSelect}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                components={{
                                    timeSlotWrapper: ColoredDateCellWrapper,
                                }}
                                eventPropGetter={(eventStyleGetter)}
                                defaultDate={moment()}
                                timeslots={1}
                                step={30} />
                            {ViewModel()}
                            {demoslot && <DemoClassModal {...props} classScheduleId={_.get(event, 'classScheduleId')} bookDemo={bookDemo} setBookDemo={setBookDemo} demoslot={demoslot} setdemoslot={setDemoslot} setPreviewModalVisible={setPreviewModalVisible}  apiCall={apiCall} />}
                            {demoslotteacher && < Rescheduleteachers {...props} demoslotteacher={demoslotteacher} setdemoslotteacher={setdemoslotteacher} setPreviewModalVisible={setPreviewModalVisible} rescheduleslotteacher={rescheduleslotteacher} reassignAirlineTeacher={reassignAirlineTeacher} apiCall={apiCall} />}

                            {/* {demoChangeStatus && <ChangeStatusClass {...props} classScheduleId={_.get(event, 'classScheduleId')} teacherId={_.get(event, 'teacherId')} demoChangeStatus={demoChangeStatus} setDemoChangeStatus={setDemoChangeStatus} setPreviewModalVisible={setPreviewModalVisible} apiCall={apiCall}/> } */}
                        </Spin>

                    </div>
                </div>

            </div>
        )
    }
    return render();
}

StudentCalendarPage.propTypes = propTypes

const mapStateToProps = state => (
    {
        students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),

    });

const mapDispatchToProps = dispatch => ({
    studentclass: details => dispatch(global.redux.action.students.classdetails(details)),
    bookDemoStatus: details => dispatch(global.redux.action.students.bookDemoStatus(details)),
    scheduledcancel: details => dispatch(global.redux.action.students.scheduledcancel(details)),
    courseallocation: details => dispatch(global.redux.action.students.courseallocation(details)),
    enrolledcourselist: details => dispatch(global.redux.action.students.enrolledcourselist(details)),
    ChangeStatusForStudentSlot: (details) =>
    dispatch(global.redux.action.students.ChangeStatusForStudentSlot(details)),
    courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudentCalendarPage);
