import React from 'react';
import "./footer.css";

const PageFooter = () => {
	return (
		<div className="footer py-2 d-flex flex-lg-column text" id="kt_footer">
			<div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
				<div className="text-dark order-2 order-md-1">
					<span className="text-muted fw-bold me-1">2022©</span>
					<a href="" target="_blank" className="text-gray-800 text-hover-primary ml-1">Artium academy</a>
				</div>
			</div>
		</div>
	);
};

export default PageFooter;