import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateAccountState;
const pathUrl = `/api/admin`;
const custompath = `api/users/admin`;
// account actions

export default {
    // get report of class
    report: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${pathUrl}/dailyreport`
                },
                actionType: actionType.account.reports,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    // photo upload
    photoupload: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${custompath}/upload/profilephoto`
                },
                actionType: actionType.account.photoupload,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
     // photo delete
     photodelete: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${custompath}/delete/profilephoto`
                },
                actionType: actionType.account.photodelete,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
      // get report of addons
      addonreport: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/api/course/admin/addon/report`
                },
                actionType: actionType.account.addonreports,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
     // bulk email 
     emailcreate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/api/course/admin/sendbulkemail`
                },
                actionType: actionType.account.bulkemail,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
}
