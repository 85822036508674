import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Modal,
    DatePicker,
    notification,
    Table,
    Collapse,
} from "antd";import _ from "lodash";
import * as SecureLS from "secure-ls";
import axios from "axios";
import appEnvirontVariables from "../../core_units/config/env-variables";
import { connect } from "react-redux";

const { RangePicker } = DatePicker;

const QaReports = (props) => {
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const { Option } = Select;
    const [form] = Form.useForm();
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [teachersList, setTeachersList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [qaReportDetail, setQaReportDetail] = useState([]);
  
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (_.isEqual(typeof _.get(props, "teachers.list.number"), "number")) {
        setPage(_.get(props, "teachers.list.number"));
    }
}, [_.get(props, "teachers.list.number")]);

  const handleSearch = () => {
    if (!searchQuery.trim()) {
        return;
    }
    props.list({
        body: {
            page: 0,
            size: 100,
            name: searchQuery,
        },
        callback: (response, data) => {
            if (response) {
                setTeachersList(_.get(data, "content", []));
                setModalVisible(true);
            } else {
            }
        },
    });
};
const handleModalClose = () => {
    setSelectedTeacherId(null);
    setModalVisible(false);
};
const handleAddTeacher = () => {
    if (selectedTeacherId) {
        const selectedTeacher = teachersList.find(teacher => teacher.teacherId === selectedTeacherId);
        if (selectedTeacher) {
            setSelectedTeacherName(selectedTeacher.teacherName);
        }
    }
    setModalVisible(false);
};

  const onFinish = (values) => {
    setIsLoading(true);
  

    const fromDate = values.dates[0].format('YYYY-MM-DD');
    const toDate = values.dates[1].format('YYYY-MM-DD');
    const teacherId = selectedTeacherId;
    
    axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/QAreport`, {
        params: {
            fromDate,
            toDate,
            teacherId,
        },
        headers: {
            Authorization: token["authorization"], 
        },
    })
  
      .then(response => {
   setQaReportDetail(response.data)
        console.log(response.data);
     
      })
      .catch(error => {
    
      
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const columns = [
    {
        title: 'Teacher Name',
        dataIndex: 'teacherName',
        key: 'teacherName',
    },
    {
        title: 'QA Score',
        dataIndex: 'qaScore',
        key: 'qaScore',
    },
    {
        title: 'Audit Date',
        dataIndex: 'auditDate',
        key: 'auditDate',
    },
];
  return (
    <div>
      <h2>QA Reports</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="dates"
          label="Date Range"
          rules={[{ required: true, message: 'Please select date range' }]}
        >
          <RangePicker/>
        </Form.Item>
        <Form.Item label="Search Teacher" name="teacherId" style={{ marginBottom: 0 }}>
                            <Input
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              
                                style={{ width: "60%" }}
                            />
                            <Button
                                type="primary"
                                disabled={!searchQuery.trim()}
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Form.Item>
                        <Modal
                            title="Select Teacher"
                            visible={modalVisible}
                            onCancel={handleModalClose}
                            footer={[
                                <Button key="cancel" onClick={handleModalClose}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={handleAddTeacher}>
                                    Add Teacher
                                </Button>,
                            ]}
                        >
                            <Radio.Group style={{ display: "inline" }}>
                                {teachersList.map((teacher) => (
                                    <div key={teacher.teacherId} className="teacher-card">
                                        <Radio
                                            value={teacher.teacherId}
                                            checked={selectedTeacherId === teacher.teacherId}
                                            onClick={() =>
                                                setSelectedTeacherId(teacher.teacherId)
                                            }
                                        >
                                            {teacher.teacherName} - {teacher.mobileNumber} -{" "}
                                            {teacher.emailId}
                                        </Radio>
                                    </div>
                                ))}
                            </Radio.Group>
                        </Modal>
                        <Form.Item label="Teacher Name"
                      
                            style={{ marginBottom: 0 }}>
                            <Input
                                value={selectedTeacherName}
                                style={{ width: "255px" }}
                                disabled
                                placeholder="Enter Teacher Name"
                            />
                        </Form.Item>
        <Form.Item style={{marginTop:"20px"}}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Generate Report
          </Button>
        </Form.Item>
      </Form>
      {error && <p>{error}</p>}
      {qaReportDetail.length > 0 ? (
      <Table
                dataSource={qaReportDetail}
                columns={columns}
                loading={isLoading}
                rowKey={(record) => record.teacherName} 
                pagination={false}
            />
     ) : (
        <div style={{display:"flex", justifyContent:"center"}}>No data Found</div>
     )}
    </div>
  );
};
const mapStateToProps = (state) => ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
    list: (details) => dispatch(global.redux.action.teachers.list(details)),
    courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QaReports);

