import React, { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import "./style.css";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { Form, DatePicker, Button, Input, notification, Spin } from "antd";
import { constant } from "../../../core_units/constant";
import mixpanel from 'mixpanel-browser';
const TeacherWorking = (props) => {
  console.log(props,"props==66")
  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  //const mixpanel = require('mixpanel-browser');
  const [editingDay, setEditingDay] = useState(null);
  const [workingHours, setWorkingHours] = useState({});
  const [editedWorkingHours, setEditedWorkingHours] = useState({});

  const startEditing = (day) => {
    setEditingDay(day);

    console.log("day:", day);
  };
  const dayNameToKey = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7,
  };
  useEffect(() => {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/teacherWorkingHours?teacherId=${props.profile.teacherId}`;
  
    axios
      .get(apiUrl, {
        headers: {
          Authorization: token["authorization"],
        },
      })
      .then((response) => {
        const workingHoursData = response.data;
  
        console.log("workingHoursData:", workingHoursData);
  
        const workingHoursObj = {};
  
        if (workingHoursData.length === 0) {
         
          daysOfWeek.forEach((day) => {
            workingHoursObj[day] = "0";
          });
        } else {
          workingHoursData.forEach((item) => {
            const dayName = item.weekDay; 
  
            if (dayName && daysOfWeek.includes(dayName.toUpperCase())) {
              workingHoursObj[dayName] = item.workingHour;
            }
          });
        }
  
        setWorkingHours(workingHoursObj);
        console.log("workingHoursObj:", workingHoursObj);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, [props.profile.teacherId]);
  

  const handleUpdateTeacherWorkingHours = () => {
    const updatedWorkingHours = [];

    for (const day in editedWorkingHours) {
      updatedWorkingHours.push({
        teacherId: props.profile.teacherId,
        weekDay: day,
        hours: editedWorkingHours[day],
      });
    }

    if (updatedWorkingHours.length === 0) {
      console.log("No working hours edited.");
      return;
    }

    axios
      .post(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/updateteacherWorkingHours`,
        updatedWorkingHours,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token.authorization,
          },
        }
      )
      .then((response) => {
        setEditingDay(null);
        mixpanel.track( constant.MIXPANEL_TEACHER_WORKING_HOURS_ADD, {
          user:token?.studentName,
          userName:token?.username,
          userId:token?.uId,
          email: props.profile.emailId,
          teacherName: props.profile.firstName,
          mobileNumber: props.profile.mobileNumber,
          birthDate:props.profile.birthDate,
          WorkingHoursDetails:updatedWorkingHours
        });
        console.log("Edited Values:", editedWorkingHours);
        notification.success({
          message: "Working hours successfully added for teacher.",
         
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        notification.error({
          message: "something went wrong.",
          
        });
      });
  };

  return (
    <div>
      <div style={{ border: "1px solid black", padding: "66px" }}>
        <table className="custom-tables">
          <thead>
            <tr>
              <th>Day</th>
              <th>Maximum Working Hours</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {daysOfWeek.map((day, index) => (
              <tr key={index}>
                <td>{day}</td>
                <td>
                  {console.log(workingHours[day], "workingHours[day]")}
                  {console.log("day:", day)}
                  <input
                    type="text"
                    placeholder="Enter Max working Hours"
                    style={{ border: "1px solid black" }}
                    value={
                      editingDay === day
                        ? editedWorkingHours[day] || ""
                        : workingHours[day] || ""
                    }
                    readOnly={editingDay !== day}
                    onChange={(e) => {
                      if (editingDay === day) {
                        const newValue = e.target.value;
                        setEditedWorkingHours((prevEditedWorkingHours) => ({
                          ...prevEditedWorkingHours,
                          [day]: newValue,
                        }));
                      }
                    }}
                  />
                </td>
                <td>
                  {editingDay === day ? (
                    <button
                      onClick={() => {
                        setEditingDay(null);

                        setWorkingHours((prevWorkingHours) => ({
                          ...prevWorkingHours,
                          [day]: editedWorkingHours[day],
                        }));
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <EditOutlined onClick={() => startEditing(day)} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={handleUpdateTeacherWorkingHours}
          style={{
            cursor: "pointer",
            borderRadius: "30px",
            backgroundColor: "lightblue",
            border: "none",
            width: "87px",
            paddingLeft: "19px",
            paddingTop: "6px",
            paddingBottom: "6px",
            display: "flex",
            float: "right",
            marginTop: "20px",
          }}
        >
          submit
        </button>
      </div>
    </div>
  );
};

export default TeacherWorking;
