import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import SecureLS from "secure-ls";
import "./DemopsShift.css";
import axios from "axios";
import dayjs from "dayjs";
import { Button, Modal, Select, notification } from "antd";
import appEnvirontVariables from "../../core_units/config/env-variables";
import { DeleteOutlined } from "@ant-design/icons";
import { constant } from "../../core_units/constant";

function DemopsShift() {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [shiftDetails, setShiftDetails] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedShifts, setEditedShifts] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newShiftDay, setNewShiftDay] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [newShiftFromTime, setNewShiftFromTime] = useState("");
  const [newShiftToTime, setNewShiftToTime] = useState("");
  //const mixpanel = require('mixpanel-browser');
  const ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");

  const [canEdit, setCanEdit] = useState(false); // State for edit permissions

  const dayOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const sortShiftsByDay = (shifts) =>
    shifts.sort((a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day));

  useEffect(() => {
    activeAPI();
    axios
      .get(`${appEnvirontVariables.API.BASE_URL}/api/admin/find/agents`, {
        headers: { Authorization: token["authorization"] },
      })
      .then((response) => setAgents(response.data))
      .catch((err) => console.error("Failed to fetch agents", err));
  }, []);

  const activeAPI = () => {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/agent?agentId=${token.uId}`;
    axios.get(apiUrl, {
      headers: {
        Authorization: token["authorization"],
      },
    })
    .then(response => {
      const userRole = response.data.role;
      setCanEdit(userRole === "Demo-Ops-Admin"); 
    })
    .catch(error => {
      console.error(error);
    });
  };

  const fetchShiftDetails = (agentId) => {
    axios
      .get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/find/agents?agentId=${agentId}`,
        {
          headers: { Authorization: token["authorization"] },
        }
      )
      .then((response) => {
        const sortedShifts = sortShiftsByDay(response.data || []);
        setSelectedAgent(agentId);
        setShiftDetails(sortedShifts);
      })
      .catch((err) => console.error("Failed to fetch shift details", err));
  };

  const handleAddShift = () => {
    if (!canEdit) return; // Prevent unauthorized users from adding shifts
    if (!newShiftDay || !newShiftFromTime || !newShiftToTime) {
      notification.error({
        description: "Please fill in all fields.",
      });
      return;
    }

    const selectedAgentData = agents.find((agent) => agent.agentId === selectedAgent);
    if (!selectedAgentData) {
      notification.error({ description: "Invalid agent selected." });
      return;
    }

    const newShift = {
      agentId: selectedAgent,
      agentName: selectedAgentData.agentName,
      role: "Demo-Ops",
      status: "active",
      day: newShiftDay,
      shiftStartTime: newShiftFromTime,
      shiftEndTime: newShiftToTime,
    };

    axios
      .post(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/add/and/delete/agents`,
        newShift,
        {
          headers: { Authorization: token["authorization"] },
        }
      )
      .then(() => {
        const updatedShifts = [...shiftDetails, newShift];
        setShiftDetails(sortShiftsByDay(updatedShifts));
        setIsAddModalOpen(false);
        setNewShiftDay("");
        setNewShiftFromTime("");
        setNewShiftToTime("");
        fetchShiftDetails(selectedAgent);
        notification.success({ description: "Shift added successfully." });
        mixpanel.track(constant.MIXPANEL_DEMOOPS_SHIFT_ADD, {
          event: "Shift Add",
          agentId: selectedAgent,
          user: token?.studentName,
          credentialId: token?.credentialId,
          agentName: selectedAgentData.agentName,
          role: "Demo-Ops",
          status: "active",
          day: newShiftDay,
          shiftStartTime: newShiftFromTime,
          shiftEndTime: newShiftToTime,
        });
      })
      .catch((err) => console.error("Failed to add new shift", err));
  };

  const handleDelete = (entryId) => {
    if (!canEdit) return; // Prevent unauthorized users from deleting shifts
    const selectedAgentData = agents.find((agent) => agent.agentId === selectedAgent);
    const shiftToDelete = shiftDetails.find((shift) => shift.id === entryId);

    if (!shiftToDelete) {
      notification.error({ description: "Shift not found." });
      return;
    }

    axios
      .post(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/add/and/delete/agents?entryId=${entryId}`,
        {},
        {
          headers: { Authorization: token["authorization"] },
        }
      )
      .then(() => {
        const updatedShifts = shiftDetails.filter((shift) => shift.id !== entryId);
        setShiftDetails(sortShiftsByDay(updatedShifts));
        setIsDeleteModalOpen(false);
        setRowToDelete(null);
        notification.success({ description: "Shift deleted successfully." });

        mixpanel.track(constant.MIXPANEL_DEMOOPS_SHIFT_DELETE, {
          event: "Shift Delete",
          agentId: selectedAgent,
          user: token?.studentName,
          credentialId: token?.credentialId,
          agentName: selectedAgentData.agentName,
          deletedDay: shiftToDelete.day,
          deletedShiftStartTime: shiftToDelete.shiftStartTime,
          deletedShiftEndTime: shiftToDelete.shiftEndTime,
          entryId: entryId,
        });
      })
      .catch((err) => console.error("Failed to delete shift", err));
  };
  
  const renderTimeOptions = (currentTime) => {
    const options = new Set();
  
    // Generate options for every half-hour
    Array.from({ length: 24 * 2 }, (_, i) => {
      const hours = Math.floor(i / 2);
      const minutes = (i % 2) * 30; // 0 or 30
      const time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00`; // Include seconds
      options.add(time);
    });
  
    // Include current time if not already in the options
    if (currentTime && !options.has(currentTime)) {
      options.add(currentTime);
    }
  
    // Convert to array and map to JSX options
    return Array.from(options).map((time) => {
      const label = dayjs(`1970-01-01T${time}`).format("h:mm:ss A"); // Display hours, minutes, and seconds
      return (
        <option key={time} value={time}>
          {label}
        </option>
      );
    });
  };
  

  const toggleEditMode = () => {
    if (!canEdit) return; // Prevent unauthorized users from entering edit mode
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedShifts(shiftDetails);
    }
  };

  const handleTimeChange = (id, field, value) => {
    setEditedShifts((prev) =>
      prev.map((shift) =>
        shift.id === id ? { ...shift, [field]: value } : shift
      )
    );
  };

  const saveAllChanges = () => {
    if (!canEdit) return; // Prevent unauthorized users from saving changes
    const selectedAgentData = agents.find((agent) => agent.agentId === selectedAgent);

    if (!selectedAgentData) {
      notification.error({ description: "Invalid agent selected." });
      return;
    }

    const updatePromises = editedShifts.map((shift) =>
      axios.put(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/update/agent/details`,
        {
          agentId: shift.agentId,
          entryId: shift.id,
          day: shift.day,
          shiftStartTime: shift.shiftStartTime,
          shiftEndTime: shift.shiftEndTime,
        },
        {
          headers: { Authorization: token["authorization"] },
        }
      )
    );

    Promise.all(updatePromises)
      .then(() => {
        setShiftDetails(sortShiftsByDay(editedShifts));
        setIsEditing(false);
        fetchShiftDetails(selectedAgent);
        editedShifts.forEach((shift) => {
          mixpanel.track(constant.MIXPANEL_DEMOOPS_SHIFT_UPDATE, {
            event: "Shift Update",
            agentId: selectedAgent,
            user: token?.studentName,
            credentialId: token?.credentialId,
            agentName: selectedAgentData.agentName,
            entryId: shift.id,
            day: shift.day,
            shiftStartTime: shift.shiftStartTime,
            shiftEndTime: shift.shiftEndTime,
          });
        });
        notification.success({ description: "Shifts updated successfully." });
      })
      .catch((err) => {
        console.error("Failed to save changes", err);
        notification.error({ description: "Failed to save some changes. Please try again." });
      });
  };

  return (
    <div className="container">
      <div className="header-container">
        <div className="dropdown-container">
          <label htmlFor="agentDropdown" className="dropdown-label">
            Select Agent:
          </label>
          <select
            id="agentDropdown"
            onChange={(e) => fetchShiftDetails(e.target.value)}
            className="dropdown"
          >
            <option value="">---Select Agent---</option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.agentId}>
                {agent.agentName}
              </option>
            ))}
          </select>
        </div>
        <div className="button-group">
          <Button
            type="primary"
            onClick={() => setIsAddModalOpen(true)}
            disabled={!selectedAgent || !canEdit} // Disable for non-admins
            className="action-button"
          >
            Add New Shift
          </Button>
          <Button
            type="default"
            onClick={toggleEditMode}
            disabled={!selectedAgent || !canEdit} // Disable for non-admins
            className="action-button"
          >
            {isEditing ? "Cancel" : "Edit All"}
          </Button>
          {isEditing && (
            <Button
              type="primary"
              onClick={saveAllChanges}
              disabled={!editedShifts.length}
              className="action-button"
            >
              Save All
            </Button>
          )}
        </div>
      </div>
      <div>
        {shiftDetails.length > 0 ? (
          <div style={{ maxHeight: "70vh", overflowY: "auto", marginTop: "20px" }}>
            <table className="shift-table">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Shift Timings</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {shiftDetails.map((shift) => (
                  <tr key={shift.id}>
                    <td>{shift.day}</td>
                    <td>
                      {isEditing ? (
                        <>
                          <select
                            value={editedShifts.find((s) => s.id === shift.id)?.shiftStartTime || shift.shiftStartTime}
                            onChange={(e) => handleTimeChange(shift.id, "shiftStartTime", e.target.value)}
                            className="time-dropdown"
                          >
                            {renderTimeOptions(shift.shiftStartTime)}
                          </select>{" "}
                          to{" "}
                          <select
                            value={editedShifts.find((s) => s.id === shift.id)?.shiftEndTime || shift.shiftEndTime}
                            onChange={(e) => handleTimeChange(shift.id, "shiftEndTime", e.target.value)}
                            className="time-dropdown"
                          >
                            {renderTimeOptions(shift.shiftEndTime)}
                          </select>
                        </>
                      ) : shift.shiftStartTime && shift.shiftEndTime ? (
                        `${dayjs(`1970-01-01T${shift.shiftStartTime}`).format("h:mm:ss A")} to ${dayjs(`1970-01-01T${shift.shiftEndTime}`).format("h:mm:ss A")}`
                      ) : (
                        "No Data"
                      )}
                    </td>
                    <td>
                      {canEdit && ( // Only show delete icon for admins
                        <DeleteOutlined
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setRowToDelete(shift);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-data" style={{ marginTop: "20px", textAlign: "center" }}>
            No Data
          </div>
        )}
      </div>

      <Modal
        title="Add New Shift"
        visible={isAddModalOpen}
        onCancel={() => setIsAddModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsAddModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="add" type="primary" onClick={handleAddShift}>
            Add Shift
          </Button>,
        ]}
      >
        <div className="modal-body">
          <label>Day:</label>
          <Select
            value={newShiftDay}
            onChange={(value) => setNewShiftDay(value)}
            placeholder="Select Day"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            className="day-select"
          >
            {dayOrder.map((day) => (
              <Select.Option key={day} value={day}>
                {day}
              </Select.Option>
            ))}
          </Select>
          <br />
          <div style={{ marginTop: "10px" }}>
            <label>From Time: </label>
            <select
              style={{ marginLeft: "10px" }}
              value={newShiftFromTime}
              onChange={(e) => setNewShiftFromTime(e.target.value)}
              className="time-dropdown"
            >
              <option value="">Select Time</option>
              {renderTimeOptions()}
            </select>
            <label style={{ marginLeft: "10px" }}>To Time: </label>
            <select
              style={{ marginLeft: "10px" }}
              value={newShiftToTime}
              onChange={(e) => setNewShiftToTime(e.target.value)}
              className="time-dropdown"
            >
              <option value="">Select Time</option>
              {renderTimeOptions()}
            </select>
          </div>
        </div>
      </Modal>
      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={() => handleDelete(rowToDelete.id)}>
            Confirm
          </Button>,
        ]}
      >
        Are you sure you want to delete this shift?
      </Modal>
    </div>
  );
}

export default DemopsShift;
