import React, { useState, useEffect } from "react";
import { notification, Table, Button ,Modal,Form,Input} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import * as SecureLS from "secure-ls";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { constant } from "../../../core_units/constant";
import mixpanel from 'mixpanel-browser';
const TeacherLeaveDetail = (props) => {
    console.log(props,"props==")
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const loginDetails = ls.get('isLogin');
    //const mixpanel = require('mixpanel-browser');
    const [form] = Form.useForm();
    const [teacherLeaveData, setTeacherLeaveData] = useState(null);
    const [leaveData, setLeaveData] = useState([]);
    const [totalRemains, setTotalRemains] = useState("");
    console.log(totalRemains,"totalRemains")
    const [totalBalance, setTotalBalance] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${appEnvirontVariables.API.BASE_URL}/api/admin/TeacherLeaveHistory?teacherId=${props.profile.teacherId}`,
                {
                    headers: { Authorization: token["authorization"] }
                }
            );
            setTeacherLeaveData(response.data);
        } catch (error) {
            console.error("Error fetching teacher leave history:", error);
            notification.error({
                message: "Error",
                description: "An error occurred while fetching teacher leave history.",
            });
        }
    };
    const fetchLeaveData = async () => {
        try {
            const response = await axios.get(
                `${appEnvirontVariables.API.BASE_URL}/api/admin/leaveBalance?teacherId=${props.profile.teacherId}`,
                {
                    headers: { Authorization: token["authorization"] }
                }
            );
            setLeaveData(response.data);
            console.log(response.data,"leave--")
        } catch (error) {
            console.error("Error fetching teacher leave history:", error);
            notification.error({
                message: "Error",
                description: "An error occurred while fetching teacher leave history.",
            });
        }
    };
    useEffect(() => {
        fetchData();
        fetchLeaveData();
    }, []);
    // const formatDate = (date) => {
    //     const formattedDate = new Date(date);
    //     return formattedDate.toLocaleDateString();
    // };
  
    const formatDate = (date) => {
        return dayjs(date).format("DD-MMM-YYYY hh:mmA");
    };
  
    const columns = [
        {
            title: 'Teacher Name',
            dataIndex: 'teacherDetails',
            key: 'firstName',
            render: (teacherDetails) => `${teacherDetails.userDetails.firstName}`
        },
        {
            title: 'Mobile Number',
            dataIndex: 'teacherDetails',
            key: 'mobileNumber',
            render: (teacherDetails) => teacherDetails.userDetails.credential ? teacherDetails.userDetails.credential.mobileNumber : ''
        },
        {
            title: 'Email',
            dataIndex: 'teacherDetails',
            key: 'emailId',
            render: (teacherDetails) => teacherDetails.userDetails.credential ? teacherDetails.userDetails.credential.emailId : ''
        },
        {
            title: 'Leave Duration',
            dataIndex: 'leaveDuration',
            key: 'leaveDuration',
        },
        {
            title: 'From Date',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (fromDate) => formatDate(fromDate),
        },
        {
            title: 'To Date',
            dataIndex: 'toDate',
            key: 'toDate',
            render: (toDate) => formatDate(toDate),
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
        },
        {
            title: 'Reason',
            dataIndex: 'reasons',
            key: 'reasons',
        },
        {
            title: 'Status',
            dataIndex: 'leaveStatus',
            key: 'name',
            render: (leaveStatus) => {
                if (leaveStatus.type === "leave_approval_pending") {
                    return "Leave Approval Pending";
                } else {
                    return leaveStatus ? leaveStatus.type : '';
                }
            }
        },
    
    ];
const handleModal = () => {
    form.resetFields();
    setIsModalVisible(true)
}
const handleCloseModal = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const handleAddLeave = async () => {
    try {
       
        const values = await form.validateFields();
        console.log(values,"values==")
        const leaveAvailedString = leaveData.leavesAvailed.toString(); 
        const response = await axios.post(
           
            `${appEnvirontVariables.API.BASE_URL}/api/admin/addLeaveBalance`,
            {
                teacherId: props.profile.teacherId,
                TotalLeaves: values.TotalLeaves || leaveData.totalBalance,
                leaveRemaining: values.leaveRemaining || leaveData.leavesRemaining,
                leaveAvailed: values.leaveAvailed || leaveData.leavesAvailed
            },
            {
                headers: { Authorization: token["authorization"] }
            }
        );
        console.log(response,"response===")
        if(response.status == 200){
            notification.success({
                message: "Success",
                description: "Leave balance updated successfully."
            });
            mixpanel.track( constant.MIXPANEL_LEAVE_EDIT, {
                user: loginDetails?.studentName,
                userName:token?.username,
                userId:token?.uId,
                teacherId: props.profile.teacherId,
                Email: props.profile.emailId,
                mobileNumber: props.profile.mobileNumber,
                firstName:props.profile.firstName,
                TotalLeaves: values.TotalLeaves,
                leaveRemaining: values.leaveRemaining,
                leaveAvailed: leaveAvailedString,
                apires: response
            });
        }else{
            notification.error({
              
                description: "Leave balance not updated."
            });
        }
       
       
      
        setIsModalVisible(false);
        fetchLeaveData();
    } catch (error) {
        console.error("Error fetching teacher leave history:", error);
        notification.error({
            message: "Error",
            description: "An error occurred while fetching teacher leave history.",
        });
    }
    
  }
    return (
        <div>
            <div>
                
           
            <div className='calendar-legend-set'>
                <span className="" style={{width:"28%"}}>
                <span  label="Total Leave Balance" style={{fontWeight:700,fontSize:"15px"}}>
      Total Leave Balance:&emsp;
                        </span>
                        <span className='legend-text' style={{color: "black",fontWeight:800}}>
                           {leaveData.totalBalance} &emsp;
                        </span>
                </span>
                <span className="" style={{width:"28%"}}>
      <span  label="Leave Remaining" style={{fontWeight:700,fontSize:"15px"}}>
                        Leave Remaining: &emsp;
                        </span>
                        <span className='legend-text' style={{color: "black",fontWeight:800}}>
                         {leaveData.leavesRemaining} &emsp;
                        </span>
      </span>

      <span className="" style={{width:"28%"}}>
                    <span label="Leave Available" style={{fontWeight:700,fontSize:"15px"}}>
                        Leave Availed: &emsp;
                        </span>
                        <span className='legend-text' style={{color: "black",fontWeight:800}}>
                        {leaveData.leavesAvailed} &emsp;
                        </span>
                        </span>   
                    <button onClick={handleModal}>Add Leave</button>   
                    <Modal
                visible={isModalVisible}
                title="Add Leave"
                onCancel={handleCloseModal}
                footer={null}
               
              >
                  <Form form={form}>
                  <Form.Item
label="Total Leave Balance"
name="TotalLeaves"
rules={[
  {
    required: false,
    message: "Please Enter Total Leave Balance",
  },
]}
>
    
    <Input />
</Form.Item>
<Form.Item
label=" Leave Remaining"
name="leaveRemaining"
rules={[
  {
    required: false,
    message: "Please Enter  Leave Remaining",
  },
]}
>
    <Input />
</Form.Item>
<Form.Item
label=" Leave Availed"
name="leaveAvailed"
rules={[
  {
    required: false,
    message: "Please Enter  Leave Availed",
  },
]}
>
    <Input />
</Form.Item>
<Button onClick={handleAddLeave}>Submit</Button>
                  </Form>

                    </Modal>
      </div>


            </div>
            <div style={{width:"150%"}}>
            {teacherLeaveData && teacherLeaveData.length > 0 ? (
                <Table dataSource={teacherLeaveData} columns={columns} pagination={false}/>
            ) : (
                <div style={{display:"flex", justifyContent:"center",width:"64%"}}>No Data Found</div>
            )}
            </div>
          
        </div>
    );
};

export default TeacherLeaveDetail;
