import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Input, DatePicker, Select, Button } from "antd";
import certificate from "../../../assets/img/certificate.png";
import Arunaji from "../../../assets/img/Arunaji.png";
import Chithraji from "../../../assets/img/Chithraji.png";
import LouizBanks from "../../../assets/img/LouizBanks.png";
import RajuSingh from "../../../assets/img/RajuSingh.png";
import SonuNigam from "../../../assets/img/SonuNigam.png";
import shubha from "../../../assets/img/shubha.png";
import ArunaSairamName from "../../../assets/img/ArunaSairamName.png";
import KSCHITHRAName from "../../../assets/img/KSCHITHRAName.png";
import LouizBankName from "../../../assets/img/LouizBankName.png";
import RajuSinghName from "../../../assets/img/RajuSinghName.png";
import SonuNigamName from "../../../assets/img/SonuNigamName.png";
import ShubhaMudgalName from "../../../assets/img/ShubhaMudgalName.png";
import Keyboard from "../../../assets/img/Keyboard.png"
import FacultyNameAneesh from "../../../assets/img/FacultyNameAneesh.png";
import HeadTabla from "../../../assets/img/HeadTabla.png"
import HeadofFacultyCarnaticClassical from "../../../assets/img/HeadofFacultyCarnaticClassical.png"
import HeadofFacultyDevotional from "../../../assets/img/HeadofFacultyDevotional.png"
import HeadofFacultyGhazal from "../../../assets/img/HeadofFacultyGhazal.png"
import HeadofFacultyGuitar from "../../../assets/img/HeadofFacultyGuitar.png"
import HeadofFacultyHindipopularFilmmusic from "../../../assets/img/HeadofFacultyHindipopularFilmmusic.png"
import HeadofFacultyHindustaniClassical from "../../../assets/img/HeadofFacultyHindustaniClassical.png"
import HeadofFacultyKaraokeStageSkills from "../../../assets/img/HeadofFacultyKaraokeStageSkills.png"
import HeadofFacultySouthPopularFilmMusic from "../../../assets/img/HeadofFacultySouthPopularFilmMusic.png"
import HeadofFacultyWesternVocal from "../../../assets/img/HeadofFacultyWesternVocal.png"
import { connect } from "react-redux";
import _ from "lodash";
import { saveAs } from "file-saver";
function Certificate(props) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [certificateForm] = Form.useForm();
  const [certificateDataURL, setCertificateDataURL] = useState("");
  const [courseList, setCourseList] = useState();
  const [selectedCourse, setSelectedCourse] = useState("");

  useEffect(() => {
    configjson();
  }, []);
  useEffect(() => {
    const canvas = document.createElement("canvas");
    const image = new Image();
    image.src = certificate;

    image.onload = function () {
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      setCertificateDataURL(dataURL);
    };
  }, []);
  useEffect(() => {
    const storedValues = JSON.parse(localStorage.getItem("formValues"));
    if (storedValues) {
      form.setFieldsValue(storedValues);
    }
  }, [form]);

  const storeFormValues = useCallback((values) => {
    localStorage.setItem("formValues", JSON.stringify(values));
  }, []);
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };

  const handleCourseChange = (value) => {
    console.log(value, "value==");
    setSelectedCourse(value);
  };

  const handleReset = () => {
    certificateForm.resetFields();
  };
  const generateCertificate = (values) => {
    const { name, date, course } = values;
    console.log(values,"9999999==")
    let signatureImage = "";
    let signatureNameImage = "";
    let facultyImage = "";
    if (course === "Carnatic Classical-Preparatory" || course === "Carnatic Classical-Intermediate" || course === "Carnatic Classical-Advanced") {
      signatureImage = Arunaji;
      signatureNameImage = ArunaSairamName;
      facultyImage = HeadofFacultyCarnaticClassical;
    } else if (
      course === "Tamil Film Music-Preparatory" ||
      course === "Tamil Film Music-Intermediate" ||
      course === "Tamil Film Music-Advanced" ||
      course === "Telugu Film Music-Preparatory" ||
      course === "Telugu Film Music-Intermediate" ||
      course === "Telugu Film Music-Advanced" ||
      course === "Kannada Film Music-Preparatory" ||
      course === "Kannada Film Music-Intermediate" ||
      course === "Kannada Film Music-Advanced" ||
      course === "Karaoke and Stage Skills-Telugu"  ||
      course === "Karaoke and Stage Skills-Kannada"  ||
      course === "Karaoke and Stage Skills-Malayalam"  ||
      course === "Karaoke and Stage Skills-Tamil"  ||
      course === "Malayalam Film Music-Preparatory" ||
      course === "Malayalam Film Music-Intermediate" ||
      course === "Malayalam Film Music-Advanced" 
    ) {
      signatureImage = Chithraji;
      signatureNameImage = KSCHITHRAName;
      facultyImage = HeadofFacultySouthPopularFilmMusic;
    } else if (course === "Piano/Keyboard-Preparatory" || course === "Piano/Keyboard-Intermediate" || course === "Piano/Keyboard-Advanced") {
      signatureImage = LouizBanks;
      signatureNameImage = LouizBankName;
      facultyImage = Keyboard;
    } else if (course === "Guitar-Preparatory" || course === "Guitar-Intermediate" || course === "Guitar-Advanced") {
      signatureImage = RajuSingh;
      signatureNameImage = RajuSinghName;
      facultyImage = HeadofFacultyGuitar;
    } else if (
      course === "Hindi Film Music-Preparatory" ||
      course === "Hindi Film Music-Intermediate" ||
      course === "Hindi Film Music-Advanced" 
      
    ) {
      signatureImage = SonuNigam;
      signatureNameImage = SonuNigamName;
      facultyImage = HeadofFacultyHindipopularFilmmusic;
    } else if (course === "HindustaniClassical-Preparatory" || course === "HindustaniClassical-Intermediate" || course === "HindustaniClassical-Advanced") {
      signatureImage = shubha;
      signatureNameImage = ShubhaMudgalName;
      facultyImage = HeadofFacultyHindustaniClassical;
    }
    else if (course === "Devotional-Preparatory" || course === "Devotional-Intermediate" || course === "Devotional-Advanced") {
      signatureImage = SonuNigam;
      signatureNameImage = SonuNigamName;
      facultyImage = HeadofFacultyDevotional;
    }
    else if (course === "Ghazal-Preparatory" || course === "Ghazal-Intermediate" || course === "Ghazal-Advanced") {
      signatureImage = SonuNigam;
      signatureNameImage = SonuNigamName;
      facultyImage = HeadofFacultyGhazal;
    }
    else if (course === "Western Vocal-Preparatory" || course === "Western Vocal-Intermediate" || course === "Western Vocal-Advanced") {
      signatureImage = SonuNigam;
      signatureNameImage = SonuNigamName;
      facultyImage = HeadofFacultyWesternVocal;
    }
    else if ( course === "Karaoke and Stage Skills-Hindi"){
      signatureImage = SonuNigam;
      signatureNameImage = SonuNigamName;
      facultyImage = HeadofFacultyKaraokeStageSkills
    }
    else if ( course === "Tabla-Preparatory" || course === "Tabla-Intermediate" || course === "Tabla-Advanced"){
      // signatureImage = SonuNigam;
      signatureNameImage = FacultyNameAneesh;
      facultyImage = HeadTabla
    }

    const svgCertificate = `
    <svg xmlns="http://www.w3.org/2000/svg" width="800" height="600">
      <image x="0" y="0" width="100%" height="100%" href="${certificateDataURL}" />
      <text x="50%" y="45%"  font-size="50" font-weight="100" text-anchor="middle" dominant-baseline="middle" font-family="Brush Script MT" font-style="italic" >${name}</text>
      <text x="72%" y="23%" font-weight="600" font-family="poppins">DATE :</text>
      <text x="79%" y="23%" font-size="16" font-weight="600" font-family="poppins">${date.format("YYYY-MM-DD")}</text>
      <text x="50%" y="60%" font-size="16" font-weight="600" text-anchor="middle" dominant-baseline="middle" font-family="poppins">${course}</text>
      <image x="72%" y="65%" width="218" height="46" href="${signatureImage}" />
      <image x="80%" y="70%" width="90" height="46" href="${signatureNameImage}" />
      <image x="80%" y="74%" width="90" height="46" href="${facultyImage}" />
      </svg>
  `;

    return svgCertificate;
  };

  const downloadCertificate = (svgCertificate, name) => {
    const fileName = `${name.replace(/\s/g, "_")}_certificate__${Date.now()}.svg`;
    const svgBlob = new Blob([svgCertificate], { type: "image/svg+xml" });
    const url = URL.createObjectURL(svgBlob);
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };

  const onFinish = (values) => {
    const { name } = values;
    const svgCertificate = generateCertificate(values);
    storeFormValues(values);
    downloadCertificate(svgCertificate, name);
  };

  return (
    <div className="" style={{ marginTop: "-25px" }}>
      <div
        style={{
          backgroundImage: `url(${certificateDataURL})`,
          backgroundSize: "cover",
          width: "1043.91px",
          height: "630px",
          position: "relative",
        }}
      >
        <Form 
        onFinish={onFinish}
        form={certificateForm}
      autoComplete="off"
        >
          <Form.Item
            name="name"
            label=""
            style={{
              width: "411px",
              position: "absolute",
              top: "41%",
              left: "30%",
            }}
            className="namefield"
          >
            <Input placeholder="Enter the Name" style={{ fontSize: "32px", fontFamily: "Brush Script MT", fontStyle:"italic", width:"100%", textAlign:"center", border:"none" }}/>
          </Form.Item>
          <Form.Item
            name="date"
            label="DATE"
            style={{
              position: "absolute",
              top: "13%",
              left: "70%",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            <DatePicker className="poppins-font"/>
          </Form.Item>
          <Form.Item
            name="course"
            label=""
            style={{
              position: "absolute",
              top: "60%",
              left: "42%",
              width: "22%",

            }}
          >
            <Select placeholder="Select the Course" onChange={handleCourseChange} className="poppins-font">
            
              
                            {_.map(_.get(courseList, "courses"), (course, eachParent) => {
    return _.map(_.get(course, "tags"), (tag, each) => {
      const optionValue = `${course.name}-${tag.name}`; 
      return (
        <Option value={optionValue} key={eachParent + "-" + each}>
          {optionValue}
        </Option>
      );
    });
  })}
            </Select>
          </Form.Item>
          <Form.Item
            name="sign"
            label=""
            style={{
              width: "218px",
              position: "absolute",
              top: "68%",
              left: "78%",
            }}
          >
            {selectedCourse === "Carnatic Classical-Preparatory" || selectedCourse === "Carnatic Classical-Intermediate" || selectedCourse === "Carnatic Classical-Advanced" ?
 (
              <img src={Arunaji} alt="Signature" />
            ) : null}

            {selectedCourse === "Tamil Film Music-Preparatory" ||
selectedCourse === "Tamil Film Music-Intermediate" ||
selectedCourse === "Tamil Film Music-Advanced" ||
selectedCourse === "Telugu Film Music-Preparatory" ||
selectedCourse === "Telugu Film Music-Intermediate" ||
selectedCourse === "Telugu Film Music-Advanced" ||
selectedCourse === "Kannada Film Music-Preparatory" ||
selectedCourse === "Kannada Film Music-Intermediate" ||
selectedCourse === "Kannada Film Music-Advanced" ||
selectedCourse === "Karaoke and Stage Skills-Telugu"  ||
selectedCourse === "Karaoke and Stage Skills-Kannada"  ||
selectedCourse === "Karaoke and Stage Skills-Malayalam"  ||
selectedCourse === "Karaoke and Stage Skills-Tamil"  ||
selectedCourse === "Malayalam Film Music-Preparatory" ||
selectedCourse === "Malayalam Film Music-Intermediate" ||
selectedCourse === "Malayalam Film Music-Advanced"  ? (
              <img src={Chithraji} alt="Signature" />
            ) : null}

            {selectedCourse === "Piano/Keyboard-Preparatory" || selectedCourse === "Piano/Keyboard-Intermediate" || selectedCourse === "Piano/Keyboard-Advanced" ? (
              <img src={LouizBanks} alt="Signature" />
            ) : null}
            
            {
            selectedCourse === "Guitar-Preparatory" || selectedCourse === "Guitar-Intermediate" || selectedCourse === "Guitar-Advanced" ?(
              <img src={RajuSingh} alt="Signature" />
            ) : null}
            {selectedCourse === "Hindi Film Music-Preparatory" ||
      selectedCourse === "Hindi Film Music-Intermediate" ||
      selectedCourse === "Hindi Film Music-Advanced" ||
      selectedCourse === "Devotional-Preparatory" || selectedCourse === "Devotional-Intermediate" || selectedCourse === "Devotional-Advanced" ||
      selectedCourse === "Western Vocal-Preparatory" || selectedCourse === "Western Vocal-Intermediate" || selectedCourse === "Western Vocal-Advanced" ||
      selectedCourse === "Ghazal-Preparatory" || selectedCourse === "Ghazal-Intermediate" || selectedCourse === "Karaoke and Stage Skills-Hindi"  || selectedCourse === "Ghazal-Advanced" ? (
              <img src={SonuNigam} alt="Signature" />
            ) : null}
            {selectedCourse === "HindustaniClassical-Preparatory" || selectedCourse === "HindustaniClassical-Intermediate" || selectedCourse === "HindustaniClassical-Advanced" ?(
              <img src={shubha} alt="Signature" />
            ) : null}
            {selectedCourse === "Tabla-Preparatory" || selectedCourse === "Tabla-Intermediate" || selectedCourse === "Tabla-Advanced" ? (
              <img/>
            ): null}
          </Form.Item>
          <Form.Item
            name="signName"
            label=""
            style={{ position: "absolute", top: "78%", left: "80%" }}
          >
          
  {selectedCourse === "Carnatic Classical-Preparatory" || selectedCourse === "Carnatic Classical-Intermediate" || selectedCourse === "Carnatic Classical-Advanced" ?
 (
  <img src={ArunaSairamName} alt="Signature" />
            ) : null}
            {selectedCourse === "Tamil Film Music-Preparatory" ||
selectedCourse === "Tamil Film Music-Intermediate" ||
selectedCourse === "Tamil Film Music-Advanced" ||
selectedCourse === "Telugu Film Music-Preparatory" ||
selectedCourse === "Telugu Film Music-Intermediate" ||
selectedCourse === "Telugu Film Music-Advanced" ||
selectedCourse === "Kannada Film Music-Preparatory" ||
selectedCourse === "Kannada Film Music-Intermediate" ||
selectedCourse === "Kannada Film Music-Advanced" ||
selectedCourse === "Karaoke and Stage Skills-Telugu"  ||
selectedCourse === "Karaoke and Stage Skills-Kannada"  ||
selectedCourse === "Karaoke and Stage Skills-Malayalam"  ||
selectedCourse === "Karaoke and Stage Skills-Tamil"  ||
selectedCourse === "Malayalam Film Music-Preparatory" ||
selectedCourse === "Malayalam Film Music-Intermediate" ||
selectedCourse === "Malayalam Film Music-Advanced"  ? (
              <img src={KSCHITHRAName} alt="Signature" />
            ) : null}
            {selectedCourse === "Piano/Keyboard-Preparatory" || selectedCourse === "Piano/Keyboard-Intermediate" || selectedCourse === "Piano/Keyboard-Advanced" ?(
              <img src={LouizBankName} alt="Signature" />
            ) : null}
            {
            selectedCourse === "Guitar-Preparatory" || selectedCourse === "Guitar-Intermediate" || selectedCourse === "Guitar-Advanced" ?(
              <img src={RajuSinghName} alt="Signature" />
            ): null}
            {selectedCourse === "Hindi Film Music-Preparatory" ||
      selectedCourse === "Hindi Film Music-Intermediate" ||
      selectedCourse === "Hindi Film Music-Advanced" ||
      selectedCourse === "Devotional-Preparatory" || selectedCourse === "Devotional-Intermediate" || selectedCourse === "Devotional-Advanced" ||
      selectedCourse === "Western Vocal-Preparatory" || selectedCourse === "Western Vocal-Intermediate" || selectedCourse === "Western Vocal-Advanced" ||
      selectedCourse === "Ghazal-Preparatory" || selectedCourse === "Ghazal-Intermediate" || selectedCourse === "Karaoke and Stage Skills-Hindi"  || selectedCourse === "Ghazal-Advanced" ? (
              <img src={SonuNigamName} alt="Signature" />
            ) : null}
            {selectedCourse === "HindustaniClassical-Preparatory" || selectedCourse === "HindustaniClassical-Intermediate" || selectedCourse === "HindustaniClassical-Advanced" ? (
              <img src={ShubhaMudgalName} alt="Signature" />
            ) : null}
             {selectedCourse === "Tabla-Preparatory" || selectedCourse === "Tabla-Intermediate" || selectedCourse === "Tabla-Advanced" ? (
             <img src={FacultyNameAneesh} alt="Signature" />
            ): null}
          </Form.Item>
          <Form.Item
           name="facultyName"
           label=""
           style={{ position: "absolute", top: "83%", left: "80%",fontSize:"23px" }}
          >
          
              {selectedCourse === "Carnatic Classical-Preparatory" || selectedCourse === "Carnatic Classical-Intermediate" || selectedCourse === "Carnatic Classical-Advanced" ?
 (
  <img src={HeadofFacultyCarnaticClassical} alt="faculty" />
            ) : null}
             {selectedCourse === "Tamil Film Music-Preparatory" ||
selectedCourse === "Tamil Film Music-Intermediate" ||
selectedCourse === "Tamil Film Music-Advanced" ||
selectedCourse === "Telugu Film Music-Preparatory" ||
selectedCourse === "Telugu Film Music-Intermediate" ||
selectedCourse === "Telugu Film Music-Advanced" ||
selectedCourse === "Kannada Film Music-Preparatory" ||
selectedCourse === "Kannada Film Music-Intermediate" ||
selectedCourse === "Kannada Film Music-Advanced" ||
selectedCourse === "Karaoke and Stage Skills-Telugu"  ||
selectedCourse === "Karaoke and Stage Skills-Kannada"  ||
selectedCourse === "Karaoke and Stage Skills-Malayalam"  ||
selectedCourse === "Karaoke and Stage Skills-Tamil"  ||
selectedCourse === "Malayalam Film Music-Preparatory" ||
selectedCourse === "Malayalam Film Music-Intermediate" ||
selectedCourse === "Malayalam Film Music-Advanced"  ? (
              <img src={HeadofFacultySouthPopularFilmMusic} alt="Signature" style={{marginLeft:"-57px"}}/>
            ) : null}
            {selectedCourse === "Piano/Keyboard-Preparatory" || selectedCourse === "Piano/Keyboard-Intermediate" || selectedCourse === "Piano/Keyboard-Advanced" ? (
              <img src={Keyboard} alt="Signature" />
            ) : null}
            {
            selectedCourse === "Guitar-Preparatory" || selectedCourse === "Guitar-Intermediate" || selectedCourse === "Guitar-Advanced" ?(
              <img src={HeadofFacultyGuitar} alt="Signature" />
            ) : null}
          
               {selectedCourse === "Hindi Film Music-Preparatory" ||
      selectedCourse === "Hindi Film Music-Intermediate" ||
      selectedCourse === "Hindi Film Music-Advanced" ? (
              <img src={HeadofFacultyHindipopularFilmmusic} alt="Signature" style={{marginLeft:"-57px"}}/>
            ) : null}
               {selectedCourse === "Devotional-Preparatory" || selectedCourse === "Devotional-Intermediate" || selectedCourse === "Devotional-Advanced" ?(
              <img src={HeadofFacultyDevotional} alt="Signature" />
            ) : null}
             {selectedCourse === "Ghazal-Preparatory" || selectedCourse === "Ghazal-Intermediate" || selectedCourse === "Ghazal-Advanced" ? (
              <img src={HeadofFacultyGhazal} alt="Signature" />
            ) : null}
            {selectedCourse === "HindustaniClassical-Preparatory" || selectedCourse === "HindustaniClassical-Intermediate" || selectedCourse === "HindustaniClassical-Advanced" ? (
              <img src={HeadofFacultyHindustaniClassical} alt="Signature" />
            ) : null}
              {selectedCourse === "Western Vocal-Preparatory" || selectedCourse === "Western Vocal-Intermediate" || selectedCourse === "Western Vocal-Advanced" ? (
              <img src={HeadofFacultyWesternVocal} alt="Signature" />
            ) : null}
               {selectedCourse === "Karaoke and Stage Skills-Hindi"  && (
              <img src={HeadofFacultyKaraokeStageSkills} alt="Signature" />
            )}
               {selectedCourse === "Tabla-Preparatory" || selectedCourse === "Tabla-Intermediate" ||selectedCourse ===  "Tabla-Advanced" ? (
             <img src={HeadTabla} alt="Signature" />
            ): null}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                position: "absolute",
                marginTop: "59%",
                borderRadius: "30px",
              }}
            >
              Generate Certificate
            </Button>
            <Button
            type="primary"
            onClick={handleReset} 
            style={{
              position: "absolute",
              marginTop: "59%",
              marginLeft: "16%",
              borderRadius: "30px",
            }}
          >
            Reset
          </Button>
          </Form.Item>

        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Certificate);