import dayjs from 'dayjs';

const formatDate = (date, includeTime = false, format = 'DD-MMM-YYYY h:mmA') => {
  if (!includeTime) {
    // Default format for date only
    format = 'DD-MMM-YYYY';
  }
  return dayjs(date).format(format);
};

export const formatDateTime = (date, time, includeTime = false) => {
  if (!includeTime) {
    return dayjs(date).format('DD-MMM-YYYY');
  }
  return dayjs(`${date} ${time}`).format('DD-MMM-YYYY h:mmA');
};

export default formatDate;
