import React from 'react';
import appPages from './app-pages';

export default [
    {
        key: "profile",
        element: <appPages.profilePage />,
        path: '/profile',
        exact: true,
        header:true
    },
    {
        key: "teachers",
        element: <appPages.teachersPage />,
        path: '/teachers',
        exact: true,
        header:true
    },
    {
        key: "teacherview",
        element: <appPages.teacherViewPage />,
        path: '/teacher-view',
        exact: true,
        header:true
    },
    {
        key: "teacherProfile",
        element: <appPages.teacherProfilePage />,
        path: '/teacher-profile',
        exact: true,
        header:true
    },
    {
        key: "calendar",
        element: <appPages.teacherCalendarPage />,
        path: '/calendar',
        exact: true,
        header:true
    },
    {
        key: "students",
        element: <appPages.studentsPage />,
        path: '/students',
        exact: true,
        header:true
    },
    {
        key: "studentProfile",
        element: <appPages.StudentProfilePage />,
        path: '/student-profile',
        exact: true,
        header:true
    },
    {
        key: "studentcalendar",
        element: <appPages.studentCalendarPage />,
        path: '/student-calendar',
        exact: true,
        header:true
    },
    {
        key: "studentview",
        element: <appPages.studentViewPage />,
        path: '/student-view',
        exact: true,
        header:true
    },
    {
        key: "dateView",
        element: <appPages.DateView/>,
        path: '/dateView',
        exact: true,
        header: true
      },
    {
        key: "addteacherProfile",
        element: <appPages.AddTeacherProfilePage />,
        path: "/add-teacher",
        exact: true,
        header: true,
      },
      {
        key: "addstudentProfile",
        element: <appPages.AddStudentProfilePage />,
        path: "/add-student",
        exact: true,
        header: true,
      },
      
      
      {
        key: "teacherSlotSearch",
        element: <appPages.TeacherSlotSearch />,
        path: "/teacher-slot-search",
        exact: true,
        header: true,
       },
       {
        key: "WebinarMasterClass",
        element: <appPages.WebinarMasterClass />,
        path: "/Webinar-masterclass",
        exact: true,
        header: true,
       },
       {
        key: "Referral",
        element: <appPages.Referral />,
        path: "/Referral",
        exact: true,
        header: true,
       },
       {
        key: "Refund",
        element: <appPages.Refund />,
        path: "/Refund",
        exact: true,
        header: true,
       },
      {
        key: "reports",
        element: <appPages.reportPage />,
        path: '/reports',
        exact: true,
        header:true
    },
    {
        key: "AcademicExpertReports",
        element: <appPages.AcademicExpertReports />,
        path: '/AcademicExpertReports',
        exact: true,
        header:true
    },
    {
        key: "CategoryWiseAeSlots",
        element: <appPages.CategoryWiseAeSlots />,
        path: '/CategoryWiseAeSlots',
        exact: true,
        header:true
    },
    {
        key: "AeConversion",
        element: <appPages.AeConversion />,
        path: '/AeConversion',
        exact: true,
        header:true
    },
    {
        key: "PaidSlots",
        element: <appPages.PaidSlots />,
        path: '/PaidSlots',
        exact: true,
        header:true
    },
    {
        key: "TeacherForecast",
        element: <appPages.TeacherForecast />,
        path: '/TeacherForecast',
        exact: true,
        header:true
    },
    {
        key: "PayoutPolicy",
        element: <appPages.PayoutPolicy />,
        path: '/PayoutPolicy',
        exact: true,
        header:true
    },
    {
        key: "Restart",
        element: <appPages.Restart />,
        path: '/Restart',
        exact: true,
        header:true
        
    },
    {
        key: "Certificate",
        element: <appPages.Certificate />,
        path: '/Certificate',
        exact: true,
        header:true
    },
    {
        key: "weeklyReport",
        element: <appPages.TeacherPayout />,
        path: '/teacherPayout',
        exact: true,
        header:true
    },
    {
        key: "teacherPayout",
        element: <appPages.WeeklyReport />,
        path: '/weeklyReport',
        exact: true,
        header:true
    },
    {
        key: "addonreports",
        element: <appPages.addonReportPage />,
        path: '/addon-reports',
        exact: true,
        header:true
    },
    {
        key: "teachercompensation",
        element: <appPages.teacherCompensationReportPage />,
        path: '/teacher-compensation',
        exact: true,
        header:true
    },
    {
        key: "PaidSlots",
        element: <appPages.PaidSlots />,
        path: '/PaidSlots',
        exact: true,
        header:true
    },

    {
        key: "bulkEmails",
        element: <appPages.bulkemailsPage />,
        path: '/emails',
        exact: true,
        header:true
    },
    {
        key: "Status",
        element: <appPages.Status />,
        path: "/Status",
        exact: true,
        header: true,
       },
       {
        key: "TeacherLeave",
        element: <appPages.TeacherLeave />,
        path: "/TeacherLeave",
        exact: true,
        header: true,
       },
       {
        key: "FindTeacherStudent",
        element: <appPages.FindTeacherStudent />,
        path: "/FindTeacherStudent",
        exact: true,
        header: true,
        
       },
       {
        key: "TeacherQa",
        element: <appPages.TeacherQa />,
        path: "/TeacherQa",
        exact: true,
        header: true,
        
       },
       {
        key: "QaReports",
        element: <appPages.QaReports />,
        path: "/QaReports",
        exact: true,
        header: true,
        
       },
       {
        key: "AssignList",
        element: <appPages.AssignList />,
        path: "/AssignList",
        exact: true,
        header: true,
     

       },
    {
        key: "chat",
        element: <appPages.Chat />,
        path: '/chat',
        exact: true,
        header: true
    },
    {
        key: "BatchCreation",
       element: <appPages.BatchCreation />,
       path: '/BatchCreation',
       exact: true,
       header:true
       
   },
   {
    key: "BatchCreationOffline",
   element: <appPages.BatchCreationOffline />,
   path: '/BatchCreationOffline',
   exact: true,
   header:true
   
},
{
    key: "AahaMoment",
   element: <appPages.AahaMoment />,
   path: '/AahaMoment',
   exact: true,
   header:true
   
},
   {
    key: "batchView",
    element: <appPages.create/>,
    path: '/batchView',
    exact: true,
    header: true
  },
  {
    key: "RegForm",
    element: <appPages.RegForm/>,
    path: '/RegForm',
    exact: true,
    header: true
  },
  {
    key: "batchDetailView",
    element: <appPages.BatchDetailView/>,
    path: '/batchDetailView',
    exact: true,
    header: true
  },
  {
    key: "CreateOffline",
    element: <appPages.CreateOffline/>,
    path: '/CreateOffline',
    exact: true,
    header: true
  },
  {
    key: "offlineStudents",
    element: <appPages.studentsPage isOffline="true" />,
    path: '/offlineStudents',
    exact: true,
    header:true
},
{
    key: "DemoOps",
    element: <appPages.DemoOps/>,
    path: '/DemoOps',
    exact: true,
    header:true
},
{
    key: "DetailViewOps",
   element: <appPages.DetailViewOps />,
   path: '/DetailViewOps',
   exact: true,
   header:true
   
},
{
    key: "AuditTrail",
   element: <appPages.AuditTrail />,
   path: '/AuditTrail',
   exact: true,
   header:true
},


{
    key: "teacherChange",
   element: <appPages.teacherChange />,
   path: '/teacherChange',
   exact: true,
   header:true
   
},
{
    key: "DemopsShift",
    element: <appPages.DemopsShift />,
    path: "/DemopsShift",
    exact: true,
    header: true,
    
   },
];
