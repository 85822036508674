import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment'
import { connect } from "react-redux";
import axios from 'axios';
import {
    Form,
    Input,
  Radio,
  Icon,
    message,
    InputNumber,
    Select,
  
    Tag,
  } from "antd";
  import * as SecureLS from "secure-ls";
import logo from '../../../assets/gif/aa_loader.gif';
import MultipleSlotsScreen from './multiple-slot-excel';
import _ from 'lodash';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Modal, Button, Image, notification, Spin, Popover } from 'antd';
import { ExclamationCircleOutlined, DiffOutlined, InfoCircleOutlined } from '@ant-design/icons';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import './style.css'
import { constant } from '../../../core_units/constant';
import StudentList from './student-list';
import { oncheckboxchange } from './student-list';
import { FaCheck, FaTimes } from 'react-icons/fa';
import mixpanel from 'mixpanel-browser';
const propTypes = {}
const { confirm } = Modal;

const CalendarPage = (props) => {
  console.log(props,"AAAAA")
  const [form] = Form.useForm();
  var ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin')
  const loginDetails = ls.get('isLogin');
  //const mixpanel = require('mixpanel-browser');
    const [teacherId] = useSearchParams();
    console.log(teacherId,"id")
    const [studentId] = useSearchParams();
    const student = useLocation();
    const [events, setEvents] = useState([]);
    const[details,setDetails]=useState([]);
    const [event, setEvent] = useState({});
    const [datefrom, setdatefrom] = useState(moment().startOf('month').format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = useState(moment(datefrom).add(1, 'M').startOf('month').format('YYYY-MM-DD'));
    const [multipleslot, setMultipleslot] = useState(false);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);
    const [modalVisible,setModalVisible] = useState(false);
    const localizer = momentLocalizer(moment);
    const [focusDate, setFocusDate] = useState();
    const [st, setst] = useState();
    const [pageloading, setpageloading] = useState(false);
    const [scheduleslot, setScheduleslot] = useState(false);
    const[viewProfile,setViewProfile] = useState([]);
    const[isRefundModalVisible,setIsRefundModalVisible] = useState(false);
    const [courseList, setCourseList] = useState();
    const [updateFlag, setUpdateFlag] = useState(false);
    const [fetchData,setFetchData] = useState([]);
    const [loading, setLoading] = useState(false);
   const [batchSlotDetails, setBatchSlotDetails] = useState([]);
   const [batchSlot, setBatchSlot] = useState([]);
   const [workingHours,setWorkingHours] = useState([]);
 const [finalFilter,setFinalFilter] = useState([]);
 const [workingDayHour,setWorkingDayHour] = useState([]);
    useEffect(() => {
       // configjson();
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) {
              setCourseList(data);
            }
          },
        });
      }, []);
      const configjson = () => {
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) {
              console.log("courselist", data);
              setCourseList(data);
              
             
            }
          },
        });
      };
    let history = useNavigate();
    let allViews = Object.keys(Views).map((k) => Views[k]);

    const ColoredDateCellWrapper = ({ children }) =>
        React.cloneElement(React.Children.only(children), {
            style: {
                backgroundColor: "#CCE8F1",
            },
        });

    /**New slot creation using calendar view */
    const handleSelect = ({ start, end }) => {
        const dur = Math.abs(new Date(start) - new Date(end));
        const hours = Math.floor(dur / 3600000);
        const mins = Math.floor(dur / 60000);
        if (moment(start) > moment() && mins >= 30 ||_.isEqual(moment(end).format('HH:mm:ss'), '23:59:59') ) {
            confirm({
                title: `Confirm selected date and time `,
                icon: <DiffOutlined />,
                content: <div>
                    {/* <span>Date :{`${moment(start).format('DD-MM-YYYY')}`}</span><br/> */}
                    <span>Date along with range: { `${moment(start).format('DD-MM-YYYY')}  ${moment(start).format('HH:mm:ss')} - ${moment(end).format('HH:mm:ss')}`}</span><br/>
                    <span style={{marginTop:"20px"}}> <input type="checkbox" id="specialSlot" style={{cursor:"pointer"}}/> Special Slot</span>
                </div>,
                onOk() {
                    const isSpecialSlot = document.getElementById("specialSlot").checked;
                    var newslot = [{
                        "teacherId": teacherId.get("id"),
                        "slotDate": moment(start).format('YYYY-MM-DD'),
                        "startTime": moment(start).format('HH:mm:ss'),
                        "EndTime":_.isEqual(moment(end).format('HH:mm:ss'), '23:59:59')?'24:00:00': moment(end).format('HH:mm:ss'),
                        "specialSlot": isSpecialSlot,
                    }]
                    props.teachersslotcreate({
                        body: newslot,
                        callback: (res, data) => {
                            if (_.isEqual(res, true)) {
                              mixpanel.track(constant.MIXPANEL_SLOT_ADD_TEACHER, {
                                user:loginDetails?.studentName,
                                teacherEmail: props.teachers.viewteacherprofile.emailId,
                                teacherMobile:props.teachers.viewteacherprofile.mobileNumber,
                                teacherName: props.teachers.viewteacherprofile?.firstName,
                                enableForPaid: props.teachers.viewteacherprofile?.enableForPaid,
                                ...newslot[0],
                                api_status:"success",
                                api_res:data
                              });
                               apiCall(datefrom, dateTo);
                                if(_.isEqual(_.get(data,'body'),"{\"TeacherSlot already exist!\":true}")) {
                                    notification.success({
                                      message: 'Slot already exist.',
                                  });  
                                  }else{
                                    notification.success({
                                      message: 'Slot added successfully.',
                                  });  
                                  }
                            } else {
                              mixpanel.track(constant.MIXPANEL_SLOT_ADD_TEACHER, {
                                user:loginDetails?.studentName,
                                teacherEmail: props.teachers.viewteacherprofile.emailId,
                                teacherMobile:props.teachers.viewteacherprofile.mobileNumber,
                                teacherName: props.teachers.viewteacherprofile?.firstName,
                                enableForPaid: props.teachers.viewteacherprofile?.enableForPaid,
                                ...newslot[0],
                                api_status:"failure",
                                api_res:data
                              });
                                notification.error({
                                    message: 'Teacher slot info already exist!',
                                });

                            }
                        }
                    })
                },
                onCancel() {
                },
            });
        }
    }
  
    // const teacherViewProfile = () => {
    //     console.log("hhhh")
    //     props.teacherData({
    //         params: {
    //             teacherId: teacherId.get("credid"),
    //         },
    //         callback: (response, data) => {
    //             console.log("res")
    //             // if (_.isEqual(response, true)) {
    //             //     setViewProfile(data);
    //             //     console.log(data,"data====")
    //             // }
    //         },
    //     });

    // };
    

    /**Component Did Mount  */
    useEffect(() => {
        handleScheduleClass(teacherId.get("id"));
    }, [])
   
    useEffect(() => {
        if(_.get(props, 'teachers.multipleSlotsCreate')){
            apiCall(datefrom, dateTo);
        }
    }, [_.get(props, 'teachers.multipleSlotsCreate')])
    /**slots list api call */
    const apiCall = (datefrom = datefrom, dateTo = dateTo) => {
        props.teachersslot({
            params: {
                "page": 0,
                "size": 2000
            },
            body: {
                teacherId: teacherId.get("id"),
                fromDate: datefrom,
                toDate: dateTo,
                "page": 0,
                "size": 2000
            },
            callback: (res, data) => {
                setpageloading(false);
                if (_.get(data, 'content')) {
                    // let arr = .uniqBy([...events, ....get(data, 'data.content', [])], function (e) {
                    //     return e.teacherSlotInfoId;
                    // });
                    setEvents(_.get(data, 'content', []))
                    
                }

            }
        })
    }
    const handleScheduleClass = (teacherId) => {
      console.log(teacherId,"8888==")
      const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/teacherWorkingHours?teacherId=${teacherId}`;
      axios
      .get(apiUrl, {
        headers: {
          Authorization: token["authorization"],
        },
      })
      .then((response) => {
        setWorkingHours(response.data)
        console.log("response==", response.data);
         
        
        })
        .catch((error) => {
          console.error("Error fetching teacher working hours:", error);
      
        });
  
    }
    /**Events list in calendar */
    const onCalendarEvents = (events,selectedDate) => {
      // const slotsForSelectedDate = events.filter(event => {
      //   return moment(event.slotDate).isSame(selectedDate, 'day');
      // });
      // console.log('Slots for selected date:', slotsForSelectedDate);
      console.log(events,"events==")
        let datas = [];
        console.log([],"!!!")
      
      
      
        _.map(events, (event, id) => {
          
            datas.push({
                id: `${_.get(event, 'teacherSlotInfoId')}`,
                key: `${_.get(event, 'teacherSlotInfoId')}`,
                title: <div className="createEventBlock" onClick={() => { setPreviewModalVisible(true); setEvent(event); setFocusDate(_.get(event, 'slotDate'));
                //      { _.get(event, 'batchId') &&
                   
                //     handleSlotClick(event);
                // } 
             
                }}>
                
              
                    <span >
                    {
  _.get(event, 'batchId') ? 'Group Slot' :
  _.get(event, 'specialSlot') === true ? 'Special Free Slot' :
  _.get(event, 'courseName') && !_.isEmpty(_.get(event, 'courseName') && _.isEqual(_.get(event, 'isBooked'), 'true')) ?
  _.get(event, 'courseName') : _.isEqual(_.get(event, 'isBooked'), 'true') ? '-' : 'Free Slot'
}

                    </span>
                    
                    <span >{_.isEqual(_.get(event, 'isAddOn'),"true") ?' -Supplementary' : null}</span>
                  
                </div>
                ,
                start: new Date(moment(
                    `${_.get(event, 'slotDate')} ${_.get(event, 'startTime')}`,
                    'YYYY-MM-DD HH-mm-ss'
                ).format()),
                end:_.isEqual(_.get(event, 'endTime'),'00:00:00')?
                new Date(moment(
                    `${_.get(event, 'slotDate')} ${"23:59:59"}`,
                    'YYYY-MM-DD HH-mm-ss'
                ).format())
                : new Date(moment(
                    `${_.get(event, 'slotDate')} ${_.get(event, 'endTime')}`,
                    'YYYY-MM-DD HH-mm-ss'
                ).format()),
                status: _.get(event, 'isBooked'),
                callStatus: _.get(event, 'classBookingStatus'),
                batchId: _.get(event, 'batchId'),
                ...event
            })
        
        
        })
        return datas
    }
    /**Multiple slots style  status*/
    const eventStyleGetter = (event, start, end, isSelected) => {
      
        let backgroundColor;
        if(event.id == "280847"){

        }
      
        if (_.get(event, 'batchId')) {
          backgroundColor = 'royalblue';
        } else {
          
          backgroundColor =
            _.isEqual(_.get(event, 'classBookingStatus'), 'completed')
              ? '#ec6363'
              : _.isEqual(_.get(event, 'classBookingStatus'), 'not_scheduled') ||
                _.isEqual(_.get(event, 'classBookingStatus'), 'scheduled') 
              ? '#ffc306'
              : _.isEqual(_.get(event, 'classBookingStatus'), 'teacherNoShow') || 
              _.isEqual(_.get(event, 'classBookingStatus'), 'PAID-NoShow') 
              ? '#c0c0c0'
              : event.specialSlot == true
              ? 'palevioletred'
              : '#68c0af';
        }
      
        var style = {
          backgroundColor: backgroundColor,
          borderRadius: '0px',
          color: _.isEqual(_.get(event, 'status'), 'true') ? '#363D3F' : '#172714',
          border: '1px solid #fff',
          display: 'block',
        };
      
        return {
          style: style
        };
      };
      

 
    
    /**Next and previous function call */
    const calenderChange = (changeDate) => {
      console.log(changeDate,"changeDate====")
        let date = moment(new Date(changeDate)).format('YYYY-MM')
        console.log(date,"date")
        let monthInDays = moment(new Date(changeDate)).daysInMonth()
        console.log(monthInDays,"monthInDays")
        let start_date = moment(`${date}-01`, "YYYY-MM-DD").format('YYYY-MM-DD')
        // let end_date = moment(`${date}-${monthInDays}`, "YYYY-MM-DD").format('YYYY-MM-DD')
        let end_date = moment(date).add(1, 'M').startOf('month').format('YYYY-MM-DD')
        setdatefrom(start_date);
        setDateTo(end_date);
        apiCall(start_date, end_date)
        console.log((start_date, end_date),"apicall")
    }
    /**Free slots delete */
    const onslotdelete = (event) => {
        confirm({
            title: 'Do you Want to do this action?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                setpageloading(true);
                props.teachersslotdelete({
                    params: {
                        slotid: _.get(event, 'teacherSlotInfoId')
                    },
                    callback: (res, data) => {
                        // setpageloading(false);
                        setPreviewModalVisible(false)
                        if (_.isEqual(res, true)) {
                          mixpanel.track(constant.MIXPANEL_SLOT_DELETE_TEACHER, {
                            user:loginDetails?.studentName,
                            teacherEmail: props.teachers.viewteacherprofile.emailId,
                            teacherMobile:props.teachers.viewteacherprofile.mobileNumber,
                            classTimings: event.startTime +" - "+ event.endTime,
                            teacherName: event.teacherName,
                            studentName: event.studentName,
                            classTitle: event.classTitle,
                            courseName: event.courseName,
                            slotId: event.id,
                            slotDate: event.classDate,
                            slotTeacherId: event.teacherId,
                            slotTimeZone: event.timezoneId,
                            teacherCurrentTimeInTimezone: event.teacherCurrentTimeInTimezone,
                            slotCancelCount: event.cancelCount,
                            slotClassBookingStatus: event.classBookingStatus,
                            api_status:"success",
                            api_res:data
                          });
                            apiCall(datefrom, dateTo)
                            notification.success({
                                message: 'Slot deleted successfully.',
                            });
                        } else {
                          mixpanel.track(constant.MIXPANEL_SLOT_DELETE_TEACHER, {
                            user:loginDetails?.studentName,
                            teacherEmail: props.teachers.viewteacherprofile.emailId,
                            teacherMobile:props.teachers.viewteacherprofile.mobileNumber,
                            classTimings: event.startTime +" - "+ event.endTime,
                            teacherName: event.teacherName,
                            studentName: event.studentName,
                            classTitle: event.classTitle,
                            courseName: event.courseName,
                            slotId: event.id,
                            slotDate: event.classDate,
                            slotTeacherId: event.teacherId,
                            slotTimeZone: event.timezoneId,
                            teacherCurrentTimeInTimezone: event.teacherCurrentTimeInTimezone,
                            slotCancelCount: event.cancelCount,
                            slotClassBookingStatus: event.classBookingStatus,
                            api_status:"failure",
                            api_res:data
                          });
                            notification.error({
                                message: 'Something went wrong',
                            });

                        }
                    }
                })
            },
            onCancel() {
            },
        });



    }
    const handleSlotClick = async (event) => {
        setpageloading(true);
        const teacherSlotInfoId = _.get(event, 'teacherSlotInfoId');
        const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/course/slot/students?teacherSlotInfoId=${teacherSlotInfoId}`;
        console.log("API URL:", apiUrl);
        return axios.get(apiUrl, { headers: { Authorization: token["authorization"] } })
            .then((response) => {
                setpageloading(false);
                setBatchSlot(response.data)
                setBatchSlotDetails(response.data)
                console.log('API Response:', response.data);
            })
            .catch((error) => {
                notification.error({
                    message: 'Something went wrong',
                });
                console.error('API Error:', error);
            });
    };
//addon class API 
const isAddOnClassCompleted = (event) => {
    let reqParams = {
        courseclass_id: _.get(event, 'courseClassId'),
        teacher_id: _.get(event, 'teacherId'),
        studentClassId: _.get(event, 'studentClass'),
        student_id: _.get(event, 'studentId'),
        consent_reason_id: 1,

    }
    props.isAddOnClass({
        body: reqParams,
        callback: (res, data) => {
            setpageloading(false);
            
        }

    })
}
// For complete class AddOn 
const onAddOnClassBooked = (event, st) => {
    let reqParams = {
        csid: _.get(event, 'classScheduleId'),
        tid: teacherId.get("id"),
        st: st,
    }
    if (_.isEqual(st, 'class_cancelled')) {
        reqParams.cancelst = "Last moment emergency"
    }
    confirm({
        title: 'Do you want to do Addon class',
        icon: <ExclamationCircleOutlined />,
        // content: 'Some descriptions',
        onOk() {
            setpageloading(true);

            props.completeclass({
                body: reqParams,
                callback: (res, data) => {
                    setpageloading(false);
                    setPreviewModalVisible(false)
                    isAddOnClassCompleted(event);  
                    if (_.isEqual(res, true)) {
                        apiCall(datefrom, dateTo)
                        notification.success({
                            message: 'Slot Status Changed successfully.',
                        });
                    } else {
                        notification.error({
                            message: 'Something went wrong',
                        });

                    }
                
        }
                
    })


        },
        onCancel() {
            props.completeclass({
                body: reqParams,
                callback: (res, data) => {
                    setpageloading(false);
                    setPreviewModalVisible(false)
                   
                    if (_.isEqual(res, true)) {
                        apiCall(datefrom, dateTo)
                        notification.success({
                            message: 'Slot Status Changed successfully.',
                        });
                    } else {
                        notification.error({
                            message: 'Something went wrong',
                        });

                    }
                
        }
                
    })
    
    
        },
    });


}
const onAddOnClassBookedNoShow = (event, st) => {
  let reqParams = {
    csid: _.get(event, 'classScheduleId'),
    tid: teacherId.get("id"),
    markForPenalty:true
}
if (_.isEqual(st, 'class_cancelled')) {
    reqParams.cancelst = "Last moment emergency"
}
confirm({
    title: 'Do you want to do mark for penalty',
    icon: <ExclamationCircleOutlined />,
    // content: 'Some descriptions',
    onOk() {
        setpageloading(true);
        mixpanel.track(constant.MIXPANEL_MARK_FOR_PENALTY, {
          user:loginDetails?.studentName,
          userName:token?.username,
          userId:token?.uId,
          reqParams: reqParams,
          slotClassBookingStatus: event.classBookingStatus,
          courseName: _.get(event, "courseName"),
          studentName: _.get(event, "studentName"),
          teacherName: _.get(event, "teacherName"),
          teacherId: _.get(event, "teacherId"),
          classTitle:  _.get(event, "classTitle"),
          studentId: _.get(event, "studentId"),
          slotId: _.get(event, "classScheduleId"),
          slotdate: _.get(event, "slotDate"),
          classTimings: event.startTime +" - "+ event.endTime,
          
        });
        props.completeclassforpenalty({
            body:reqParams,
          
            callback: (res, data) => {
                setpageloading(false);
                setPreviewModalVisible(false)
                
                if (_.isEqual(res, true)) {
                
                    apiCall(datefrom, dateTo)
                    notification.success({
                        message: 'Slot Status Changed successfully.',
                    });
                } else {
                    notification.error({
                        message: 'Something went wrong',
                    });

                }
            
    }
            
})


    },
    onCancel() {



    },
});
}
const classCancelFetch = (event) => {
    props.cancelClassFetch({
        params:{
            studentId: _.get(event, 'studentId'),
        },
            callback: (res, data) => {
               setFetchData(data);
               console.log(data,"data391==")
               
                if (_.isEqual(res, true)) {
                  
                    apiCall(datefrom, dateTo)
                    notification.success({
                        message: '',
                    });
                } else {
                    notification.error({
                        message: 'Something went wrong',
                    });

                }
            
    }
        
    })
}

   
  const classStatus = (event,st) => {
    let reqParams = {
      slotdate: _.get(event, "slotDate"),
      studentClassId: _.get(event, "classScheduleId"),
      status: st
    };
confirm({
  title: 'Do you want to do this action?',
  icon: <ExclamationCircleOutlined />,
content: 

<div>
  {st == "class_noshow" &&
 <div>
  <select onChange={(e) => { reqParams.status = e.target.value }}>
  <option value="teacherNoShow">Teacher Paid Noshow</option>
  <option value="PAID-NoShow">Student Paid Noshow</option>
</select>

  </div>
}
</div>,
  onOk() {
      setpageloading(true);
      props.classNoShow({
        body:reqParams,
        callback: (res, data) => {
          setpageloading(false);
          setPreviewModalVisible(false);
           if (_.isEqual(res, true)) {
            let mixpanelEventName;
            if (st === 'class_cancelled') {
              mixpanelEventName = constant.MIXPANEL_CANCEL_CLASS;
            } else if (st === 'class_completed') {
              mixpanelEventName = constant.MIXPANEL_SLOT_COMPLETE;
            }
            mixpanel.track(mixpanelEventName, {
              user:loginDetails?.studentName,
              slotId: _.get(event, "classScheduleId"),
              slotdate: _.get(event, "slotDate"),
              classTimings: event.startTime +" - "+ event.endTime,
              userName:token?.username,
                userId:token?.uId,
              api_status:"success",
              status: st,
              slotClassBookingStatus: event.classBookingStatus,
              courseName: _.get(event, "courseName"),
              studentName: _.get(event, "studentName"),
              teacherName: _.get(event, "teacherName"),
              teacherId: _.get(event, "teacherId"),
              classTitle:  _.get(event, "classTitle"),
              studentId: _.get(event, "studentId"),
            });
              apiCall(datefrom, dateTo)
              notification.success({
                  message: 'Slot marked as successfully',
              });
          } else {
              notification.error({
                  message: 'Something went wrong',
              });
      
          }
      }
      })
  },
  onCancel() {
  },
});






  }
    const onbookedslotdelete = (event, st) => {
        let selectedReason = '';
        let reqParams = {
          csid: _.get(event, 'classScheduleId'),
          tid: teacherId.get("id"),
          st: st,
          cancellationReasonByStudent: '',
        };
      
        if (_.isEqual(st, 'class_cancelled')) {
          reqParams.cancelst = "Last moment emergency";
        }
      
        let errorSpanRef = null;
        setpageloading(true);
        props.cancelClassFetch({
          params: {
            studentId: _.get(event, 'studentId'),
          },
          callback: (res, data) => {
            setpageloading(false);
            setFetchData(data);
            
            // Check if data is empty, if not, show the first modal
            if (data.length === 0) {
              showSelectReasonsModal();
            } else {
              console.log(data, "cancelclass==");
      
              const tableData = data.map((item, index) => (
                <tr key={index}>
                  <td style={{ paddingLeft: '15px' }}>{item.slotDate}</td>
                  <td>{item.startTime}</td>
                  <td>{item.cancelReason}</td>
                  <td>{item.cancelBy}</td>
                </tr>
              ));
      
              confirm({
                title: 'Previous Cancel Class',
                content: (
                  <div className='cancelReason'>
                    <table style={{ width: '103%' }}>
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: "15px" }}>Date</th>
                          <th>Time</th>
                          <th>Cancel Reason</th>
                          <th>CancelBy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData}
                      </tbody>
                    </table>
                  </div>
                ),
                width: 800,
                onOk() {
                  showSelectReasonsModal();
                },
                onCancel() {},
              });
            }
          },
        });
      
        // Function to show the "Select reasons for cancel class" modal
        function showSelectReasonsModal() {
          confirm({
            title: 'Select reasons for cancel class',
            content: (
              <div>
                {console.log(reqParams.cancellationReasonByStudent, "reqParams.cancellationReasonByStudente")}
                <Radio.Group
                  onChange={(e) => {
                    reqParams.cancellationReasonByStudent = e.target.value;
                    errorSpanRef.textContent = '';
                  }}
                >
                  {courseList && courseList.classCancellationReasonsForAdmin.map((reason) => (
                    <Radio key={reason.id} value={reason.reason}>
                      {reason.reason}
                    </Radio>
                  ))}
                </Radio.Group>
                <span ref={(span) => (errorSpanRef = span)} style={{ color: 'red' }}></span>
              </div>
            ),
            onOk() {
               
                  
                mixpanel.track(constant.MIXPANEL_CANCEL_CLASS_TEACHER, {
                    user:loginDetails?.studentName,
                    teacherName: event.teacherName,
                    studentName: event.studentName,
                    reason: reqParams.cancellationReasonByStudent, 
                    teacherEmail: props.teachers.viewteacherprofile.emailId,
                    teacherMobile:props.teachers.viewteacherprofile.mobileNumber,
                    classTitle: event.classTitle,
                    classTimings: `${event.startTime} - ${event.endTime}`,
                    courseName: event.courseName,
                    cancelledVia: "Teacher Side",
                    slotId: event.id,
                    slotDate: event.slotDate,
                    classScheduleId: event.classScheduleId
                  });

              if (!reqParams.cancellationReasonByStudent) {
                errorSpanRef.textContent = 'Please select an option';
                return Promise.reject();
              }
              setpageloading(true);
      
              props.completeclass({
                body: reqParams,
                callback: (res, data) => {
                  setpageloading(false);
                  setPreviewModalVisible(false);
                  if (_.isEqual(res, true)) {
                    apiCall(datefrom, dateTo);
                    notification.success({
                      message: 'Slot Status Changed successfully.',
                    });
                  } else {
                    notification.error({
                      message: 'Something went wrong',
                    });
                  }
                }
              });
            },
            onCancel() {
            //   onbookedslotdelete(event, st);
            },
          });
        }
      };
      
      const markJoinNotJoinClass = (event) => {
       
        confirm({
            title: 'Do you Want to do this action?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
              mixpanel.track(constant.MIXPANEL_MARK_AS_JOIN, {
                user:loginDetails?.studentName,
                userName:token?.username,
                userId:token?.uId,
                classscheduleId: _.get(event, 'classScheduleId'),
                        useraction: "joined",
                slotClassBookingStatus: event.classBookingStatus,
                courseName: _.get(event, "courseName"),
                studentName: _.get(event, "studentName"),
                teacherName: _.get(event, "teacherName"),
                teacherId: _.get(event, "teacherId"),
                classTitle:  _.get(event, "classTitle"),
                studentId: _.get(event, "studentId"),
                slotId: _.get(event, "classScheduleId"),
                slotdate: _.get(event, "slotDate"),
                classTimings: event.startTime +" - "+ event.endTime,
                
              });
                setpageloading(true);
                props.markJoinClass({
                    params:{
                        classscheduleId: _.get(event, 'classScheduleId'),
                        useraction: "joined",
                    },
                    callback: (res, data) => {
                        setpageloading(false);
                        setPreviewModalVisible(false);
                         if (_.isEqual(res, true)) {
                            apiCall(datefrom, dateTo)
                            notification.success({
                                message: 'Slot marked as successfully',
                            });
                        } else {
                            notification.error({
                                message: 'Something went wrong',
                            });

                        }
                    }

                })
            },
            onCancel() {
            },
        });
    }
       
    const onreschedule = (event) => {
        confirm({
            title: 'Do you Want to do this action?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setpageloading(true);
                props.rescheduleclass({
                    params: {
                        classScheduleId: teacherId.get("Schid"),
                        pushNotification:"true"
                    },
                    body: {
                        "teacherSlotInfoId": _.get(event, 'teacherSlotInfoId'),
                        "slotDate": moment(_.get(event, 'slotDate')).format('DD/MM/YYYY'),
                        "slotStartTime": moment(_.get(event, 'startTime'), 'HH:mm').format('HH:mm'),
                        "slotEndTime": moment(_.get(event, 'endTime'), 'HH:mm').format('HH:mm'),
                        "courseId":student?.state?.selectedCourse?.courseId
                    },
                    callback: (res, data) => {
                        setpageloading(false);
                        setPreviewModalVisible(false)
                        if (_.isEqual(res, true)) {
                            apiCall(datefrom, dateTo)
                            notification.success({
                                message: 'Slot rescheduled successfully.',
                            });
                            //    errorAndSuccessMessage('Slot added successfully','success')    
                        } else {
                            notification.error({
                                message: 'No Slots available',
                            });
                            // errorAndSuccessMessage('Something went wrong','error')    

                        }
                    }
                })
            },
            onCancel() {
            },
        });

    }
    const onstudentschedule = () => {
      const isWorkingHoursExceeded = workingDayHour == finalFilter || finalFilter > workingDayHour;
        let reqbody = {
            "studentId":_.get(student,'state.studentId'),
            "courseId": _.get(student,'state.studentCourseId'),
            "teacherId":_.get(event,'teacherId'),
            "selectedSlots": [
                {            
                    "teacherSlotInfoId":_.get(event,'teacherSlotInfoId'),
                    "slotDate":moment(_.get(event,'slotDate')).format('DD/MM/YYYY'),
                    "slotStartTime": moment(_.get(event,'startTime'),"HH:mm").format('HH:mm'),
                    "slotEndTime": moment(_.get(event,'endTime'),"HH:mm").format('HH:mm')
                }
                
            ]
        }
            confirm({
                title: 'Do you Want to do this action?',
                icon: <ExclamationCircleOutlined />,
                content: <div>
                    <span>
    Date: {_.upperCase(moment(_.get(event, 'slotDate')).format('DD MMM YYYY'))}
                </span><br/>
                <span>
                Timing: {moment(_.get(event,'startTime'),"HH:mm").format('HH:mm')}-{moment(_.get(event,'endTime'),"HH:mm").format('HH:mm')}
                </span><br/>
                <span>
    Student: {_.startCase(_.get(student,'state.firstName', 'No-name'))+' '+_.startCase(_.get(student,'state.lastName',''))}
                </span><br/>
                <div style={{marginTop:"20px"}}>
                {isWorkingHoursExceeded &&
  <span style={{ color: 'red',marginTop:"20px" }}>
  <FaTimes type="exclamation-circle" className="blinking-icon" /> Teacher working Hour is exceeded, still you want to continue?
</span>
                }
                </div>
                
             
                    </div>,
                onOk() {
                  mixpanel.track(constant.MIXPANEL_CLASS_SCHEDULE, {
                    user:loginDetails?.studentName,
                Date:event.slotDate,
                Timing: `${moment(_.get(event, 'startTime'), "HH:mm").format('HH:mm')}-${moment(_.get(event, 'endTime'), "HH:mm").format('HH:mm')}`,
                Student: student?.state?.firstName,
                schedule: "Teacher working Hour is exceeded,still you want to continue?",
                studentId:_.get(student,'state.studentId'),
                courseId: _.get(student,'state.studentCourseId'),
                teacherId:_.get(event,'teacherId'),
                studentName: _.get(student,'state.firstName', 'No-name'),
                teacherId:_.get(event,'teacherId'),
                  });
                  if (workingDayHour == finalFilter || finalFilter > workingDayHour) {
                  

                
                  
                  } 

                 
                    setpageloading(true);
                    props.scheduleClass({
                        body: reqbody,
                        params:{
                            pushNotification:"true",
                            moreThanThreeClass:"true"

                        },
                        callback: (res, data) => {
                          console.log(data,"res==")
                            setpageloading(false);
                            setPreviewModalVisible(false)

                            if (_.isEqual(res, true)) {
                                apiCall(datefrom, dateTo)
                                notification.success({
                                    message: 'Class scheduled successfully.',
                                });
                            } else {
                              const errorMessage = _.get(data, 'message', 'Something went wrong');
              
                              notification.error({
                                  message: errorMessage
                                 
                              });
                          }
                        }
    
                    })
                  
                   
                },
                onCancel() {
                               },
            });
    }
    const ViewModel = () => {
        return (
            <div className={`modal-container ${_.isEqual(previewModalVisible, false) ? 'd-none' : ''}`}>
                <div className="cookiesContent" id="cookiesPopup">
                    <button className="close" onClick={() => setPreviewModalVisible(false)}>✖</button>
                    {_.isEqual(_.get(event, 'classBookingStatus'), 'completed') ||
                        _.isEqual(_.get(event, 'classBookingStatus'), 'DEMO-NoShow') ||
                        _.isEqual(_.get(event, 'classBookingStatus'), 'PAID-NoShow') ||
                        _.isEqual(_.get(event, 'classBookingStatus'), 'teacherNoShow') 
                        ?

                        (
                            <React.Fragment>
                                {!_.get(event, 'batchId') && (
                                <div>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Course title:</b> {_.startCase(_.get(event, 'courseName'))}{_.isEqual(_.get(event, 'isAddOn'),"true") ?' -Supplementary' : null}
                                    </span>
                                </div>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Class title:</b> {_.startCase(_.get(event, 'classTitle'))}
                                    </span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Description:</b> {_.get(event, 'classDescription')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Session Number:</b> {_.get(event, 'classNumber')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Student name:</b> {_.startCase(_.get(event, 'studentName'))}</span>
                                </div>
                                
                                <div className='viewmodalstatus mb-2'>
                                    <span><b>Call status:</b> {_.startCase(_.get(event, 'classBookingStatus'))}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Class Date: </b> {_.upperCase(moment(_.get(event, 'slotDate')).format('DD MMM YYYY'))}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                <span><b>Class Start-End Time: </b> {_.get(event, 'startTime')} - {_.get(event, 'endTime')}
                                        </span>
                                </div>
                                {event.classBookingStatus == "teacherNoShow" &&  (
                                  <div>
                                     <button className="accept mt-2" onClick={() => {setst('class_cancelled');classStatus(event, 'class_cancelled')}}>Cancel</button>
                                <button className="reschdeule mt-2 mx-2" onClick={() => {setst('class_completed'); classStatus(event, 'class_completed')}}>Complete</button>
                                  </div>
                                )}
                                 {event.classBookingStatus == "PAID-NoShow"  &&  (
                                  <div>
                                     <button className="accept mt-2" onClick={() => {setst('class_cancelled');classStatus(event, 'class_cancelled')}}>Cancel</button>
                                <button className="reschdeule mt-2 mx-2" onClick={() => {setst('class_completed'); classStatus(event, 'class_completed')}}>Complete</button>
                                  </div>
                                )}
                                </div>
                                )}
                            
                          
                            
                             
                            {_.get(event, 'batchId') &&
                                <div>
                                  
                                  {batchSlotDetails.length > 0 && (
                 <div>     
                            {pageloading ? (

<div style={{display:"flex", justifyContent:"center"}}>  <Spin size="large" /></div>
) : (              
      <div>
         <div className='viewmodaldescription mb-2'>
          <span><b>Batch Name:</b> {batchSlotDetails[0].batchName}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Course Name:</b> {batchSlotDetails[0].courseName}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Title:</b> {batchSlotDetails[0].classTitle}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Description:</b> {batchSlotDetails[0].classDescription}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Date:</b> {_.upperCase(moment(batchSlotDetails[0].slotDate).format('DD MMM YYYY'))}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Start-End Time:</b> {batchSlotDetails[0].startTime} - {batchSlotDetails[0].endTime}</span>
        </div>
       

        <table className="custom-tables">
        <thead>
              <tr>
              
                <th>Student Name</th>
                <th>Joining Status</th>
             
               
              </tr>
            </thead>
        <tbody>
          {batchSlotDetails.map((batchSlot, index) => (
            <tr key={index}>
            
              <td>{batchSlot.studentName}</td>
           <td>      {batchSlot.joinUrl ? (
                    <FaCheck style={{ color: 'green' }} title="Student Joined"/>
                  ) : (
                    <FaTimes style={{ color: 'red' }} title="Student not Joined"/>
                  )}</td>
            
            </tr>
     
        
          ))}
        </tbody>
        </table>
    
      </div>
)}
</div>
    )}
 
                                
                                </div>
    }
                                {/* {
                                    _.get(event, 'joinUrl') && !_.get(event, 'batchId') &&
                                    <div className='viewmodalstatus mb-2'>
                                        <span><b>Join Url:</b><a href={_.get(event, 'joinUrl')} target="_blank" style={{
                                            lineBreak: 'anywhere',
                                            wordBreak: 'break-all'
                                        }}> {_.get(event, 'joinUrl')}</a></span>
                                    </div>
                                } */}
                                {
                                    _.get(event, 'joinUrl') && !_.get(event, 'batchId') &&
                                    <div className='viewmodalstatus mb-2'>
                                        <span><b>Join Url:</b><a href={`${appEnvirontVariables.VIDEO_CALL_URL}${event.classScheduleId}?activeSectionValue=4&classNumber=${event.classNumber}`} target="_blank" style={{
                                            lineBreak: 'anywhere',
                                            wordBreak: 'break-all'
                                        }}> {appEnvirontVariables.VIDEO_CALL_URL}{event.classScheduleId}?activeSectionValue=4&classNumber={event.classNumber}</a></span>
                                    </div>
                                }
                                {
                                    _.get(event, 'joinUrl') && _.get(event, 'batchId') &&
                                    <div className='viewmodalstatus mb-2'>
                                        <span><b>Join Url:</b><a href={`${appEnvirontVariables.VIDEO_CALL_URL}${event.teacherSlotInfoId}?activeSectionValue=4&classNumber=${event.classNumber}`} target="_blank" style={{
                                            lineBreak: 'anywhere',
                                            wordBreak: 'break-all'
                                        }}> {appEnvirontVariables.VIDEO_CALL_URL}{event.teacherSlotInfoId}?activeSectionValue=4&classNumber={event.classNumber}</a></span>
                                    </div>
                                }
                                {_.isEqual(_.get(event, 'classBookingStatus'), 'completed') &&
                                  <div>
                                     <button className="accept mt-2" onClick={() => {setst('class_cancelled');classStatus(event, 'class_cancelled')}}>Cancel</button>
                                     <button className="reschdeule mt-2 mx-2" onClick={() => {setst('class_noshow');classStatus(event, 'class_noshow')}}>No Show</button>

                                  </div>
                                }
                            </React.Fragment>
                        )
                        :
                        _.isEqual(_.get(event, 'isBooked'), 'true') ?
                        
                            (
                                <React.Fragment>
                                  {!_.get(event, 'batchId') && (
                                <div>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Course title:</b> {_.startCase(_.get(event, 'courseName'))}{_.isEqual(_.get(event, 'isAddOn'),"true") ?' -Supplementary' : null}
                                    </span>
                                </div>
                                <div className='viewmodaltitle mb-2'>
                                    <span><b>Class title:</b> {_.startCase(_.get(event, 'classTitle'))}
                                    </span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Description:</b> {_.get(event, 'classDescription')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Session Number:</b> {_.get(event, 'classNumber')}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Student name:</b> {_.startCase(_.get(event, 'studentName'))}</span>
                                </div>
                                
                                <div className='viewmodalstatus mb-2'>
                                    <span><b>Call status:</b> {_.startCase(_.get(event, 'classBookingStatus'))}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                    <span><b>Class Date: </b> {_.upperCase(moment(_.get(event, 'slotDate')).format('DD MMM YYYY'))}</span>
                                </div>
                                <div className='viewmodaldescription mb-2'>
                                <span><b>Class Start-End Time: </b> {_.get(event, 'startTime')} - {_.get(event, 'endTime')}
                                        </span>
                                </div>
                                </div>
                                )}
                              
                              
                              {_.get(event, 'batchId') &&
                                <div>
                                  
                                  {batchSlotDetails.length > 0 && (
                 <div>     
                            {pageloading ? (

<div style={{display:"flex", justifyContent:"center"}}>  <Spin size="large" /></div>
) : (              
      <div>
         <div className='viewmodaldescription mb-2'>
          <span><b>Batch Name:</b> {batchSlotDetails[0].batchName}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Course Name:</b> {batchSlotDetails[0].courseName}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Title:</b> {batchSlotDetails[0].classTitle}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Description:</b> {batchSlotDetails[0].classDescription}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Date:</b> {_.upperCase(moment(batchSlotDetails[0].slotDate).format('DD MMM YYYY'))}</span>
        </div>
        <div className='viewmodaldescription mb-2'>
          <span><b>Class Start-End Time:</b> {batchSlotDetails[0].startTime} - {batchSlotDetails[0].endTime}</span>
        </div>
       

        <table className="custom-tables">
        <thead>
              <tr>
              
                <th>Student Name</th>
                <th>Joining Status</th>
             
               
              </tr>
            </thead>
        <tbody>
          {batchSlotDetails.map((batchSlot, index) => (
            <tr key={index}>
            
              <td>{batchSlot.studentName}</td>
           <td>      {batchSlot.joinUrl ? (
                    <FaCheck style={{ color: 'green' }} title="Student Joined"/>
                  ) : (
                    <FaTimes style={{ color: 'red' }} title="Student not Joined"/>
                  )}</td>
            
            </tr>
     
        
          ))}
        </tbody>
        </table>
    
      </div>
)}
</div>
    )}
 
                                
                                </div>
    }
                               
                                {/* {
                                  _.get(event, 'joinUrl') && (!_.get(event, 'batchId')) &&
                                  <div className='viewmodalstatus mb-2'>
                                    <span><b>Join Url:</b><a href={_.get(event, 'joinUrl')} target="_blank" style={{
                                      lineBreak: 'anywhere',
                                      wordBreak: 'break-all'
                                    }}> {_.get(event, 'joinUrl')}</a></span>
                                  </div>
                                } */}
                                {
                                  _.get(event, 'joinUrl') && (!_.get(event, 'batchId')) &&
                                  <div className='viewmodalstatus mb-2'>
                                    <span><b>Join Url:</b><a href={`${appEnvirontVariables.VIDEO_CALL_URL}${event.classScheduleId}?activeSectionValue=4&classNumber=${event.classNumber}`} target="_blank" style={{
                                      lineBreak: 'anywhere',
                                      wordBreak: 'break-all'
                                    }}> {appEnvirontVariables.VIDEO_CALL_URL}{event.classScheduleId}?activeSectionValue=4&classNumber={event.classNumber}</a></span>
                                  </div>
                                }
                                {
                                  _.get(event, 'joinUrl') && (_.get(event, 'batchId')) &&
                                  <div className='viewmodalstatus mb-2'>
                                    <span><b>Join Url:</b><a href={`${appEnvirontVariables.VIDEO_CALL_URL}${event.teacherSlotInfoId}?activeSectionValue=4&classNumber=${event.classNumber}`} target="_blank" style={{
                                      lineBreak: 'anywhere',
                                      wordBreak: 'break-all'
                                    }}> {appEnvirontVariables.VIDEO_CALL_URL}{event.teacherSlotInfoId}?activeSectionValue=4&classNumber={event.classNumber}</a></span>
                                  </div>
                                }
                                {!_.get(event, 'batchId') && ( 
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '60%' }}>
                                    {event.classBookingStatus !== "teacherNoShow" && event.classBookingStatus !== "PAID-NoShow"  && (
                                      <div>
                                        {_.isEqual(_.get(event, 'isAddOn'), "true") ?
                                          <button className="accept mt-2" onClick={() => onaddonclassdelete(event)}>Cancel supplementary</button>
                                          :
                                          <button className="accept mt-2"
                                            disabled={_.isEqual(st, 'class_cancelled') && _.isEqual(_.get(props, 'teachers.completeclassLoadingInProgress'), true) ? true : false}
                                            onClick={() => { setst('class_cancelled'); setEvent(event); onbookedslotdelete(event, 'class_cancelled'); setPreviewModalVisible(true) }}>
                                            {_.get(props, 'teachers.bookedslotcancelLoadingInProgress') ? 'Loading...' : 'Cancel class'}
                                          </button>
                                        }
                                      </div>
                                    )}
                                    {event.classBookingStatus !== "teacherNoShow" &&  event.classBookingStatus !== "PAID-NoShow"  &&!_.get(event, 'batchId') && ( // Condition to hide the button when batchId is present
                                      <button className="reschdeule mt-2 mx-2"
                                        disabled={_.isEqual(st, 'class_completed') && _.isEqual(_.get(props, 'teachers.completeclassLoadingInProgress'), true) ? true : false}
                                        onClick={() => { setst('class_completed'); onAddOnClassBooked(event, 'class_completed') }}>
                                        {_.get(props, 'students.rescheduleclassLoadingInProgress') ? 'Loading...' : 'Complete'}
                                      </button>
                                    )}
                   
                                  </div>
                               ) }
                                 {/* {event.classBookingStatus !== "teacherNoShow" && event.classBookingStatus !== "PAID-NoShow"  && !_.get(event, 'batchId') && ( 
                               <div>
                             

<button
className="reschdeule mt-2"
onClick={() => { onAddOnClassBookedNoShow(event, 'class_completed')}}>
{_.get(props, 'students.rescheduleclassLoadingInProgress') ? 'Loading...' : ' Mark for Penalty'}

</button>

                               </div>
                               )} */}
                                {event.classBookingStatus !== "teacherNoShow" && !_.get(event, 'batchId') && event.classBookingStatus !== "PAID-NoShow"  && ( 
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '60%' }} disabled>
                                    {/* <button
                                      className="reschdeule mt-2"
                                      disabled={_.isEqual(st, 'no_show') && _.isEqual(_.get(props, 'teachers.completeclassLoadingInProgress'), true) ? true : false}
                                      onClick={() => { setst('no_show'); onbookedslotdelete(event, 'no_show') }}
                                    >
                                      {_.get(props, 'students.rescheduleclassLoadingInProgress') ? 'Loading...' : 'Noshow'}
                                    </button> */}
                                    <button
className="reschdeule mt-2"
onClick={() => { onAddOnClassBookedNoShow(event, 'class_completed')}}>
{_.get(props, 'students.rescheduleclassLoadingInProgress') ? 'Loading...' : ' Mark for Penalty'}

</button>
                                   
                                    {(_.get(event, 'joinUrl') !== null && _.get(event, 'startUrl') !== null) ? (
                                      <button disabled className="reschdeule mt-2 mx-2 disabled-btn">Already Joined</button>
                                    ) : (
                                      <button className="reschdeule mt-2 mx-2" onClick={() => { markJoinNotJoinClass(event, 'classscheduleId') }}>
                                        Mark Join
                                      </button>
                                    )}
                                  </div>
                                )}
                                {event.classBookingStatus == "teacherNoShow" &&  (
                                  <div>
                                     <button className="accept mt-2" onClick={() => {setst('class_cancelled');classStatus(event, 'class_cancelled')}}>Cancel</button>
                                <button className="reschdeule mt-2 mx-2" onClick={() => {setst('class_completed'); classStatus(event, 'class_completed')}}>Complete</button>
                                  </div>
                                )}
                                 {event.classBookingStatus == "PAID-NoShow"  &&  (
                                  <div>
                                     <button className="accept mt-2" onClick={() => {setst('class_cancelled');classStatus(event, 'class_cancelled')}}>Cancel</button>
                                <button className="reschdeule mt-2 mx-2" onClick={() => {setst('class_completed'); classStatus(event, 'class_completed')}}>Complete</button>
                                  </div>
                                )}
                               
                               
                              </React.Fragment>
                              
                            ) : 
                           
                            (
                                <React.Fragment>
                                  {_.get(event, 'courseName') && (
  <div className='viewmodaltitle mb-2'>
    <span><b>Course:</b> {_.get(event, 'courseName')}</span>
  </div>
)}
                                    <div className='viewmodaltitle mb-2'>
                                        <span><b>Teacher:</b> {_.get(event, 'teacherName')}
                                        </span>
                                    </div>
                                    
                                    <div className='viewmodaltitle mb-2'>
                                        <span><b>Timing:</b> {_.get(event, 'startTime')} - {_.get(event, 'endTime')}
                                        </span>
                                    </div>
                                    <div className='viewmodaltitle mb-2'>
                                        <span><b>Date:</b> {_.upperCase(moment(_.get(event, 'slotDate')).format('DD MMM YYYY'))}
                                        </span>
                                    </div>
                                    {_.isEqual(teacherId.get("from"), 'student') ?
                                    teacherId.has("Schid") ?   !_.get(event, 'batchId') &&
                                    
                                        <button className="accept mt-2" 
                                        loading={_.get(props, 'students.rescheduleclassLoadingInProgress')} 
                                        disabled={_.get(props, 'students.rescheduleclassLoadingInProgress')} 
                                        onClick={() => onreschedule(event)}>{_.get(props, 'students.rescheduleclassLoadingInProgress') ? 'Loading...' : 'Reschedule'}</button>
                                        :
                                        <button className="reschdeule mt-2" 
                                        loading={_.get(props, 'students.scheduleclassLoadingInProgress')} 
                                        disabled={_.get(props, 'students.scheduleclassLoadingInProgress')} 
                                        onClick={() => onstudentschedule(event)}>{_.get(props, 'students.scheduleclassLoadingInProgress') ? 'Loading...' : 'Schedule'}</button>
                                       
                                        :
                                        !_.get(event, 'batchId') && ( 
                                        <React.Fragment>
                                            <button
                                                className="accept mt-2"
                                                disabled={_.get(props, 'teachers.deleteslotsLoadingInProgress')}
                                                onClick={() => onslotdelete(event)}>
                                                {_.get(props, 'teachers.deleteslotsLoadingInProgress') ? 'Loading...' : 'Delete'}
                                            </button>
                                             
                                             {/* <button
                                                className="reschdeule mt-2 mx-2" disabled={_.get(props, 'teachers.deleteslotsLoadingInProgress') &&  (viewProfile?.workingStatus == 'TERMINATED')} 
                                                onClick={() => onscheduleslot(event)}
                                            >
                                                {_.get(props, 'teachers.deleteslotsLoadingInProgress') ? 'Loading...' : 'Schedule'}
                                            </button> */}
                            
                                            {/* <StudentList {...props} slot={event} scheduleslot={scheduleslot} setScheduleslot={setScheduleslot} apiCall={apiCall} /> */}
                                        </React.Fragment>
                                        )
                                    }
                                </React.Fragment>

                            )}

                            
                </div>
                {/* <Modal
          title="Cancel Class"
          visible={isRefundModalVisible}
          onOk={handleRefundOk}
          onCancel={handleRefundCancel}
          footer={false}
        >
          <Form
            initialValues={{
              remember: true,
            }}
            // onFinish={onRefundFinish}
            autoComplete="off"
            form={form}
          >
           
            <Form.Item>
              
            </Form.Item>
          </Form>
        </Modal> */}
            </div>
        )

    }
    /**Addon class delete */
    const onaddonclassdelete = (event) => {
        let reqParams = {
            studentId: _.get(event, 'studentId'),
            studentClassId: _.get(event, 'studentClass'),
        }
        confirm({
            title: 'Do you Want to do this action?',
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                setpageloading(true);
                props.canceladdons({
                    params: reqParams,
                    callback: (res, data) => {
                        setpageloading(false);
                        setPreviewModalVisible(false);
                         if (_.isEqual(res, true)) {
                            apiCall(datefrom, dateTo)
                            notification.success({
                                message: 'Slot Status Changed successfully.',
                            });
                        } else {
                            notification.error({
                                message: 'Something went wrong',
                            });

                        }
                    }

                })
            },
            onCancel() {
            },
        });


    }
  
    const onscheduleslot = (event) => {
        props.onscheduledata(event)
        setPreviewModalVisible(false)
        let search  =Object.fromEntries([...teacherId]);

        delete search.tab
        let searchdata = new URLSearchParams(search).toString();
        setScheduleslot(true)

        history(`/teacher-view?${searchdata}&tab=3`,{
        
            // state:{event}
 })
    }
    const onmultipleslot = () => {
        setMultipleslot(true)
    }


const content = () =>{
    return(
    <div>
        <h4>Adding slots </h4>
      <p>To add a single slot use the calendar, To add mutliple slot follow the below steps</p> 
      <p><b>Step 1:</b> Download the sample template 
      (Link<a className='ml-2' href={require("../../../assets/format/teacherslots.csv")} target="_blank" download="sample-excel-format">sample template</a>)
 </p> 
      <p><b>Step 2:</b> Fill the template </p>
      <p><b>Step 3:</b> Upload the template
      <Button className='ml-2 ant-btn ant-btn-primary' onClick={onmultipleslot}>Add multiple slots</Button> 
</p>
<div className='calendar-legendset-conatiner'>
                                    <div className='calendar-legend-set'>
                                        <span className='legend-square' style={{ backgroundColor: '#ec5e63' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Completed &emsp; 
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: '#ffc306' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Booked  &emsp; 
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: '#c0c0c0' }}>

                                        </span>
                                        <span className='legend-text'>
                                            No-Show &emsp;
                                        </span>

                                        <span className='legend-square' style={{ backgroundColor: '#68c0af' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Free &emsp;
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: 'royalblue' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Group Slot &emsp;
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: ' palevioletred' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Special-Free Slot &emsp;
                                        </span>
                                       
                                    </div>
                                </div>
    </div>
    
    )
};
    const render = () => {
        // if (_.isEqual(pageloading, true)) {
        //     return <div className='loadingclass'> <img src={logo} alt="loading..." /></div>
        // }else{
        return (
            <div className='teachers-slots-container'>
                <div className='row'>
                    {!_.isEqual(teacherId.get("from"), 'student') &&
                        <React.Fragment>
                            <div className='col-12  mb-2' >
                            {content()}
                                <div className='slot-btn' style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    {/* <Button onClick={onmultipleslot}>Add multiple slots</Button>  */}
                                    <MultipleSlotsScreen {...props} multipleslot={multipleslot} setMultipleslot={setMultipleslot} apiCall={apiCall} />
                                </div>
                            </div>
                           
                        </React.Fragment>

                    }
                    {_.isEqual(teacherId.get("from"), 'student') &&
                        <React.Fragment>
                            <div className='col-12  mb-2' >
                                <div className='slot-btn' style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <span>Teacher Calendar</span>
                                </div>
                            </div>
                            <div className='col-12  mb-2'>
                                <span>
                                    {_.get(events[0], 'teacherName')}
                                </span>
                            </div>
                            <div className='calendar-legendset-conatiner'>
                    <div className='calendar-legend-set'>
                        <span className='legend-square' style={{ backgroundColor: '#ec5e63' }}>
                        </span>
                        <span className='legend-text'>
                            Completed &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: '#ffc306' }}>
                        </span>
                        <span className='legend-text'>
                            Booked  &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: '#c0c0c0' }}>

                        </span>
                        <span className='legend-text'>
                            No-Show &emsp;
                        </span>

                        <span className='legend-square' style={{ backgroundColor: '#68c0af' }}>
                        </span>
                        <span className='legend-text'>
                            Free &emsp;
                        </span>
                        <span className='legend-square' style={{ backgroundColor: 'royalblue' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Group Slot &emsp;
                                        </span>
                                        <span className='legend-square' style={{ backgroundColor: ' palevioletred' }}>
                                        </span>
                                        <span className='legend-text'>
                                            Special-Free Slot &emsp;
                                        </span>
                    </div>
                </div>
                        </React.Fragment>

                    }
                    {_.isEqual(pageloading, true) ? <div className="SpinBoolean">
                        <Spin size="large" spinning={!pageloading} />
                    </div> : ''
                    }
                    <div className='col-12'>
                        <Spin spinning={pageloading}>
                            <Calendar
                                selectable
                                tooltipAccessor={null}
                                onNavigate={(focusDate, flipUnit, prevOrNext) => calenderChange(focusDate,prevOrNext)}
                                localizer={localizer}
                                events={onCalendarEvents(events)}
                                views={['month', 'week', 'day']}
                                showMultiDayTimes
                                onSelectEvent={(event) => { setPreviewModalVisible(true); setEvent(event); console.log(event, 'event'); 
                                const selectedDate = moment(event.slotDate);
                                const dayOfWeek = selectedDate.format('dddd').toUpperCase();

                                console.log('Selected day of the week:', dayOfWeek);
                                const filteredWorkingHours = workingHours.filter(wh => wh.weekDay === dayOfWeek);
setWorkingDayHour(filteredWorkingHours[0].workingHour)
  console.log('workingDayHour', filteredWorkingHours[0].workingHour);
                                let filterDate = selectedDate.format('YYYY-MM-DD');
                                const filteredEvents = events.filter(event => moment(event.slotDate).isSame(filterDate, 'day'));
                                const filteredEventsWithConditions = filteredEvents.filter(
                                  filteredEvent => filteredEvent.classBookingStatus !== null && !filteredEvent.specialSlot
                                );
                                setFinalFilter(filteredEventsWithConditions.length);
                                console.log('finalFilter', filteredEventsWithConditions.length);
                                 // setNewEvents(filteredEvents);
                                
                                 if (_.get(event, 'batchId')) {
                                    handleSlotClick(event);
                                } }}
                              
                              
                               
                               
                             
                                onSelectSlot={handleSelect}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                components={{
                                    timeSlotWrapper: ColoredDateCellWrapper,
                                }}
                                eventPropGetter={(eventStyleGetter)}
                                defaultDate={moment()}
                                timeslots={1}
                                step={30}
                            />
                            {ViewModel()}

                        </Spin>
                    </div>
                </div>
            </div>
        )
        // }

    }
    return render();
}

CalendarPage.propTypes = propTypes

const mapStateToProps = state => (
    {
        teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
        students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),

    });

const mapDispatchToProps = dispatch => ({
    teachersslot: details => dispatch(global.redux.action.teachers.slotDetails(details)),
    teachersslotcreate: details => dispatch(global.redux.action.teachers.createSlots(details)),
    teachersslotdelete: details => dispatch(global.redux.action.teachers.deleteSlots(details)),
    teachersbookedslotdelete: details => dispatch(global.redux.action.teachers.cancelbookedslots(details)),
    rescheduleclass: details => dispatch(global.redux.action.students.rescheduleclass(details)),
    createMultipleSlots: details => dispatch(global.redux.action.teachers.createMultipleSlots(details)),
    completeclass: details => dispatch(global.redux.action.teachers.completeclass(details)),
    completeclassforpenalty:details => dispatch(global.redux.action.teachers.completeclassforpenalty(details)),
    cancelClassFetch: details => dispatch(global.redux.action.teachers.cancelClassFetch(details)),
    classNoShow: details => dispatch(global.redux.action.teachers.classNoShow(details)),
    canceladdons: details => dispatch(global.redux.action.teachers.canceladdons(details)),
    scheduleClass: details => dispatch(global.redux.action.teachers.scheduleClass(details)),
    isAddOnClass: details => dispatch(global.redux.action.teachers.isAddOnClass(details)),
    teacherData: (details) =>
        dispatch(global.redux.action.teachers.viewteacherprofile(details)),
        studentclass: details => dispatch(global.redux.action.students.classdetails(details)),
        courseList: (details) =>
        dispatch(global.redux.action.students.courseList(details)),
        markJoinClass: details => dispatch(global.redux.action.teachers.markJoinClass(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);