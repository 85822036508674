export const reduxActionTypes = {

  // please have all account actions types const in the below account property
  account: {
    name: 'account',
    isLogin: 'isLogin',
    profile: 'profile',
    login: 'login',
    newLogin:"newLogin",
    logout: 'logout',
    forgotPassword: "forgotPassword",
    resetPassword: "resetPassword",

  },

  // please have all teachers actions types const in the below account property
  teachers: {
    name: 'teacher',
    list: 'list',
    slotdetails: 'slotdetails',
    createslots: 'createslots',
    slotAdd:'slotAdd',
    deleteslots: 'deleteslots',
    deleteAeSlots:'deleteAeSlots',
    multipleSlotsCreate: 'multipleSlotsCreate',
    editteacherprofile: "editTeacherProfile",
    viewteacherprofile: "viewteacherprofile",
    viewPreviousClass:"viewPreviousClass",
    viewTeacherNoshowClass:"viewTeacherNoshowClass",
    deleteTeacherNoshowClass:"deleteTeacherNoshowClass",
    bookedslotcancel: 'bookedslotcancel',
    addteacherprofile: "addteacherprofile",
    classcount: 'classcount',
    completeclass: 'completeclass',
    completeclassforpenalty:"completeclassforpenalty",
    cancelClassFetch:'cancelClassFetch',
    classNoShow:'classNoShow',
    isAddOnClass:'isAddOnClass',
    scheduleclass: 'scheduleclass',
    activeInactiveClass: 'activeInactiveClass',
    coursetags:'coursetags',
    canceladdon:'canceladdon',
    courselist:'courselist',
    feedbacklist:'feedbacklist',
    teacherstatus:'teacherstatus',
    enablePaidClass:'enablePaidClass',
    checkteacherstatus:'checkteacherstatus',
    teacherlist : 'teacherlist',
    teacherage : 'teacherage',
    markJoinClass:'markJoinClass',
    teacherlanguage: 'teacherlanguage',
updateCourseteachers:'updateCourseteachers',
listteacherforcourse:'listteacherforcourse',
planCodeForStudent:'planCodeForStudent',
changecourseforstudent:'changecourseforstudent',
blockstatus:'blockstatus',
webinarLink:'webinarLink',
updateBanner:'updateBanner',
referral:'referral',
approvereferral:'approvereferral',
cancelClassForComplete:'cancelClassForComplete',
fetchWebinar:'fetchWebinar',
deleteWebinar:'deleteWebinar'
  },

  // please have all students actions types const in the below account property
  students: {
    name: 'student',
    list: 'list',
    classdetails: 'classdetails',
    bookedclasscancel: 'bookedclasscancel',
    rescheduleclass: 'rescheduleclass',
    viewprofile: 'viewprofile',
    editprofile: 'editprofile',
    courselist: 'courseList',
    feedbacklist:'feedbacklist',
    courseslots: 'courseslots',
    addstudentprofile: "addstudentprofile",
    courseallocation: 'courseallocation',
    classcount: 'classcount',
    scheduleclass: 'scheduleclass',
    bookDemoStatus:'bookDemoStatus',
    classDeduct:"classDeduct",
    studenttype:'studenttype',
    refundClass:'refundClass',
    enrollnewcourse:'enrollnewcourse',
    enrolledcourselist:'enrolledcourselist',
    classRearrangement:'classRearrangement',
    syncSalesForce:'syncSalesForce',
    ChangeStatusForStudentSlot:'ChangeStatusForStudentSlot',
    uploadphoto:'uploadphoto',
    validatemobile:'validatemobile',
validateEmail:'validateEmail',
expiryDate:'expiryDate',
  },
  admin: {
    name: 'admin',
    reports: 'reports',
    photoupload: 'photoupload',
    photodelete: 'photodelete',
    addonreports: 'addonreports',
    teachercompensation: 'teachercompensation',
    bulkemail:'bulkemail',
  },
  payment: {
    name:'payment',
    createpayment:'createpayment',
    directpayment:'directpayment',
    paymentlink:'paymentlink'
  },

  // update specific action state
  reduxUpdateAccountState: 'reduxUpdateAccountState',
  reduxUpdateTeacherState: 'reduxUpdateTeacherState',
  reduxUpdateStudentState: 'reduxUpdateStudentState',
  reduxUpdateAdminState: 'reduxUpdateAdminState',
  reduxUpdatePaymentState: 'reduxUpdatePaymentState'
};
export default reduxActionTypes;