import React, {useState, useEffect} from 'react';
import './styles.css';
import _ from 'lodash';
import { useSearchParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { connect } from "react-redux";
import {  Table, } from 'antd';
import gmail from '../../../assets/img/Gmail.svg';
import whatsapp from '../../../assets/img/WhatsApp.png';
// import sms from '../../../assets/img/sms.png';



const PaymentList = (props) => {
    const [studentId] = useSearchParams();

    const [paymentlist, setpaymentlist] = useState([]);

useEffect(()=>{
    PaymentDataApiCall();

},[])

    const PaymentDataApiCall = () => {
        props.paymentlink({
          params: {
            studentId: studentId.get("id")
          },
          callback: (response, data) => {
            data = data.filter(e => e.paymentFor == "directpayment");
            if (response = true && !_.isEmpty(data)) {
              setpaymentlist(data)
            };
    
            console.log("payment", data)
          }
        })
      }

      const columns = [
        // {
        //   title: 'S.No',
        //   dataIndex: 'no',
        //   key: 'no',
        // },
        {
          title: 'Date Created By',
          dataIndex: 'created_by',
          key: 'created_by',
        },
        {
          title: 'Course Name',
          dataIndex: 'course_name',
          key: 'course_name',
        },
        {
          title: 'No.Of Classes',
          dataIndex: 'no_of_classes',
          key: 'no_of_classes',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Discount',
          dataIndex: 'discount',
          key: 'discount',
        },
        {
          title: 'Netamount',
          dataIndex: 'netamount',
          key: 'netamount',
        },
        {
          title: 'Link',
          dataIndex: 'link',
          key: 'link',
        },

        {
          title: 'Payment Update',
          dataIndex: 'paymentupdate',
          key: 'paymentupdate',
        },

        {
          title: 'Payment Status',
          dataIndex: 'paymentstatus',
          key: 'paymentstatus',
        },
        
      ];
    
      const paymentDataSource = () => {
        let data = [];
        _.map(paymentlist, (each, i) => {
            console.log('each', each);
          data.push({
            created_by: `${moment(new Date(each.createdAt).toLocaleString()).format('DD-MM-YYYY')} ${moment(new Date(each.createdAt).toLocaleString()).format('HH:mm')}`,
            course_name: `${each.courseList.map(e => e.courseName).join(', ')}`,
            no_of_classes: `${each.courseList.map(e => e.classesToPurchase).join(', ')}`,
            amount: `${each.courseList.map(e => e.price).join(', ')}`,
            discount: `${each.courseList.map(e => e.discountedPrice).join(', ')}`,
            netamount: _.get(each, 'totalPrice'),
            link: <a href={_.get(each, 'payment_link')} target={'_blank'}>{_.get(each, 'payment_link')} </a>,
         paymentupdate: 
         
         <div className="sociallink">
           
           

           {/* <Button className="float-right mb-2" onClick={() => {
        
      }}>Share Link</Button>
           <div><img src={sms}/>sms</div> */}
           <div><img src={whatsapp}/>WhatsApp</div>
           <div><img src={gmail}/>Gmail</div>
           </div>
          })
        })
        return data
      }

    const render = () => {
        return(
            <div className='payment-details-container'>

            <Table dataSource={paymentDataSource()} columns={columns} />
            
          </div>
          
          
        )
    }
    return render();
}

const mapStateToProps = state => (
    {
      payment: _.get(state, `app.${global.redux.actionTypes.payment.name}`, {}),
      students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
  
  
    });
  
  const mapDispatchToProps = dispatch => ({
    createpayment: details => dispatch(global.redux.action.payment.createpayment(details)),
    paymentlink: details => dispatch(global.redux.action.payment.paymentlink(details)),
    directpayment: details => dispatch(global.redux.action.payment.directpayment(details)),
  });

  export default connect(mapStateToProps, mapDispatchToProps,)(PaymentList);