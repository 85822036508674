import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, DatePicker,Table, Spin,Modal,notification} from "antd";
import _ from "lodash";
import axios from 'axios';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import SecureLS from "secure-ls";
import dayjs from "dayjs";
import { ExclamationCircleOutlined, DiffOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { constant } from "../../../core_units/constant";
import {formatDateTime} from "../../../core_units/utils/dayjs";
import mixpanel from 'mixpanel-browser';
const propTypes = {}
const { confirm } = Modal;
function TeacherNoshow(props) {
  const { RangePicker } = DatePicker;
  var ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin')
  const loginDetails = ls.get('isLogin');
  //const mixpanel = require('mixpanel-browser');
  const [form] = Form.useForm();
const [teacherDetails,setTeacherDetails]= useState([]);
const [isLoading, setIsLoading] = useState(false);
const [deleteRecord, setDeleteRecord] = useState(null); 
const [isModalVisible, setIsModalVisible] = useState(false);
const [saveDelete, setSaveDelete] = useState();
  const onFinish = (values) => {
    setIsLoading(true);
    const [fromDate, toDate] = values.fromDate;
    const teacherId = props.teachers.viewteacherprofile.id;

   
    const formattedFromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedToDate = dayjs(toDate).format("YYYY-MM-DD");

    props.viewTeacherNoshowClass({
      params: {
        teacherId,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      },
      callback: (response, data) => {
        setIsLoading(false);
       setTeacherDetails(data)
       form.resetFields();
       console.log(data,"data")
      },
    });
  };
  const showDeleteConfirmationModal = (record) => {
    console.log("Selected Row:", record);
    setDeleteRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
   
    onDelete(deleteRecord);
   
    console.log(deleteRecord,"deleteRecord==")
    setDeleteRecord(null);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setDeleteRecord(null);
    setIsModalVisible(false);
  };
 
  const onDelete = (record) => {
    confirm({
      title: 'Do you Want to do this action?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
       
        const { studentId, studentName, classNumber, courseName, classDateTime } = record;
        

          mixpanel.track(constant.MIXPANEL_TEACHER_NOSHOW_DELETE, {
            user:loginDetails?.studentName,
            TeacherNoShow:"Delete",
              studentId,
              studentName,
              classNumber,
              courseName,
              classDateTime
            
          });
        
        setIsLoading(true);
          const { teacherNoshowClassId } = record;
          props.deleteTeacherNoshowClass({
            params: {
                    teacherNoShowClassId: teacherNoshowClassId,
                  },
              callback: (res, data) => {
                setIsLoading(false);
                  if (_.isEqual(res, true)) {
                    console.log(res,"res==")
                      notification.success({
                          message: 'Slot deleted successfully.',
                      });
                      setTeacherDetails(prevDetails => prevDetails.filter(item => item.teacherNoshowClassId !== teacherNoshowClassId));
                  } else {
                      notification.error({
                          message: 'Something went wrong',
                      });
                  }
              }
          })
      },
      onCancel() {
      },
  });

  };
  
  const columns = [
    {
      title: "Student Id",
      dataIndex: "studentId",
      key: "studentId",
    },
    {
        title: "Student Name",
        dataIndex: "studentName",
        key: "studentName",
      },
      {
        title: "Class Number",
        dataIndex: "classNumber",
        key: "classNumber",
      },
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
    },
    
    {
      title: "Class Date & Time",
      key: "classDateTime",
      render: (record) => (
        <span>
        {formatDateTime(record.classDate, record.classStartTime, true)}
      </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button type="primary" danger onClick={() => onDelete(record)}>
          Delete
        </Button>
      ),
    },
    
  ];

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <div className="" style={{ maxWidth: "100%" }}>
          <Form.Item
            label="Date"
            name="fromDate"
            rules={[
              {
                required: true,
                message: "Please input your date range!",
              },
            ]}
          >
            <RangePicker />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
        </div>
      ) : (
        teacherDetails.length > 0 ? (
          <Table columns={columns} dataSource={teacherDetails} pagination={false} />
        ) : (
          <div style={{ textAlign: "center" }}>No data found.</div>
        )
      )}
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  viewTeacherNoshowClass: (details) =>
    dispatch(global.redux.action.teachers.viewTeacherNoshowClass(details)),
    deleteTeacherNoshowClass: (details) =>
    dispatch(global.redux.action.teachers.deleteTeacherNoshowClass(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherNoshow);
