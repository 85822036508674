import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Select, notification, Result } from 'antd';
import _ from 'lodash';
import { connect } from "react-redux";
import './style.css';
import axios from 'axios';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
const TeacherCompensationReport = (props) => {
    var ls = new SecureLS({ encodingType: 'aes' });
    const token = ls.get('isLogin')
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const [form] = Form.useForm();
    const [disablesubmit, setdisablesubmit] = useState(true);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [datereq, setdatereq] = useState(false);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState(''); 
    const [day, setDay] = useState('');
    const [dayArray, setDayArray] = useState([]);
    useEffect(() => {
        document.getElementById('year').value = year;
        document.getElementById('month').value = month;
        document.getElementById('day').value = day;
      }, [year, day, month])
    const monthArray = [{name :'January', id:'01', day : '16 - 31'},
                {name :'February', id:'02', day : '16 - 28'},
                {name :'March', id:'03' , day : '16 - 31'},
                {name :'April', id:'04', day : '16 - 30'},
                {name :'May', id:'05', day : '16 - 31'}, 
                {name :'June', id:'06', day : '16 - 30'}, 
                {name :'July', id:'07', day : '16 - 31'}, 
                {name :'August', id:'08', day : '16 - 31'}, 
                {name :'September', id:'09', day : '16 - 30'},
                {name :'October', id:'10', day : '16 - 31'}, 
                {name :'November', id:'11', day : '16 - 30'}, 
                {name :'December', id:'12', day : '16 - 31'}];
    const onFinish = (values) => {
        setDisabledBtn(true);
        teacherBilling(values).then(result => {
            console.log(result);
            notification.success({
                message: result.data,
              });
            setDisabledBtn(false);
        },(er) => {
            setDisabledBtn(false);
        });
        
    };
    const teacherBilling = (v) => {
        let fdate = `${v.year}/${v.month}/${v.day.split('-')[0].trim()}`;
        let ldate = `${v.year}/${v.month}/${v.day.split('-')[1].trim()}`;
        return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/teacherBilling?fromDate=${fdate}&toDate=${ldate}&emailId=${v.emailId}`,
          { headers: { "Authorization": token['authorization'] } });
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const selectMonth = (e) => {
        
        //document.getElementById('day').value = '';
        let value = e.target.value;
        setMonth(value);
        if(value){
        if(value == '02' && year == '2024'){
            let ary = ['01 - 15']; 
            ary.push('16 - 29');
            setDayArray(ary , () => setDay(''));
        }else{
            let ary = ['01 - 15']; 
            ary.push(monthArray.find(z => z.id == value).day);
            setDayArray(ary);
        }
        setDay('')
    }
    }
    const selectYear = (e) => {
        //document.getElementById('day').value = '';  
        let value = e.target.value;
        setYear(value);
        if(value){
            if(dayArray.length && month == '02' && value == '2024'){
                let ary = ['01 - 15'];
                ary.push('16 - 29');
                setDayArray(ary, () => setDay(''));
            }else if(dayArray.length && month == '02' && value != '2024'){
                let ary = ['01 - 15'];
                ary.push('16 - 28');
                setDayArray(ary);
            }
            setDay('')
        }
        
    }
    const selectDay = (e) => {
        let value = e.target.value;
        setDay(value);
    }
    const clearForm = (e) => {
        setYear("");
        setMonth("");
        setDay("");
        document.getElementById('email').value = "";
    } 
    const render = () => {
        return (
            <div className="report-card">
                <div className="report-filter-conatiner">
                    <span><b>Teacher Compensation Report</b></span>

                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={(change, allValues) => {  }}
                        form={form}
                    >
                        <div className="row" style={{"paddingTop": "10px"}}>
                        <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <Form.Item
                                label="Date"
                                name="year"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Year!',
                                    },
                                ]}
                            >
                                <select id="year" value={year} className="form-control" style={{appearance: 'auto'}} onChange={(value) => selectYear(value)} placeholder='Year'>
                                    <option value="">Select Year</option>
                                    <option value="2022">2022 </option>
                                    <option value="2023">2023 </option>
                                    <option value="2024">2024 </option>
                                    <option value="2025">2025 </option>
                                </select>
                            </Form.Item>
                        </div>
                        <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <Form.Item
                                name="month"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Month!',
                                    },
                                ]}
                            >
                                <select id="month" value={month} className="form-control" style={{appearance: 'auto'}} onChange={(value) => selectMonth(value)} placeholder='Month'>
                                <option value="">Select Month </option>
                                    {monthArray.map((v,i) => {
                                        return <option value={v.id}>{v.name} </option>
                                    })}
                                </select>
                            </Form.Item>
                        </div>	
                        <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <Form.Item
                                name="day"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Day!',
                                    },
                                ]}
                            >
                                <select id="day" value={day} className="form-control" style={{appearance: 'auto'}} onChange={(value) => selectDay(value)} placeholder='No of Days'>
                                <option value="">Select Days</option>
                                    {(dayArray || []).map((v,i) => {
                                        return <option value={v}>{v} </option>
                                    })}
                                </select>
                            </Form.Item>
                        </div>	
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <Form.Item
                                label="Email"
                                name="emailId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    }, {
                                        pattern: new RegExp(/^[A-Za-z0-9._%+-]+@artiumacademy.com$/)
                                    }
                                ]}
                            >
                                <Input id="email" />
                            </Form.Item>
                        </div>
                        </div>

                        <div className="report-btn">
                            <Form.Item>
                                <Button onClick={(e) => clearForm(e)} type="primary mr-2" danger >
                                    Clear
                                </Button>
                                <Button type="primary" htmlType="submit" disabled={disabledBtn}>
                                {disabledBtn ? 'Loading' : 'Submit'}
                                </Button>
                            </Form.Item>
                        </div>

                    </Form>
                </div>
            </div>

        )
    }
    return render();
}

const mapStateToProps = state => (
    {
        account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),

    });

const mapDispatchToProps = dispatch => ({
    addonreport: details => dispatch(global.redux.action.admin.addonreport(details))
});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherCompensationReport);
