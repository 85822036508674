import React, { useState, Fragment, useEffect } from 'react';
import { render } from "react-dom";
import axios from 'axios';
import { Checkbox, Col, Row } from "antd";
import { Button, Select, Table, Form, Input, InputNumber,notification, Spin } from 'antd';
 import _ from 'lodash';
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { async } from 'q';
import { constant } from '../../../core_units/constant';
import mixpanel from 'mixpanel-browser';
const { Option } = Select;

let lmsCourseList = [];
let configCourseList = [];
let configData;
let subscriptionCourseList = [];
let studentCourseList = [];
//let teacherList = [];
//const currency = "INR";
let profile = "";
let viewProfile = "";
let closeModal = "";
let customer_id = "";
let ls = new SecureLS({ encodingType: 'aes' });
const loginDetails = ls.get('isLogin');
const artiumCashReduction = {
  redemptionRules: [
    {
      "count": 36,
      "INRamount": 5000,
      "USDamount": 125
    },
    {
      "count": 24,
      "INRamount": 2000,
      "USDamount": 75
    },
    {
      "count": 12,
      "INRamount": 500,
      "USDamount": 25
    }
  ], classLimit: 4
};
const item = {
  courseName: "",
  teacherId: "",
  courseIndex: "",
  classesToPurchase: 0,
  discountedPrice: 0,
  unit_price: 0,
  pricePerClass: 0,
  amountBeforeTax: 0,
  gst: 0,
  account_id_prod: "",
  account_id_uat: "",
  item_id_prod: "",
  item_id_uat: "",
  level: "",
  discountedPercentage: 0,
  courseId: "",
  trialTeacher: "",
  price: 0,
  courseObj: {},
  isFree:false,
  teacherList: []
};
//const mixpanel = require('mixpanel-browser');
class Courselist extends React.Component {

  constructor(props) {
    super(props);
    profile = props.profile;
    viewProfile = props.viewProfile;

    props.childFunc.current = this.reset;
    closeModal = props.closemodal;
    const defaultExpiryDate = new Date();
    defaultExpiryDate.setDate(defaultExpiryDate.getDate() + (props.batchData ? 2 : 7));
    let year = defaultExpiryDate.getFullYear();
    let month = String(defaultExpiryDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    let day = String(defaultExpiryDate.getDate()).padStart(2, '0');

    const defaultExpiryDateString = `${year}-${month}-${day}`;
    this.state = {
      rows: [{ ...{}, ...item }],
      courselist: [],
      totalPrice: 0,
      currency: "",   //(_.get(profile, 'countryCode') == "+91" || _.get(profile, 'countryCode') == "91")?"INR":"USD",
      state: '',
      showState: !profile.state && (profile?.countryCode == "+91" || profile?.countryCode == "91") ? true : false,
      showErr: false,
      showErrTeacher: false,
      disabledBtn: false,
      voucher: null,
      referral: null,
      artiumCash: 0,
      myCash: 0,
      useWallet: true,
      usedPoints: 0,
      externalPayment: !!props.onlyExternal,
      skipConvertion: false,
      refNumber:null,
      skipInvoice:false,
      paymentMode:"Cash",
      paymentModeValue:"Cash",
      errMessage: "",
      showErrMessage: false,
      expiryDate: defaultExpiryDateString,
      loading:true,
      reason:null,
      enrollmentFee: "",
      offline:false,
      centerList: null,
      selectedBranch: null,
    };
    this.getAllList();
  }
  alertUser() {
    alert('You clicked!')
  }

  handleBranchChange = (event) => {
    this.setState({ selectedBranch: event.target.value })
    let courseArray = [];
    courseArray = this.state.centerList.find(e => e.centerId == event.target.value)?.courses;
    const cList = [];
    courseArray.forEach(element => {
      element.tags.forEach(ele => {
        ele.groupId = element.id;
        if(ele.name){
          ele.courseName = `${element.name} - ${ele.name}`
        }else{
          ele.courseName = `${element.name}`
        }        
        cList.push(ele);
      });
    });
    configCourseList = cList;
    this.reset();
  }
  getAllList = async () => {
    let courseIdForSub;
    if(this.props.batchData && !this.props.batchData.batchCourseDetials.length){
      courseIdForSub = this.props.selectedOnlineGroupCourse; //this.props.batchData.batchCourseDetials.push({id:this.props.selectedOnlineGroupCourse});
    }else if(this.props.batchData){
      courseIdForSub = this.props.batchData.batchCourseDetials[0].id;
    }
    const result = await Promise.all([this.getAllCousreList().catch(() => { return {} }),
    this.getAllLmsCousreList().catch(() => { return {} }),
    this.getSubcriptionCourse((this.props.batchData ? courseIdForSub : null)).catch(() => { return {} }),
    this.getStudentCousreList().catch((e) => { return {} }),
      //this.getAllTeacherList().catch((e) => { return{}})
    ]);
    this.getCouponByPhoneNumber().then(c => {
      if (c?.data?.coupons?.expiryDate && new Date(c?.data?.coupons?.expiryDate) > new Date()) {
        this.setState({ voucher: c.data.coupons });
      }
    });
    this.getArtiumCash().then(c => {
      if (c?.data && c?.data.points) {
        c.data.points = +(c.data.points * c?.data[`${this.state.currency}PointConversion`]).toFixed(2);
      }
      if (c?.data) {
        this.setState({ referral: c?.data });
      }
    });
    
    result[0].courses = result[0].courses.filter(e => !e.isBatchCourse); 
    configData = result[0];
    const cList = [];
    // const processCourses = (courses, isOffline = false) => {
    //   courses.forEach(course => {
    //     course.tags.forEach(tag => {
    //       tag.groupId = course.id;
    //       tag.offline = isOffline;
    //       tag.courseName = tag.name ? `${course.name} - ${tag.name}` : course.name;
    //       cList.push(tag);
    //     });
    //   });
    // };

    // const offlineCentre = configData.offlineCourseCentres.find(centre => centre.centerId === 1);
    
    // const courseType = this.props.batchData ? "onlineGroupCourses" : "courses";
    // processCourses(configData[courseType]);

    // if (offlineCentre) {
    //   processCourses(offlineCentre.courses, true);
    // }
    
    let courseArray = [];
    if(this.props.isOffline){
      let cntList = await this.getCenterList(profile.token.uId);
      if (cntList.data && cntList.data.length) {
        this.setState({ centerList: cntList.data });
        this.setState({ selectedBranch: cntList.data[0].centreId });
        courseArray = result[0]["offlineCourseCentres"].find(e => e.centerId == cntList.data[0].centreId)?.courses;
      }else{
        result[0]["offlineCourseCentres"].forEach(e => {
          e.centreId = e.centerId;
          e.centreName = e.centreName;
        })
        this.setState({ centerList: result[0]["offlineCourseCentres"] });
        this.setState({ selectedBranch: result[0]["offlineCourseCentres"][0].centreId });
        courseArray = result[0]["offlineCourseCentres"].find(e => e.centerId == result[0]["offlineCourseCentres"][0].centreId)?.courses;
      }
      this.setState({ offline: true });
    }else{
      this.setState({ offline: false });
      courseArray = result[0][(this.props.batchData? "onlineGroupCourses" : "courses")];
    }
    
    courseArray.forEach(element => {
      element.tags.forEach(ele => {
        ele.groupId = element.id;
        if(ele.name){
          ele.courseName = `${element.name} - ${ele.name}`
        }else{
          ele.courseName = `${element.name}`
        }        
        cList.push(ele);
      });
    });
    if(this.props.batchData){
      if(this.props.batchData.batchCourseDetials.length){
        let gid = cList.find(z => z.paidCourseId == this.props.batchData.batchCourseDetials[0].id).groupId;
        this.props.batchData.batchCourseDetials = [];
        cList.forEach(e => {
          if(e.groupId == gid){
            this.props.batchData.batchCourseDetials.push({id:e.paidCourseId});
          }
        })
      }else{
          let gid = cList.find(z => z.paidCourseId == this.props.selectedOnlineGroupCourse).groupId;
          cList.forEach(e => {
            if(e.groupId == gid){
              this.props.batchData.batchCourseDetials.push({id:e.paidCourseId});
            }
          })
        }
    }
    lmsCourseList = result[1]?.data || [];
    configCourseList = cList;
    subscriptionCourseList = result[2]?.data || [];
    studentCourseList = result[3]?.data || [];
    //teacherList = result[4]?.data?.content || [];
    //console.log('result[1]',result[2]);
    this.setState({
      courselist: cList,
      stateList: result[0]?.states
    }, () => {
      if (this.props.batchData) {
        setTimeout(() => {
          this.state.rows[0].teacherList = [{teacherId : this.props.batchData.teacherId, teacherName: this.props.batchData.teacherName}];
          this.state.rows[0].teacherId = this.props.batchData.teacherId;
          let index = this.state.courselist.findIndex((e) => e.paidCourseId == this.props.batchData.batchCourseDetials[0].id);
          this.state.rows[0].courseIndex = index;
          this.setState({
            rows: this.state.rows
          });
          this.courseChange({ target: "", currentTarget: { value: index.toString(), dataset: { rowindex: "0" } } })
          addBatchCourse(1);
          addBatchCourse(2);
          addBatchCourse(3);
        }, 300);
      }
      if(this.props.excessPrice){
        setTimeout(() => {
          let index = this.state.courselist.findIndex((e) => e.paidCourseId == this.props.excessPrice.toCourseId);
          this.state.rows[0].teacherId = this.props.excessPrice.teacherId;
          this.state.rows[0].courseIndex = index;
          this.setState({
            rows: this.state.rows
          });
          this.courseChange({ target: "", currentTarget: { value: index.toString(), dataset: { rowindex: "0" } } })
        }, 300);
      }
      const addBatchCourse = (i) => {
        if(this.props.batchData.batchCourseDetials[i]){
          this.setState({
            rows: [...this.state.rows, {...{},...item}]
          }, () => {
            this.state.rows[i].teacherList = [{teacherId : this.props.batchData.teacherId, teacherName: this.props.batchData.teacherName}];
            this.state.rows[i].teacherId = this.props.batchData.teacherId;
            let index1 = this.state.courselist.findIndex((e) => e.paidCourseId == this.props.batchData.batchCourseDetials[i].id);
            this.state.rows[i].courseIndex = index1;
            this.setState({
              rows: this.state.rows
            });
            this.courseChange({target: "",currentTarget:{value : index1.toString(),dataset :{rowindex : i.toString()}}});
          });
        }
      }
    });
    this.setState({loading:false});
  }

  getCenterList = async (uId) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/counsellor/centre?uId=${uId}`,
      { headers: { "Authorization": profile.token['authorization'] } });
  }

  getAllCousreList = async () => {

    const base = process.env.REACT_APP_ENV === 'production' ?

      'https://api.artiumacademy.com/config/prod/paymentconfig.json' :
      'https://api.artiumacademy.com/config/prod/paymentconfig.json'
    const response = await fetch(base);
    console.log(base);
    return await response.json();
  }
  getAllTeacherList = (courseId) => {
    return axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teachers?courseId=${courseId}`,
      { page: 0, size: 500, courseId: courseId },
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getAllLmsCousreList = () => {
    return axios.get('https://lmsapi.artiumacademy.com/production/api/courses',
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getAllLmsCousreList = () => {
    return axios.get('https://lmsapi.artiumacademy.com/production/api/courses',
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getSubcriptionCourse = async (courseId) => {
    let url = courseId ? `studentId=${profile.studentId}&courseId=${courseId}` : `studentId=${profile.studentId}`
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/subscription/data?${url}`,
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getStudentCousreList = async () => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/course/courseListForStudentDashboard/${profile.credId}?credid=${profile.credId}`,
      { headers: { "Authorization": profile.token['authorization'] } });
  }

  getAllLmsCousreList = () => {
    return axios.get('https://lmsapi.artiumacademy.com/production/api/courses',
      { headers: { "Authorization": profile.token['authorization'] } });
  }

  getCouponByPhoneNumber = () => {
    return axios.get(`${appEnvirontVariables.API.COUPON_URL}/v1/coupon/list/${profile.countryCode}${profile.mobileNumber}`,
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getReferral = () => {
    return axios.post(`${appEnvirontVariables.API.REFERRAL_URL}/api/referral/purchaseReferral`,
      { type: profile.userType.toLowerCase(), userId: profile.userId },
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getArtiumCash = () => {
    return axios.get(`${appEnvirontVariables.API.REFERRAL_URL}/api/artium-cash/${profile.userId}?userType=${profile.userType.toLowerCase()}`,
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  triggerWalletPayment = (data) => {
    let apiURL;
      if(this.props.excessPrice){
        apiURL = `${appEnvirontVariables.API.PAYMENT_URL}/api/payment/walletPayment-course-change`;
      }else{
        apiURL = `${appEnvirontVariables.API.PAYMENT_URL}/api/payment/v2/walletPayment`;
      }
    return axios.post(apiURL,
      data,
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  triggerWalletPaymentV2 = (data) => {
    return axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/v2/walletPayment`,
      data,
      { headers: { "Authorization": profile.token['authorization'] } });
  }
  getArtiumAccountDetail = (courseId) => {
    return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/account-detail/${courseId}`,
      { headers: { "Authorization": profile.token['authorization'] } });
  }

  getCoursePrice = async (data) => {
    let courseId = encodeURIComponent(data.courseId)
    let ccObject = configData.timezoneWithCountryName[profile.timezone];
    let countryCode = encodeURIComponent(ccObject.code);
    let countryName = encodeURIComponent(ccObject.country.toLowerCase());
    let result = await axios
    .get(appEnvirontVariables.API.COURSE_PRICE_URL + "/api/courseDetails/getPrice" + `?courseId=${courseId}&countryCode=${countryCode}&countryName=${countryName}`);
    return result
  }

  freeCourseAPI = (data) => {
    return axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/free-course`,
      data,
      { headers: { "Authorization": profile.token['authorization'] } });
  }

  addStudentinBatch = async () => {
    let defaultExpiryDate = new Date();
    defaultExpiryDate.setDate(defaultExpiryDate.getDate() + 1);
    let year = defaultExpiryDate.getFullYear();
    let month = String(defaultExpiryDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    let day = String(defaultExpiryDate.getDate()).padStart(2, '0');

    let defaultExpiryDateString = `${year}-${month}-${day}`;
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/batch/student`;

    const payload = {
      batchId: this.props.batchData.id,
      studentId: profile.studentId,
      addedBy: loginDetails?.studentName,
      courseList: this.props.batchData.batchCourseDetials.map(item => item.id).toString(),
      linkExpiryDate: defaultExpiryDateString,
      // studentStatus: "Confirmed"
    };
    return axios
      .post(apiUrl, payload, {
        headers: {
          Authorization: profile.token["authorization"],
          "Content-Type": "application/json",
        },
      })
  }

  handleChange = idx => e => {
    //const re = /^[0-9\b]+$/;
    //if (e.target.value === '' || re.test(e.target.value)) {
    if (e.target.value === '' || +e.target.value <= +e.target.dataset.limit) {
      const { name, value } = e.target;
      this.state.rows[idx][name] = value;
      this.setState({
        rows: this.state.rows
      }, this.calc);
    }

  };

  monthChange = idx => e => {
    //const re = /^[0-9\b]+$/;
    //if (e.target.value === '' || re.test(e.target.value)) {
    if (e.target.value === '' || +e.target.value <= +e.target.dataset.limit) {
      const { name, value } = e.target;
      this.state.rows[idx][name] = value * this.state.rows[idx].monthCount;
      this.setState({
        rows: this.state.rows
      }, this.calc);
    }
  };

  teacherChange = idx => e => {
    const { name, value } = e.target;
    this.state.rows[idx][name] = value || "";
    this.setState({
      rows: this.state.rows,
      showErrTeacher: false
    });
  };
  handleAddRow = () => {
    this.setState({
      rows: [...this.state.rows, { ...{}, ...item }]
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1)
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows]
    rows.splice(idx, 1)
    this.setState({ rows })
  }

  courseChange = async (event) => {
    
    let index = (event.target.value || event.currentTarget.value);
    let rowindex = (event.target || event.currentTarget).dataset.rowindex;
    // this.setState({ offline: !!this.state.courselist[index].offline });
    // if(!this.state.courselist[index].offline){
    //   this.setState({addEnrollmentFee:false});
    // }
    if (index > -1) {
      if (this.state.rows.filter(e => e.courseId == this.state.courselist[index].paidCourseId).length) {
        (event.target || event.currentTarget).value = "";
        this.state.rows[rowindex] = { ...{}, ...item };
        this.calc();

        return;

      }

      let price_INR
      let price_USD
      let accountDetail = {}
      let teacherlist;
      let coursePriceData = await this.getCoursePrice({ courseId: this.state.courselist[index].paidCourseId, countryCode: profile?.countryCode })
      if(this.props.excessPrice){
        const existCoursePriceData = await this.getCoursePrice({ courseId: this.props.excessPrice.fromCourseId, countryCode: profile?.countryCode });
        coursePriceData.data.price = (coursePriceData.data.price < existCoursePriceData.data.price) ? 0 : (coursePriceData.data.price - existCoursePriceData.data.price);
      }
      if(!this.props.batchData){
        teacherlist = await this.getAllTeacherList(this.state.courselist[index].paidCourseId);
        if(teacherlist?.data?.content){
          teacherlist.data.content = teacherlist.data.content.filter(e => (e.workingStatus == "ACTIVE" && e.teacherDisabled == "false" && e.enableForPaid == "true" && e.isAcademicExpert == "false"));
        }
      }
      let accountDetailResponse = await this.getArtiumAccountDetail(this.state.courselist[index].paidCourseId);
      if (accountDetailResponse.data && accountDetailResponse.data.data) {
        accountDetail = accountDetailResponse.data.data;
      }
      if (coursePriceData?.data?.currency === 'USD') {
        price_INR = coursePriceData?.data?.price * 80
        price_USD = coursePriceData?.data?.price
      }
      else if (coursePriceData?.data?.currency === 'INR') {
        if(this.state.offline){
          price_USD = (coursePriceData?.data?.price / 80).toFixed(2)
          price_INR = coursePriceData?.data?.centerPriceMap[this.state.selectedBranch];
        }else{
          price_USD = (coursePriceData?.data?.price / 80).toFixed(2)
          price_INR = coursePriceData?.data?.price
        }        
      }
      this.setState({ currency: coursePriceData?.data?.currency });
      let selectedLmsCourse = lmsCourseList.find(e => e.courseId == this.state.courselist[index]?.lmsCourseId);
      let subscriptionCourse = (subscriptionCourseList || []).find(e => e.courseId == this.state.courselist[index].paidCourseId);
      let trialCourse = (studentCourseList || []).find(e => e?.courseId == this.state.courselist[index].demoCourseId);
      customer_id = subscriptionCourseList.length ? subscriptionCourseList[0].zohoSubscriptionId : customer_id;
      this.state.rows[rowindex].courseObj = selectedLmsCourse || {};
      this.state.rows[rowindex].courseObj.price_INR = price_INR;
      this.state.rows[rowindex].courseObj.price_USD = price_USD;
      if(!this.props.batchData){
        this.state.rows[rowindex].teacherList = teacherlist?.data?.content || [];
      }
      if(this.state.offline){
        this.state.rows[rowindex].monthCount = this.state.courselist[index].monthCount || 8;
      }
      this.state.rows[rowindex].courseId = this.state.courselist[index].paidCourseId;
      this.state.rows[rowindex].trialTeacher = trialCourse ? `${trialCourse.teacherFirstName} ${trialCourse.teacherLastName}` : '';
      this.state.rows[rowindex].courseName = this.state.courselist[index].courseName;
      this.state.rows[rowindex].discountedPercentage = 0; //(selectedLmsCourse.data.length > 11) ? (selectedLmsCourse.data.length < 20) ? 10 : 15 : 0;
      this.state.rows[rowindex].classesToPurchase = subscriptionCourse ? subscriptionCourse.classesAvailableToBuy : this.state.courselist[index].classsize;
    
      if(this.props.excessPrice && this.props.excessPrice.classNumber){
        this.state.rows[rowindex].classesToPurchase = this.props.excessPrice.classNumber;
      }
      this.state.rows[rowindex].courseObj.classLimit = subscriptionCourse ? subscriptionCourse.classesAvailableToBuy : this.state.courselist[index].classsize;
      // this.state.rows[rowindex].unit_price = this.state.currency == "INR" ? selectedLmsCourse.price_data.price_INR : selectedLmsCourse.price_data.price_USD;
      this.state.rows[rowindex].unit_price = this.state.rows[rowindex].isFree ? 0 : this.state.currency == "INR" ? price_INR :  price_USD ;
      // this.state.rows[rowindex].pricePerClass = this.state.currency == "INR" ? (selectedLmsCourse.price_data.price_INR / 1.18).toFixed(2) : selectedLmsCourse.price_data.price_USD;
      this.state.rows[rowindex].pricePerClass = this.state.rows[rowindex].isFree ? 0 : this.state.currency == "INR" ? (price_INR / 1.18).toFixed(2) : price_USD;

      //this.state.rows[rowindex].item_id = this.state.currency == "INR" ? selectedLmsCourse.item_id_INR : selectedLmsCourse.item_id_USD;
      this.state.rows[rowindex].account_id_prod = accountDetail.account_id;
      this.state.rows[rowindex].account_id_uat = accountDetail.account_id;
      this.state.rows[rowindex].item_id_prod = this.state.rows[rowindex].isFree ? accountDetail.item_id_free : accountDetail.item_id;
      this.state.rows[rowindex].item_id_uat = this.state.rows[rowindex].isFree ? accountDetail.item_id_free : accountDetail.item_id;
      if(this.props.excessPrice){
      this.state.rows[rowindex].account_id_prod = accountDetail.account_id_extra;
      this.state.rows[rowindex].account_id_uat = accountDetail.account_id_extra;
      this.state.rows[rowindex].item_id_prod = accountDetail.item_id_extra;
      this.state.rows[rowindex].item_id_uat = accountDetail.item_id_extra;
      }
      this.state.rows[rowindex].level = accountDetail.level;
      if(!this.props.batchData && !this.props.excessPrice){
        this.setState({
          courselist: configCourseList.filter(e =>
            (e.groupId == this.state.courselist[index].groupId))
        }, () => {
          (event.currentTarget || event.target).value = this.state.courselist.findIndex(e => e.paidCourseId == this.state.rows[rowindex].courseId)
        });
      }
      
      this.calc();
    } else {
      this.state.rows[rowindex] = { ...{}, ...item };
      this.calc();
    }
  }

  isFreeChange = idx => e => {
    this.state.rows[idx].isFree = e.target.checked;
    this.state.rows[idx].unit_price = e.target.checked?0:this.state.currency == "INR" ? this.state.rows[idx].courseObj?.price_INR || 0 :  this.state.rows[idx].courseObj?.price_USD ||0;
    this.state.rows[idx].pricePerClass = e.target.checked?0:this.state.currency == "INR" ? ((this.state.rows[idx].courseObj?.price_INR || 0) / 1.18).toFixed(2) :  this.state.rows[idx].courseObj?.price_USD ||0;
    this.state.rows[idx].discountedPercentage = 0;
    this.setState({
      rows: this.state.rows
    },() => {
      this.calc();
    });
  };

  currencyChange = (e, key) => {
    this.setState({
      currency: e.target.value
    }, this.calc);
  }
  selectStateChange = (e, key) => {
    this.setState({
      state: e.target.value,
      showErr: false
    });
  }


  handleSaveRow = (external = false) => {
    if(this.props.excessPrice && this.state.totalPrice < 1){
      notification.error({
        message: "",
        description: `Couldn't create payment link without minimum price.`,
      });
      return;
    }
    
    if (!profile.state && !this.state.state && this.state.currency == "INR" && !this.state.offline) {
      this.setState({
        showErr: true
      });
    }
    else if (!profile.state && this.state.state && this.state.currency == "INR" && !this.state.offline) {
      this.setState({ disabledBtn: true }, () => {
        this.handleUpdateProfile(() => {
          this.getSubcriptionCourse().then((result) => {
            customer_id = result?.data?.length ? result?.data[0]?.zohoSubscriptionId : customer_id;
            if(external){
              this.createExternalPaymentLink();
            }else{
              this.createPaymentLink();
            }            
          });
        });
      });

    }
    else {
      this.setState({ disabledBtn: true }, () => {
        if(external){
          this.createExternalPaymentLink();
        }else{
          this.createPaymentLink();
        }
      });

    }
  }

  buyFromWallet = () => {
    this.setState({disabledBtn:true}, () => {
      if(this.state.rows && this.state.rows.filter(e => !e.teacherId).length && !this.state.offline){
        this.setState({
          showErrTeacher: true
        });
        this.setState({ disabledBtn: false });
        return;
      }
      
      let courses = this.state.rows.filter(e => {
        delete e.courseObj;
        delete e.teacherList;
        return e.price || e.isFree;
      });
      const confirmCreate = window.confirm("Are you sure you want to create class using wallet money?");
      if (!confirmCreate) {
        return;
      }
      if(this.props.excessPrice){
        this.props.excessPrice.classNumber = courses[0].classesToPurchase;
      }
      let data = {
        "studentId": profile.studentId,
        "userId": profile.userId,
        "totalPrice": +this.state.totalPrice.toFixed(2),
        "firstName": _.startCase(_.get(profile, 'firstName', 'No-name')),
        "name": _.startCase(_.get(profile, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(profile, 'lastName', '')),
        "contact": _.get(profile, 'mobileNumber'),
        "email": _.get(profile, 'emailId'),
        "countryCode": _.get(profile, 'countryCode'),
        "currency": this.state.currency,
        "customer_id": (customer_id || (_.get(profile, 'zohoSubscriptionCustomerId') || "")),
        "place_of_supply": "",
        "token": _.get(profile.token, 'authorization'),
        "artiumCashPoint": +this.state.referral?.totalArtiumCash || 0,
        "myCashPoint": +this.state.referral?.myCash || 0,
        "artiumCash": this.state.useWallet ? (+this.state.artiumCash || 0) : 0,
        "myCash": this.state.useWallet ? (+this.state.myCash || 0) : 0,
        "conversionRate": +this.state.referral[`${this.state.currency}PointConversion`],
        "referralCode": this.state.referral?.referralCode || "",
        "version": "2.0",
        "freeCourse":false,
        "paymentFor": "directpayment",
        "excessAmount": !!this.props.excessPrice,
        "excessAmountData": this.props.excessPrice,
        "isNonConversionTransaction" : this.state.skipConvertion,
        "createdBy": loginDetails?.studentName || "",
        "gender": _.get(profile, 'gender'),
        "ageGroup": _.get(profile, 'ageGroup'),
        "studentType": _.get(profile, 'studentType'),
        "userType": _.get(profile, 'userType'),
        "isOffline": this.state.offline,
        "centreId": this.state.offline ? this.state.selectedBranch : "",
        "enrollmentFee": this.state.offline && this.state.enrollmentFee? +this.state.enrollmentFee : 0,
        "state": this.state.offline ? this.state.selectedBranch == "3" ? 'Maharashtra':'Tamil Nadu' : _.get(profile, 'state'),
        "courseList": courses
      }
      this.triggerWalletPayment(data).then(r => {
        mixpanel.track(constant. MIXPANEL_CREATE_PAYMENT_LINK_WALLET, {
          user:loginDetails?.studentName,
          ...data,
          api_res:r
        });
        if(r?.data?.success){
          //if(!isFreeCourse){
            this.getArtiumCash().then(c => {
              if(c?.data && c?.data.points){
                c.data.points = +(c.data.points * c.data[`${this.state.currency}PointConversion`]).toFixed(2);
              }
              if(c?.data){
                this.setState({referral: c?.data});
              }
             });
          //}
          notification.success({
            message: "Payment Success",
          });
          this.setState({ disabledBtn: false });
          this.reset();
          closeModal();
        }
      });
    });
  }

  buyFreeCourse = (isFree = false) => {
    if(!this.state.reason?.trim()){
      notification.error({
        message: "",
        description: `Please enter the reason for free class`,
      });
      return;
    }
    this.setState({disabledBtn:true}, () => {
      if(this.state.rows && this.state.rows.filter(e => !e.teacherId).length && !this.state.offline){
        this.setState({
          showErrTeacher: true
        });
        this.setState({ disabledBtn: false });
        return;
      }

      const confirmCreate = window.confirm("Are you sure you want to create free class?");
      if (!confirmCreate) {
        this.setState({ disabledBtn: false });
        return;
      }
      let courses = this.state.rows.filter(e => {
        delete e.courseObj;
        delete e.teacherList;
        return e.isFree;
      });
      let dt = new Date();
      let d = dt.getDate();
      let m = dt.getMonth() + 1;
      let y = dt.getFullYear();
      let data = {
        "studentId": profile.studentId,
        "batchId": this.props.batchData ? this.props.batchData.id : null,
        "userId": profile.userId,
        "totalPrice": +this.state.totalPrice.toFixed(2),
        "firstName": _.startCase(_.get(profile, 'firstName', 'No-name')),
        "name": _.startCase(_.get(profile, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(profile, 'lastName', '')),
        "contact": _.get(profile, 'mobileNumber'),
        "email": _.get(profile, 'emailId'),
        "countryCode": _.get(profile, 'countryCode'),
        "currency": this.state.currency,
        "customer_id": (customer_id || (_.get(profile, 'zohoSubscriptionCustomerId') || "")),
        "place_of_supply": "",
        "token": _.get(profile.token, 'authorization'),
        "artiumCashPoint":  0,
        "myCashPoint":  0,
        "artiumCash":  0,
        "myCash": 0,
        "conversionRate": 0,
        "referralCode": "",
        "version": "2.0",
        "isFreeClass":true,
        "paymentFor": "directpayment",
        "createdBy": loginDetails?.studentName || "",
        "skipInvoice" : this.state.skipInvoice,
        "paymentMode" : this.state.externalPayment ? this.state.paymentMode : "",
        "isNonConversionTransaction" : this.state.skipConvertion,
        "refNumber": this.state.refNumber,
        "gender": _.get(profile, 'gender'),
        "ageGroup": _.get(profile, 'ageGroup'),
        "studentType": _.get(profile, 'studentType'),
        "userType": _.get(profile, 'userType'),
        "isOffline": this.state.offline,
        "centreId": this.state.offline ? this.state.selectedBranch : "",
        "enrollmentFee": this.state.offline && this.state.enrollmentFee? +this.state.enrollmentFee : 0,
        "state": this.state.offline ? this.state.selectedBranch == "3" ? 'Maharashtra':'Tamil Nadu' : _.get(profile, 'state'),
        "invoice_date": (y + '-' + (m.toString().length < 2 ? '0' + m : m) + '-' + (d.toString().length < 2 ? '0' + d : d)),
        "courseList": courses,
        "freeClassReason": this.state.reason
      }
      if (courses.length){
        this.freeCourseAPI(data).then(r => {
          mixpanel.track(constant.MIXPANEL_CREATE_PAYMENT_LINK_FREECOURSE, {
            user:loginDetails?.studentName,
            ...data,
            api_res:r
          });
          if(r?.data?.success){
            notification.success({
              message: "Free class added successfully",
            });
            this.setState({ disabledBtn: false });
            this.reset();
            setTimeout(() => {
              window.location.reload();
            },500);
          }
        },(err) => {
          closeModal();
          setTimeout(() => {
            window.location.reload();
          },500);
        });
      }
    });
  }

  createPaymentLink = async () => {
    if(this.state.rows && this.state.rows.filter(e => !e.teacherId).length && !this.state.offline){
      this.setState({
        showErrTeacher: true
      });
      this.setState({ disabledBtn: false });
      return;
    }

    let courses = this.state.rows.filter(e => {
      delete e.courseObj;
      delete e.teacherList;
      return e.price || e.isFree;
    });

    if (courses.length) {
      if (this.props.batchData && !this.props.batchData.renewal) {
        const apires = await this.addStudentinBatch();
        if(!this.props.fromNewLink){
          if(apires.status == 200){
            notification.success({
              message: "",
              description: apires.data,
            });
          }else{
            notification.error({
              message: "",
              description: `Error while adding student.`,
            });
          }

        }
        console.log("apires =====", apires);
      }
      if(this.props.excessPrice){
         this.props.excessPrice.classNumber = courses[0].classesToPurchase;
      }
      let req = {
        "studentId": profile.studentId,
        "batchId": this.props.batchData ? this.props.batchData.id : null,
        "userId": profile.userId,
        "totalPrice": +this.state.totalPrice.toFixed(2),
        "name": _.startCase(_.get(profile, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(profile, 'lastName', '')),
        "contact": _.get(profile, 'mobileNumber'),
        "email": _.get(profile, 'emailId'),
        "countryCode": _.get(profile, 'countryCode'),
        "currency": this.state.currency,
        "customer_id": (customer_id || _.get(profile, 'zohoSubscriptionCustomerId')),
        "place_of_supply": "",
        "token": _.get(profile.token, 'authorization'),
        // "couponCode": this.state.referral ? this.state.referral.referral :'',
        // "walletAmount": this.state.useWallet ? this.state.referral.points : '',
        // "referralAmount": this.state.referral ? this.state.referral[`${this.state.currency}`] :'',
        // "amountFromWallet" : this.state.referral && this.state.referral.amountFromWallet ? this.state.referral.amountFromWallet : '',
        // "conversionRate" : this.state.useWallet && this.state.referral.points ? this.state.referral[`${this.state.currency}PointConversion`] : '',
        "artiumCashPoint": +this.state.referral?.totalArtiumCash || 0,
        "myCashPoint": +this.state.referral?.myCash || 0,
        "artiumCash": this.state.useWallet ? (+this.state.artiumCash || 0) : 0,
        "myCash": this.state.useWallet ? (+this.state.myCash || 0) : 0,
        "conversionRate": this.state.referral ? +this.state.referral[`${this.state.currency}PointConversion`] : 0,
        "referralCode": this.state.referral?.referralCode || "",
        "version": "2.0",
        "paymentFor": "directpayment",
        "excessAmount": !!this.props.excessPrice,
        "excessAmountData": this.props.excessPrice,
        "isNonConversionTransaction" : this.state.skipConvertion,
        "createdBy": loginDetails?.studentName || "",
        "gender": _.get(profile, 'gender'),
        "ageGroup": _.get(profile, 'ageGroup'),
        "studentType": _.get(profile, 'studentType'),
        "userType": _.get(profile, 'userType'),
        "isOffline": this.state.offline,
        "centreId": this.state.offline ? this.state.selectedBranch : "",
        "enrollmentFee": this.state.offline && this.state.enrollmentFee? +this.state.enrollmentFee : 0,
        "state": this.state.offline ? this.state.selectedBranch == "3" ? 'Maharashtra':'Tamil Nadu' :_.get(profile, 'state'),
        "courseList": courses,
        "timeZone": profile.timezone || "",
        "expiryDate": this.state.expiryDate
      }
      axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/order/directpayment`, req, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }

      })
        // .then(response => response.json())
        .then(json => {
          mixpanel.track(constant.MIXPANEL_CREATE_PAYMENT_LINK, {
            user:loginDetails?.studentName,
            ...req,
            api_res:json
          });
          if(this.props.batchData && this.props.fromNewLink){
            notification.success({
              message: "",
              description: "Payment Link Created Successfully",
            });
             //this.triggerMail({emailId:profile.emailId, studentName:profile.firstName, payment_link:json.payment_link});
          }
          if (this.state.useWallet) {
            this.getArtiumCash().then(c => {
              if (c?.data && c?.data.points) {
                c.data.points = +(c.data.points * c.data[`${this.state.currency}PointConversion`]).toFixed(2);
              }
              if (c?.data) {
                this.setState({ referral: c?.data });
              }
            });
          }
          this.setState({ disabledBtn: false });
          this.reset();
          if(this.props.excessPrice){
            closeModal(json.data.payment_link);
          }else{
            closeModal();
          }
        });
    } else {
      this.setState({ disabledBtn: false });
    }
  }

  createExternalPaymentLink = async () => {
    if(!this.state.paymentMode?.trim()){
        this.setState({
            errMessage: "Please select payment mode",
            showErrMessage: true
          });
          this.setState({ disabledBtn: false });
          return;
    }
    if (this.state.rows && this.state.rows.filter(e => !e.teacherId).length && !this.state.offline) {
      this.setState({
        showErrTeacher: true
      });
      this.setState({ disabledBtn: false });
      return;
    }

    const confirmCreate = window.confirm("Are you sure you want to create class?");
      if (!confirmCreate) {
        this.setState({ disabledBtn: false });
        return;
      }
      let courses = this.state.rows.filter(e => {
        delete e.courseObj;
        delete e.teacherList;
        return e.price;
      });
    if (courses.length && (customer_id || _.get(profile, 'zohoSubscriptionCustomerId'))) {
      if (this.props.batchData && !this.props.batchData.renewal) {
        const apires = await this.addStudentinBatch();
        if(!this.props.fromNewLink){
          if(apires.status == 200){
            notification.success({
              message: "",
              description: apires.data,
            });
          }else{
            notification.error({
              message: "",
              description: `Error while adding student.`,
            });
          }

        }
        console.log("apires =====", apires);
      }
      let dt = new Date();
      let d = dt.getDate();
      let m = dt.getMonth() + 1;
      let y = dt.getFullYear();
      if(this.props.excessPrice){
        this.props.excessPrice.classNumber = courses[0].classesToPurchase;
      }
      let req = {
        "studentId": profile.studentId,
        "batchId": this.props.batchData ? this.props.batchData.id : null,
        "userId": profile.userId,
        "totalPrice": +this.state.totalPrice.toFixed(2),
        "name": _.startCase(_.get(profile, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(profile, 'lastName', '')),
        "contact": _.get(profile, 'mobileNumber'),
        "email": _.get(profile, 'emailId'),
        "countryCode": _.get(profile, 'countryCode'),
        "currency": this.state.currency,
        "customer_id": (customer_id || _.get(profile, 'zohoSubscriptionCustomerId')),
        "place_of_supply": "",
        "token": _.get(profile.token, 'authorization'),
        // "couponCode": this.state.referral ? this.state.referral.referral :'',
        // "walletAmount": this.state.useWallet ? this.state.referral.points : '',
        // "referralAmount": this.state.referral ? this.state.referral[`${this.state.currency}`] :'',
        // "amountFromWallet" : this.state.referral && this.state.referral.amountFromWallet ? this.state.referral.amountFromWallet : '',
        // "conversionRate" : this.state.useWallet && this.state.referral.points ? this.state.referral[`${this.state.currency}PointConversion`] : '',
        "artiumCashPoint": +this.state.referral?.totalArtiumCash || 0,
        "myCashPoint": +this.state.referral?.myCash || 0,
        "artiumCash": this.state.useWallet ? (+this.state.artiumCash || 0) : 0,
        "myCash": this.state.useWallet ? (+this.state.myCash || 0) : 0,
        "conversionRate": this.state.referral ? +this.state.referral[`${this.state.currency}PointConversion`] : 0,
        "referralCode": this.state.referral?.referralCode || "",
        "version": "2.0",
        "paymentFor": "nonrazorpay",
        "excessAmount": !!this.props.excessPrice,
        "excessAmountData": this.props.excessPrice,
        "isNonConversionTransaction" : this.state.skipConvertion,
        "createdBy": loginDetails?.studentName || "",
        "gender": _.get(profile, 'gender'),
        "skipInvoice" : this.state.skipInvoice,
        "paymentMode" : this.state.paymentMode,
        "refNumber": this.state.refNumber,
        "ageGroup": _.get(profile, 'ageGroup'),
        "studentType": _.get(profile, 'studentType'),
        "userType": _.get(profile, 'userType'),
        "isOffline": this.state.offline,
        "centreId": this.state.offline ? this.state.selectedBranch : "",
        "enrollmentFee": this.state.offline && this.state.enrollmentFee? +this.state.enrollmentFee : 0,
        "state": this.state.offline ? this.state.selectedBranch == "3" ? 'Maharashtra':'Tamil Nadu' : _.get(profile, 'state'),
        "courseList": courses,
        "timeZone": profile.timezone || "",
        "invoice_date": (y + '-' + (m.toString().length < 2 ? '0' + m : m) + '-' + (d.toString().length < 2 ? '0' + d : d)),
        "expiryDate": this.state.expiryDate
      }
      let apiURL;
      if(this.props.excessPrice){
        apiURL = `${appEnvirontVariables.API.PAYMENT_URL}/api/payment/external-payment-course-change`;
      }else{
        apiURL = `${appEnvirontVariables.API.PAYMENT_URL}/api/payment/external-payment`;
      }
      axios.post(apiURL, req, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
        // .then(response => response.json())
        .then(json => {
          mixpanel.track(constant. MIXPANEL_CREATE_PAYMENT_LINK_NON_RAZORPAY, {
            user:loginDetails?.studentName,
            ...req,
            api_res:json
          });
            if(json && json.data.success){
                this.setState({ disabledBtn: false });
                this.reset();
                notification.success({
                    message: "Classes added successfully",
                  });
                closeModal();
                setTimeout(() => {
                    window.location.reload();
                  },500);
            }
        },(err) => {
          closeModal();
          setTimeout(() => {
            window.location.reload();
          },500);
        });
    } else {
      if(!courses.length){
        notification.error({
          message: "",
          description: `Couldn't create class without minimum price.`,
        });
      }
      this.setState({ disabledBtn: false });
    }
  }

 triggerMail = (obj) => { 
    //obj.emailId = "pandi@artiumacademy.com"
    if (!obj.emailId) {
      obj.emailId = 'bala@artiumacademy.com'
    }
    const msg = {
      to:  obj.emailId,
      from: {
        email: 'info@artiumacademy.com',
        name: 'Artium Academy'
      },
      templateId: 'd-40d8b91cdc5143308eec09bcbd3ea02d',
      dynamicTemplateData: {
        username: obj.studentName,
        Paymentlink: obj.payment_link
      },
    };
    axios
    .post(`https://pd9q7y3vyl.execute-api.ap-south-1.amazonaws.com/sendemail`,msg, {
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then(response => {
      notification.success({
        message: "",
        description: "Email sent Successfully",
      });
    })
    .catch(error => {
      console.error("Error sending email:", error);
    });
  }
  handleUpdateProfile = (cb) => {
    profile.state = this.state.state;
    viewProfile.state = this.state.state;
    let reqParam = {
      "birthDate": viewProfile?.birthDate,
      "mobileNumber": viewProfile?.mobileNumber,
      "countryCode": viewProfile?.countryCode,
      "emailId": viewProfile?.emailId,
      "firstName": viewProfile?.firstName || "",
      "lastName": viewProfile?.lastName || "",
      "genderId": (configData?.genders || []).find((e) => e.name?.toLowerCase() == viewProfile?.gender?.toLowerCase())?.id,
      "profilePhoto": viewProfile?.profilePhoto,
      //"password":"",
      "timezone": (configData?.timezones || []).find((e) => e.type?.toLowerCase() == viewProfile?.timezone?.toLowerCase())?.id,
      "userId": viewProfile?.userId,
      //"preferredCommunicationValue":"",
      "ageGroupId": (configData?.ageGroups || []).find((e) => e.name?.toLowerCase() == viewProfile?.ageGroup?.toLowerCase())?.id,
      "interestTags": viewProfile?.interestTags,
      "socialHandles": viewProfile?.socialHandles,
      "isOffline": this.state.offline,
      "centreId": this.state.offline ? this.state.selectedBranch : "",
      "enrollmentFee": this.state.offline && this.state.enrollmentFee? +this.state.enrollmentFee : 0,
      "state": this.state.offline ? this.state.selectedBranch == "3" ? 'Maharashtra':'Tamil Nadu' : viewProfile?.state,
      //"additionalInfo":"",
      //"profileInterests":""
    }
    axios.put(`${appEnvirontVariables.API.BASE_URL}/api/users/profile`, reqParam,
      { headers: { "Authorization": profile.token['authorization'] } }).then((r) => { cb() });
  }

  reset = () => {
    this.setState({
      rows: [{ ...{}, ...item }],
      courselist: [],
      showErr: false,
      showErrTeacher: false,
      state: '',
      useWallet: true,
      externalPayment: !!this.props.onlyExternal,
      skipConvertion: false,
      refNumber:null,
      skipInvoice:false,
      paymentMode:"Cash",
      paymentModeValue:"Cash",
      errMessage: "",
      showErrMessage: false,
      totalPrice: 0,
      currency: "", //(_.get(profile, 'countryCode') == "+91" || _.get(profile, 'countryCode') == "91")?"INR":"USD"
      enrollmentFee: "",
     // offline:false
    }, () => {
      //document.getElementById('select_currency').value = 'INR';
      this.setState({ courselist: configCourseList });

    });
  }
  calc = () => {
    let totalClasses = 0;
    this.state.totalPrice = 0;
    this.state.rows.forEach((v, i) => {
      if (v.courseObj) {
        // v.unit_price = this.state.currency == "INR" ? v.courseObj.price_data.price_INR : v.courseObj.price_data.price_USD;
        v.unit_price = v.isFree? 0: this.state.currency == "INR" ? v.courseObj.price_INR : v.courseObj.price_USD;

        //v.item_id = this.state.currency == "INR" ? v.courseObj.item_id_INR : v.courseObj.item_id_USD;
        // v.discountedPercentage = (!v.discountedPercentage && v.classesToPurchase > 12 )?
        //  (v.classesToPurchase < 20)? 10 : 15 :v.discountedPercentage;
        totalClasses += +(v.classesToPurchase || 0);
        let nofclass = (v.classesToPurchase || 0);
        let discper = v.isFree? 0: (v.discountedPercentage || 0);
        let classprice = v.isFree? 0: (v.unit_price || 0);
        v.discountedPrice = v.isFree? 0: +((nofclass * classprice) * (discper / 100)).toFixed(2);
        v.price = v.isFree? 0: +((nofclass * classprice) - v.discountedPrice).toFixed(2);
        v.amountBeforeTax = v.isFree? 0: +((nofclass * classprice) / 1.18).toFixed(2);
        v.gst = v.isFree? 0: +(v.amountBeforeTax *(18/100)).toFixed(2);
        this.state.totalPrice += v.price;
      }
    });
    if (this.state.voucher && this.state.rows.filter(e => e.classesToPurchase >= 12).length) {
      this.state.totalPrice = this.state.totalPrice - +(this.state.voucher[`value${this.state.currency}`]);
    }
    if(!this.props.batchData){
      if (this.state.useWallet && this.state.referral?.totalArtiumCash) {
        let artCash = this.deductOfferAmount(this.state.referral, totalClasses, this.state.currency);
        if (artCash) {
          this.setState({ artiumCash: artCash });
          //setArtiumCash(artCash);
          this.state.totalPrice -= artCash;
        } else {
          this.setState({ artiumCash: 0 });
          //setArtiumCash(0);
        }
      }
    }
    
    if (this.state.useWallet && this.state.referral?.myCash) {
      let mCash = (+this.state.referral.myCash) * (+this.state.referral[`${this.state.currency}PointConversion`]);
      if (+mCash >= this.state.totalPrice) {
        this.setState({ myCash: +this.state.totalPrice });
        //setMyCash(+total);
        this.state.totalPrice = 0;
      } else {
        this.setState({ myCash: +mCash });
        //setMyCash(+mCash);
        this.state.totalPrice -= +mCash;
      }
    }
    // if(this.state.referral && this.state.referral.referral ){
    //   this.state.totalPrice = this.state.totalPrice - +(this.state.referral[`${this.state.currency}`]);
    // }
    // if(this.state.useWallet && this.state.referral && this.state.referral.points){
    //   if(this.state.totalPrice >= this.state.referral.points){
    //     this.state.totalPrice = this.state.totalPrice - +(this.state.referral.points);
    //     this.state.referral.amountFromWallet = this.state.referral.points;
    //   }else{
    //     this.state.referral.amountFromWallet = this.state.totalPrice;
    //     this.state.totalPrice = 0;
    //   }
    //   this.setState({referral : this.state.referral});
    // }
    if(this.state.totalPrice <= 0 && !this.props.onlyExternal){
      this.setState({
        externalPayment: false,
      });
    }
    if(this.state.offline && this.state.enrollmentFee){
      this.state.totalPrice += +(this.state.enrollmentFee || 0);
    }
    this.setState({
      rows: this.state.rows,
      totalPrice: this.state.totalPrice
    });
  }
  deductOfferAmount = (referralData, courseCount, currency) => {
    let deductionAmount = 0;
    const artiumCashAmount = (+referralData.totalArtiumCash) * (+referralData[`${currency}PointConversion`]);
    const redemtionRule = artiumCashReduction?.redemptionRules.find((item) => +courseCount >= item.count);
    if (redemtionRule) {
      let fRedemtionAmount = +artiumCashAmount >= redemtionRule[`${currency}amount`] ? +redemtionRule[`${currency}amount`] : (+artiumCashAmount || 0);
      deductionAmount += (+fRedemtionAmount);
    }
    return deductionAmount;
  }
  walletChange = (event) => {
    this.setState({ useWallet: event.target.checked }, this.calc);
  }
  handleInputChange = event => {
    this.setState({ expiryDate: event.target.value });
  };
  handleExternalChange = e => {
        if(!(this.state.rows.filter(e => e.classesToPurchase || e.isFree).length != 0 && this.state.rows.filter(e => e.classesToPurchase || e.isFree).length == this.state.rows.length && this.state.totalPrice <= 0)){
          const { name, checked } = e.target;
          let obj = {};
          obj[name] = checked;
          this.setState(obj);
        }
  };
  handleRefChange = e => {
    this.setState({
        errMessage: "",
        showErrMessage: false
      });
      const { name, value } = e.target;
      let obj = {};
      obj[name] = value;
      this.setState(obj);
  };

  handleSkipChange = e => {
    //const re = /^[0-9\b]+$/;
    //if (e.target.value === '' || re.test(e.target.value)) {
        const { name, checked } = e.target;
        let obj = {};
        obj[name] = checked;
        this.setState(obj);        
  };
  handleEnrollmentFeeChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        const { name, value } = e.target;
        let obj = {};
        obj[name] = value;
        this.setState(obj,() => {
          this.calc();
        });    
      }
  };

  paymentModeChange = e => {
    //const re = /^[0-9\b]+$/;
    //if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({
            errMessage: "",
            showErrMessage: false
          });
        const { name, value } = e.target;
        let obj = {};
        obj[name] = value;
        this.setState(obj);
        if(value != "Others"){
            this.setState({paymentMode:value});
        }else{
            this.setState({paymentMode:""});
        }
        
  };
  otherReasonchange = e => {
    //const re = /^[0-9\b]+$/;
    //if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({
            errMessage: "",
            showErrMessage: false
          });
        const {value} = e.target;
        this.setState({paymentMode:value});
        
  };
  render() {
    return (
      <div>
        <div className="container" style={{ minWidth: '1050px' }}>
          <div className="row">
            <div className="col-lg-8">{this.state.offline ? <div>
                  <label htmlFor="branchname" style={{ fontWeight: 800 }}>
                    Branch Name:
                  </label>
                  <select
                    id="branchname"
                    value={this.state.selectedBranch}
                    onChange={(e) => this.handleBranchChange(e)}
                    style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                  >
                    {this.state.centerList?.map((centre, index) => (
                      <option value={centre.centreId || centre.centerId} key={index}>
                        {centre.centreName}
                      </option>
                    ))}
                  </select>
                </div>: ""}</div>
            {!this.props.onlyExternal ? <><div className="col-lg-2 font currency">
              Expiry At</div>
            <div className="col-lg-2 currency">
              <input className="form-control" style={{ width: '150px', textAlign: 'right', marginTop: '5px' }}
                type="date"
                id="expiry-date"
                value={this.state.expiryDate}
                disabled={this.props.batchData}
                onChange={this.handleInputChange}
              /></div></> : ""}
          </div>
          <div className="row clearfix">
            <div className="col-md-12 column">

              <div className="row">
                <div className="col-8 currency">
                  {this.state.referral && (this.state.myCash || this.state.artiumCash) ? <div style={{ 'padding': '15px', 'marginTop': '5px', 'color': 'green' }} className='wallet-txt'><label className='wallet-lbl'><input className='wallet-check' checked={this.state.useWallet} onChange={this.walletChange} type='checkbox'></input> <span>Use {+this.state.artiumCash + +this.state.myCash} {this.state.currency} of your {(+this.state?.referral.myCash + (this.props.batchData ? 0 : +this.state?.referral.totalArtiumCash))} {this.state.currency} wallet balance</span></label></div> : ''}
                </div>
                {this.state.currency ? <><div className="col-2 fnt" style={{ textAlign: 'right', marginTop: '5px' }}>Currency</div>
                <div className="col-2 pad"><select value={this.state.currency} className="form-control" style={{ appearance: 'auto' }}
                  dropdownClassName=''
                  placeholder=''
                  disabled
                  onChange={this.currencyChange}
                >
                  {["INR", "USD"].map((v, index) => {
                    return <option value={v}>{v}</option>
                  })}
                </select></div></> : ""}
              </div>
              
             

              {
                this.state.currency == 'INR' && this.state.showState && !this.state.offline &&

                <div className="row mb-6" style={{ marginTop: '-70px' }}>
                  {/* <div>{this.state.currency}{this.state.showState}</div> */}
                  <div className="col-lg-2 currency"></div>
                  <div className="col-lg-2 font" style={{ textAlign: 'right', marginTop: '5px' }}>*State</div>
                  <div className="col-4 padd"><select value={this.state.state} className="form-control" style={{ appearance: 'auto' }}
                    dropdownClassName=''
                    placeholder=''
                    onChange={this.selectStateChange}




                  >	 <option value="">Select state</option>
                    {_.size(_.get(this, 'state.stateList')) > 0 && this.state.stateList.map((state, index) => {
                      return <option value={state} >{state}</option>
                    })}

                  </select>

                  </div>
                </div>
              }
               
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center">Free Course</th>
                    <th className="text-center">Course Name</th>
                    <th className="text-center">Teacher</th>
                    {this.state.currency == "INR" &&
                      <th className="text-center">Price / Class</th>
                    }
                    {this.state.offline && <th className="text-center"> Months </th>}
                    <th className="text-center"> Qty </th>
                    {this.state.currency == "INR" &&
                      <th className="text-center">Amount (Before Tax)</th>
                    }
                    {this.state.currency == "INR" &&
                      <th className="text-center">GST (18%)</th>
                    }
                    {/* <th className="text-center"> Currency </th> */}
                    {this.state.currency != "INR" &&
                      <th className="text-center">Price per Class</th>
                    }

                    <th className="text-center"> Discount%</th>
                    <th className="text-center">Price</th>

                  </tr>
                </thead>
                <tbody>
                 
                  {this.state.rows.map((item, idx) => (
                    <tr id="" key={idx}>
                       <td>
                       <div style={{textAlign:'center', marginTop:'9px'}}>
                          <input disabled={!!this.props.excessPrice} className='' checked={this.state.rows[idx].isFree} onChange={this.isFreeChange(idx)} type='checkbox'></input>
                       </div>
                       </td>
                      {/* <td>{idx}</td> */}
                      <td className="ant-select">
                        {/* <input
                          type="text"
                          name="coursename"
                          value={this.state.rows[idx].coursename}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                          
                        /> */}
                        {(this.props.batchData || this.props.excessPrice) ? <select className="form-control" style={{ width: '100%', appearance: 'auto' }}
                          dropdownClassName='course-list-dropdown'
                          placeholder='Select Course'
                          data-rowindex={idx}
                          id="course-list"
                          value={this.state.rows[idx].courseIndex}
                          disabled={this.props.batchData || this.props.excessPrice}
                          title={this.state.rows[idx].courseName}
                          onChange={this.courseChange}
                        >
                          <option value="">Select Course</option>
                          {_.size(_.get(this, 'state.courselist')) > 0 && this.state.courselist.map((course, index) => {
                            return <option value={index} >{course['courseName']}</option>
                          })}
                        </select> : 
                        <select className="form-control" style={{ width: '100%', appearance: 'auto' }}
                          dropdownClassName='course-list-dropdown'
                          placeholder='Select Course'
                          data-rowindex={idx}
                          id="course-list"
                          onChange={this.courseChange}
                        >
                          <option value="">Select Course</option>
                          {_.size(_.get(this, 'state.courselist')) > 0 && this.state.courselist.map((course, index) => {
                            return <option value={index} >{course['courseName']}</option>
                          })}
                        </select>}
                      </td>
                      <td style={{ width: '165px' }}>
                        <select className="form-control" style={{ width: '100%', appearance: 'auto' }}
                          dropdownClassName=''
                          placeholder='Select Teacher'
                          data-rowindex={idx}
                          name="teacherId"
                          value={this.state.rows[idx].teacherId}
                          disabled={this.props.batchData || this.state.offline}
                          onChange={this.teacherChange(idx)}
                        >
                          <option value="">Select Teacher</option>
                          {_.size(this.state.rows[idx].teacherList) > 0 && this.state.rows[idx].teacherList.map((teacher, index) => {
                            return <option value={teacher['teacherId']} >{teacher['teacherName']}</option>
                          })}
                        </select>
                      </td>
                      {this.state.currency == "INR" &&
                        <td>
                          <input style={{ width:"60px"}}
                            type="text"
                            name="pricePerClass"
                            readOnly
                            value={this.state.rows[idx].pricePerClass}
                            onChange={this.handleChange(idx)}
                            className="form-control"
                          />
                        </td>
                      }
                       {this.state.offline && <td>
                        <input
                          type="text"
                          name="classesToPurchase"
                          value={this.state.rows[idx].classesToPurchase / (this.state.rows[idx].monthCount || 8)}
                          onChange={this.monthChange(idx)}
                          // disabled={this.props.batchData}
                          data-limit={999999}
                          className="form-control"
                        />
                      </td>}
                      <td>
                        <input style={{ width:"60px"}}
                          type="text"
                          name="classesToPurchase"
                          value={this.state.rows[idx].classesToPurchase}
                          onChange={this.handleChange(idx)}
                          disabled={this.state.offline}
                          data-limit={999999}
                          className="form-control"
                        />
                      </td>

                      {this.state.currency == "INR" &&
                        <td>
                          <input style={{ width:"75px"}}
                            type="text"
                            name="amontbeforetax"
                            readOnly
                            value={this.state.rows[idx].amountBeforeTax}
                            onChange={this.handleChange(idx)}
                            className="form-control"
                          />
                        </td>
                      }
                      {this.state.currency == "INR" &&
                        <td>
                          <input style={{ width:"70px"}}
                            type="text"
                            name="gst"
                            readOnly
                            value={this.state.rows[idx].gst}
                            onChange={this.handleChange(idx)}
                            className="form-control"
                          />
                        </td>
                      }
                      {/* <td>
                        <input
                          type="text"
                          name="currency"
                          value={this.state.rows[idx].currency}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td> */}
                      {this.state.currency != "INR" &&
                        <td>
                          <input
                            type="text"
                            name="unit_price"
                            readOnly
                            value={this.state.rows[idx].unit_price}
                            onChange={this.handleChange(idx)}
                            className="form-control"
                          />
                        </td>}
                      <td>
                        <input
                          type="text" style={{ width:"50px"}}
                          name="discountedPercentage"
                          readOnly = {this.state.rows[idx].isFree}
                          value={this.state.rows[idx].discountedPercentage}
                          onChange={this.handleChange(idx)}
                          data-limit={'99'}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="price"
                          readOnly
                          value={this.state.rows[idx].price}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>

                    </tr>
                  ))}
                  <tr>
                    {/* {this.state.currency == "INR" && 
                  <td colSpan={7} style={{borderRight: "none", fontWeight: "bold"}} ><div className="totalprice">{this.state.voucher && this.state.rows && this.state.rows.filter(e => e.classesToPurchase >= 12).length ? <span style={{'color':'green','fondSize':'12px'}}>VOUCHER APPLIED {this.state.currency==='INR'?'₹':'$'}{this.state.voucher[`value${this.state.currency}`]}</span> : ''} Total Price :</div></td>
                  }
                   {this.state.currency != "INR" && 
                  <td colSpan={5} style={{borderRight: "none", fontWeight: "bold"}} ><div className="totalprice">{this.state.voucher && this.state.rows && this.state.rows.filter(e => e.classesToPurchase >= 12).length ? <span style={{'color':'green','fondSize':'12px'}}>VOUCHER APPLIED {this.state.currency==='INR'?'₹':'$'}{this.state.voucher[`value${this.state.currency}`]}</span> : ''} Total Price :</div></td>
                  }   */}
                    {this.state.currency == "INR" &&
                      <td colSpan={this.state.offline? 9:8} style={{ borderRight: "none", fontWeight: "bold" }} ><div className="totalprice">
                        {this.state.offline && this.state.enrollmentFee ? <span style={{ 'paddingRight': '10px', 'color': 'green', 'fondSize': '12px' }}>{'₹ '}{this.state.enrollmentFee} Added for Enrollment fee on final Amount</span> : ''}
                        {this.state.referral && this.state.referral.referral && this.state.rows && this.state.rows.filter(e => e.classesToPurchase > 0).length ? <span style={{ 'paddingRight': '10px', 'color': 'green', 'fondSize': '12px' }}>{this.state.currency === 'INR' ? '₹' : '$'}{this.state.referral[`${this.state.currency}`]} discount applied for referral code {this.state.referral.referral} on final Amount</span> : ''}
                        {this.state.referral && this.state.rows && this.state.rows.filter(e => e.classesToPurchase >= 1).length && this.state.useWallet && this.state.referral.points ? <span style={{ 'paddingRight': '10px', 'color': 'green', 'fondSize': '12px' }}>{this.state.currency === 'INR' ? '₹' : '$'}{this.state.referral.amountFromWallet > 0 ? this.state.referral.amountFromWallet : 0} discounted from Wallet</span> : ''}
                        Total Price :</div>
                        {!(this.state.totalPrice > 0) && !this.props.onlyExternal && this.state.rows.filter(e => e.isFree).length ?  (
  <div>
    <label><span style={{color:"red"}}>*</span>Reason for free class:</label>
    <input value={this.state.reason} style={{display:"inline-block",width:"400px", padding:"5px", textAlign:"left",marginLeft:"10px",textAlign:"left"}} className='form-control' placeholder='Please enter the reason for free class' onChange={(e) => this.setState({ reason: e.target.value })} />
  </div>
):""}

                        </td>
                    }
                    {this.state.currency != "INR" &&
                      <td colSpan={this.state.offline? 7:6} style={{ borderRight: "none", fontWeight: "bold" }} ><div className="totalprice">
                        {this.state.referral && this.state.referral.referral && this.state.rows && this.state.rows.filter(e => e.classesToPurchase > 0).length ? <span style={{ 'paddingRight': '10px', 'color': 'green', 'fondSize': '12px' }}>{this.state.currency === 'INR' ? '₹' : '$'}{this.state.referral[`${this.state.currency}`]} discount applied for referral code {this.state.referral.referral} on final Amount</span> : ''}
                        {this.state.referral && this.state.rows && this.state.rows.filter(e => e.classesToPurchase >= 1).length && this.state.useWallet && this.state.referral.points ? <span style={{ 'paddingRight': '10px', 'color': 'green', 'fondSize': '12px' }}>{this.state.currency === 'INR' ? '₹' : '$'}{this.state.referral.amountFromWallet > 0 ? this.state.referral.amountFromWallet : 0} discounted from Wallet</span> : ''}
                        Total Price :</div>
                        {!(this.state.totalPrice > 0) && !this.props.onlyExternal && this.state.rows.filter(e => e.isFree).length ?  (
  <div>
    <label><span style={{color:"red"}}>*</span>Reason for free class:</label>
    <input value={this.state.reason} style={{display:"inline-block",width:"400px", padding:"5px", textAlign:"left",marginLeft:"10px",textAlign:"left"}} className='form-control' placeholder='Please enter the reason for free class' onChange={(e) => this.setState({ reason: e.target.value })} />
  </div>
) : ""}

                        </td>
                    }
                    <td style={{ borderLeft: "none" }}>
                      {this.state.currency && this.state.currency == "INR" &&
                        <span style={{ position: 'absolute', fontWeight: 'bold', marginTop: '9px' }}>₹</span>
                      }
                      {this.state.currency && this.state.currency != "INR" &&
                        <span style={{ position: 'absolute', fontWeight: 'bold', marginTop: '9px' }}>$</span>
                      }
                      <input
                        style={{ width:"100px", border: "none", background: "none", fontWeight: "bold" }}
                        type="text"
                        name="totalPrice"
                        readOnly
                        value={this.state.totalPrice}
                        className="form-control"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{display: "block"}}>
                <div className='mr-2 mb-3' style={{display: "inline-block"}}>
                <b>Record External Payment : </b><input disabled={!!this.props.onlyExternal} style={{cursor:"pointer"}} type='checkbox' name="externalPayment"
                            checked={this.state.externalPayment}
                            onChange={this.handleExternalChange}
                            className="wallet-check"/>
                </div>
                {!this.state.offline && <div className='mr-2 mb-3' style={{display: "inline-block"}}>
                <b>Skip Convertion Credit : </b><input style={{cursor:"pointer"}} type='checkbox' name="skipConvertion"
                            checked={this.state.skipConvertion}
                            onChange={this.handleSkipChange}
                            className="wallet-check"/>
                </div>}
                {this.state.offline ? <div className='mr-2 mb-3' style={{display: "inline-block"}}>
                 <b>Add Enrollment Fee : </b><input
                              style={{fontWeight: "bold", cursor:"text" }}
                              type="text"
                              name="enrollmentFee"
                              value={this.state.enrollmentFee}
                              onChange={this.handleEnrollmentFeeChange}
                              className="form-control refnumber"
                          />
                          {/* <input style={{cursor:"pointer"}} type='checkbox' name="addEnrollmentFee"
                            checked={this.state.addEnrollmentFee}
                            onChange={this.handleEnrollmentFeeChange}
                            className="wallet-check"/> */}
                </div>:""}
              </div>
              {this.state.externalPayment ?               
                <div style={{display: "block"}}>
                  <div className='mb-3 mr-2' style={{display: "inline-block"}}>
                      <b>Payment Reference Number : </b><input
                              style={{fontWeight: "bold", cursor:"text" }}
                              type="text"
                              name="refNumber"
                              value={this.state.refNumber}
                              onChange={this.handleRefChange}
                              className="form-control refnumber"
                          />
                  </div>
                  <div className='mr-2 mb-3' style={{display: "inline-block"}}>
                  <b><span style={{color:"red"}}>*</span>Payment Mode : </b><select value={this.state.paymentModeValue} className="form-control refnumber mr-2" style={{ appearance: 'auto' }}
                    dropdownClassName=''
                    placeholder=''
                    name="paymentModeValue"
                    onChange={this.paymentModeChange}
                  >
                    {["Stripe", "PayPal","Bank transaction", "UPI", "Cash", "Others" ].map((v, index) => {
                      return <option value={v}>{v}</option>
                    })}
                  </select>
                  {this.state.paymentModeValue == "Others" ? <input className='form-control mr-2 mb-3 otherPayment' type='input'value={this.state.paymentMode} onChange={this.otherReasonchange} placeholder='Please enter other payment mode'/> : ""}
                  </div>
                  <div className='mr-2 mb-3' style={{display: "inline-block"}}>
                  <b>Skip Invoice : </b><input style={{cursor:"pointer"}} type='checkbox' name="skipInvoice"
                              checked={this.state.skipInvoice}
                              onChange={this.handleSkipChange}
                              className="wallet-check"/>
                  </div>
                </div> 
              : ""}
              {!this.props.batchData && !this.props.excessPrice ? <button onClick={this.handleAddRow} className="btn btn-primary">
                Add Course
              </button> : ""}
              {this.state.useWallet && this.state.referral && (this.state.artiumCash || this.state.myCash) && this.state.totalPrice == 0 && this.state.rows.filter(e => e.classesToPurchase >= 1).length ? <button
                onClick={this.buyFromWallet}
                className="btn btn-primary float-right" disabled={this.state.disabledBtn}
              >
                {this.state.disabledBtn ? 'Loading' : 'Buy Course By Wallet Amount'}
              </button> : !(this.state.totalPrice > 0) && !this.props.onlyExternal && this.state.rows.filter(e => e.isFree).length ? 
              <button
              onClick={() => {this.buyFreeCourse(true)}}
              className="btn btn-primary float-right" disabled={this.state.disabledBtn}
              >
                {this.state.disabledBtn ? 'Loading' : 'Buy Course Without Payment'}
              </button> :
              this.state.externalPayment ?  
                <button
                  onClick={() => {this.handleSaveRow(true)}}
                  className="btn btn-primary float-right" disabled={this.state.disabledBtn}
                >
                  {this.state.disabledBtn ? 'Loading' : 'Create Class'}
                </button> :
                <button
                  onClick={() => {this.handleSaveRow()}}
                  className="btn btn-primary float-right" disabled={this.state.disabledBtn}
                >
                  {this.state.disabledBtn ? 'Loading' : 'Save'}
                </button>}
                {!this.props.batchData && !this.props.excessPrice ? <button
                onClick={this.reset}
                className="btn btn-secondary float-right mr-2"
              >
                Reset
              </button> : ""}
              {this.state.showErrMessage && <p className="float-right mr-2" style={{ color: 'red', display: 'inline-block' }}>{this.state.errMessage}</p>}
              {this.state.showErr && <p className="float-right mr-2" style={{ color: 'red', display: 'inline-block' }}>*Please select state</p>}
              {this.state.showErrTeacher && <p className="float-right mr-2" style={{ color: 'red', display: 'inline-block' }}>*Please select Teacher</p>}
            </div>
          </div>
        </div>
        {this.state.loading ? <div style={{
          position: "fixed",
          background: "rgb(128 128 128 / 12%)",
          left:0,
          top:0,
          bottom:0,
          right:0,
          zIndex: "9"}}><div style={{display:"flex", justifyContent:"center", marginTop:"30%"}}>
          <Spin tip="Loading..." size="large"></Spin>
          </div>
        </div>: ""}
        
      </div>
    );
  }
}

// render(<Courselist />, document.getElementById("root"));
export default Courselist;