

import React, { useState, useEffect } from "react";
import { Card, Modal, Button, Form, Select, Rate, notification,Spin,DatePicker } from "antd";
import appEnvirontVariables from "../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import axios from "axios";
import "./style.css";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { PlayCircleOutlined, DownloadOutlined } from "@ant-design/icons";

const AahaMoment = (props) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { RangePicker } = DatePicker;
  const [ahaMoments, setAhaMoments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false);
  const [courseList, setCourseList] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState(null);
  const [ratings, setRatings] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    configjson();
  }, []);

  //course dropdown show
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };

  // for card details show API
  const fetchData = async (key) => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      let requestBody = {
        filter: {}
      };
      if (startDate && endDate) {
        requestBody.date = {
          startDate: startDate,
          endDate: endDate
        };
      }
  
      let url;
  
      if (selectedCourseId) {
        requestBody.filter.courseId = selectedCourseId;
      }
  
      if (selectedGender) {
        requestBody.filter.studentGender = selectedGender;
      }
  
      if (selectedAgeGroup) {
        requestBody.filter.studentAgeGroup = selectedAgeGroup;
      }
      if (values.ratings) {
        requestBody.filter.ratings = values.ratings;
      }
      if (key == "pagination") {
        url = `${
          appEnvirontVariables.API.GET_MEDIA_URL
        }/api/admin/ahamoments?pageSize=20
              ${
                lastEvaluatedKey
                  ? `&lastEvaluatedKey=${encodeURIComponent(
                      JSON.stringify(lastEvaluatedKey)
                    )}`
                  : ""
              }`;
      } else {
        url = `${appEnvirontVariables.API.GET_MEDIA_URL}/api/admin/ahamoments?pageSize=20`;
      }
  
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      });
  
      if (response?.data?.data?.Items?.length === 0) {
        if (key != "pagination") {
          setAhaMoments([]);
        }
      } else {
        const momentsData = response.data.data.Items.map((moment) => ({
          ...moment,
          ratings: moment.ratings || 0,
        }));
  
        const filteredMoments = response.data.data.Items.filter((moment) => {
          if (moment.subType === "Student") {
            return moment.userId === moment.studentId;
          }
        
          return true;
        });
  
        if (key == "pagination") {
          setAhaMoments((prevMoments) => [...prevMoments, ...filteredMoments]);
        } else {
          setAhaMoments(filteredMoments);
          console.log(filteredMoments,"filteredMoments====")
        }
        setLastEvaluatedKey(response.data.data.LastEvaluatedKey);
  
        const updatedRatings = { ...ratings };
        filteredMoments.forEach((moment, index) => {
          const originalIndex = response.data.data.Items.findIndex(
            (item) => item.fileName === moment.fileName
          );
          updatedRatings[index + (ahaMoments.length || 0)] =
            momentsData[originalIndex].ratings;
        });
        setRatings(updatedRatings);
        console.log("Updated ratings:", updatedRatings);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  // for consent API
    const getConsents = (userId) => {
  return axios.get(
    `${appEnvirontVariables.API.BASE_URL}/api/users/user/details?userId=${userId}`,
    { headers: { Authorization: token["authorization"] } }
  );
};

  //for rating API
  const handleRatingChange = (index, value) => {
    const updatedRatings = { ...ratings };
    updatedRatings[index] = value;
    setRatings(updatedRatings);

    const momentData = ahaMoments[index];
    const postData = {
      fileName: momentData.fileName,
      userId: momentData.userId,
      ratings: value,
      user: momentData.studentName,
    };
    axios
      .post(
        `${appEnvirontVariables.API.GET_MEDIA_URL}/api/admin/aha-moment/rating`,
        postData,
        {
          headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Rating submitted successfully!");
        notification.success({
          message: "Rating submitted successfully!",
        });
      })
      .catch((error) => {
        console.error("Error while submitting rating:", error);
        notification.error({
          message: "Error",
          description: "An error occurred while submitting rating.",
        });
      });
  };

  const handleFetchData = () => {
    fetchData(selectedCourseId, selectedGender, selectedAgeGroup);
  };

  //videoplayer method.
  const handlePlayerIconClick = (videoUrl,index) => {
    setSelectedVideoUrl(videoUrl);
    setSelectedIndex(index); 
    setTimeout(() => {
      setModalVisible(true);
    }, 500);
  };
  // loadmore data from API.
  const handleLoadMore = () => {
    console.log("handleload")
    fetchData("pagination");
  };

  //for download option
  const downloadVideo = (data) => {
    // Create a link element
    const downloadLink = document.createElement("a");
    downloadLink.href = data.url;
    downloadLink.download = data.displayName;

    // Append the link to the body
    document.body.appendChild(downloadLink);

    // Trigger the download
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  };

  //for particular card detail download method.
  const handleConsent = (index, data) => {

    getConsents(data.userId).then((res) => {

      console.log(res, "res==");
      setAhaMoments((prevMoments) => {
        const updatedMoments = [...prevMoments];
        updatedMoments[index].consentGiven =
          res?.data?.studentProfile?.tncAccepted === true
            ? "green"
            : res?.data?.studentProfile?.tncAccepted === false
            ? "red"
            : "darkorange";
            console.log(consentGiven,"consentGiven==")
        return updatedMoments;
      });
    });
  };
  
// const getColor = (consentGiven) => {
//   switch (consentGiven) {
//     case true:
//       return "green";
//     case false:
//       return "red"; 
//     case null:
//       return "darkorange"; 
//     default:
//       return "#1890ff"; 
//   }
// };
  const onDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  return (
    <div>
   <div>
            


                <div className="report-filter-conatiner">
        <span><b></b></span>
                           <div style={{ display: "flex", gap: "16px", position: "fixed",zIndex:1,backgroundColor:"white",height:"180px",marginTop:"-205px",width:"100%" }}>

            <div className="row" style={{"paddingTop": "30px"}}>
             
          
             <Form
       
        form={form}
      
       
        autoComplete="off"
        >
           <div className="row" style={{ display: 'flex', justifyContent: 'space-between',gap:"90px" }}>
              <div className="col">
              <Form.Item
          label="Start Date"
          name="startDate"
        
          style={{display:"contents"}}
        >
          <RangePicker style={{width:"250px"}} onChange={onDateRangeChange} />
        </Form.Item>
            </div>
            <div className="col">
            <Form.Item name="courseId" label="Course"
                    style={{display:"contents"}}
                    >
                <Select
                  placeholder="Select the Course"
                  allowClear
                  style={{ width: "224px", background: "#f5f8fa" }}
                  onChange={(value) => setSelectedCourseId(value)}
                >
                  {_.map(_.get(courseList, "courses"), (course, eachParent) => {
                    return _.map(_.get(course, "tags"), (tag, each) => {
                      console.log("tags", tag);
                      console.log("course", course);
                      return (
                        <Option
                        value={_.get(tag, "paidCourseId")}
                        key={eachParent + "-" + each}
                        >
                          {_.get(course, "name")}-{_.get(tag, "name")}
                        </Option>
                      );
                    });
                  })}
                </Select>
              
              </Form.Item>
                        </div> 
                        <div className="col">  
                        <Form.Item
              name="studentGender" label="Gender" style={{display:"contents"}}
              >
                <Select
                  placeholder="Select Gender"
                  style={{ width: "136px", background: "#f5f8fa" }}
                  allowClear
                  onChange={(value) => setSelectedGender(value)}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
                        </div> 
  </div>
  <div className="row" style={{ display: 'flex', justifyContent: 'space-between',gap:"90px" }}>
  <div className="col">
  <Form.Item
                         label="Age group"
                          name={"studentAgeGroup"}
                          style={{display:"contents"}}
                        >
                          <Select placeholder="Select Age group"    allowClear
                            onChange={(value) => setSelectedAgeGroup(value)}
                          style={{ width: "250px", background: "#f5f8fa" }}>
                            {_.map(
                              _.get(courseList, "ageGroups"),
                              (agegroups, each) => {
                                console.log("agegroups", agegroups);
                                return (
                                  <Option
                                    value={_.get(agegroups, "name")}
                                    key={each}
                                  >
                                    {_.get(agegroups, "name")}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                        </Form.Item>
</div>
<div className="col">
<Form.Item
                         label="Ratings"
                         name={"ratings"} style={{display:"contents"}}
                        >
<Select placeholder="Select Ratings"    style={{ width: "224px", background: "#f5f8fa" }}  allowClear>
  <Option value={1}>1</Option>
  <Option value={2}>2</Option>
  <Option value={3}>3</Option>
  <Option value={4}>4</Option>
  <Option value={5}>5</Option>

</Select>
                        </Form.Item>
</div>
<div className="col" style={{marginTop:"35px"}}>
<Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "20px" }}
                  onClick={handleFetchData}
                >
                  Submit
                </Button>
          </div>
          
    </div>


       
       
        </Form>
          </div>
</div>
          </div>
      </div>
      <div style={{marginTop:"162px"}}> 
      {loading ? ( 
          <div style={{ textAlign: "center" }}>
          <Spin size="large" tip="Loading..." style={{ textAlign: "center", marginTop:"100px" }} />
        </div>
      ) : (
      <div style={{marginTop:"20px"}}>
      {ahaMoments.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {Array.isArray(ahaMoments) &&
            ahaMoments.map((moment, index) => (
              <Card
                hoverable
                className="customCard"
                key={index}
                style={{
                  width: "270px",
                  position: "relative",
                  boxShadow: "inset 0 0 10px rgba(0,0,0,0.3)",
                 
                }}
                cover={
                  <img
                    src={moment.thumbnailUrl}
                    alt="Aaha Moment"
                    style={{
                      width: "270px",
                      height: "477px",
                      objectFit: "cover",
                    }}
                    onClick={() => handlePlayerIconClick(moment.url,index)} 
                  />
                }
              >
                <div
                  title="Click for Consent Status"
                  style={{
                    position: "absolute",
                    top: "7%",
                    left: "77%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "10px",
                    fontWeight: "bold",
                    backgroundColor: moment.consentGiven === "green" ? 
                    "green" : moment.consentGiven === "red" ?
                     "red" : moment.consentGiven === "darkorange" ? 
                     "darkorange" : "#1890ff",
                    borderRadius: "5px",
                    padding: "1px 10px",
                    color: "white",
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleConsent(index, moment);
                }}
                >
{moment.consentGiven === "green" && "Accepted"}
    {moment.consentGiven === "red" && "Denied"}
    {moment.consentGiven === "darkorange" && "Pending" }
   
   {!moment.consentGiven ? "consent" : ""}
                  {moment.consentGiven == "green" ? (
                    <DownloadOutlined
                      title="Download Video"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        downloadVideo(moment);
                    }}
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "-22px",
                        fontSize: "1.5em",
                        color: "white",
                        padding: "1px 2px",
                        borderRadius: "5px",
                        background: "#2b2c2db3",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <PlayCircleOutlined
                  onClick={() => handlePlayerIconClick(moment.url,index)}
                  style={{
                    position: "absolute",
                    top: "35%",
                    left: "47%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "3em",
                    color: "white",
                  }}
                />
                <div className="overlay">
                  <p style={{marginTop:"0rem", marginBottom:"0rem"}}>
                    <span style={{ fontWeight: "bold" }}>Age Group:</span>{" "}
                    {moment.studentAgeGroup}
                  </p>
                  <p style={{marginTop:"0rem", marginBottom:"0rem"}}>
                    <span style={{ fontWeight: "bold" }}>Gender:</span>{" "}
                    {moment.studentGender}
                  </p>
                  <p style={{marginTop:"0rem", marginBottom:"0rem"}}>
                    <span style={{ fontWeight: "bold" }}>Teacher:</span>{" "}
                    {moment.teacherName}
                  </p>
                  <p style={{marginTop:"0rem", marginBottom:"0rem"}}>
                    <span style={{ fontWeight: "bold" }}>Student:</span>{" "}
                    {moment.studentName}
                  </p>
                  <p style={{marginTop:"0rem", marginBottom:"0rem"}}>
                    <span style={{ fontWeight: "bold" }}>Course:</span>{" "}
                    {moment.course}
                  </p>
                  <div className="" style={{ marginLeft: "40px",cursor: "not-allowed",pointerEvents: "none"}}>
                    {/* <Rate
                      value={ratings[index]}
                      onChange={(value) => handleRatingChange(index, value)}
                      style={{ color: "#fadb14" }}
                    /> */}
                     <Rate value={ratings[index]} style={{ color: "#fadb14",cursor: "not-allowed",pointerEvents: "none" }} disabled />
                  </div>
                </div>
              </Card>
            ))}

          {modalVisible ? (
            <Modal
              width={408}
              title="Aha Moment"
              visible={modalVisible}
              style={{ top: 5 }}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
               <div style={{ display:"flex",justifyContent:"center",marginTop:"-24px",backgroundColor:"black" }}>
              { console.log("Selected index:", selectedIndex) }
    <Rate value={ratings[selectedIndex]} onChange={(value) => handleRatingChange(selectedIndex, value)} style={{ color: "#fadb14" }} />
  </div>
              <video width="360" height="640" controls autoPlay controlsList="nodownload">
                <source src={selectedVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Modal>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          No data found
        </div>
      )}
      </div>
      )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {ahaMoments.length > 0 && lastEvaluatedKey &&(
          <Button
            style={{ fontWeight: "bold" }}
            type="primary"
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  list: (details) => dispatch(global.redux.action.teachers.list(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AahaMoment);


