// FilterControls.js
import React from 'react';
import { Select, Button } from 'antd';
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const FilterControls = ({ country, setCountry, status, setStatus, aeFound, setAeFound, timezone, setTimezone, uniqueTimezones }) => {
  
  // Function to reset all filters
  const clearFilters = () => {
    setCountry(null);
    setStatus(null);
    setAeFound(null);
    setTimezone(null); // Clear timezone filter as well
  };

  return (
    <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', alignItems: 'center' }}>
      
      {/* Demo Status Filter */}
      <Select
        placeholder="Filter by Demo Status"
        style={{ width: 150 }}
        value={status}
        onChange={setStatus}
        allowClear
      >
        <Option value="SCHEDULED">Scheduled</Option>
        <Option value="NOT CONDUCTED">Not Conducted</Option>
        <Option value="CANCELLED">Cancelled</Option>
        <Option value="RESCHEDULED">Rescheduled</Option>
        <Option value="ONGOING">Ongoing</Option>
        <Option value="COMPLETED">Completed</Option>
        <Option value="NOSHOW">No show</Option>
      </Select>

      {/* AE Found Filter */}
      {/* <Select
        placeholder="Filter No-AE Found Cases"
        style={{ width: 180 }}
        value={aeFound}
        onChange={setAeFound}
        allowClear
      >
        <Option value="AE_FOUND">AE Found</Option>
        <Option value="NO_AE">No AE Found</Option>
      </Select> */}

      {/* Timezone Filter */}
      <Select
        placeholder="Filter by Timezone"
        style={{ width: 180 }}
        value={timezone}
        onChange={setTimezone}
        allowClear
      >
        {uniqueTimezones.map((tz, index) => (
          <Option key={index} value={tz}>{tz}</Option>
        ))}
      </Select>

      {/* Clear Filters Button */}
      {/* <Button type="primary" icon={<CloseOutlined />} onClick={clearFilters}>
        Clear Filters
      </Button> */}
    </div>
  );
};

export default FilterControls;
