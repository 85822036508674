import React, { useEffect, useState } from "react";
import "./student-profile-style.css";
import { useSearchParams, useLocation } from "react-router-dom";
import Profile from "../view-details/index";
import { connect } from "react-redux";
import axios from "axios";
import SecureLS from "secure-ls";
import _ from "lodash";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  InputNumber,
  Checkbox,
  Select,
  DatePicker,
  Radio,
  notification,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";

const { Option } = Select;
const dateformat = "DD/MM/YYYY";
const AddStudentProfilePage = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [form] = Form.useForm();
  let history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [courseList, setCourseList] = useState();
  const [languageslist, setlanguageslist] = useState();
  const [teacherId] = useSearchParams();
  const [agegroups, setagegroups] = useState();
  const [dob,setDob] = useState();
const [countryChange, setCountryChange] =useState();

  const [state, setState] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false
  });
  const handleData = name => {
    name = "one";
    setState(
      {
        ...state,
        [name]: !state[name]
      },
      console.log(state[name])
    );
  };
  //api call
  useEffect(() => {
    configjson();
  }, []);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      );
    }
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) { 
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };
  const agegrouplist = () => {
    props.getagroups({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setagegroups(data);
        // form.setFieldsValue({
        //     course: _.uniqBy(_.map(_.get(data, 'courses'), 'name') )
        // })
      },
    });
  };

  
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  useEffect(() => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  }, []);
  const languagelist = () => {
    props.getlanguages({
      params: {
        teacherId: teacherId.get("id"),
      },
      callback: (response, data) => {
        setlanguageslist(_.get(data, "courses"));
        _.map(_.get(data, "courses"), "name");
        form.setFieldsValue({
          course: _.uniqBy(_.map(_.get(data, "courses"), "name")),
        });
      },
    });
  };

  const onFinish = (values) => {
    console.log('values', values);
    let req = {
      
      ...values,
      
      birthDate: dob,
      profilePhoto: imageUrl,
      locale: "en_US",

      interestId: _.get(values, "interestId") ?? 5,
      isAgreeOnTermsAndConditions: _.get(values, "isAgreeOnTermsAndConditions") ?? true,
      isReadyForWhatsappUpdates: _.get(values, "isReadyForWhatsappUpdates") ?? false,
      genderId: +_.get(values, "genderId") ?? "",
      mobileNumber: (_.get(values, "mobileNumber") ||  "").toString(),
      ageGroupId: _.get(values, "ageGroupId") ?? "",
      preferredlanguage: _.get(values, "preferredlanguage") ?? "",
      preferredTimezone: _.get(values, "preferredTimezone") ?? "",
      state: _.get(values, "state") ?? "",
      countryCode: _.get(values, "countryCode") ?? "",
      
    };

if(state.one ==true){
  req.userType="TEST"
}else{
  req.userType=null
}

//step1: email validate props.valiemail({cb{if{step2: mobile validate props.valimobime({params:rq.mobileNumber,cb{if{step 3: profile add}}})}}})

props.validateEmail({
  params:{
    email: req.emailId
  },
  callback: (res, data) => {
    console.log('res', data);
    if(data == 'EmailId Already Existed'){
      notification.error({
        message: "Email Already Exist.",
      });
      return;
    }
    
    if (_.isEqual(res, true)) {
      props.validatemobile({
        params:{
          countryCode: req.countryCode,
          mobile: req.mobileNumber
        },
        
        callback: (res, data) => {
          if(data == 'mobile already existed'){
            notification.error({
              message: "Mobile Already Exist",
            });
            return;
          }
          console.log('res', data);
          if (_.isEqual(res, true)) {
            axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/addstudents`, 
            req,
           {
             headers: {
                 Authorization: token["authorization"]
             }
         }).then(response => {
           console.log("API Response:", response);
           if (_.isEqual(response.status, 200)) {
            console.log("API status:", response.status);
           history("/students");
                 notification.success({
                   message: "Profile is added successfully.",
                 });
                }else {
                  notification.error({
                    message: "Something went wrong.",
                  });
                }
         })
         .catch(error => {
           console.error("API Error:", error);
           notification.error({
               message: 'Something went wrong',
           });
         });
          } else {
            notification.error({
              message: "Something went wrong.",
            });
          }
        },
      });

    } else {
      notification.error({
        message: "Something went wrong.",
      });
    }
  },
});




    
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  const render = () => {
    // console.log(_.get(props, "teachers.viewteacherprofile.data.firstName"));
    // console.log("form", form.getFieldsValue());
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload Photo</div>
      </div>
    );
    return (
      <div className="teacher-profile-container">
        <div>{/* <Profile /> */}</div>
        <div>
          <div className="card mb-5 mb-xl-10">
            {/* <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">Teacher Profile Details</h3>
                            </div>
                        </div> */}

            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                    <div className="row mb-6">
                      <div className="col-lg-8">
                        <Form.Item
                          name="profilePhoto"
                          rules={[
                            {
                              required: false,
                              message: "Please input your image!",
                            },
                          ]}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            accept="image/*"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) => handleChange(e)}
                            customRequest={({ file, onSuccess }) => {
                              setTimeout(() => {
                                onSuccess("ok");
                              }, 0);
                            }}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </div>
                    </div>
                    
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        First Name  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"firstName"}
                          // label="Email"
                          rules={[{ required: true }]}
                          onKeyPress={(e) =>
                            !/[a-zA-Z]/.test(e.key) && e.preventDefault()
                          }
                        >
                          <Input placeholder="Enter Your First Name " />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Middle Name 
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"middleName"}
                          // label="Email"
                          rules={[
                            {
                              required: false

                              
                            },
                          ]}
                          onKeyPress={(e) =>
                            !/[a-zA-Z]/.test(e.key) && e.preventDefault()
                          }
                        >
                          <Input placeholder="Enter Your Middle Name" />
                        </Form.Item>{" "}
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Last Name  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"lastName"}
                          // label="Email"
                          rules={[
                            {
                              required: false

                              
                            },
                          ]}
                          onKeyPress={(e) =>
                            !/[a-zA-Z]/.test(e.key) && e.preventDefault()
                          }
                        >
                          <Input placeholder="Enter Your Last Name " />
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6 mt-2">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Email  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"emailId"}
                          // label="Email"
                          rules={[
                            {
                              required: true,
                              type: "email",
                            },
                          ]}
                        >
                          <Input placeholder="Enter valid Email Address " />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Password  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"password"}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Create Password" />
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6 mt-2">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Country Code  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4 fv-row">
                            <Form.Item
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              name="countryCode"

                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                style={{ width: 100 }}
                                placeholder="Select CountryCode"
                              >
                                {_.map(
                                  _.get(courseList, "countryCodes"),
                                  (countrycodes, each) => {
                                 
                                    return (
                                      <Option
                                        value={_.get(
                                          countrycodes,
                                          "countryCode"
                                        )}
                                        key={each}
                                      >
                                        {_.get(countrycodes, "countryCode")}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label required fw-bold fs-6">
                        Mobile Number  <span className="validationsign">*</span>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"mobileNumber"}
                          rules={[{ required: true }]}
                        >
                          <InputNumber
                            style={{ width: 260 }}
                            onKeyPress={(e) =>
                              !/[0-9]/.test(e.key) && e.preventDefault()
                            }
                            placeholder="Enter Your Mobile Number"
                            maxLength={13}
                          />
                        </Form.Item>{" "}
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Gender  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"genderId"}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: 420 }}
                            placeholder="Select Gender"
                          >
                            <Option value="1">Male</Option>
                            <Option value="2">Female</Option>
                            <Option value="3">Other</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Preferred agegroup  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"ageGroupId"}
                          rules={[{ required: true }]}
                        >
                          <Select placeholder="Select Agegroup">
                            {_.map(
                              _.get(courseList, "ageGroups"),
                              (agegroups, each) => {
                                console.log("agegroups", agegroups);
                                return (
                                  <Option
                                    value={_.get(agegroups, "id")}
                                    key={each}
                                  >
                                    {_.get(agegroups, "name")}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Date of Birth  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"birthDate"}
                          
                          rules={[]}
                        >
                          <DatePicker
                            format={dateformat}
                            onChange = {(date) => setDob(dayjs(date).format("DD/MM/YYYY")
                            )}
                            style={{ width: 420 }}
                          />{" "}
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">State <span className="validationsign">*</span></span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'state'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select placeholder="Select State">
                                                    {courseList?.states?.length && courseList?.states.map(state => {
                                                        return <Option value={state}>{state}</Option>
                                                    })}
                                                        
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div> 
                    
                                        <div className="row mb-6">
                                            <div className="col-lg-4 col-form-label fw-bold fs-6">
                                                <span className="required">Time zone <span className="validationsign">*</span></span>
                                            </div>
                                            <div className="col-lg-6 fv-row">
                                                <Form.Item
                                                    className="form-control form-control-lg form-control-solid"
                                                    name={'preferredTimezone'}
                                                    rules={[
                                                    ]}
                                                >
                                                    <Select placeholder="Select Timezone">
                                                    {courseList?.timezones?.length && courseList?.timezones.map(timezone => {
                                                        return <Option value={timezone.type}>{`${timezone.type} ${timezone.name}`}</Option>

                                                    })}
                                                        
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                   
                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Preferred language  <span className="validationsign">*</span></span>
                      </div>
                      <div className="col-lg-6 fv-row">
                        <Form.Item
                          className="form-control form-control-lg form-control-solid"
                          name={"preferredlanguage"}
                          rules={[{ required: true }]}
                        >
                          <Select  placeholder="Select Language">
                            {_.map(
                              _.get(courseList, "languages"),
                              (languages, each) => {
                                console.log("languages", languages);
                                return (
                                  <Option
                                    value={_.get(languages, "id")}
                                    key={each}
                                  >
                                    {_.get(languages, "name")}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row mb-6">
                      <div className="col-lg-4 col-form-label fw-bold fs-6">
                        <span className="required">Student Type</span>
                      </div>
                      <div className="col-lg-6 fv-row">
                      <Form.Item
                          
                          name={"userType"}
                          rules={[{ required: false }]}
                        >
                      <Checkbox
        checked={state.one}
        size="10"
        tickSize="4" 
        borderThickness="5"
        name="two"
        color="#fabf1b"
        tickAnimationDuration="100"
        backAnimationDuration="100"
        onChange={() =>
          setState({
            ...state,
            one: !state.one
          })
          
        }
        
      />
      <span className="required testuser">TEST</span>
      </Form.Item>
      
                      </div>
                      
                    </div>

                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <Form.Item>
                      <Button
                        htmlType="button"
                        className="btn btn-light btn-active-light-primary mr-2"
                        onClick={onReset}
                      >
                        Discard
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                        Save
                      </Button>
                    </Form.Item>
                    
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) =>
  // console.log("state", state),
  ({
    students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
  });

const mapDispatchToProps = (dispatch) => ({
  add: (details) =>
    dispatch(global.redux.action.students.addstudentprofile(details)),
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
    validatemobile: (details) =>
    dispatch(global.redux.action.students.validatemobile(details)),
    validateEmail: (details) =>
    dispatch(global.redux.action.students.validateEmail(details)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStudentProfilePage);

//birthplace name, address, referred by, ready for training


