import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Modal,
  DatePicker,
  Table,
  notification,
  Collapse,
} from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import * as SecureLS from "secure-ls";
import axios from "axios";
import appEnvirontVariables from "../../../core_units/config/env-variables";

const TeacherLeaveList = (props) => {
  console.log(props, "props===");
  const [formDataList, setFormDataList] = useState([]);
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { Option } = Select;

  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [teachersList, setTeachersList] = useState([]);
  const [page, setPage] = useState(0);
  const [studentName, setStudentName] = useState("");
  const [teacherCategory, setTeacherCategory] = useState("");
  const [sessionDate, setSessionDate] = useState(null);
  const [course, setCourse] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [auditDate, setAuditDate] = useState("");
  const [feedbackLink, setFeedbackLink] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const [startedOnTime, setStartedOnTime] = useState("");
  const [greetings, setGreetings] = useState("");
  const [internetConnection, setInternetConnection] = useState("");
  const [assignmentOfPreviousClass, setAssignmentOfPreviousClass] =
    useState("");
  const [aoiWithExamples, setAoiWithExamples] = useState("");
  const [addressByStudentName, setAddressByStudentName] = useState("");
  const [engagementLevel, setEngagementLevel] = useState("");
  const [studentComfort, setStudentComfort] = useState("");
  const [avoidanceCriteria, setAvoidanceCriteria] = useState("");
  const [casualApproach, setCasualApproach] = useState("");
  const [acknowledgment, setAcknowledgment] = useState("");
  const [activeListening, setActiveListening] = useState("");
  const [interruption, setInterruption] = useState("");
  const [energyLevel, setEnergyLevel] = useState("");
  const [rateSpeech, setRateSpeech] = useState("");
  const [customerDelight, setCustomerDelight] = useState("");
  const [energetic, setEnergetic] = useState("");
  const [disconnect, setDisconnect] = useState("");
  const [doubtsSolving, setDoubtSolving] = useState("");
  const [mic, setMic] = useState("");
  const [headphones, setHeadphones] = useState("");
  const [jacket, setJacket] = useState("");
  const [videoQuality, setVideoQuality] = useState("");
  const [practiceStudio, setPracticeStudio] = useState("");
  const [instrumentUsed, setInstrumentUsed] = useState("");
  const [appropriatelyDressed, setAppropriatelyDressed] = useState("");
  const [videoOn, setVideoOn] = useState("");
  const [unprofessionalBehaviour, setUnprofessionalBehaviour] = useState("");
  const [sessionStandardTime, setSessionStandardTime] = useState("");
  const [observation, setObservation] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [courseList, setCourseList] = useState();
  const [getDetails, setGetDetails] = useState();
  const [editMode, setEditMode] = useState(
    Array(formDataList.length).fill(true)
  );
  const [editedIndex, setEditedIndex] = useState(null);
  const [qaReportDetail, setQaReportDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { Panel } = Collapse;

  useEffect(() => {
    handleGetForm();
  }, []);

  const handleGetForm = () => {
    axios
      .get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/getQAScore?teacherId=${props.profile.teacherId}`,
        {
          headers: {
            Authorization: token["authorization"],
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data && data.length > 0) {
          const formDataList = data.map((qaData) => {
            const qaDetails = JSON.parse(qaData.qaDetails);
            return {
              feedbackStatus: qaDetails.feedbackStatus || '',
              feedbackLink: qaDetails.feedbackLink || '',
              teacherName: qaDetails.teacherName || '',
              teacherId: qaData.teacherId || '',
              sessionDate: qaDetails.sessionDate || '',
              teacherCategory: qaDetails.teacherCategory ||'',
              studentName: qaDetails.studentName || '',
              course: qaDetails.course || '',
              ageGroup: qaDetails.ageGroup || '',
              auditDate: qaDetails.auditDate || '',
              observation: qaDetails.observation || '',
              startedOnTime: qaDetails.payload[0]?.Opening.startedOnTime || '',
              internetConnection: qaDetails.payload[0]?.Opening.internetConnection || '',
              greetingIntroduction: qaDetails.payload[0]?.Opening.greetingIntroduction || '',
              constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass: qaDetails.payload[0]?.Feedback.constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass || '',
              aOIWithExamples: qaDetails.payload[0]?.Feedback.aOIWithExamples || '',
              addressTheStudentByTheirName: qaDetails.payload[0]?.Feedback.addressTheStudentByTheirName || '',
              engagementLevel: qaDetails.payload[0]?.RapportBuilding.engagementLevel || '',
              madeTheStudentFeelComfortable: qaDetails.payload[0]?.RapportBuilding.madeTheStudentFeelComfortable || '',
              deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided: qaDetails.payload[0]?.communicationSkillsAndSoftskills.deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided || '',
              casualApproachToneUsageOfSlangLanguage: qaDetails.payload[0]?.communicationSkillsAndSoftskills.casualApproachToneUsageOfSlangLanguage || '',
              acknowledgment: qaDetails.payload[0]?.communicationSkillsAndSoftskills.acknowledgment || '',
              activeListening: qaDetails.payload[0]?.communicationSkillsAndSoftskills.activeListening || '',
              interruption: qaDetails.payload[0]?.communicationSkillsAndSoftskills.interruption || '',
              energyLevel: qaDetails.payload[0]?.communicationSkillsAndSoftskills.energyLevel || '',
              rateOfSpeech: qaDetails.payload[0]?.communicationSkillsAndSoftskills.rateOfSpeech || '',
              customerDelight: qaDetails.payload[0]?.Closing.customerDelight || '',
              DoubtsSolvingQueryResolution: qaDetails.payload[0]?.Closing.DoubtsSolvingQueryResolution || '',
              energeticAndPositiveClosing: qaDetails.payload[0]?.Closing.energeticAndPositiveClosing || '',
              disconnectionAfterTheStudent: qaDetails.payload[0]?.Closing.disconnectionAfterTheStudent || '',
              mic: qaDetails.payload[0]?.TechGadgets.mic || '',
              headphones: qaDetails.payload[0]?.TechGadgets.headphones || '',
              jacket: qaDetails.payload[0]?.TechGadgets.jacket || '',
              videoQualityCamAngleBodyLanguageBackground: qaDetails.payload[0]?.TechGadgets.videoQualityCamAngleBodyLanguageBackground || '',
              practiceStudio: qaDetails.payload[0]?.TechGadgets.practiceStudio || '',
              pleaseSpecifyTheInstrumentUsed: qaDetails.payload[0]?.TechGadgets.pleaseSpecifyTheInstrumentUsed || '',
              appropriatelyDressed: qaDetails.payload[0]?.Presentation.appropriatelyDressed || '',
              videoOn: qaDetails.payload[0]?.Presentation.videoOn || '',
              rudeSarcasticIntimidatingBehaviourUnprofessionalism: qaDetails.payload[0]?.Presentation.rudeSarcasticIntimidatingBehaviourUnprofessionalism || '',
              sessionAsPerStandardTime: qaDetails.payload[0]?.Time.sessionAsPerStandardTime || '',
              qaScore: qaData.qaScore || 0 ,
            };
          });
          setFormDataList(formDataList);
          console.log(formDataList, "formDataList==");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const exportToExcel = () => {
 
    if (!Array.isArray(formDataList)) {
      
        console.log(formDataList, "form======");
        return; 
    }
    const headers = [
      
      'Feedback Status','Feedback Link','Teacher Name','Teacher ID','Session Date','Teacher Category','Student Name', 'Course',
      'Age Group', 'Audit Date','Observation', 'QA Score','Started On Time', 'Internet Connection', 'Greeting Introduction',
      'Constructive Feedback While Discussing The Assessment Assignment Of The Previous Class', 'AOI With Examples',
      'Address The Student By Their Name', 'Engagement Level', 'Made The Student Feel Comfortable',
      'Dead Air Fumbling Fillers Unnecessary Mute Needs To Be Avoided', 'Casual Approach Tone Usage Of Slang Language',
      'Acknowledgment','Active Listening', 'Interruption', 'Energy Level', 'Rate Of Speech','Customer Delight',
      'Doubts Solving Query Resolution','Energetic And Positive Closing', 'Disconnection After The Student',
       'Mic', 'Headphones', 'Jacket','Video Quality Cam Angle Body Language Background', 'Practice Studio',
       'Please Specify The Instrument Used', 'Appropriately Dressed', 'Video On','Rude Sarcastic Intimidating Behaviour Unprofessionalism', 
       'Session As Per Standard Time'
         
    ];

    let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
    csvContent += formDataList.map(report => Object.values(report).join(",")).join("\n");




  
    const encodedUri = encodeURI(csvContent);
const link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "QA_Report.csv");


link.onload = () => setIsLoading(false);

document.body.appendChild(link);
link.click();
document.body.removeChild(link);
};
  return (
    <div>
      <div style={{ marginTop: "30px" }}>
      <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>

        {formDataList.map((formData, index) => (
          <Collapse key={index}>
            <div style={{ position: "relative" }}></div>
            <div
              style={{
                marginTop: "20px",
                padding: "15px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Teacher Name:</b>
                    </label>
                  </div>
                  <div>{formData.teacherName}</div>
                </div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Teacher Category:</b>
                    </label>
                  </div>
                  <div>{formData.teacherCategory}</div>
                </div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Student Name:</b>
                    </label>
                  </div>
                  <div>{formData.studentName}</div>
                </div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Age Group:</b>
                    </label>
                  </div>
                  <div>{formData.ageGroup}</div>
                </div>
              </div>
              <div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Course:</b>
                    </label>
                  </div>
                  <div>{formData.course}</div>
                </div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Session Date:</b>
                    </label>
                  </div>
                  <div>{formData.sessionDate}</div>
                </div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    <label>
                      <b>Audit Date:</b>
                    </label>
                  </div>
                  <div>{formData.auditDate}</div>
                </div>
              </div>
            </div>

            <Panel header="View Full Detail Form" key="1">
              <Form
                labelAlign="left"
                labelCol={{ span: 14 }}
                style={{ padding: "20px", backgroundColor: "#f7f7f7" }}
              >
                <Form.Item label="Feedback Status" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.feedbackStatus}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].feedbackStatus = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Feedback Link" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.feedbackLink}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].feedbackLink = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Openings</b>
                </p>
                <Form.Item label="Started on Time" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.startedOnTime}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].startedOnTime = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Internet Connection"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.internetConnection}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].internetConnection =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Greeting/Introduction"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.greetingIntroduction}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].greetingIntroduction =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Feedback</b>
                </p>
                <Form.Item
                  label="Assignment of the Previous Class"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={
                      formData.constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass
                    }
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[
                        index
                      ].constructiveFeedbackWhileDiscussingTheAssessmentAssignmentOfThePreviousClass =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="AOI with Examples"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.aOIWithExamples}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].aOIWithExamples = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Address the Student by Their Name"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.addressTheStudentByTheirName}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].addressTheStudentByTheirName =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Reporting Building</b>
                </p>
                <Form.Item label="Engagement Level" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.engagementLevel}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].engagementLevel = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Made the Student Feel Comfortable"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.madeTheStudentFeelComfortable}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].madeTheStudentFeelComfortable =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Communication skills and Soft skills</b>
                </p>
                <Form.Item
                  label="Dead Air/Fumbling/Fillers/Unnecessary Mute Needs to be Avoided"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={
                      formData.deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided
                    }
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[
                        index
                      ].deadAirFumblingFillersUnnecessaryMuteNeedsToBeAvoided =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Casual Approach/Tone, Usage of Slang Language"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.casualApproachToneUsageOfSlangLanguage}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[
                        index
                      ].casualApproachToneUsageOfSlangLanguage = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Acknowledgment" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.acknowledgment}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].acknowledgment = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Active Listening" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.activeListening}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].activeListening = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Interruption" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.interruption}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].interruption = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="energy Level" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.energyLevel}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].energyLevel = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Rate of Speech" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.rateOfSpeech}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].rateOfSpeech = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Closing</b>
                </p>
                <Form.Item label="Customer Delight" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.customerDelight}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].customerDelight = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Doubts solving/ query resolution"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.DoubtsSolvingQueryResolution}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].DoubtsSolvingQueryResolution =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Energetic and positive closing"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.energeticAndPositiveClosing}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].energeticAndPositiveClosing =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Disconnection after the student"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.disconnectionAfterTheStudent}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].disconnectionAfterTheStudent =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Tech & Gadgets</b>
                </p>
                <Form.Item label="Mic" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.mic}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].mic = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Headphones" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.headphones}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].headphones = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Jacket" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.jacket}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].jacket = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Video Quality/Cam Angle/Body Language/Background"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.videoQualityCamAngleBodyLanguageBackground}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[
                        index
                      ].videoQualityCamAngleBodyLanguageBackground =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Practice Studio (Yes/No)"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.practiceStudio}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].practiceStudio = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Please specify the instrument used (Personal/Dashboard)"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.pleaseSpecifyTheInstrumentUsed}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].pleaseSpecifyTheInstrumentUsed =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Presentation</b>
                </p>
                <Form.Item
                  label="Appropriately Dressed"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.appropriatelyDressed}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].appropriatelyDressed =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Video On" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.videoOn}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].videoOn = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Rude/Sarcastic/Intimidating Behaviour/Unprofessionalism"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={
                      formData.rudeSarcasticIntimidatingBehaviourUnprofessionalism
                    }
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[
                        index
                      ].rudeSarcasticIntimidatingBehaviourUnprofessionalism =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <p>
                  <b>Time</b>
                </p>
                <Form.Item
                  label="Session as per standard Time"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    value={formData.sessionAsPerStandardTime}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].sessionAsPerStandardTime =
                        e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Observation" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.observation}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].observation = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Qa Score" style={{ marginBottom: 0 }}>
                  <Input
                    value={formData.qaScore}
                    style={{ width: "255px" }}
                    readOnly
                    onChange={(e) => {
                      const updatedFormData = [...formDataList];
                      updatedFormData[index].qaScore = e.target.value;
                      setFormDataList(updatedFormData);
                    }}
                  />
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        ))}
      </div>
    </div>
  );
};

export default TeacherLeaveList;
