import React, { useState, useEffect,useRef } from "react";

import { Form, DatePicker, Button, Select, Modal, Empty, Input, Radio, notification, Spin, Tag, Checkbox } from "antd";
import { useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { connect } from "react-redux";
import _ from "lodash";
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Courselist from '../../students/payment/courselist';
import { constant } from "../../../core_units/constant";
import mixpanel from 'mixpanel-browser';
const { Option } = Select;
const StudentType = (props) => {
  console.log(props,"props===")
  const linkRef = useRef(null);
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { handleClickCourse = (f) => f } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStudentModalVisible, setIsStudentModalVisible] = useState(false);
  const [coursechange, setcoursechange] = useState(0);
  const [studentId] = useSearchParams();
  const [form] = Form.useForm();
  const [courselist, setcourselist] = useState();
  const [planDetails, setPlanDetails] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [finalDiscount, setFinalDiscount] = useState();

  const [allcourselist, setallcourselist] = useState();
  const [filteredAllCourseList, setFilteredAllCourseList] = useState();
  const [changestatus, setchangestatus] = useState(false);
  const [newcoursestatus, setnewcoursestatus] = useState(false);
  const [newcourseswitchstatus, setnewcourseswitchstatus] = useState(false)
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
  const [issubModalVisible, setIsSubModalVisible] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [existcourse, setexistcourse] = useState();
  const [newcourselist, setnewcourselist] = useState();
  const [changeteacherstatus, setchangeteacherstatus] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [studentPlanCode, setStudentPlanCode] = useState();
  const [discountChange, setDiscountChange] = useState();
  const [courseSelected, setCourseSelected] = useState();
  const [response, SetResponse] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [queryvalue, setqueryvalue] = useState();
  const [loading, setLoading] = useState(false);
  const [studentsList, setStudentsList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [selectedStudentCred, setSelectedStudentCred] = useState("");
  const [selectedTTeacherName, setSelectedTTeacherName] = useState("");
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [selectedTTeacher, setSelectedTTeacher] = useState("");
  console.log(selectedTTeacher, "selectedTTeacher")
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [otpValue, setOtpValue] = useState("")
  const [isCopied, setIsCopied] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [changeCourseDetails, setChangeCourseDetails] = useState({});
  const [createPayment, setCreatePayment] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isBothFieldsFilled, setIsBothFieldsFilled] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [payLink,setPayLink] = useState(false);
  const [selectedExistingCourseId, setSelectedExistingCourseId] = useState(null);
  const [unscheduledClassLimit, setUnscheduledClassLimit] = useState(0);
  const [unscheduledClassValue, setUnscheduledClassValue] = useState("");
  const [saveData,setSaveData] = useState([]);
  const [classNumber, setClassNumber] = useState(""); 
const [deductedClassCount, setDeductedClassCount] = useState("");
const [existName, setExistName] = useState("");
const [newCourseName, setNewCourseName] = useState("");
const [courseName,setCourseName] = useState('');
const [levelCourseName, setLevelCourseName] = useState(""); 
const [courseInfo, setCourseInfo] = useState('');
  //const mixpanel = require('mixpanel-browser');
  useEffect(() => {
    getallcourselist();
  }, []);
  const closePaymentModel = (paymentlink) => {
    setPayLink(paymentlink);
   
    console.log("paymentlink---",paymentlink);
    setIsPaymentModalVisible(false);
  }
  const childFunc = React.useRef(null);
  useEffect(() => {
    setexistcourse(_.get(props, "students.enrolledcourselist"));
    if (allcourselist && allcourselist.length) {
      let cList = [];
      // allcourselist.forEach(element => {
      //   element.tags.forEach(ele => {
      //     if(!_.get(props, 'students.enrolledcourselist').find(e => e.tagId == ele.id)){
      //       cList.push(ele);
      //     }
      //   });
      // });
      setFilteredAllCourseList(cList);
      console.log(cList, "cList")
    }
  }, [_.get(props, "students.enrolledcourselist"), allcourselist]);
  const showRefundModal = () => {
    setIsRefundModalVisible(true);
  };
  const openModal = () => {
    setSearchPerformed(false);
    setStudentsList([]);
    setSearchQuery("");
    setSelectedStudentCred("");
    setSelectedStudent("");
    setIsStudentModalVisible(true);
  };

  const closeModal = () => {
    // setSelectedTTeacher("");
    setSelectedTTeacherName("");
    setIsStudentModalVisible(false);
  };
  const onStudentFinish = () => {

  };

  const handleRadioChange = (studentId, studentName) => {
    setSelectedTTeacher(studentId);
    console.log(studentId, "studentId")
    setSelectedTTeacherName(studentName);

  };

  const handleSearch = () => {
    const isQueryEmpty = searchQuery.replace(/\s+/g, '') === '';
    setErrorMessage(isQueryEmpty);

    if (isQueryEmpty) {

    } else {
      setSearchPerformed(true);

      let query = {};


      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchQuery)) {
        query.emailId = searchQuery;
      }

      else if (new RegExp(/^[0-9\b]+$/).test(searchQuery)) {
        query.mobileNumber = searchQuery;
      }

      else {
        query.name = searchQuery;
      }


      query.page = 0;
      query.size = 100;


      apicall(query);

      console.log(query);
    }
  };

  const apicall = (query = {}, page = 0) => {
    setqueryvalue(query)
    setLoading(true);
    props.list({
      body: {
        page: page,
        size: 100,
        ...query
      },
      callback: (response, data) => {


        if (_.isEqual(response, true)) {
          let arr = [];
          if (_.isEmpty(query) && !_.isEmpty(_.get(data, "content"))) {
            arr = _.uniqBy(
              [...studentsList, ..._.get(data, "content")],
              function (e) {
                return e.studentId;
              }
            );
          } else if (!_.isEmpty(query)) {
            if (
              _.size(studentsList) < _.get(data, "totalElements") &&
              _.isEqual(queryvalue, query)
            ) {
              arr = _.uniqBy(
                [...studentsList, ..._.get(data, "content")],
                function (e) {
                  return e.studentId;
                }
              );
            } else {
              arr = _.get(data, "content");
            }
          }
          setStudentsList(arr);
          console.log(arr, "arr")
        }
        setLoading(false);
      }
    })

  }

  const handleRefundCancel = () => {
    onReset();
    setIsRefundModalVisible(false);
    SetResponse();
  }
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleRefundOk = () => {
    setIsRefundModalVisible(false);
  }
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setnewcoursestatus(false);
    setnewcourseswitchstatus(false)
    setchangestatus(false);
    form.resetFields();
    onReset();
    setShowMessage(false);
    setDisabledBtn(false);
    setCreatePayment(false);
    setIsCheckboxChecked(false);
    setIsModalVisible(false);
    setPayLink(false);
  };
  const showsubModal = () => {
    setIsSubModalVisible(true);
  };
  const handleSubCancel = () => {
    setIsSubModalVisible(false);
    setnewcoursestatus(false);
    setnewcourseswitchstatus(false)
    setchangestatus(false);
    form.resetFields();
    setShowMessage(false);
    setDisabledBtn(false);
    setCreatePayment(false);
    setIsCheckboxChecked(false);
    setPayLink(false);
    onReset();
  };

  const getallcourselist = () => {
    props.couselist({
      callback: (response, data) => {
        if (response == true) {
          setallcourselist(_.get(data, "courses"));

        }
      },
    });
  };
  const onFinish = (values) => {

    setDisabledBtn(true);
    const code = values.code;

    props.usertypechange({
      params: {
        studentId: studentId.get("id"),
        discount: discountPrice
      },
      body: {
        planCode: studentPlanCode,

      },
      callback: (res, data) => {
        setIsModalVisible(false);
        if (_.isEqual(res, true)) {
          form.resetFields();
          props.apicall();
          props.classcountcall();
          notification.success({
            message: "Type updated successfully.",
          });
        } else {
          if (
            _.isEqual(
              _.get(props, "students.studenttypeLoadingInProgress"),
              false
            ) &&
            _.isEmpty(_.get(props, "students.studenttype"))
          ) {
            notification.error({
              message: "Something went wrong",
            });
          } else {
            notification.success({
              message: "Activated course plan",
            });
            form.resetFields();
            props.apicall();
          }
        }
        setDisabledBtn(false);
      },
    });
  };
  const onRefundFinish = (values) => {

    props.refundClass({
      body: {
        studentId: studentId.get("id"),
        ...values
      },
      callback: (res, data) => {

        SetResponse(data)

        if (res == false && data['Student_Class_Ready_For_Scheduling_count']) {
          setIsRefundModalVisible(true)
        } else {
          setIsRefundModalVisible(false)
        }
        if (_.isEqual(res, true)) {
          form.resetFields();
          notification.success({
            message: "Refund updated successfully.",
          });
        }
        // else {
        //   form.resetFields();
        //   notification.error({
        //     message: "Something Went Wrong",
        //   });
        // }

        setDisabledBtn(false);
      },
    });
  };
  const studentCourseList = () => {
    props.enrolledcourselist({
      params: {
        credid: studentId.get("credid"),
      },
      callback: (response, data) => {
        // setselectedcourse(data[0])
        // setstudentcourse(data)
        // if(data && data.length){
        //     classcountcall({ courseId:data[0].courseId})
        //     setselectedcourse(data[0])
        // }
      },
    });
  };

  const onReset = () => {
    form.resetFields();
    setPlanDetails();
    setFinalDiscount();
    setStudentPlanCode();
  };
  const onFinishFailed = (errorInfo) => {

    form.resetFields();

  };
  //Paid student model functions
  // const oncoursechange = (event) => {
  //   setErrorMsg("");

  //   teacherlist(event);
  //   console.log(event, "event====teacher")
  //   setCourseSelected(event);
  //   console.log(event, "event====course")
  //   if (event === form.getFieldValue("fromCourseId")) {
    
  //     classcountcallAPI(event);
  //   }
  //      const courseName = existcourse.find(item => item.courseId === event).courseName;
  //   if (event === form.getFieldValue("fromCourseId")) {
  //     setExistName(courseName);
  //     console.log(courseName, "courseName for existing course");
  //   } else if (event === form.getFieldValue("toCourseId")) {
  //     setNewCourseName(courseName);
  //     console.log(courseName, "courseName for new course");
  //   }
  // };
  const oncoursechange = (event) => {
    setErrorMsg("");

    teacherlist(event);
    console.log(event, "event====teacher")
    setCourseSelected(event);
    console.log(event, "event====course")
    if (event === form.getFieldValue("fromCourseId")) {
        classcountcallAPI(event);
    }

    if (existcourse && existcourse.length > 0) {
        const course = existcourse.find(item => item.courseId === event);
        if (course) {
            console.log(course,"course")
            const courseName = course.courseName;
            setNewCourseName(courseName);
            console.log(courseName,"course")
            if (event === form.getFieldValue("fromCourseId")) {
                setExistName(courseName);
                console.log(courseName, "courseName for existing course");
            } 
        } else {
            console.log("Course not found in existcourse");
        }
    } else {
        console.log("Existcourse is empty or undefined");
    }

    const selectedCourse = allcourselist.find(course => {
        return course.tags.some(tag => tag.paidCourseId === event);
    });

    console.log("Selected Course:", selectedCourse);

    if (selectedCourse) {
      setCourseName(selectedCourse.name)
        const courseLevels = selectedCourse.tags.map(tag => tag.paidCourseId);
        console.log("Course Levels for Mixpanel:", courseLevels);

        const courseNames = selectedCourse.tags.map(tag => tag.localizedName);
        let selectedCourseName = "";

        if (courseLevels.includes(event)) {
            selectedCourseName = courseNames[courseLevels.indexOf(event)];
            console.log("Selected course level:", event, "Name:", selectedCourseName);
            setLevelCourseName(selectedCourseName);
        } else {
            console.log("Selected course level not found");
        }
        const concatenatedName = `${courseName} - ${selectedCourseName}`;
        setCourseInfo(concatenatedName);
      
    }
};
const onteacherchange = (value) => {
  let selectedTeacherName = ""; 
  const selectedTeacher = courselist.find((teacher) => teacher.teacherId === value);
  selectedTeacherName = selectedTeacher ? selectedTeacher.teacherName : "";
  console.log(selectedTeacherName,"selectedTeacherName===")
  setTeacherName(selectedTeacherName);

}

  const teacherlist = (courseId) => {
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/admin/teachers?courseId=${courseId}`,
      { page: 0, size: 500, courseId: courseId },
      { headers: { "Authorization": token['authorization'] } }).then(data => {
        if (data?.data?.content) {
          data.data.content = data.data.content.filter(e => (e.workingStatus == "ACTIVE" && e.teacherDisabled == "false" && e.enableForPaid == "true" && e.isAcademicExpert == "false"));
          setcourselist(data?.data?.content);
        }
      });
    return;
    props.listteacherforcourse({
      params: {
        courseId: courseId,
      },
      callback: (response, data) => {
        data.content = data.content.filter(e => (e.workingStatus == "ACTIVE" && e.teacherDisabled == "false" && e.enableForPaid == "true" && e.isAcademicExpert == "false"));
        setcourselist(data.content);

      },
    });
  };
  const coursePlanDetails = async () => {

    return axios
      .get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/plancode?planCode=${studentPlanCode}`,
        { headers: { Authorization: token["authorization"] } }
      )
      .then((res) => {
        if (res?.data) {
          setPlanDetails(res.data);

          notification.success({
            message: "PlanCode Successfully Verified",
          });
        } else {
          notification.error({
            message: "PlanCode Not Found",
          }
          )
        }
      })
      .catch((err) => {
        notification.error({
          message: "Invalid PlanCode",
        })
      },
      );
  };
  const onDiscountChange = (event) => {

    setDiscountPrice(event);

    const finalPrice = (planDetails?.planAmount * event) / 100;

    setFinalDiscount(planDetails?.planAmount - event);

  };

  // const coursechangefinish = (values) => {
  //   setChangeCourseDetails({
  //     studentId: +studentId.get("id"),
  //     ...values,
  //   });
  //   setIsPaymentModalVisible(true);
  //   return;
  //   setDisabledBtn(true);
    
  //   if (values.fromCourseId === values.toCourseId) {
  //     alert("Existing and new course are same");
  //     setDisabledBtn(false);
  //   } else {
  //     let params = {
  //       studentId: +studentId.get("id"),
  //       ...values,
  //     };
  
  //     props.changecourseforstudent({
  //       params: params,
  //       callback: (res, data) => {
  //         setIsModalVisible(false);
  //         if (_.isEqual(res, true)) {
  //           form.resetFields();
  //           props.apicall();
  //           props.classcountcall();
  //           notification.success({
  //             message: "Status updated successfully.",
  //           });
  //         } else {
          
  //           if (data && data.status === 400 && data.message === "Number of classes are not equal to classes in student account") {
  //             notification.error({
  //               message: "Number of classes are not equal to classes in the student account.",
  //             });
  //           } else {
            
  //             if (
  //               _.isEqual(
  //                 _.get(
  //                   props,
  //                   "students.changecourseforstudentLoadingInProgress"
  //                 ),
  //                 false
  //               ) &&
  //               _.isEmpty(_.get(props, "students.changecourseforstudent"))
  //             ) {
  //               notification.error({
  //                 message: "Something went wrong",
  //               });
  //             } else {
  //               notification.success({
  //                 message: "Activated course plan",
  //               });
  //               form.resetFields();
  //               props.apicall();
  //             }
  //           }
  //         }
  //         setDisabledBtn(false);
  //       },
  //     });
  //   }
  // };
  const coursechangefinish = (values) => {
    const classNumber = form.getFieldValue("classNumber");
    const deductedClassCount = form.getFieldValue("deductedClassCount");
  
    if (createPayment && (!classNumber || !deductedClassCount)) {
      setShowMessage(true);
      return;
    }
  
   
    const payload = {
      studentId: +studentId.get("id"),
      ...values     
    };
  
    setChangeCourseDetails(payload);
  
    if (isCheckboxChecked) {
      setIsPaymentModalVisible(true);
    } else {
      setDisabledBtn(true);
  
      if (values.fromCourseId === values.toCourseId) {
        alert("Existing and new course are the same");
        setDisabledBtn(false);
      } else {
        let params = {
         
          deductedClassCount, 
        };
        let mixEventName = changeteacherstatus ? "Change teacher":"Course Change";
        let mixpanelEventName;
        if (changeteacherstatus === true) {
          mixpanelEventName = constant. MIXPANEL_CHANGE_TEACHER;
        } else if (changeteacherstatus === false) {
          mixpanelEventName = constant.MIXPANEL_CHANGE_COURSE;
        }
        props.changecourseforstudent({
          params: params,
          body: {
            studentId: +studentId.get("id"),
            fromCourseId: values.fromCourseId,
            toCourseId: values.toCourseId,
            teacherId: values.teacherId,
            ...values,
          },
          callback: (res, data) => {
            setIsModalVisible(false);
            if (_.isEqual(res, true)) {
              mixpanel.track(mixpanelEventName, {
                user:token?.studentName,
                studentId: +studentId.get("id"),
                fromCourseId: values.fromCourseId,
                fromCourseName : existName,
                toCourseName : `${courseName} - ${levelCourseName}`,
                toCourseId: values.toCourseId,
                teacherName:teacherName,
                teacherId: values.teacherId,
                teacherName: teacherName,
                studentName: props.students.viewprofile.firstName,
                Email: props.students.viewprofile.emailId,
                mobile : props.students.viewprofile.mobileNumber,
                ...values,
                api_status:"success",
                api_res:data
              });
              form.resetFields();
              props.apicall();
              props.classcountcall();
              notification.success({
                message: "Status updated successfully.",
              });
            } else {
              mixpanel.track(mixpanelEventName, {
                user:token?.studentName,
                studentId: +studentId.get("id"),
                fromCourseId: values.fromCourseId,
                fromCourseName : existName,
                toCourseName : `${courseName} - ${levelCourseName}` ,
                studentName: props.students.viewprofile.firstName,
                Email: props.students.viewprofile.emailId,
                mobile : props.students.viewprofile.mobileNumber,
                toCourseId: values.toCourseId,
                teacherName:teacherName,
                teacherId: values.teacherId,
                teacherName: teacherName,
                ...values,
                api_status:"faiure",
                api_res:data
              });
              if (data && data.status === 400 && data.message === "Number of classes are not equal to classes in student account") {
                notification.error({
                  message: "Number of classes are not equal to classes in the student account.",
                });
              } else {
              
                if (
                  _.isEqual(
                    _.get(
                      props,
                      "students.changecourseforstudentLoadingInProgress"
                    ),
                    false
                  ) &&
                  _.isEmpty(_.get(props, "students.changecourseforstudent"))
                ) {
                  notification.error({
                    message: "Something went wrong",
                  });
                } else {
                  notification.success({
                    message: "Activated course plan",
                  });
                  form.resetFields();
                  props.apicall();
                }
              }
            }
            setDisabledBtn(false);
          },
        });
      }
    }
  };
  
 

  



  
  

  const newcoursecfinish = (values) => {

    setDisabledBtn(true);
    props.enrollnewcourse({
      params: {
        studentId: +studentId.get("id"),
        plancode: studentPlanCode,
        ...values,
      },
      callback: (res, data) => {
        setIsModalVisible(false);
        if (_.isEqual(res, true)) {
          form.resetFields();
          props.apicall();
          props.classcountcall();
          notification.success({
            message: "Course Created successfully.",
          });
          handleClickCourse(true);
        } else {
          if (data == "Student already enrollment with this course") {
            setErrorMsg(data);
          }
          if (
            _.isEqual(
              _.get(props, "students.enrollnewcourseLoadingInProgress"),
              false
            ) &&
            _.isEmpty(_.get(props, "students.enrollnewcourse"))
          ) {
            if (_.get(data, "message")) {
              notification.error({
                message: _.get(data, "message"),
              });
            }

            // } else {
            //   notification.success({
            //     message: 'Activated course plan',
            //   });
            //   form.resetFields();
            //   props.apicall()
          }
        }
        setDisabledBtn(false);
      },
    });
  };

  const newcoursecswitchfinish = (selectedTTeacher, selectedTeacherName, formValues) => {
    setDisabledBtn(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/move/classes`;
    const requestBody = {
      fromStudentId: +studentId.get("id"),
      toStudentId: selectedTTeacher,
      fromCourseId: formValues.fromCourseId,
      toCourseId: formValues.toCourseId,
      numberClassToAdd: formValues.numberClassToAdd,
      numberClassToDeducted: formValues.numberClassToDeducted,
      teacherId: formValues.teacherId,

    };

    console.log({
      fromStudentId: +studentId.get("id"),
      toStudentId: selectedTTeacher,
      fromCourseId: formValues.fromCourseId,
      toCourseId: formValues.toCourseId,
      numberClassToAdd: formValues.numberClassToAdd,
      numberClassToDeducted: formValues.numberClassToDeducted,
      teacherId: formValues.teacherId,

    }, "request")
    axios
      .post(apiUrl, requestBody, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })

      .then(async (response) => {

        notification.success({
          message: "",
          description: "classes successfully switched to another account",
        });

      })
      .catch((error) => {
        setLoading(false);
        console.error("API Error:", error);
        notification.error({
          message: "",
          description: "An error occurred while fetching batch details.",
        });
      });

  };

  const handleGetLoginOTP = () => {
    setLoading(true);
    setDisabledBtn(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/temp/otp?credentialId=${studentId.get("credid")}`;
    const requestBody = {
      credentialId: studentId.get("credid")
    };
    axios
      .post(apiUrl, requestBody, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const otpValue = response.data?.OTP;


          setIsLoginModalVisible(true);
          setOtpValue(otpValue);
        } else {
          console.error('API Error:', response.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("API Error:", error);
      });
  };

  const copyToClipboard = (otpValue) => {
    navigator.clipboard
      .writeText(otpValue)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(() => {
        notification.error({
          message: 'Error while copying',
        });
      });
  };
  const DigitBox = ({ digit }) => (
    <div
      style={{
        width: '54px',
        height: '40px',
        borderRadius: '30px',
        border: '6px solid #009688',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px',
        fontWeight: 'bold',
        fontSize: '18px',
      }}
    >
      {digit}
    </div>
  );
  const renderOtpDigits = () => {
    return otpValue.split('').map((digit, index) => (
      <DigitBox key={index} digit={digit} />
    ));
  };

  const handleCopyLink = (payLink) => {
    navigator.clipboard
      .writeText(payLink)
      .then(() => {
        alert("Copied successfully");
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          placement: 'topRight',
        });
      });
  };



  const classcountcallAPI = (courseSelected) => {
    props.classcount({
      params: {
        studentId: studentId.get("id"),
        courseId: courseSelected,
      },
      callback: (response, data) => {
        console.log('student course',data);
        setSaveData(data);
      },
    });
  };


  const isSubmitDisabled = () => {
    return (
      isCheckboxChecked && (!classNumber || !deductedClassCount) 
    );
  };
  










  const render = () => {


    return (
      <div
        className="col-lg-4"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div className="mr-2">
          <Tag color="#f50">
            {_.capitalize(_.get(props, "students.viewprofile.userType"))}
          </Tag>
        </div>
        <Button type="primary" style={{ marginLeft: "10px" }} onClick={handleGetLoginOTP}>Get Login OTP</Button>
        <Modal
          title="Login OTP"
          visible={isLoginModalVisible}
          onOk={() => setIsLoginModalVisible(false)}
          onCancel={() => setIsLoginModalVisible(false)}
          footer={false}
          className="custom-modal"
        >
          <div className="grid-container" style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
            {renderOtpDigits()}
            {isCopied &&
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => copyToClipboard(otpValue)}
                icon={<CheckCircleOutlined />}
                title="Copied"
              />
            }
            {
              !isCopied &&
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => copyToClipboard(otpValue)}
                icon={<CopyOutlined />}
                title="Copy"
              />
            }


          </div>
        </Modal>
        {/* {(() => {
            return (
              <React.Fragment> */}
        {/* <Button onClick={showModal}>Make as paid</Button> */}
        <Modal
          title="Course Plan"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          <Form
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Plan code"
              name="code"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input your code!",
            //   },
            // ]}
            >

              <Input
                disabled={planDetails?.planAmount}
                style={{ width: "calc(115% - 200px)" }}
                placeholder="Enter Plancode to Enable Discount Box"
                onChange={(event) => {
                  setStudentPlanCode(event.target.value);
                }}
                value={studentPlanCode}
              />
              <Button
                type="primary"
                onClick={() => {
                  coursePlanDetails();
                }}
              >
                Verify
              </Button>
              <Button
                style={{ marginLeft: "1px" }}
                type="primary"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
            <Form.Item
              label="Discount"
              name="discount"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input Your Discount!',
            //   },
            // ]}

            >

              <Input disabled={!planDetails}
                onChange={(event) => onDiscountChange(event.target.value)}
                placeholder="Enter Discount Amount"
              />
            </Form.Item>

            <Form.Item label="Plan Amount" name="planAmount">
              {console.log(planDetails?.planAmount, "%%%")}
              <Input disabled value={planDetails?.planAmount} />
            </Form.Item>
            <Form.Item label="Total Price" value={finalDiscount}>
              <Input disabled={!planDetails} value={finalDiscount} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!discountPrice}>
                {disabledBtn ? "Loading" : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {/* </React.Fragment>
            )
        })()}

        <React.Fragment> */}
        <Button onClick={showsubModal} className="ml-3">
          Subscription
        </Button>
        <Modal
          title="Course Plan"
          visible={issubModalVisible}
          onOk={handleOk}
          onCancel={handleSubCancel}
          footer={false}
          className="custom-modal"
        >
          <div className="grid-container">
            <div className="grid" style={{ display: "inline-block" }}>
              <Button
                disabled={
                  !_.isEqual(
                    _.get(props, "students.viewprofile.userType"),
                    "DEMO"
                  )
                    ? false
                    : true
                }
                onClick={() => {
                  setchangestatus(true);
                  setnewcoursestatus(false);
                  setnewcourseswitchstatus(false)
                  setchangeteacherstatus(false);
                  form.resetFields();
                }}
              >
                Change Course
              </Button>
            </div>
            {/* <div className="grid" style={{display:"inline-block", marginLeft:"5px"}}>
              <Button
                onClick={() => {
                   setnewcoursestatus(true);
                   setnewcourseswitchstatus(false)
                  setchangestatus(false);
                  setchangeteacherstatus(false);
                  form.resetFields();
                  setErrorMsg("");
                }}
              >
              Add course
              </Button>
            </div>
               <div className="grid" style={{display:"inline-block", marginLeft:"5px"}}>
              <Button
                onClick={() => {
                   setnewcourseswitchstatus(true);
                   setnewcoursestatus(false);
                  setchangestatus(false);
                  setchangeteacherstatus(false);
                  form.resetFields();
                  setErrorMsg("");
                }}
              >
               Switch Classes 
              </Button>
            </div> */}

            <div className="grid" style={{ display: "inline-block", marginLeft: "5px" }}>
              {/* <Button 
                disabled={!_.isEqual(_.get(props, 'students.viewprofile.userType'), "DEMO")?false:true}
                onClick={() => { setchangestatus(true); setnewcoursestatus(false);setchangeteacherstatus(true); form.resetFields() }}>
                  Change Teacher
                </Button> */}
              {/* *** this button was disabled for Demo student now that that condition removed for airline feature ***  */}
              <Button
                onClick={() => {
                  setchangestatus(true);
                  setnewcoursestatus(false);
                  setnewcourseswitchstatus(false)
                  setchangeteacherstatus(true);
                  form.resetFields();
                }}
              >
                Change Teacher
              </Button>
            </div>
          </div>

          {_.isEqual(changestatus, true) && (
            <Form
              initialValues={{
                remember: true,
              }}
              onFinish={coursechangefinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Existing Course"
                name="fromCourseId"
                rules={[
                  {
                    required: true,
                    message: "Please input your code!",
                  },
                ]}
              >
                {/* <Input onChange={(e) => oncoursechange(e)} /> */}
                <Select
                  dropdownClassName="course-list-dropdown"
                  onSelect={(value) => oncoursechange(value)}
                  placeholder="Select Course"
                >
                  {_.map(existcourse, (course, index) => {
                    // return _.map(_.get(course, 'tags'), (tag, i) => {
                    return (
                      <Option
                        value={_.get(course, "courseId")}
                        key={_.get(course, "id")}
                      >
                        {_.get(course, "courseName")}
                      </Option>
                    );

                    // })
                  })}
                </Select>
              </Form.Item>
              {changeteacherstatus == false && (
                <Form.Item
                  label="New Course"
                  name="toCourseId"
                  rules={[
                    {
                      required: true,
                      message: "Please input your code!",
                    },
                  ]}
                >
                  {/* <Input onChange={(e) => oncoursechange(e)} /> */}
                  <Select
                    dropdownClassName="course-list-dropdown"
                    onSelect={(value) => oncoursechange(value)}
                    placeholder="Select Course"
                  >
                    {_.map(
                      allcourselist.filter((e) => {
                        return true;
                      }),
                      (course, index) => {
                        return _.map(_.get(course, "tags"), (tag, i) => {
                          return (
                            <Option
                              value={_.get(tag, "paidCourseId")}
                              key={_.get(tag, "id")}
                            >
                              {_.get(tag, "parent") + "- " + _.get(tag, "name")}
                            </Option>
                          );
                        });
                      }
                    )}
                    {/* {filteredAllCourseList && _.map(filteredAllCourseList, (course, index) => {
                        
                        return <Option value={_.get(course, 'paidCourseId')} key={_.get(course, 'id')}>{_.get(course, 'parent') + "- " + _.get(course, 'name')}</Option>

                    })
                    } */}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Teacher"
                name="teacherId"
                rules={[
                  {
                    required: true,
                    message: "Please select the teacher!",
                  },
                ]}
              >
                <Select dropdownClassName="course-list-dropdown" placeholder="Select Teacher"

                 onSelect={(value) => onteacherchange(value)}

                >
                  {_.map(courselist, (teacher, index) => {
                    return (
                      <Option value={_.get(teacher, "teacherId")} key={index}>
                        {_.get(teacher, "teacherName")}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              
              {changeteacherstatus == false && (
                <Form.Item
                label="No.of classes to move for new course"
                name="classNumber"
                validateStatus={isCheckboxChecked && !classNumber ? "error" : ""}
                help={isCheckboxChecked && !classNumber && "Please enter the No. of classes to move for the new course."}
              >
                <Input
                  style={{ width: "100px" }}
                  onChange={(e) => {
                    setClassNumber(e.target.value);
                  }}
                />
              </Form.Item>
              )}
              {changeteacherstatus === false && (
  <Form.Item
    label="No. of unscheduled classes from the existing course"
    name="deductedClassCount"
    validateStatus={isCheckboxChecked && (deductedClassCount > saveData.unscheduleClass) ? "error" : ""}
    help={
      isCheckboxChecked && !deductedClassCount ? (
        <span style={{ color: "red" }}>Please enter the No. of unscheduled classes from the existing course.</span>
      ) : (
        isCheckboxChecked && deductedClassCount > saveData.unscheduleClass && (
          <span style={{ color: "red" }}>The maximum allowed value is {saveData.unscheduleClass}.</span>
        )
      )
    }  >
    <Input
      style={{ width: "100px" }}
      value={deductedClassCount}
      onChange={(e) => {
        const value = parseInt(e.target.value); 
        if (!isNaN(value)) { 
          setDeductedClassCount(value);
        } else {
          setDeductedClassCount(""); 
        }
      }}
    />
    {/* {isCheckboxChecked && deductedClassCount > saveData.unscheduleClass &&
 <div style={{color:"red"}}>The maximum allowed value is {saveData.unscheduleClass}</div>
    } */}
   
  </Form.Item>
)}


              {changeteacherstatus == false && (
                <Form.Item
                  label="Create payment link for excess amount."
                  name="checkpayment"
                >
                               <Checkbox
  name="excess_amount"
  onChange={(e) => {
   
    setIsCheckboxChecked(e.target.checked);
    console.log("Checkbox selected:", e.target.checked);
  }}
/>
                </Form.Item>
              )}
               {changeteacherstatus == false && payLink && (
              <Form.Item
              label="Payment Link:"
              name="paymentLink"
              >
<span>
        <a ref={linkRef} href={payLink} target="_blank" rel="noopener noreferrer">
          {payLink}
        </a>
        <Button style={{ marginLeft: "20px" }}
          onClick={() => handleCopyLink(payLink)}
        >
          Copy Link
        </Button>
      </span>
              </Form.Item>
               )}
              <Form.Item>
                <Button    type="primary" htmlType="submit" disabled={isSubmitDisabled()}>
                  {disabledBtn ? "Loading" : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          )}
          {_.isEqual(newcourseswitchstatus, true) && (
            <Form
              initialValues={{
                remember: true,
              }}
              onFinish={newcoursecswitchfinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Existing Course"
                name="fromCourseId"
                rules={[
                  {
                    required: true,
                    message: "Please input your code!",
                  },
                ]}
              >

                <Select
                  dropdownClassName="course-list-dropdown"
                  onSelect={(value) => oncoursechange(value)}

                  placeholder="Select Course"
                >
                  {console.log(existcourse, "existcourse")}
                  {_.map(existcourse, (course, index) => {

                    return (
                      <Option
                        value={_.get(course, "courseId")}
                        key={_.get(course, "id")}
                      >
                        {_.get(course, "courseName")}
                      </Option>
                    );


                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="New Course"
                name="toCourseId"
                rules={[
                  {
                    required: true,
                    message: "Please input your code!",
                  },
                ]}
              >

                <Select
                  dropdownClassName="course-list-dropdown"
                  onSelect={(value) => oncoursechange(value)}
                  placeholder="Select Course"
                >
                  {_.map(
                    allcourselist.filter((e) => {
                      return true;
                    }),
                    (course, index) => {
                      return _.map(_.get(course, "tags"), (tag, i) => {
                        return (
                          <Option
                            value={_.get(tag, "paidCourseId")}
                            key={_.get(tag, "id")}
                          >
                            {_.get(tag, "parent") + "- " + _.get(tag, "name")}
                          </Option>
                        );
                      });
                    }
                  )}

                </Select>
              </Form.Item>
              <Form.Item
                label="Teacher"
                name="teacherId"
                rules={[
                  {
                    required: true,
                    message: "Please select the teacher!",
                  },
                ]}
              >
                <Select dropdownClassName="course-list-dropdown" placeholder="Select Teacher">
                  {_.map(courselist, (teacher, index) => {
                    return (
                      <Option value={_.get(teacher, "teacherId")} key={index}>
                        {_.get(teacher, "teacherName")}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
              >
                <Button
                  type="dashed"
                  onClick={openModal}
                >
                  Select Student
                </Button>
              </Form.Item>
              <Form.Item
                label="Student Name"
                name="toStudentId"
                rules={[
                  {
                    required: true,
                    message: "Please select Student Name!",
                    validator: (_, value) => {
                      if (!selectedTeacherName) {
                        return Promise.reject("Please select a Student.");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {console.log(selectedTeacherName, "selectedTeacherName")}
                <Input
                  disabled
                  value={selectedTeacherName}
                  onChange={(e) => setSelectedTeacherName(e.target.value)}
                  style={{ width: "255px" }}
                />
              </Form.Item>
              <Form.Item
                label="No.of classes to move for new course"
                name="numberClassToAdd"
              >
                <Input style={{ width: "100px" }} />
              </Form.Item>
              <Form.Item
                label="No.of classes deduct for existing course"
                name="numberClassToDeducted"
              >
                <Input style={{ width: "100px" }} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  disabled={disabledBtn}
                  htmlType="submit"
                  onClick={() => newcoursecswitchfinish(selectedTTeacher, selectedTeacherName, form.getFieldsValue())}
                >
                  {disabledBtn ? "Loading" : "Submit"}
                </Button>
              </Form.Item>

            </Form>
          )}
          {_.isEqual(newcoursestatus, true) && (
            <Form
              initialValues={{
                remember: true,
              }}
              onFinish={newcoursecfinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="New Course"
                name="courseId"
                rules={[
                  {
                    required: true,
                    message: "Please input your course!",
                  },
                ]}
              >
                {/* <Input onChange={(e) => oncoursechange(e)} /> */}
                <Select
                  dropdownClassName="course-list-dropdown"
                  onSelect={(value) => oncoursechange(value)}
                  placeholder="Select Course"
                >

                  {_.map(allcourselist, (course, index) => {

                    return _.map(_.get(course, "tags"), (tag, i) => {

                      return (
                        <Option
                          value={_.get(tag, "paidCourseId")}
                          key={_.get(tag, "id")}
                        >
                          {_.get(tag, "parent") + "- " + _.get(tag, "name")}
                        </Option>
                      );
                    });
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Plan Code"
                name="planCode"

              >


                <Input
                  disabled={planDetails?.planAmount}
                  style={{ width: "calc(115% - 200px)" }}
                  placeholder="Enter Plancode to Enable Discount Box"
                  onChange={(event) => {
                    setStudentPlanCode(event.target.value);
                  }}
                  value={studentPlanCode}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    coursePlanDetails();
                  }}
                >
                  Verify
                </Button>
                <Button
                  style={{ marginLeft: "1px" }}
                  type="primary"
                  onClick={onReset}
                >
                  Reset
                </Button>
              </Form.Item>
              <Form.Item
                label="Discount"
                name="discount"
                rules={[
                  {
                    required: true,
                    message: 'Please input Your Discount!',
                  },
                ]}
              >
                <Input disabled={!planDetails}
                  onChange={(event) => onDiscountChange(event.target.value)}
                  placeholder="Enter Discount Amount"
                />
              </Form.Item>
              <Form.Item label="Plan Amount" name="planAmount">
                {console.log(planDetails?.planAmount, "%%%")}
                <Input disabled value={planDetails?.planAmount} />
              </Form.Item>
              <Form.Item label="Total Price" value={finalDiscount}>
                <Input disabled={!planDetails} value={finalDiscount} />
              </Form.Item>
              <Form.Item
                label="Teacher"
                name="teacherId"
                rules={[
                  {
                    required: true,
                    message: "Please select the teacher!",
                  },
                ]}
              >
                <Select dropdownClassName="course-list-dropdown" placeholder="Select Teacher">
                  {_.map(courselist, (teacher, index) => {
                    return (
                      <Option value={_.get(teacher, "teacherId")} key={index}>
                        {_.get(teacher, "teacherName")}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {errorMsg && (
                <span
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    display: "inline-block",
                  }}
                >
                  *{errorMsg}
                </span>
              )}
              <Form.Item>
                <Button type="primary" disabled={disabledBtn} htmlType="submit">
                  {disabledBtn ? "Loading" : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Modal>

        {isCheckboxChecked && isPaymentModalVisible ? (
  <Modal
    title="Create Payment"
    width={1150}
    visible={isPaymentModalVisible}
    onOk={() => {
      
      setIsPaymentModalVisible(false);
    }}
    onCancel={() => {
      setIsPaymentModalVisible(false);
      childFunc.current();
    }}
    footer={null}
  >
    <div className="new-payment-form" style={{ width: "100%", overflow: "auto" }}>
      <Courselist childFunc={childFunc} excessPrice={changeCourseDetails} closemodal={closePaymentModel} viewProfile={_.get(props, "students.viewprofile")} profile={{ ..._.get(props, "students.viewprofile"), ...{ credId: studentId.get("credid") }, ...{ token } }} />
    </div>
  </Modal>
) : null}

        {/* <Button onClick={showRefundModal} style={{marginLeft:16}}>Refund</Button> */}
        <Modal
          title="Class Refund"
          visible={isRefundModalVisible}
          onOk={handleRefundOk}
          onCancel={handleRefundCancel}
          footer={false}
        >
          <Form
            initialValues={{
              remember: true,
            }}
            onFinish={onRefundFinish}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Course"
              name="courseId"
              rules={[
                {
                  required: true,
                  message: "Please input your course!",
                },
              ]}
            >
              <Select
                dropdownClassName="course-list-dropdown"
                onSelect={(value) => oncoursechange(value)}
                placeholder="Select Course"
              >
                {_.map(existcourse, (course, index) => {
                  // return _.map(_.get(course, 'tags'), (tag, i) => {
                  return (
                    <Option
                      value={_.get(course, "courseId")}
                      key={_.get(course, "id")}
                    >
                      {_.get(course, "courseName")}
                    </Option>
                  );
                  // })
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="No.of class Refund"
              name="numberofClass"
              rules={[
                {
                  required: true,
                  message: "Please Enter class Refund.",
                },
              ]}
            >
              <Input
              />
            </Form.Item>
            {response && response['Student_Class_Ready_For_Scheduling_count'] && response['Number_Of_Class_Request_for_Refund'] &&
              <span style={{ float: "right", marginRight: "71px", marginTop: "-29px", color: "red" }}>{response.error_message}</span>
            }
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {disabledBtn ? "Loading" : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Add Student"
          visible={isStudentModalVisible}
          onFinish={onStudentFinish}
          onOk={() => {
            setSelectedTeacher(selectedTTeacher);
            console.log(selectedTTeacher, "selectedTTeacherselectedTTeacher")
            setSelectedTeacherName(selectedTTeacherName);
            closeModal()
          }}
          onCancel={closeModal}
        >
          <Form form={form} onFinish={onStudentFinish}>
            <Form.Item label="Search Student">
              <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
                placeholder="Enter Student name"
                style={{ width: "60%" }}
              />

              <Button disabled={!searchQuery.trim()} type="primary" onClick={handleSearch}>
                Search
              </Button>
            </Form.Item>
            {errorMessage &&
              <span style={{ color: "red", marginLeft: "152px" }}>Space is not Allowed.</span>
            }
            {loading ? (
              <Spin tip="Loading...">
                <div style={{ minHeight: '200px' }} />
              </Spin>
            ) : (

              <div className="modal-body-scrollable">
                {searchPerformed && studentsList.length === 0 ? (
                  <div style={{ display: "flex", justifyContent: "center", color: "red" }}>No Student Found</div>
                ) : (
                  <Form.Item>
                    <Radio.Group style={{ display: "inline" }}>
                      {studentsList.map((student) => (
                          <div key={student.studentId} className="student-card">
                          <Radio
                            value={student.studentId}
                            onClick={() => handleRadioChange(student.studentId, student.studentName, student.credentialId)}
                          >
                            {student.studentName} -  {student.mobileNumber} - {student.emailId}
                          </Radio>
                        </div>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>
            )}
          </Form>
        </Modal>
        {/* </React.Fragment> */}
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  usertypechange: (details) =>
    dispatch(global.redux.action.students.usertypechange(details)),
  refundClass: (details) =>
    dispatch(global.redux.action.students.refundClass(details)),
  changecourseforstudent: (details) =>
    dispatch(global.redux.action.teachers.changecourseforstudent(details)),
  listteacherforcourse: (details) =>
    dispatch(global.redux.action.teachers.listteacherforcourse(details)),
  planCodeForStudent: (details) =>
    dispatch(global.redux.action.teachers.planCodeForStudent(details)),
  couselist: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  enrollnewcourse: (details) =>
    dispatch(global.redux.action.students.enrollnewcourse(details)),
  enrolledcourselist: (details) =>
    dispatch(global.redux.action.students.enrolledcourselist(details)),
  list: (details) => dispatch(global.redux.action.students.list(details)),
  classcount: (details) =>
  dispatch(global.redux.action.students.viewclasscount(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudentType);

