
import { useEffect } from 'react'
import { collection, where, query, onSnapshot, orderBy, limit } from "firebase/firestore"
import { db } from "../../../firebase"
import { getUserInfo } from "../services"

function useLoadConversations({ setLoading, CONVERSATION_LENGTH, userId, initialfetchChatList, setLastDoc, setConv, setConvCopy, allStudents }) {
  
  // Load existing conversation list to chat sidebar
  useEffect(() => {
    if (userId) {
      setLoading(true)
      
      let pageSize = !initialfetchChatList ? 1 : CONVERSATION_LENGTH;

      const q = query(
        collection(db, "chats"),
        where("participants", "array-contains", String(userId)),
        orderBy("lastMsgTimestamp", "desc"),
        limit(pageSize)
      );
    
      //real time update
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        if (initialfetchChatList) {
          // console.log("initialfetchChatList - true")
          let newData = [];
          for (let docs of snapshot.docs) {
            let data = docs.data();

            const userDataObject = {}
            userDataObject.id = docs.id
            userDataObject.courseName = data.courseName
            const currentChatId = docs.id
            const currentStudentId = data.studentId
            let currentCourseId = ""
            if (currentChatId.includes(currentStudentId)) {
              currentCourseId = currentChatId.substring(currentStudentId.length)
            }

            if (allStudents && Object.keys(allStudents).length > 0) {
              const studentData = allStudents[String(data.studentId)]
              if (studentData) {
                userDataObject.firstName = studentData?.firstName ? studentData.firstName : ""
                userDataObject.profilePhoto = studentData?.profilePhoto ? studentData.profilePhoto : ""
                userDataObject.courseId = currentCourseId
                userDataObject.showConvBadge = false
                if (data.teacherId === String(userId)) {
                  newData.push({...userDataObject, ...data})
                }
              }
            }
          }
          
          setLastDoc(snapshot.docs[snapshot.size - 1]);
          setConv(newData)
          // Copy of data for local search
          if (setConvCopy) {
            setConvCopy(newData)
          }
            
          initialfetchChatList = false;
          pageSize = 1;
        } else {
          // console.log("initialfetchChatList - false")
          let newData = {};
          snapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              // console.log("loadConversations added")

              const userDataObject = {}
              userDataObject.id = change.doc.id
              userDataObject.courseName = change.doc.data().courseName

              const currentChatId = change.doc.id
              const currentStudentId = change.doc.data().studentId
              let currentCourseId = ""
              if (currentChatId.includes(currentStudentId)) {
                currentCourseId = currentChatId.substring(currentStudentId.length)
              }

              if (allStudents && Object.keys(allStudents).length > 0) {
                const studentData = allStudents[String(change.doc.data().studentId)]
                if (studentData) {
                  userDataObject.firstName = studentData?.firstName ? studentData.firstName : ""
                  userDataObject.profilePhoto = studentData?.profilePhoto ? studentData.profilePhoto : ""
                  userDataObject.courseId = currentCourseId
                  userDataObject.showConvBadge = false
                } else {
                  const userData = await getUserInfo(String(change.doc.data().studentId))
                  // console.log(userData, "userData - when student info not found in teachers current student list")
                  userDataObject.firstName = userData?.data?.data?.firstName ? userData.data.data.firstName : ""
                  userDataObject.profilePhoto = userData?.data?.data?.profilePhoto ? userData.data.data.profilePhoto : ""
                  userDataObject.courseId = currentCourseId
                  userDataObject.showConvBadge = false
                }
                newData = { ...userDataObject, ...change.doc.data() };
              }
            
              setConv((prev) => {
                return [newData, ...prev]
              })
              // Copy of data for local search
              if (setConvCopy) {
                setConvCopy((prev) => [newData, ...prev])
              }
            } 
            
            if (change.type === 'modified') {
              // console.log("loadConversations modified")
              let lastmsg = change.doc.data().lastMessage
              let lastMsgTimestamp = change.doc.data().lastMsgTimestamp
              const lsChatDataId = JSON.parse(localStorage.getItem("selectedChatDataId"))

              setConv((prev) => {
                prev.find( p => p.id === change.doc.id && (
                    p.lastMessage = lastmsg, 
                    p.lastMsgTimestamp = lastMsgTimestamp,
                    p.showConvBadge = p.id !== lsChatDataId ? true : false, 
                    true
                  ) 
                )
                
                // targetElement - picking current conversation
                const targetElement = prev.find(item => item.id === change.doc.id)
                // moving current conversation to the top of the list
                if (targetElement) {
                  return [targetElement, ...prev.filter(item => item.id !== change.doc.id)]
                } else {
                  return [...prev]
                }
              })
              // Copy of data for local search
              if (setConvCopy) {
                setConvCopy((prev) => {
                  prev.find( p => p.id === change.doc.id && (
                    p.lastMessage = lastmsg, 
                    p.lastMsgTimestamp = lastMsgTimestamp, 
                    p.showConvBadge = p.id !== lsChatDataId ? true : false,
                    true
                    ) 
                  )
                  
                  // targetElement - picking current conversation
                  const targetElement = prev.find(item => item.id === change.doc.id)
                  // moving current conversation to the top of the list
                  if (targetElement) {
                    return [targetElement, ...prev.filter(item => item.id !== change.doc.id)]
                  } else {
                    return [...prev]
                  }
                })
              }
            }
          })
        }
        setLoading(false)
      })

      return () => unsubscribe()
    }
  }, [allStudents, userId])

}

export default useLoadConversations