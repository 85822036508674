import actionType from '../actionTypes';
import _ from 'lodash';

const reducerName = actionType.reduxUpdateAccountState;
const pathUrl = `/api/admin`;
const custompath = `api/users/admin`;

// account actions

export default {
    // get create payment of class
    createpayment: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/api/course/subscription/data`
                },
                actionType: actionType.payment.createpayment,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
     // get direct payment of class
     directpayment: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    baseURI:`https://58eh80x2x6.execute-api.ap-south-1.amazonaws.com`,
                    method: 'post',
                    url: `/api/order/directpayment`
                },
                actionType: actionType.payment.directpayment,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    // get direct payment of class
    paymentlink: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    baseURI:`https://58eh80x2x6.execute-api.ap-south-1.amazonaws.com`,
                    method: 'get',
                    url: `/api/order/paymentlink/${_.get(componentRequest, 'params.studentId')}`
                },
                actionType: actionType.payment.paymentlink,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
}
