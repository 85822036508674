import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Button, Upload, Select, message, Spin, Modal, Card } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as SecureLS from 'secure-ls';
import appEnvirontVariables from '../../../core_units/config/env-variables';

const { Option } = Select;

const TeacherPayout = () => {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1; 
  const months = moment.months();

  const ls = new SecureLS({ encodingType: 'aes' });
  const token = ls.get('isLogin');
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString().padStart(2, '0'));
  const [loading, setLoading] = useState(false);

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleUpload = useCallback(async ({ file }) => {
    if (!file) {
      message.error('No file detected. Please upload a valid file.');
      return;
    }

    const fileType = file.type;
    const fileName = file.name;

    const allowedTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv'
    ];
    const allowedExtensions = ['.csv', '.xls', '.xlsx'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();

    if (!allowedTypes.includes(fileType) || !allowedExtensions.includes(`.${fileExtension}`)) {
      message.error('Only CSV and Excel files are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const fromDate = moment(`${selectedYear}-${selectedMonth}-01`).startOf('month').format('YYYY-MM-DD');
    const toDate = moment(`${selectedYear}-${selectedMonth}-01`).endOf('month').format('YYYY-MM-DD');

    setLoading(true);
    try {
      const response = await axios.post(
        `${appEnvirontVariables.API.BASE_URL}/api/users/teacherpayoutexceltojason`,
        formData,
        {
          headers: {
            Authorization: token['authorization'],
            'Content-Type': 'multipart/form-data',
          },
          params: {
            fromDate,
            toDate,
          },
        }
      );

      setLoading(false);
      if (response.status === 200) {
        message.success('File uploaded successfully.');
      } else {
        message.error(`Error uploading file: ${response.statusText}`);
      }
    } catch (error) {
      setLoading(false);
      message.error('Error uploading file. Please try again later.');
    }
  }, [selectedYear, selectedMonth, token]);

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this payout?',
      onOk: async () => {
        const fromDate = moment(`${selectedYear}-${selectedMonth}-01`).startOf('month').format('YYYY-MM-DD');
        const toDate = moment(`${selectedYear}-${selectedMonth}-01`).endOf('month').format('YYYY-MM-DD');

        setLoading(true);
        try {
          const response = await axios.put(
            `${appEnvirontVariables.API.BASE_URL}/api/users/deletePayslips?fromDate=${fromDate}&toDate=${toDate}`,
            {
              headers: {
                Authorization: token['authorization'],
              },
              // params: {
              //   fromDate,
              //   toDate,
              // },
            }
          );

          setLoading(false);
          if (response.status === 200) {
            message.success('Payout deleted successfully.');
          } else {
            message.error(`Error deleting payout: ${response.statusText}`);
          }
        } catch (error) {
          setLoading(false);
          message.error('Error deleting payout. Please try again later.');
        }
      },
    });
  };

  return (
    <div>
       <p style={{ fontWeight: 'bold', color:'black', textAlign:"center" }} > <h3>Teacher Payslip</h3></p>
       {/* <h1>Teacher Payslip</h1> */}

    <div style={styles.container}>

      
      <Card style={styles.card}>
  
        <div style={{ marginBottom: 15 }}>
          {/* <p style={{ fontWeight: 'bold', color:'black' }} >     Teacher Payslip</p> */}
          <p style={{ fontWeight: 'bold', color:'black' }} ><b>Select Year:</b></p>
          <Select
            defaultValue={currentYear}
            style={{ width: 120, marginRight: 16 }}
            onChange={handleYearChange}
          >
            <Option value={currentYear}>{currentYear}</Option>
          </Select>
          <p style={{ fontWeight: 'bold', color:'black' }}><b>Select Month:</b></p>
          <Select
            defaultValue={currentMonth.toString().padStart(2, '0')}
            style={{ width: 120 }}
            onChange={handleMonthChange}
          >
            {months.map((month, index) => (
              <Option key={index} value={String(index + 1).padStart(2, '0')}>
                {month}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginBottom: 30 }}>
          <p style={{ fontWeight: 'bold', color:'black' }}>Upload Payout</p>
          <Upload
            accept=".csv,.xls,.xlsx"
            customRequest={handleUpload}
            showUploadList={false}
            disabled={loading}
          >
            <Button
              icon={<UploadOutlined />}
              style={{ marginBottom: '20px' }}
              loading={loading}
              type="primary"
            >
              Upload File
            </Button>
          </Upload>
        </div>

        <div>
          <p style={{ fontWeight: 'bold', color:'black' }}>Delete Payout</p>
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            disabled={loading}
          >
            Delete
          </Button>
        </div>

        {loading && (
          <div style={styles.spinnerContainer}>
            <Spin size="large" />
          </div>
        )}
      </Card>
    </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',


    // backgroundColor: '#f0f2f5',
  },
  card: {
    padding: '30px',
    width: '400px',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',

    backgroundColor: '#fff',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    marginTop: '20px',
  },
};

export default TeacherPayout;
