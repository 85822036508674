import React, { useState, useEffect } from "react";
import { Form, DatePicker, Button, Table, Spin, Tag, Select, Modal } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import axios from 'axios';
import { connect } from "react-redux";
import "./style.css";
import * as SecureLS from "secure-ls";
import appEnvirontVariables from "../../../core_units/config/env-variables";
function AeConversion() {
  const { RangePicker } = DatePicker;
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (startDate && endDate) {
        fetchAPIResponse(startDate, endDate);
      }
  }, [startDate, endDate]);
  const fetchAPIResponse = (startDate, endDate) => {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/ae/conversion?fromDate=${startDate}&toDate=${endDate}`;
    setLoading(true);
    setData([]); 
    axios.get(apiUrl, {
      headers: {
        Authorization: token["authorization"]
      }
    })
    .then(response => {
     
      const responseData = response.data;
      console.log(response.data,"response===")
      if (responseData && responseData.length > 0) {
        responseData.forEach(element => {
          const domesticTotal = (element.Domestic?.classConducted || 0) + (element.Domestic?.classesNotConducted || 0) + (element.Domestic?.classesNotMarked || 0);
          const internationalTotal = (element.International?.classConducted || 0) + (element.International?.classesNotConducted || 0) + (element.International?.classesNotMarked || 0);

          if (domesticTotal !== 0) {
            element.Domestic.classesConductedDomesticPercentage = ((element.Domestic.classConducted / domesticTotal) * 100).toFixed(2) + "";
          } else {
            element.Domestic.classesConductedDomesticPercentage = "0"; 
          }

          if (internationalTotal !== 0) {
            element.International.classesConductedInternationalPercentage = ((element.International.classConducted / internationalTotal) * 100).toFixed(2) + "";
          } else {
            element.International.classesConductedInternationalPercentage = "0"; 
          }
        });
        setData(responseData);
        console.log(responseData,"responseData==")
      }
      setLoading(false);
      // form.resetFields();
    })
  
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
};

  
  const openPopup = (student) => {
    setSelectedStudent(student);
    console.log(student,"student==")
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  
  const columns = [
    {
      title: "Teacher ID",
      dataIndex: "TeacherId",
      key: "TeacherId",
    },
    {
      title: "Teacher Name",
      dataIndex: "TeacherName",
      key: "TeacherName",
    },
    {
      title: "Category Name",
      dataIndex: "CategoryName",
      key: "CategoryName",
    },
    {
      title: "Total Slots",
      dataIndex: "TotalSlots",
      key: "TotalSlots",
    },
    {
      title: "Classes Conducted",
      children: [
        {
          title: "Domestic",
          dataIndex: ["Domestic", "classConducted"], 
          key: "domesticClassConducted",
        },
        {
          title: "International",
          dataIndex: ["International", "classConducted"], 
          key: "internationalClassConducted",
        },
        {
          title: "Total",
          dataIndex: "totalConducted",
          key: "totalConducted",
          render: (text, record) => {
            const domesticConducted = record.Domestic ? record.Domestic.classConducted || 0 : 0;
            const internationalConducted = record.International ? record.International.classConducted || 0 : 0;
            const totalConducted = domesticConducted + internationalConducted;
            return totalConducted;
          }
        },
      ],
    },
    {
      title: "Classes Not Conducted",
      children: [
        {
          title: "Domestic",
          dataIndex: ["Domestic", "classesNotConducted"], 
          key: "Domestic.classesNotConducted",
        },
        {
          title: "International",
          dataIndex: ["International", "classesNotConducted"], 
          key: "International.classesNotConducted",
        },
        {
          title: "Total",
          dataIndex: "totalNotConducted",
          key: "totalNotConducted",
          render: (text, record) => {
            const domesticNotConducted = record.Domestic ? record.Domestic.classesNotConducted || 0 : 0;
            const internationalNotConducted = record.International ? record.International.classesNotConducted || 0 : 0;
            const totalNotConducted = domesticNotConducted + internationalNotConducted;
            return totalNotConducted;
          }
        },
      ],
    },
    {
      title: "Classes Not Marked",
      children: [
        {
          title: "Domestic",
          dataIndex:[ "Domestic","classesNotMarked"],
          key: "Domestic.classesNotMarked",
        },
        {
          title: "International",
          dataIndex: ["International","classesNotMarked"],
          key: "International.classesNotMarked",
        },
        {
          title: "Total",
          dataIndex: "totalNotMarked",
          key: "totalNotMarked",
          render: (text, record) => {
            const domesticNotMarked = record.Domestic ? record.Domestic.classesNotMarked || 0 : 0;
            const internationalNotMarked = record.International ? record.International.classesNotMarked || 0 : 0;
            const totalNotMarked = domesticNotMarked + internationalNotMarked;
            return totalNotMarked;
          }
        },
      ],
    },
    {
      title: "Classes Conducted Percentage",
      children: [
        {
          title: "Domestic",
          dataIndex: ["Domestic","classesConductedDomesticPercentage"],
          key: "Domestic.classesConductedDomesticPercentage",
        },
        {
          title: "International",
          dataIndex: ["International","classesConductedInternationalPercentage"],
          key: "International.classesConductedInternationalPercentage",
        },
      
      ],
    },
    
    {
      title: "Converted Students",
      children: [
        {
          title: "Domestic",
          dataIndex: ["Domestic","convertedStudents"],
          key: "Domestic.convertedStudents",
        },
        {
          title: "International",
          dataIndex: ["International","convertedStudents"],
          key: "International.convertedStudents",
        },
        {
          title: "Total",
          render: (text, record) => {
            const domesticConverted = record.Domestic ? record.Domestic.convertedStudents || 0 : 0;
            const internationalConverted = record.International ? record.International.convertedStudents || 0 : 0;
            const totalConvertedStudents = domesticConverted + internationalConverted;
            return totalConvertedStudents;
          }
        },
      ],
    },
    {
      title: "Conversion Percentage",
      dataIndex: "conversionPercentage",
      key: "conversionPercentage",
      render: (text, record) => {
        const totalConducted = ((record.Domestic ? record.Domestic.classConducted || 0 : 0) + (record.International ? record.International.classConducted || 0 : 0)) || 1; // To prevent division by zero
        const totalConvertedStudents = (record.Domestic ? record.Domestic.convertedStudents || 0 : 0) + (record.International ? record.International.convertedStudents || 0 : 0);
        const conversionPercentage = ((totalConvertedStudents / totalConducted) * 100).toFixed(2);
        return `${conversionPercentage}%`;
      }
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {(record.Domestic && record.Domestic.convertedStudentsDetails && record.Domestic.convertedStudentsDetails.length > 0) ||
           (record.International && record.International.convertedStudentsDetails && record.International.convertedStudentsDetails.length > 0) ? (
            <Button type="primary" onClick={() => openPopup(record)}>
              View details
            </Button>
          ) : null}
        </>
      ),
    },
    
  ];
  
  const onFinish = (values) => {
    const startDate = values.fromDate[0].format("YYYY-MM-DD");
    const endDate = values.fromDate[1].format("YYYY-MM-DD");
    setLoading(true);
    fetchAPIResponse(startDate, endDate);
  };
//   const exportToExcel = () => {
//     if (!Array.isArray(data) || data.length === 0) {
//         console.log(data, "form======");
//         return;
//     }

//     const headers = columns.map(column => {
//         if (column.children) {
//             return column.children.map(child => {
//                 return column.title + ' ' + child.title;
//             });
//         } else {
//             return column.title;
//         }
//     }).flat();

//     let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";

//     console.log("Headers:", headers);

//     data.forEach(report => {
//         const values = [];
//         columns.forEach(column => {
//             if (column.children) {
//                 column.children.forEach(child => {
//                     if (child.dataIndex) {
//                         const nestedData = report[child.dataIndex[0]];
//                         if (nestedData) {
//                             values.push(nestedData[child.dataIndex[1]]);
//                         } else {
//                             values.push('');
//                         }
//                     } else {
//                         values.push('');
//                     }
//                 });
//             } else if (column.dataIndex === "totalConducted" || column.dataIndex === "totalNotConducted" || column.dataIndex === "totalNotMarked" || column.dataIndex === "Converted Students Total" || column.dataIndex === "conversion percentage") {
               
//                 values.push(report[column.key]); 
//             } else {
//                 values.push(report[column.dataIndex]);
//             }
//         });

//         csvContent += values.join(",") + "\n";
//     });

//     console.log("CSV Content:", csvContent);

//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "AE_Conversion_Report.csv");

//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// };




const exportToExcel = () => {
  if (!Array.isArray(data) || data.length === 0) {
      console.log(data, "form======");
      return;
  }

  const headers = columns.map(column => {
      if (column.children) {
          return column.children.map(child => {
              return column.title + ' ' + child.title;
          });
      } else {
          return column.title;
      }
  }).flat();

  let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";

  console.log("Headers:", headers);

  data.forEach(report => {
    console.log("Report:", report); 
      const values = [];
      headers.forEach(header => {
        switch (header) {
          case "Teacher ID":
              values.push(report.TeacherId || '');
              break;
          case "Teacher Name":
              values.push(report.TeacherName || '');
              break;
          case "Category Name":
              values.push(report.CategoryName || '');
              break;
          case "Total Slots":
              values.push(report.TotalSlots || 0);
              break;
          case "Classes Conducted Domestic":
              values.push(report.Domestic.classConducted || 0);
              break;
          case "Classes Conducted International":
              values.push(report.International.classConducted || 0);
              break;
          case "Classes Not Conducted Domestic":
              values.push(report.Domestic.classesNotConducted || 0);
              break;
          case "Classes Not Conducted International":
              values.push(report.International.classesNotConducted || 0);
              break;
          case "Classes Not Marked Domestic":
              values.push(report.Domestic.classesNotMarked || 0);
              break;
          case "Classes Not Marked International":
              values.push(report.International.classesNotMarked || 0);
              break;
          case "Converted Students Domestic":
              values.push(report.Domestic.convertedStudents || 0);
              break;
          case "Converted Students International":
              values.push(report.International.convertedStudents || 0);
              break;
          case "Classes Conducted Percentage Domestic":
              values.push(report.Domestic.classesConductedDomesticPercentage || 0);
              break;
          case "Classes Conducted Percentage International":
              values.push(report.International.classesConductedInternationalPercentage || 0);
              break;
          case "Classes Conducted Total":
              const classesConductedTotal = (report.Domestic.classConducted || 0) + (report.International.classConducted || 0);
              values.push(classesConductedTotal);
              break;
          case "Classes Not Conducted Total":
              const classesNotConductedTotal = (report.Domestic.classesNotConducted || 0) + (report.International.classesNotConducted || 0);
              values.push(classesNotConductedTotal);
              break;
          case "Classes Not Marked Total":
              const classesNotMarkedTotal = (report.Domestic.classesNotMarked || 0) + (report.International.classesNotMarked || 0);
              values.push(classesNotMarkedTotal);
              break;
          case "Converted Students Total":
              const convertedStudentsTotal = (report.Domestic.convertedStudents || 0) + (report.International.convertedStudents || 0);
              values.push(convertedStudentsTotal);
              break;
          case "Conversion Percentage":
              const totalConducted = ((report.Domestic.classConducted || 0) + (report.International.classConducted || 0)) || 1; 
              const totalConvertedStudents = (report.Domestic.convertedStudents || 0) + (report.International.convertedStudents || 0);
              const conversionPercentage = ((totalConvertedStudents / totalConducted) * 100).toFixed(2);
              values.push(`${conversionPercentage}%`);
              break;
          default:
              if (report.hasOwnProperty(header)) {
                  values.push(report[header] || '');
              } else {
                  values.push('');
              }
              break;
      }
      
      
      });

      csvContent += values.join(",") + "\n";
  });

  console.log("CSV Content:", csvContent);

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "AE_Conversion_Report.csv");

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  



  return (
    <div className="report-filter-container" style={{width:"210%"}}>
      <span>AE Conversion Details</span>
      <Form form={form} onFinish={onFinish}>
        <div className="" style={{ maxWidth: "100%" }}>
          <Form.Item
            label="Date"
            name="fromDate"
            rules={[
              {
                required: true,
                message: "Please input your date range!",
              },
            ]}
          >
            <RangePicker/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
            </div>
          ) : (
            <>
              {data.length > 0 ? (
                <Table columns={columns} dataSource={data} pagination={false} />
              ) : (
                <div style={{display:"flex", justifyContent:"center",marginLeft:"-37%"}}>No data found</div>
              )}
           <Modal
  visible={showModal}
  title="Conversion Details"
  onCancel={handleCloseModal}
  footer={null}
  className="modalTable"
>
  {selectedStudent && (
    <>
      {selectedStudent.Domestic && selectedStudent.Domestic.convertedStudentsDetails && (
        <>
          <h6>Domestic Converted Students Details</h6>
          <Table
            columns={[
              {
                title: "Enrollment Date",
                dataIndex: "enrollmentDate",
                key: "enrollmentDate",
                render: (enrollmentDate) => dayjs(enrollmentDate).format("MMMM DD, YYYY"),
              },
              {
                title: "Student Id",
                dataIndex: "studentId",
                key: "studentId",
              },
              {
                title: "Student Name",
                dataIndex: "studentName",
                key: "studentName",
              },
              {
                title: "Mobile Number",
                dataIndex: "mobilenumber",
                key: "mobilenumber",
              },
              {
                title: "Email Id",
                dataIndex: "emailId",
                key: "emailId",
              },
            ]}
            dataSource={selectedStudent.Domestic.convertedStudentsDetails}
            pagination={false}
          />
        </>
      )}
      {selectedStudent.International && selectedStudent.International.convertedStudentsDetails && (
        <>
          <h6 style={{marginTop:"20px"}}>International Converted Students Details</h6>
          <Table
            columns={[
              {
                title: "Enrollment Date",
                dataIndex: "enrollmentDate",
                key: "enrollmentDate",
                render: (enrollmentDate) => dayjs(enrollmentDate).format("MMMM DD, YYYY"),
              },
              {
                title: "Student Id",
                dataIndex: "studentId",
                key: "studentId",
              },
              {
                title: "Student Name",
                dataIndex: "studentName",
                key: "studentName",
              },
              {
                title: "Mobile Number",
                dataIndex: "mobilenumber",
                key: "mobilenumber",
              },
              {
                title: "Email Id",
                dataIndex: "emailId",
                key: "emailId",
              },
            ]}
            dataSource={selectedStudent.International.convertedStudentsDetails}
            pagination={false}
          />
        </>
      )}
    </>
  )}
</Modal>

            </>
          )}
        </div>
      </Form>
    </div>  );
}

export default AeConversion;


