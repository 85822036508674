import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import SecureLS from "secure-ls";


import axios from "axios";

import { Modal, Form, Button, DatePicker, Input,notification } from "antd";
import appEnvirontVariables from "../../../core_units/config/env-variables";
function Restart() {
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const [server, setServer] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const serverDetails = (serverNumber) => {
        setServer("");
        setModalContent("");
    axios.post(`${appEnvirontVariables.API.BASE_URL}/api/course/restart?server=${serverNumber}`, {}, {
        headers: {
            Authorization: token["authorization"]
        }
    })
            .then(response => {
                const data = response.data; 
                console.log("API Response Data:", data);
                setServer(data);
                showModal();
            })
            .catch(error => {
                console.error("API Error:", error);
                showModal("Oops! Request triggered to other server. Try Again.");
            });
    };
    const showModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
  return (
    <div>
      <div>
        <Button type="primary" htmlType="button" onClick={() => serverDetails(1)}>
          Restart Server-1
        </Button>
        <Button
          type="primary"
          htmlType="button"
          style={{ marginLeft: "10px" }}
          onClick={() => serverDetails(2)}
        >
          Restart Server-2
        </Button>
      </div>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
      >
        
        {server && <p>{server}</p>}

      {modalContent}
     
      </Modal>
    </div>
  )
}

export default Restart
