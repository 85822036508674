import React, { useState, useEffect } from "react";

import moment from "moment";
import { connect } from "react-redux";
import "./style.css";
import * as SecureLS from "secure-ls";
import { Link } from "react-router-dom";
import { Form, DatePicker, Button, Select, Modal, Empty, Input, Radio, notification, Spin } from "antd";
import _ from "lodash";
import axios from "axios";
import formatDate from "../../core_units/utils/dayjs/index";
import appEnvirontVariables from "../../core_units/config/env-variables"
import Courselist from '../students/payment/courselist';
import Paymentlink from "../students/payment/paymentlink";

const BatchCreation = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [form] = Form.useForm();

  // Generate an array of time slots from 03:00 PM to 09:00 PM
  const timeSlots = [];
  const startTime = moment("07:00", "HH:mm");
  const endTime = moment("24:00", "HH:mm");
  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  while (startTime.isBefore(endTime)) {
    timeSlots.push(startTime.format("hh:mmA"));
    startTime.add(1, "hour");
  }


  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [showBatchDetails, setShowBatchDetails] = useState(false);
  const [batchDetails, setBatchDetails] = useState(null);
  const [startDate, setStartDate] = useState(moment().format("MMMM"));
  const [endDate, setEndDate] = useState(moment().format("YYYY"));
  const [courseList, setCourseList] = useState();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("Preparatory");
  const [selectedBranch, setSelectedBranch] = useState("Chennai");
  const [selectedBatchType, setSelectedBatchType] = useState("Offline");
  const [batchTags, setBatchTags] = useState([]);
  const [showExistingDetails, setShowExistingDetails] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [queryvalue, setqueryvalue] = useState();
  const [studentsList, setStudentsList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [selectedStudentCred, setSelectedStudentCred] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentProfile, setStudentProfile] = useState();
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isPaymentDetailModalVisible, setIsPaymentDetailModalVisible] = useState(false);
  const [selectedStudentInBatch, setSelectedStudentInBatch] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [copiedLink, setCopiedLink] = useState(null);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [fromNewLink, setFromNewLink] = useState(false);
  const [showStudentExistErr, setShowStudentExisterr] = useState(false);
  const [showStudentAddErr, setShowStudentAdderr] = useState(false);
  const [showCourseErr, setShowCourseErr] = useState(false);
  const [paymentHistoryStudentId, setPaymentHistoryStudentId] = useState();
  const [onlineGroupCourseList, setOnlineGroupCourseList] = useState([]);
  const [selectedOnlineGroupCourse, setSelectedOnlineGroupCourse] = useState();
  const [selectedOnlineGroupCourseIndex, setSelectedOnlineGroupCourseIndex] = useState();

  const closePaymentModel = () => {
    setIsPaymentModalVisible(false);
    closeModal();
    handleTagClick(batchDetails.batchDetails.id);
  }
  const closePaymentDetailModel = () => {
    setIsPaymentDetailModalVisible(false);
  }


  const handleCancel = () => {
    setDeleteModalVisible(false);
  };

  const childFunc = React.useRef(null);
  const profileApi = (studentid, cb) => {
    props.view({
      params: {
        studentId: studentid,
      },
      callback: (response, data) => {
        setStudentProfile(data);
        cb();
      },
    });
  };
  const getExternalPayment = async (id) => {
    return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/external-payment/${id}`,
      { headers: { "Authorization": token['authorization'] } });
  }
  const getCourseDetails = async (sid, cid) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/studentClassesCount/45851?studentId=${sid}&courseId=${cid}`,
      { headers: { "Authorization": token['authorization'] } });
  }
  const createPaymentLink = () => {

    // let req = {
    //   "studentId": profile.studentId,
    //   "userId": profile.userId,
    //   "totalPrice": +this.state.totalPrice.toFixed(2),
    //   "name": _.startCase(_.get(profile, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(profile, 'lastName', '')),
    //   "contact": _.get(profile, 'mobileNumber'),
    //   "email": _.get(profile, 'emailId'),
    //   "countryCode": _.get(profile, 'countryCode'),
    //   "currency": this.state.currency,
    //   "customer_id": (customer_id || _.get(profile, 'zohoSubscriptionCustomerId')),
    //   "place_of_supply": "",
    //   "token": _.get(profile.token, 'authorization'),
    //   "artiumCashPoint": +this.state.referral?.totalArtiumCash || 0,
    //   "myCashPoint": +this.state.referral?.myCash || 0,
    //   "artiumCash": this.state.useWallet ? (+this.state.artiumCash || 0) : 0,
    //   "myCash": this.state.useWallet ? (+this.state.myCash || 0) : 0,
    //   "conversionRate": +this.state.referral[`${ this.state.currency}PointConversion`],
    //   "referralCode": this.state.referral?.referralCode || "",
    //   "version":"2.0",
    //   "paymentFor":"directpayment",
    //   "createdBy": loginDetails?.studentName || "" ,
    //   "gender": _.get(profile, 'gender'),
    //   "ageGroup": _.get(profile, 'ageGroup'),
    //   "studentType": _.get(profile, 'studentType'),
    //   "userType": _.get(profile, 'userType'),
    //   "state": _.get(profile, 'state'),
    //   "courseList": courses,
    //   "timeZone": profile.timezone || "",
    //   "expiryDate": this.state.expiryDate       
    // }
    // axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/order/directpayment`,req, {
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8"
    //   }

    // })
    //   // .then(response => response.json())
    //   .then(res => {
    //      console.log(res);
    //   });
  }

  const createCourse = () => {
    return
    // {
    //   "studentId": null,
    //   "courseId": "35",
    //   "courseName": "Popular/Film Music-Tamil",
    //   "classesToPurchase": "12",
    //   "teacherId": null,
    //   "lmsCourseId": "55",
    //   "zohoCrmId": null,
    //   "zohoSubscriptionId": "908601000002451479",
    //   "recommendedCourseId": null,
    //   "transaction": null,
    //   "classesAvailableToBuy": "12",
    //   "totalClassesInCourse": "12",
    //   "demoCourseId": "37",
    //   "level": "Preparatory",
    //   "item_id": "908601000000146305",
    //   "id": "35",
    //   "account_id": "908601000000146285",
    //   "parent_id": "26",
    //   "unit_price": 800,
    //   "priceWithoutGST": "656.00",
    //   "currency": "INR",
    //   "discountPercentage": 5,
    //   "disabled": true,
    //   "discountedPrice": 480,
    //   "price": 9120
    // }
  }
  const [errorMessage, setErrorMessage] = useState();
  const [searchPerformed, setSearchPerformed] = useState(false);

  // Handle course selection change
  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  // Handle level selection change
  const handleLevelChange = (e) => {
    setSelectedLevel(e.target.value);
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };
  const handleBatchTypeChange = (e) => {
    setSelectedBatchType(e.target.value);
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          data.onlineGroupCourses = data.onlineGroupCourses.filter(e => e.enabled);
          setCourseList(data);
          setSelectedCourse(data.onlineGroupCourses[0].name);
          const cList = [];
          // data.onlineGroupCourses.forEach(element => {
          //   element.tags.forEach(ele => {
          //     ele.groupId = element.id;
          //     if(ele.name){
          //       ele.courseName = `${element.name} - ${ele.name}`
          //     }else{
          //       ele.courseName = `${element.name}`
          //     }        
          //     cList.push(ele);
          //   });
          // });
          //setOnlineGroupCourseList(cList);
          setOnlineGroupCourseList(data.onlineGroupCourses);
        }
      },
    });
  };

  useEffect(() => {
    configjson();
  }, []);

  const openModal = () => {
    setSearchPerformed(false);
    setStudentsList([]);
    setSearchQuery("");
    setSelectedStudentCred("");
    setSelectedStudent("");
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);
    setSelectedOnlineGroupCourse("");
    setSelectedOnlineGroupCourseIndex("");
    if (batchDetails?.studentDetails && batchDetails.studentDetails && batchDetails.studentDetails.filter(
      (student) => student.studentStatus != "Expired"
    ).length >= 3) {
      notification.error({
        message: "",
        description: "Number of Student Limit Exceeded",
      });
    } else {
      form.resetFields();
      setIsModalVisible(true);
    }
  };
  const closeModal = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const formatMonthYearToDates = (selectedMonth, selectedYear) => {
    const startOfMonth = moment(`${selectedYear}-${moment().month(selectedMonth).format("MM")}-01`, "YYYY-MM-DD");
    const endOfMonth = startOfMonth.clone().endOf("month");

    const startDate = startOfMonth.format("YYYY-MM-DD");
    const endDate = endOfMonth.format("YYYY-MM-DD");

    return { startDate, endDate };
  };


  const handleSubmit = () => {
    setLoading(true);
    const selectedMonth = document.getElementById("startDate").value;
    const selectedYear = document.getElementById("endDate").value;
    const { startDate, endDate } = formatMonthYearToDates(selectedMonth, selectedYear);

    const selectedCourseObject = courseList.onlineGroupCourses.find(course => course.name === selectedCourse);

    console.log("Selected Course Object:", selectedCourseObject);
    if (selectedCourseObject) {
      let courseIds = [];


      // if (selectedLevel === "Preparatory + Intermediate") {
      //   console.log("Tag Matching for Preparatory + Intermediate");
      //   courseIds = [
      //     ...courseIds,
      //     selectedCourseObject.tags.find(tag => tag.localizedName === "Preparatory")?.paidCourseId,
      //     selectedCourseObject.tags.find(tag => tag.localizedName === "Intermediate")?.paidCourseId,
      //   ];
      // } else if (selectedLevel === "Advanced") {
      console.log("Tag Matching for Advanced");

      courseIds = [
        ...courseIds,
        selectedCourseObject.tags.find(tag => tag.localizedName === selectedLevel)?.paidCourseId,
      ];
      //}

      const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/fetch/batches`;

      const requestBody = {
        branchName: "Online",
        batchType: "Online",
        courseIds,
        startDate,
        endDate,
      };

      axios
        .post(apiUrl, requestBody, {
          headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          setLoading(false);
          console.log(response, "response----")
          if (response.status === 204) {
            setLoading(false);
            setBatchTags([]);
            notification.error({
              message: "",
              description: "No Batches Found",
            });
          }
          console.log("API Response:", response.data);
          const batchData = response.data;
          const batchTags = [];
          batchData.forEach((batch) => {
            const { batchTimings } = batch;
            (batchTimings || []).forEach((timing) => {
              const { dayofWeek, startTime } = timing;
              const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mmA");
              const tag = {
                dayOfWeek: dayofWeek,
                startTime: formattedStartTime,
                batchId: batch.id,
              };

              batchTags.push({ ...tag, ...batch });
            });
          });

          setBatchTags(batchTags);
          console.log(batchTags, "batchTags");
        })
        .catch((error) => {
          setLoading(false);
          console.error("API Error:", error);
        });
    } else {
      console.error("Selected course not found in courseList");
    }
  };

  const handleBackClick = () => {
    setShowBatchDetails(false);
    setShowExistingDetails(true);
    setTimeout(handleSubmit, 0);
  };


  //   const handleSearch = () => {
  //     const isQueryEmpty = searchQuery.replace(/\s+/g, '') === '';
  // setErrorMessage(isQueryEmpty);
  // if (isQueryEmpty) {


  //   } else {
  //     setSearchPerformed(true)
  //     let query = {};
  //     apicall({ name: searchQuery });
  //     console.log(searchQuery, "name: searchQuery");
  //   }

  //   };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);

    if (value.trim() === '') {
      setStudentsList([]);
    }
  };
  const handleSearch = () => {
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);
    const isQueryEmpty = searchQuery.replace(/\s+/g, '') === '';
    setErrorMessage(isQueryEmpty);

    if (isQueryEmpty) {
      

    } else {
      setSearchPerformed(true);

      let query = {};


      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchQuery)) {
        query.emailId = searchQuery;
      }

      else if (new RegExp(/^[0-9\b]+$/).test(searchQuery)) {
        query.mobileNumber = searchQuery;
      }

      else {
        query.name = searchQuery;
      }
     

      query.page = 0;
      query.size = 100;


      apicall(query);

      console.log(query);
    }
  };



  const apicall = (query = {}, page = 0) => {
    setqueryvalue(query)
    setLoading(true);
    props.list({
      body: {
        page: page,
        size: 100,
        ...query
      },
      callback: (response, data) => {


        if (_.isEqual(response, true)) {
          let arr = [];
          if (_.isEmpty(query) && !_.isEmpty(_.get(data, "content"))) {
            arr = _.uniqBy(
              [...studentsList, ..._.get(data, "content")],
              function (e) {
                return e.studentId;
              }
            );
          } else if (!_.isEmpty(query)) {
            if (
              _.size(studentsList) < _.get(data, "totalElements") &&
              _.isEqual(queryvalue, query)
            ) {
              arr = _.uniqBy(
                [...studentsList, ..._.get(data, "content")],
                function (e) {
                  return e.studentId;
                }
              );
            } else {
              arr = _.get(data, "content");
            }
          }
          setStudentsList(arr);
          setSelectedStudent("");
          console.log(arr, "arr")
        }
        setLoading(false);
      }
    })

  }
  const handleRadioChange = (studentId, studentName, credId) => {
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);
    setSelectedStudent(studentId);
    setSelectedStudentName(studentName);
    setSelectedStudentCred(credId);
  };
  const handleTagClick = (batchId) => {
    setSelectedBatchId(batchId);
    setShowBatchDetails(true);
    setShowExistingDetails(false);
    setLoading(true);

    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/batch/details?batchId=${batchId}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {

        console.log("Group Details API Response:", response.data);
        setShowBatchDetails(true);
        setLoading(false);
        //setBatchDetails(response.data);
        if (response.data?.studentDetails) {
          const updatedStudentData = [];
          for (const student of response.data?.studentDetails) {
            await fetchStudentDetails(student, response.data);
            updatedStudentData.push(student);
          }
          response.data.studentDetails = updatedStudentData;
        }
        setBatchDetails(response.data);
        console.log(response.data, "res==")

      })
      .catch((error) => {
        setLoading(false);
        console.error("API Error:", error);
        notification.error({
          message: "",
          description: "An error occurred while fetching batch details.",
        });
      });
  };
  const fetchStudentDetails = async (student, rdata) => {
    try {
      const response = await new Promise((resolve, reject) => {
        props.getpaymentlink({
          params: {
            studentId: student.studentId
          },
          callback: (response, data) => {
            if (response === true && data.length > 0) {
              const filteredData = data.filter(e => e.paymentFor === "directpayment")
                .sort((a, b) => b.created_at - a.created_at);
              resolve(filteredData);
            } else {
              resolve([]);
            }
          }
        });
      });
      const externalData = await getExternalPayment(student.studentId);
      const aggregateresult = [...(externalData.data || []), ...response];
      //const courseData = await getCourseDetails(student.studentId, rdata.batchDetails.batchCourseDetials[0].id);
      student.paymentData = aggregateresult.sort((a, b) => b.created_at - a.created_at);
      console.log(student.paymentData, "responseppppp")
    } catch (error) {
      console.error(`Error fetching details for student ID ${student.studentId}:`, error);
      student.paymentData = null;
    }
  }

  const deleteBatch = () => {
    const confirmCreate = window.confirm("Are you sure you want to delete batch?");
    if (!confirmCreate) {
      return;
    }
    axios.delete(`${appEnvirontVariables.API.BASE_URL}/api/course/batch?batchId=${selectedBatchId}`,
      { headers: { "Authorization": token['authorization'] } }).then(r => {
        handleBackClick();
      })
  }

  const addStudentToBatch = (batchId, studentId, credId, renewal = false) => {
    batchDetails.batchDetails.renewal = renewal;
    setBatchDetails(batchDetails);
    if (credId) {
      setSelectedStudentCred(credId);
    }
    profileApi((credId || selectedStudentCred), () => {
      setIsPaymentModalVisible(true);
    });
    return;
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/course/batch/student?batchId=${batchId}&studentId=${studentId}`;

    axios
      .post(apiUrl, null, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //createPaymentLink(batchId, studentId);
        console.log("API Response:", response.data);
        handleTagClick(batchId);
        form.resetFields();
        notification.success({
          message: "",
          description: `Student ${selectedStudentName} has been added to the batch.`,
        });
        closeModal();
      })
      .catch((error) => {
        console.error("API Error:", error);

        notification.error({
          message: "Error",
          description: "Failed to add the student to the batch.",
        });
      });
  };
  const onFinish = () => {
    if (!batchDetails.batchDetails.batchCourseDetials.length) {
      if (!selectedOnlineGroupCourse) {
        setShowCourseErr(true);
        return;
        //batchDetails.batchDetails.batchCourseDetials.push({id:selectedOnlineGroupCourse});
      }
    }
    if (selectedStudent && selectedBatchId) {
      if (!batchDetails.studentDetails.filter(e => e.studentId == selectedStudent).length) {
        setFromNewLink(false);
        addStudentToBatch(selectedBatchId, selectedStudent);
      } else {
        setShowStudentExisterr(true);
      }
    }
    if (!selectedStudent) {
      setShowStudentAdderr(true);
    }
  };
  function getTagColor(studentList) {
    if (studentList.filter(e => e.studentStatus != "Expired").length < 3) {
      return "green";
    } else if (studentList.filter(e => e.studentStatus == "Confirmed").length < 3) {
      return "orange";
    } else {
      return "red";
    }
  }

  const fetchBatchTags = () => {

    const currentMonthYear = moment().format("YYYY-MM");
    const startDate = `${currentMonthYear}-01`;
    const endDate = `${currentMonthYear}-${moment(currentMonthYear).daysInMonth()}`;
    const courseIds = [155, 156];
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/fetch/batches`;

    const requestBody = {
      branchName: "Online",
      batchType: "Online",
      courseIds,
      startDate,
      endDate,
    };

    axios
      .post(apiUrl, requestBody, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        const batchData = response.data;
        const batchTags = [];
        batchData.forEach((batch) => {
          const { batchTimings } = batch;
          (batchTimings || []).forEach((timing) => {
            const { dayofWeek, startTime } = timing;
            const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mmA");
            const tag = {
              dayOfWeek: dayofWeek,
              startTime: formattedStartTime,
              batchId: batch.id,
            };

            batchTags.push({ ...tag, ...batch });
          });
        });

        setBatchTags(batchTags);
        console.log(batchTags, "batchTags");
      })
      .catch((error) => {
        console.error("API Error:", error);
      });

  }
  useEffect(() => {

    fetchBatchTags();


  }, []);
  const shouldDisableAddStudentButton = () => {
    if (!batchDetails || !batchDetails.batchDetails || !batchDetails.batchDetails.studentDetails) {
      return false;
    }

    const expiredStudentsCount = batchDetails.batchDetails.studentDetails.filter(
      (student) => student.studentStatus === "Expired"
    ).length;
    return (batchDetails.batchDetails.studentDetails.length - expiredStudentsCount >= 3);
  };
  const triggerMail = (obj) => {

    if (!obj.emailId) {
      obj.emailId = 'bala@artiumacademy.com'
    }
    const msg = {
      to: obj.emailId,
      from: {
        email: 'info@artiumacademy.com',
        name: 'Artium Academy'
      },
      templateId: 'd-40d8b91cdc5143308eec09bcbd3ea02d',
      dynamicTemplateData: {
        username: obj.studentName,
        Paymentlink: obj.paymentData[0].payment_link
      },
    };
    axios
      .post(`https://pd9q7y3vyl.execute-api.ap-south-1.amazonaws.com/sendemail`, msg, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      .then(response => {
        notification.success({
          message: "",
          description: "Email sent Successfully",
        });
      })
      .catch(error => {
        console.error("Error sending email:", error);
      });
  }

  const isPrepIntermediateCourse = batchDetails && batchDetails.batchDetails.batchCourseDetials && batchDetails.batchDetails.batchCourseDetials.length === 2;

  const copyToClipboard = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        notification.success({
          message: 'Copied successfully',
          placement: 'topRight',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          placement: 'topRight',
        });
      });
  };
  const deleteStudent = (batchId, studentId) => {
    Modal.confirm({
      title: '',
      content: 'Do you want to delete this student?',
      onOk() {

        fetch(`${appEnvirontVariables.API.BASE_URL}/api/admin/delete/batch/student?batchId=${batchId}&studentId=${studentId}`, {
          method: 'post',
          headers: {
            Authorization: token["authorization"],
          },
        })
          .then(response => {
            if (response.status === 200) {
              notification.success({
                message: '',
                description: 'Student Deleted Successfully.',
              });
              setBatchDetails(prevBatchDetails => ({
                ...prevBatchDetails,
                studentDetails: prevBatchDetails.studentDetails.filter(student => student.studentId !== studentId),
              }));
            } else {
              console.error('Error deleting student');
            }
          })
          .catch(error => {
            console.error('API call error:', error);
          });
      },
      onCancel() {

      },
    });
  };

  const showDeleteModal = (studentId) => {

    deleteStudent(batchDetails.batchDetails.id, studentId)

  };

  return (
    <div>
      <div>
        {showExistingDetails && (
          <div>
            <div>

            <Link
  to={{
    pathname: "/batchView",
    search: "?", 
  }}
  style={{ cursor: "pointer", color: "black" }}
>
  <Button type="primary">Create Group</Button>
</Link>

            </div>
            <div>
              <div className="calendar-legendset-conatiner" style={{ float: "right" }}>
                <div className="calendar-legend-set">
                  <span className="legend-square" style={{ backgroundColor: "green", cursor: "pointer" }}></span>
                  <span className="legend-text">FREE &emsp; </span>
                  <span className="legend-square" style={{ backgroundColor: "red", cursor: "pointer" }}></span>
                  <span className="legend-text">FILLED &emsp; </span>
                  <span className="legend-square" style={{ backgroundColor: "orange", cursor: "pointer" }}></span>
                  <span className="legend-text">PAYMENT PENDING &emsp;</span>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "10px" }} className="row batchDetail">
              <div style={{ width: "auto" }}>
                <label htmlFor="courseSelect" style={{ fontWeight: 800 }}>
                  Teacher Course:
                </label>
                {courseList && courseList.onlineGroupCourses &&
                  <select
                    id="courseSelect"
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    style={{ marginLeft: "15px", width: "180px", cursor: "pointer" }}
                  >
                    {_.map(_.filter(courseList.onlineGroupCourses, { isBatchCourse: true }), (course, eachParent) => (
                      <option value={course.name} key={eachParent}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                }
              </div>

              <div style={{ width: "auto" }}>
                <label htmlFor="levelSelect" style={{ fontWeight: 800 }}>
                  Teacher Level:
                </label>
                <select
                  id="courseIds"
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                  <option value="Preparatory">Preparatory</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced 1">Advanced 1</option>
                  <option value="Advanced 2">Advanced 2</option>
                </select>
              </div>

              <div style={{ width: "auto" }}>
                <label htmlFor="startDatePicker" style={{ fontWeight: 800 }}>
                  Month:
                </label>

                <select
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>

              <div style={{ width: "auto" }}>
                <label htmlFor="endDatePicker" style={{ fontWeight: 800 }}>
                  Year:
                </label>

                <select
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>


              <div style={{ width: "auto" }}>
                <button
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer",
                    borderRadius: "30px",
                    backgroundColor: "lightblue",
                    border: "none",
                    width: "97px",
                    padding: "4px",
                    marginTop: "-3px",
                  }}
                >
                  Find Group
                </button>
              </div>
            </div>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin tip="Loading..." size="large">

                </Spin>
              </div>
            ) : (
              <div style={{ marginTop: "10px" }}>
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Timings</th>
                      <th>MONDAY</th>
                      <th>TUESDAY</th>
                      <th>WEDNESDAY</th>
                      <th>THURSDAY</th>
                      <th>FRIDAY</th>
                      <th>SATURDAY</th>
                      <th>SUNDAY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeSlots.map((timeSlot) => (
                      <tr key={timeSlot}>
                        <td>{timeSlot}</td>
                        {daysOfWeek.map((day) => {
                          const matchedTags = batchTags.filter(
                            (tag) => tag.startTime === timeSlot && tag.dayOfWeek === day
                          );

                          return (
                            <td key={day}>
                              {matchedTags.map((tag) => {

                                const nonExpiredStudents = (tag.batchStudents || []).filter(
                                  (student) => student.studentStatus !== "Expired"
                                );

                                console.log(nonExpiredStudents, "nonExpiredStudents")
                                return (
                                  <div
                                    style={{
                                      backgroundColor: getTagColor((tag.batchStudents || [])),
                                      padding: "4px",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      margin: "1px",
                                      marginLeft: "2px",
                                      color: "white",
                                    }}
                                    onClick={() => handleTagClick(tag.batchId)}
                                    key={tag.batchId}
                                  >
                                    <span className="batch-box">B-{tag.batchId}</span>
                                    <span title="Start Date" className="batch-box">
                                      {formatDate (tag.batchStartDate)}
                                    </span>
                                    <span title="Teacher Name" className="batch-box">
                                      {tag.teacherName}
                                    </span>
                                    <span
                                      title="No of Student in batch / max no of student"
                                      className="batch-box"
                                    >
                                      {nonExpiredStudents.length}/3
                                    </span>
                                  </div>
                                );
                              })}
                            </td>
                          );
                        })}
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            )}
          </div>

        )}




        {showBatchDetails && selectedBatchId && batchDetails && (

          <div className="full-page-view-loader">

            <div className="batch-details-content">

              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spin tip="Loading..." size="large">

                  </Spin>
                </div>
              ) : (
                <div className="batchTableView">
                  <div>
                    {batchDetails.studentDetails
                              .filter(student =>
                                student.studentStatus === "Payment Link Shared" || student.studentStatus === "Confirmed"
                              ).length < 1 ? <button
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "114px",
                        padding: "4px",
                        marginTop: "-42px",
                        marginRight: "125px",
                        float: "right"
                      }}
                      onClick={() => deleteBatch()}
                    >
                      Delete Batch
                    </button> : ""}
                    <button
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "114px",
                        padding: "4px",
                        marginTop: "-42px",
                        float: "right"
                      }}
                      onClick={() => openModal()}
                    >
                      Add Student
                    </button>
                  </div>
                  <div style={{ marginTop: "-112px" }}>
                    <button
                      onClick={handleBackClick}
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "97px",
                        padding: "4px",
                        marginTop: "70px",
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div
                    style={{
                      fontWeight: 800,
                      color: "deeppink",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "large",
                    }}
                  >
                    GROUP DETAILS
                  </div>

                  <div style={{ marginTop: "5px" }} className="row batchDetail">
                    <div style={{ width: "auto" }}>
                      <label htmlFor="courseSelect" style={{ fontWeight: 800 }}>
                        Group Name:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{batchDetails.batchDetails.batchName}</label>
                    </div>
                    <div style={{ width: "auto" }}>
                      <label htmlFor="levelSelect" style={{ fontWeight: 800 }}>
                        Start Date:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{formatDate (batchDetails.batchDetails.batchStartDate)}</label>
                    </div>
                    <div style={{ width: "auto" }}>
                      <label htmlFor="levelSelect" style={{ fontWeight: 800 }}>
                        End Date:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{formatDate (batchDetails.batchDetails.batchEndDate)}</label>
                    </div>
                    {batchDetails.batchDetails.batchCourseDetials && batchDetails.batchDetails.batchCourseDetials.length ? <div style={{ width: "auto" }}>
                      <label htmlFor="monthSelect" style={{ fontWeight: 800 }}>
                        Course:
                      </label>
                      <label style={{ marginLeft: "5px" }}>

                        {batchDetails.batchDetails.batchCourseDetials[0].name ? `${batchDetails.batchDetails.batchCourseDetials[0].name.split("-")[0]}${batchDetails.batchDetails.batchCourseDetials[0].name.split("-")[1] ? "-" + batchDetails.batchDetails.batchCourseDetials[0].name.split("-")[2] : ""}` : ""}             </label>
                    </div> : ''}
                    <div style={{ width: "auto" }}>
                      <label htmlFor="yearSelect" style={{ fontWeight: 800 }}>
                        Teacher Name:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{batchDetails.batchDetails.teacherName}</label>
                    </div>
                    {/* <div style={{ width: "auto" }}>
            <label htmlFor="coursename" style={{ fontWeight: 800 }}>
              Course Name:
            </label>
            <label style={{ marginLeft: "5px" }}>{batchDetails.batchCourseDetials[0].name}</label>
          </div> */}
                    {/* <div style={{ width: "auto" }}>
            <label htmlFor="branch" style={{ fontWeight: 800 }}>
              Branch:
            </label>
            <label style={{ marginLeft: "10px" }}>{batchDetails.batchDetails.branchName}</label>
          </div> */}
                  </div>
                  {batchDetails.studentDetails.length > 0 ? (
                    <div style={{ marginTop: "10px" }}>
                      {batchDetails.studentDetails.some(student => student.studentStatus !== "Expired") && (
                        <table className="custom-table">

                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Student Name</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Student Status</th>
                              <th>Recent Payment Status</th>

                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            {batchDetails.studentDetails
                              .filter(student =>
                                student.studentStatus === "Payment Link Shared" || student.studentStatus === "Confirmed"
                              )
                              .map((student, index) => (
                                <tr key={student.studentId}>
                                  <td>{index + 1}</td>
                                  <td>{student.studentName}</td>
                                  <td>{student.emailId}</td>
                                  <td>{student.mobileNumber}</td>
                                  <td>{student.studentStatus}</td>
                                  <td>{student.paymentData && student.paymentData[0] ? (
                                    student.paymentData[0].payment_status === "order_created"
                                      ? 'New'
                                      : (student.paymentData[0].payment_status || "-")
                                  ) : (
                                    "-"
                                  )}</td>
                                  {/* {student.studentStatus !== "Confirmed" ? (
                <td>
                  {student.paymentData && student.paymentData[0] ? (
                    <>
                      <button
                        onClick={() => copyToClipboard(student.paymentData[0].payment_link)}
                        style={{
                          cursor: "pointer",
                          width: "150px",
                          borderRadius:"30px"
                        }}
                      >
                        Copy payment link
                      </button>

                      <button
                        onClick={() => triggerMail(student)}
                        style={{ width: "150px", marginTop:"10px", cursor: "pointer", borderRadius:"30px"}}
                      >
                        Send email
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                  <button
                    style={{
                      cursor: "pointer",
                      width: "150px",
                      marginTop:"10px",
                      borderRadius:"30px"
                    }}
                    onClick={() => {
                      setFromNewLink(true);
                      addStudentToBatch(
                        batchDetails.batchDetails.id,
                        student.studentId,
                        student.credentialId,
                      ) }
                    }
                  >
                    Generate new link
                  </button>
                </td>
              ) : (
                <td>
                {student.paymentData && student.paymentData[0] && student.paymentData[0].payment_status === "order_created" ? (
                  <>
                    <button
                      onClick={() => copyToClipboard(student.paymentData[0].payment_link)}
                      style={{
                        cursor: "pointer",
                        width: "150px",
                        borderRadius:"30px"
                      }}
                    >
                      Copy payment link
                    </button>

                    <button
                      onClick={() => triggerMail(student)}
                      style={{ width: "150px", marginTop:"10px", cursor: "pointer", borderRadius:"30px"}}
                    >
                      Send email
                    </button>
                  </>
                ) : (
                  ""
                )}
                <button
                  style={{
                    cursor: "pointer",
                    width: "150px",
                    marginTop:"10px",
                    borderRadius:"30px"
                  }}
                  onClick={() => {
                    addStudentToBatch(
                      batchDetails.batchDetails.id,
                      student.studentId,
                      student.credentialId,
                      true
                    ) }
                  }
                >
                  Renew link
                </button>
              </td>
              )} */}

                                  <td>
                                    <button style={{
                                      cursor: "pointer",
                                      borderRadius: "30px",
                                      backgroundColor: "lightblue",
                                      border: "none",
                                      width: "114px",
                                      padding: "4px",
                                    }}
                                      onClick={() => {
                                        setPaymentHistoryStudentId(student.studentId);
                                        batchDetails.batchDetails.renewal = (student.studentStatus == "Confirmed");
                                        setBatchDetails(batchDetails);
                                        if (student.credentialId) {
                                          setSelectedStudentCred(student.credentialId);
                                        }
                                        profileApi((student.credentialId || selectedStudentCred), () => {
                                          setIsPaymentDetailModalVisible(true);
                                        });
                                      }
                                      }
                                    >
                                      Payments
                                    </button>
                                    {/* {student.paymentData && student.paymentData[0] ? (
              student.paymentData[0].payment_status === "order_created"
                ? 'New'
                : student.paymentData[0].payment_status
            ) : (
              ""
            )} */}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {batchDetails.studentDetails.some(student => student.studentStatus === "Expired") && (
                        <div>
                          <div style={{ marginTop: "20px", fontWeight: "800" }}> Expired Students</div>
                          <table className="custom-table" style={{ marginTop: "20px" }}>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Student Status</th>
                                <th>Recent Payment Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {batchDetails.studentDetails
                                .filter(student => student.studentStatus === "Expired")
                                .map((student, index) => (
                                  <tr key={student.studentId}>
                                    <td>{index + 1}</td>
                                    <td>{student.studentName}</td>
                                    <td>{student.emailId}</td>
                                    <td>{student.mobileNumber}</td>
                                    <td>{student.studentStatus}</td>
                                    <td>
                                      <td>
                                        <button style={{
                                          cursor: "pointer",
                                          borderRadius: "30px",
                                          backgroundColor: "lightblue",
                                          border: "none",
                                          width: "114px",
                                          padding: "4px",
                                        }}
                                          onClick={() => {
                                            setPaymentHistoryStudentId(student.studentId);
                                            batchDetails.batchDetails.renewal = (student.studentStatus == "Confirmed");
                                            setBatchDetails(batchDetails);
                                            if (student.credentialId) {
                                              setSelectedStudentCred(student.credentialId);
                                            }
                                            profileApi((student.credentialId || selectedStudentCred), () => {
                                              setIsPaymentDetailModalVisible(true);
                                            });
                                          }
                                          }
                                        >
                                          Payments
                                        </button>
                                        {/* {student.paymentData && student.paymentData[0] ? (
              student.paymentData[0].payment_status === "order_created"
                ? 'New'
                : student.paymentData[0].payment_status
            ) : (
              ""
            )} */}
                                      </td>
                                    </td>
                                    {/* <td>
              {student.paymentData && student.paymentData[0] ? (
                <>
                 

                 
                </>
              ) : (
                ""
              )}
              <button
                style={{
                  cursor: "pointer",
                  width: "150px",
                  borderRadius:"30px"
                }}
                onClick={() =>{
                  setFromNewLink(true);
                  addStudentToBatch(
                    batchDetails.batchDetails.id,
                    student.studentId,
                    student.credentialId
                  ) }
                }
              >
                Generate new link
              </button>
            </td> */}
                                    <td>
                                      <button style={{ borderRadius: "30px" }} onClick={() => showDeleteModal(student.studentId, batchDetails.batchDetails.id)}>Delete</button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                    </div>


                  ) : (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", fontWeight: "800" }}>No students in this batch please add student.</div>
                  )}




                </div>
              )}
            </div>

          </div>
        )}





      </div>
      {isPaymentDetailModalVisible ? <Modal title="Payment History" width={1150}
        visible={isPaymentDetailModalVisible}
        onOk={() => {
          setIsPaymentDetailModalVisible(false);
        }}
        onCancel={() => {
          setIsPaymentDetailModalVisible(false);
        }}
        footer={null}>
        <div className='new-payment-form' style={{ width: "100%", overflow: "auto" }}>
          <Paymentlink selectedOnlineGroupCourse={selectedOnlineGroupCourse} fromNewLink={fromNewLink} studentId={paymentHistoryStudentId} studentInBatch={selectedStudentInBatch} batchData={batchDetails?.batchDetails} closemodal={closePaymentDetailModel} viewProfile={studentProfile} profile={{ ...studentProfile, ...{ credId: selectedStudentCred }, ...{ token } }} />
        </div>
      </Modal> : ""}
      {isPaymentModalVisible ? <Modal title="Create Payment" width={1150}
        visible={isPaymentModalVisible}
        onOk={() => {
          setIsPaymentModalVisible(false);
        }}
        onCancel={() => {
          setIsPaymentModalVisible(false);
          childFunc.current();
        }}
        footer={null}>
        <div className='new-payment-form' style={{ width: "100%", overflow: "auto" }}>
          <Courselist selectedOnlineGroupCourse={selectedOnlineGroupCourse} fromNewLink={fromNewLink} childFunc={childFunc} batchData={batchDetails?.batchDetails} closemodal={closePaymentModel} viewProfile={studentProfile} profile={{ ...studentProfile, ...{ credId: selectedStudentCred }, ...{ token } }} />
        </div>
      </Modal> : ""}
      <Modal
        title="Add Student"
        visible={isModalVisible}
        onOk={onFinish}
        onCancel={closeModal}
      >
        <Form form={form} onFinish={onFinish}>
          {!(batchDetails && batchDetails.batchDetails && batchDetails.batchDetails.batchCourseDetials.length) ? <select className="form-control mb-2" style={{ width: '100%', appearance: 'auto' }}
            dropdownClassName='course-list-dropdown'
            placeholder='Select Course'
            value={selectedOnlineGroupCourseIndex}
            id="course-list"
            onChange={(event) => {
              setShowCourseErr(false);
              setSelectedOnlineGroupCourseIndex(event.target.value);
              setSelectedOnlineGroupCourse(onlineGroupCourseList.find((v, i) => { return i == event.target.value }).tags[0].paidCourseId);
            }}
          >
            <option value="">Select Course</option>
            {onlineGroupCourseList.length > 0 && onlineGroupCourseList.map((course, index) => {
              return <option value={index} >{course['name']}</option>
            })}
          </select> : ""}
          <Form.Item label="Search Student" style={{ marginTop: "27px" }}>
      <Input
        value={searchQuery}
        onChange={handleInputChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
          }
        }}
        placeholder="Enter Student name"
        style={{ width: "77%" }}
      />

            <Button disabled={!searchQuery.trim()} type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Form.Item>
         

          {errorMessage &&
            <span style={{ color: "red", marginLeft: "152px" }}>Space is not Allowed.</span>
          }
          {loading ? (
            <Spin tip="Loading...">
              <div style={{ minHeight: '200px' }} />
            </Spin>
          ) : (

            <div className="modal-body-scrollable">
              {searchPerformed && studentsList.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center", color: "red" }}>No Student Found</div>
              ) : (
                <Form.Item>
                  <Radio.Group style={{ display: "inline" }}>
                    {studentsList.map((student) => (
                      <div key={student.studentId} className="student-card">
                        <Radio
                          value={student.studentId}
                          onClick={() => handleRadioChange(student.studentId, student.studentName, student.credentialId)}
                        >
                          {student.studentName} -  {student.mobileNumber} - {student.emailId}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
            </div>
          )}
        </Form>
        {showStudentAddErr ? <p style={{ color: "red" }}>Please select student.</p> : ""}
        {showStudentExistErr ? <p style={{ color: "red" }}>Student already added.</p> : ""}
        {showCourseErr ? <p style={{ color: "red" }}>Please select course.</p> : ""}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teachers: state.app[global.redux.actionTypes.teachers.name] || {},
});

const mapDispatchToProps = (dispatch) => ({
  getpaymentlink: details => dispatch(global.redux.action.payment.paymentlink(details)),
  view: (details) =>
    dispatch(global.redux.action.students.viewprofile(details)),
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  list: (details) => dispatch(global.redux.action.students.list(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchCreation);

