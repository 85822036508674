import React, { useState, useEffect } from "react";
import {
  Form,
  DatePicker,
  Button,
  Select,
  Modal,
  Empty,
  Input,
  Radio,
  notification,
  Spin,
  Descriptions,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import "./style.css";
import axios from "axios";
import * as SecureLS from "secure-ls";
import { Link, useNavigate } from "react-router-dom";
import appEnvirontVariables from "../../core_units/config/env-variables";
import { constant } from "../../core_units/constant";
import mixpanel from 'mixpanel-browser';

const { TextArea } = Input;
const CreateOffline = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { Option } = Select;
  const [form] = Form.useForm();
  const loginDetails = ls.get('isLogin');
  //const mixpanel = require('mixpanel-browser');
  const [courseName, setCourseName] = useState("");
  const [courseLevel, setCourseLevel] = useState("");
  const [timeLevel, setTimeLevel] = useState("");
  const [courseList, setCourseList] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [queryvalue, setqueryvalue] = useState();
  const [page, setPage] = useState();
  const [successNotificationVisible, setSuccessNotificationVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [cloading, setCLoading] = useState(false);
  const navigate = useNavigate();
  const [timeDaySelections, setTimeDaySelections] = useState([
    { day: "", time: "" },
  ]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [teacherslist, setTeachersList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [selectedTTeacher, setSelectedTTeacher] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [selectedTTeacherName, setSelectedTTeacherName] = useState("");
  const [selectedTeacherName, setSelectedTeacherName] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [batchName, setBatchName] = useState("");
  const [desc, setDesc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [selectedBranch, setSelectedBranch] = useState();
  const [searchPerformed, setSearchPerformed] = useState(false);
  console.log("Selected Course:", selectedCourse);
  const [isSelectTeacherClicked, setIsSelectTeacherClicked] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [centerList, setCenterList] = useState([]);
  console.log("Selected Level:", selectedLevel);
  const closeSuccessNotification = () => {
    setSuccessNotificationVisible(false);
  };
  const handleSubmit = (value) => {
    console.log(value,"value===")
    setLoading(true);
    setIsSubmitted(true);
    if (!selectedTeacher) {
      console.error("Please select a teacher before submitting the form.");
      return;
    }
    const selectedCourseObject = courseList.onlineGroupCourses.find(
      (course) => course.name === selectedCourse
    );
    console.log("Selected Course Object:", selectedCourseObject);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/course/create/batch`;

    const batchTimings = timeDaySelections.map((selection) => {
      const startTime = moment(selection.time, "hh:mmA");
      const endTime = startTime.clone().add(1, "hour");
      return {
        startTime: startTime.format("HH:mm:ss"),
        endTime: endTime.format("HH:mm:ss"),
        dayofWeek: selection.day,
      };
    });
    const requestBody = {
      batchName,
      branchName: selectedBranch,
      teacherId: selectedTeacher,
      teacherName: selectedTeacherName,
      batchStartDate: startDate.format("YYYY-MM-DD"),
      batchType: "Offline",
      maxStudentsInBatch: "25",
      description: desc || "",
      courseIds: [],
      batchTimings,
    };
    console.log("Request Body:", requestBody);
    setCLoading(true);
    axios
      .post(apiUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token.authorization,
        },
      })
      .then((response) => {
      
        if(response.status,200){
          setLoading(false);
          setCLoading(false);
          console.log("API Response:", response.data);
          notification.success({
            message: "Group Created successfully",
          });
          form.resetFields();
          setSelectedCourse("");
          setSelectedLevel("");
          setSelectedDays([]);
          setSelectedTeacherName("");
          setSearchQuery("");
          setSelectedTeacher("");
          mixpanel.track(constant.MIXPANEL_CREATE_OFFLINE, {
            LoginUser: loginDetails?.studentName,
            batchName : batchName,
            branchName: selectedBranch,
            teacherId: selectedTeacher,
            teacherName: selectedTeacherName,
            batchStartDate: startDate.format("YYYY-MM-DD"),
            batchType: "Offline",
            maxStudentsInBatch: "25",
            batchTimings : batchTimings,
          });
          navigate("/BatchCreationOffline")
        }else{
          notification.error({
            message: "something went wrong",
          });
          mixpanel.track(constant.MIXPANEL_CREATE_OFFLINE, {
            LoginUser: loginDetails?.studentName,
            batchName : batchName,
            branchName: selectedBranch,
            teacherId: selectedTeacher,
            teacherName: selectedTeacherName,
            batchStartDate: startDate.format("YYYY-MM-DD"),
            batchType: "Offline",
            maxStudentsInBatch: "25",
            batchTimings : batchTimings,
          });
        }
        
      })

      .catch((error, a, b) => {
        setCLoading(false);
        setLoading(false);
        if (error?.response && error?.response.status == 400) {
          notification.error({
            message: error?.response.data,
          });
          setLoading(false);
        }
        console.error("API Error:", error);
      });
    // } else {

    // }
  };

  useEffect(() => {
    if (_.isEqual(typeof _.get(props, "teachers.list.number"), "number")) {
      setPage(_.get(props, "teachers.list.number"));
    }
  }, [_.get(props, "teachers.list.number")]);
  const apicall = (query = {}, page = 0) => {
    setqueryvalue(query);
    setLoading(true);
    props.list({
      body: {
        page: page,
        size: 100,
        ...query,
      },
      callback: (response, data) => {
        if (_.isEqual(response, true)) {
          let arr = [];

          if (_.isEmpty(query) && !_.isEmpty(_.get(data, "content"))) {
            arr = _.uniqBy(
              [...teacherslist, ..._.get(data, "content", [])],
              function (e) {
                return e.teacherId;
              }
            );
          } else if (!_.isEmpty(query)) {
            if (
              _.size(teacherslist) < _.get(data, "totalElements") &&
              _.isEqual(queryvalue, query)
            ) {
              arr = _.uniqBy(
                [...teacherslist, ..._.get(data, "content")],
                function (e) {
                  return e.studentId;
                }
              );
            } else {
              arr = _.get(data, "content");
            }
          }
          arr = _.get(data, "content");
          setTeachersList(arr);
          console.log(arr, "arr");
          setApiDataLoaded(true);
        }
        setLoading(false);
      },
    });
  };
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
          getCenterList(token.uId).then(result => {
            if(result.data && result.data.length){
              setSelectedBranch(result.data[0].centreName)
              setCenterList(result.data);
            }else{
              setSelectedBranch(data.offlineCentres[0].centreName)
              setCenterList(data.offlineCentres);
            }
          })
        }
      },
    });
  };
  useEffect(() => {
    configjson();
  }, []);
  const getCenterList= (uId) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/counsellor/centre?uId=${uId}`,
      { headers: { "Authorization": token["authorization"] } });
  }

  const openModal = () => {
    setSearchPerformed(false);
    setTeachersList([]);
    setSearchQuery("");
    setIsModalVisible(true);
  };

  const handleSearch = () => {
    const isQueryEmpty = searchQuery.replace(/\s+/g, "") === "";
    setErrorMessage(isQueryEmpty);

    if (isQueryEmpty) {
    } else {
      setSearchPerformed(true);

      let query = {};

      if (
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchQuery)
      ) {
        query.emailId = searchQuery;
      } else if (new RegExp(/^[0-9\b]+$/).test(searchQuery)) {
        query.mobileNumber = searchQuery;
      } else {
        query.name = searchQuery;
      }

      query.page = 0;
      query.size = 100;

      apicall(query);

      console.log(query);
    }
  };

  const handleRadioChange = (teacherId, teacherName) => {
    setSelectedTTeacher(teacherId);
    setSelectedTTeacherName(teacherName);
  };
  const closeModal = () => {
    setSelectedTTeacher("");
    setSelectedTTeacherName("");
    setIsModalVisible(false);
  };

  const handleAddTimeDay = () => {
    setTimeDaySelections([...timeDaySelections, { day: "", time: "" }]);
  };
  const handleTimeChange = (index, value) => {
    const updatedSelections = [...timeDaySelections];
    updatedSelections[index].time = value;

    const startTime = moment(value, "hh:mmA");
    const endTime = startTime.add(1, "hour");

    updatedSelections[index].endTime = endTime.format("hh:mmA");

    setTimeDaySelections(updatedSelections);
  };

  const handleDayChange = (index, value) => {
    const updatedSelections = [...timeDaySelections];
    updatedSelections[index].day = value;
    setTimeDaySelections(updatedSelections);

    setSelectedDays([...selectedDays, value]);
    form.validateFields(['date']); 
  };
  const handleReset = () => {
    setTimeDaySelections([{ day: "", time: "" }]);
    setSelectedDays([]);
    form.resetFields();
  };

  const handleCourseChange = (value) => {
    setSelectedCourse(value);
    console.log(value, "value");
  };

  // Handle level selection change
  const handleLevelChange = (value) => {
    setSelectedLevel(value);
  };
  const renderDaySelection = (selection, index) => {
    const disabledDays = selectedDays.slice(0, index);

    return (
      <div key={index}>
        <Form.Item
          label={`Day ${index + 1}`}
          name={`day-${index}`}
          rules={[
            {
              required: true,
              message: "Please select the day!",
            },
          ]}
        >
          <Select
            value={selection.day}
            onChange={(value) => handleDayChange(index, value)}
            style={{ width: "255px" }}
            required
          >
            <Option value="MONDAY">MONDAY</Option>
            <Option value="TUESDAY">TUESDAY</Option>
            <Option value="WEDNESDAY">WEDNESDAY</Option>
            <Option value="THURSDAY">THURSDAY</Option>
            <Option value="FRIDAY">FRIDAY</Option>
            <Option value="SATURDAY">SATURDAY</Option>
            <Option value="SUNDAY">SUNDAY</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={`Time ${index + 1}`}
          name={`startTime-${index}`}
          rules={[
            {
              required: true,
              message: "Please select a time!",
            },
          ]}
        >
          <Select
            value={selection.time}
            onChange={(value) => handleTimeChange(index, value)}
            style={{ width: "255px" }}
            required
          >
            <Option value="07:00:00">07:00AM</Option>
            <Option value="08:00:00">08:00AM</Option>
            <Option value="09:00:00">09:00AM</Option>
            <Option value="10:00:00">10:00AM</Option>
            <Option value="11:00:00">11:00AM</Option>
            <Option value="12:00:00">12:00PM</Option>
            <Option value="13:00:00">01:00PM</Option>
            <Option value="14:00:00">02:00PM</Option>
            <Option value="15:00:00">03:00PM</Option>
            <Option value="16:00:00">04:00PM</Option>
            <Option value="17:00:00">05:00PM</Option>
            <Option value="18:00:00">06:00PM</Option>
            <Option value="19:00:00">07:00PM</Option>
            <Option value="20:00:00">08:00PM</Option>
            <Option value="21:00:00">09:00PM</Option>
            <Option value="22:00:00">10:00PM</Option>
            <Option value="23:00:00">11:00PM</Option>
          </Select>
        </Form.Item>
      </div>
    );
  };
  const disabledDate = (current) => {
    const today = moment();

    return current && current < today.startOf("day");
  };

  return (
    <div>
      <div className="report-card">
        <div>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => navigate("/BatchCreationOffline")}
          >
            Back
          </Button>
        </div>

        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin tip="Loading..." size="large"></Spin>
          </div>
        ) : (
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item style={{marginTop:"20px"}}>
              <Button type="dashed" onClick={openModal}>
                Select Teacher
              </Button>
            </Form.Item>
            {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item> */}

            <div>
              <Form.Item
                label="Teacher Name"
                name="teacherName"
                rules={[
                  {
                    required: true,
                    message: "Please select Teacher Name!",
                    validator: (_, value) => {
                      if (!selectedTeacherName) {
                        return Promise.reject("Please select a teacher.");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {console.log(selectedTeacherName, "selectedTeacherName")}
                <Input
                 readOnly
                  value={selectedTeacherName}
                  onChange={(e) => setSelectedTeacherName(e.target.value)}
                  style={{ width: "255px" }}
                />
              </Form.Item>

              <Form.Item
                name="date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please Select Date!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const selectedDay = getFieldValue('day-0'); 
                      console.log('Selected Day:', selectedDay);
                      console.log('Selected Date:', value);
                      if (selectedDay && value) {
                        const selectedDayOfWeek = moment(value).day(); 
                        const selectedDayOfWeekNumeric = moment().day(selectedDay).day(); 
                        console.log('Day of Week for Selected Date:', selectedDayOfWeek);
                        console.log('Numeric value of Selected Day:', selectedDayOfWeekNumeric);
                        if (selectedDayOfWeek !== selectedDayOfWeekNumeric) {
                          return Promise.reject('The selected date does not match the selected day.');
                        }
                       
                      }
                      return Promise.resolve(); 
                    },
                  }),
                ]}
              >
                <DatePicker
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  //disabledDate={disabledDate}
                />
              </Form.Item>

              {timeDaySelections.map((selection, index) =>
                renderDaySelection(selection, index)
              )}
              <Form.Item>
                <Button
                  type="dashed"
                  disabled={timeDaySelections?.length >= 7}
                  onClick={handleAddTimeDay}
                >
                  + ADD
                </Button>
                <Button
                  type="dashed"
                  onClick={handleReset}
                  style={{ marginLeft: "10px" }}
                >
                  RESET
                </Button>
              </Form.Item>
              {/* <Form.Item
          label="Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select the Type!",
            },
          ]}
        >
               <Radio.Group>
                            <Radio value="Offline">OFFLINE</Radio>
                            <Radio value="Online">ONLINE</Radio>
                          </Radio.Group>
        </Form.Item> */}
              <Form.Item
                label="Group Name"
                name="batchName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Group Name!",
                  },
                ]}
              >
                <Input
                  value={batchName}
                  onChange={(e) => setBatchName(e.target.value)}
                  style={{ width: "255px" }}
                />
              </Form.Item>
              <Form.Item
                label="Branch Name"
                name="branchName"
                rules={[
                  {
                    required: true,
                    message: "Please select the Branch Name!",
                  },
                ]}
              >
                <Select
                  value={selectedBranch}
                  onChange={(value) => setSelectedBranch(value)}
                  style={{ width: "255px" }}
                >
                  {centerList?.map((centre, index) => (
                    <Select.Option key={index} value={centre.centreName}>
                      {centre.centreName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              
              <Form.Item
                label="Description"
                name="desc"
                rules={[
                  {
                    required: false,
                    message: "Group description",
                  },
                ]}
              >
                <TextArea rows={3} onChange={(e) => setDesc(e.target.value)} placeholder="" maxLength={256} />
              </Form.Item>
              <Form.Item
                label="Max.No of Students"
                name="maxStudents"
                rules={[
                  {
                    required: false,
                    message: "Please enter the max number of Students!",
                  },
                ]}
              >
                <Input defaultValue={"25"} style={{ width: "255px" }} readOnly />
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={cloading}
                  type="primary"
                  htmlType="submit"
                  style={{ float: "right" }}
                >
                  {cloading ? "Loading" : "CREATE"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
      <Modal
        title="Add Teacher"
        visible={isModalVisible}
        onOk={() => {
          setSelectedTeacher(selectedTTeacher);
          setSelectedTeacherName(selectedTTeacherName);
          closeModal();
        }}
        onCancel={closeModal}
      >
        <Form.Item label="Search Teacher">
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter teacher name"
            style={{ width: "60%" }}
          />
          <Button
            type="primary"
            disabled={!searchQuery.trim()}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Form.Item>
        {errorMessage && (
          <span style={{ color: "red", marginLeft: "152px" }}>
            Space is not Allowed.
          </span>
        )}
        {loading ? (
          <Spin tip="Loading...">
            <div style={{ minHeight: "200px" }} />
          </Spin>
        ) : (
          <div className="modal-body-scrollable">
            {searchPerformed && teacherslist.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                No Teacher Found
              </div>
            ) : (
              <Form.Item>
                <Radio.Group style={{ display: "inline" }}>
                  {teacherslist.map((teacher) => (
                    <div key={teacher.teacherId} className="student-card">
                      <Radio
                        value={teacher.teacherId}
                        onClick={() =>
                          handleRadioChange(
                            teacher.teacherId,
                            teacher.teacherName
                          )
                        }
                      >
                        {teacher.teacherName} - {teacher.mobileNumber} -{" "}
                        {teacher.emailId}
                      </Radio>
                    </div>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  list: (details) => dispatch(global.redux.action.teachers.list(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffline);