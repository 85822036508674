import React, { useState , useEffect} from "react";
import { Form, DatePicker, Button, Table, Spin, Tag, Select, Modal } from "antd";
import { connect } from "react-redux";
import dayjs from "dayjs";
import _ from "lodash";
import "./style.css";
import "antd/dist/antd.css";
import airplane from '../../../assets/img/airplane.svg'
import home from '../../../assets/img/home.svg'
import appEnvirontVariables from "../../../core_units/config/env-variables";
import DateView from "../aereports/dateView";
import * as SecureLS from "secure-ls";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
function Status(props) {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateViewDetails,setDateViewDetails] = useState([]);
  const [defaultDateRange, setDefaultDateRange] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    dayjs().add(7, "days").format("YYYY-MM-DD")
  );
  const [iCCBooked, setICCBooked] = useState({});
  const [iCCTotal, setICCTotal] = useState({});
  const [CCBooked, setCCBooked] = useState({});
  const [CCTotal, setCCTotal] = useState({});
  const [iACBooked, setIACBooked] = useState();
  const [iACTotal, setIACTotal] = useState();
  const [ACBooked, setACBooked] = useState();
  const [ACTotal, setACTotal] = useState();
  useEffect(() => {
    const delay = setTimeout(() => {
      fetchAPIResponse(startDate, endDate)
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error fetching API response:", error);
          setLoading(false);
        });
      clearTimeout(delay);
    }, 200); 

    return () => clearTimeout(delay);
  }, [startDate, endDate]);

  const fetchAPIResponse = (startDate, endDate) => {

    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/airline/details?fromDate=${startDate}&toDate=${endDate}`;

    axios.get(apiUrl, {
      headers: {
        Authorization: token["authorization"]
      }
    })
    .then(response => {
      const responseData = response.data;
      const processedData = processApiResponse(responseData);
      setTableData(processedData);
      console.log(processedData, "30");
      setLoading(false);
      // form.resetFields();
      console.log(processedData);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  };
  const onFinish = (values) => {
    console.log(values,"40")
    const startDate = values.fromDate[0].format("YYYY-MM-DD");
    const endDate = values.fromDate[1].format("YYYY-MM-DD");
    const slotType = values.slotType;
    setLoading(true);
    fetchAPIResponse(startDate, endDate);
    
  };
  

  const processApiResponse = (response) => {
    const processedData = [];
    const categoriesSet = new Set();
    const internationalSlots = [];
    const domesticSlots = [];
    let intAllCountBooked = 0
    let intAllCountTotal = 0
    let allCountBooked = 0
    let allCountTotal = 0
    let intCategoryCountbooked = {};
    let intCategoryCounttotal = {};
    let categoryCountbooked = {};
    let categoryCounttotal = {};
    response.forEach((slotType) => {
      const isInternational = slotType.slotType === "International";
      
      slotType.academicSlotDetails.forEach((slotDetail) => {
        const date = slotDetail.slotDate;
        const row = { date, isInternational };
        if (isInternational) {
          row.ibslots = 0;
          row.itslots = 0;
        } else {
          row.dbslots = 0;
          row.dtslots = 0;
        }
        slotDetail.slotsDetails.forEach((slot) => {
          if (isInternational) {
            intAllCountBooked += slot.bookedSlots;
            intAllCountTotal += slot.totalSlots;
            if(Object.keys(intCategoryCountbooked).includes(slot.categoryName)){
              intCategoryCountbooked[slot.categoryName] += slot.bookedSlots;
            }else{
              intCategoryCountbooked[slot.categoryName] = slot.bookedSlots || 0;
            }
            if(Object.keys(intCategoryCounttotal).includes(slot.categoryName)){
              intCategoryCounttotal[slot.categoryName] += slot.totalSlots;
            }else{
              intCategoryCounttotal[slot.categoryName] = slot.totalSlots || 0;
            }
            row.ibslots += slot.bookedSlots;
            row.itslots += slot.totalSlots;
          } else {
            allCountBooked += slot.bookedSlots;
            allCountTotal += slot.totalSlots;
            if(Object.keys(categoryCountbooked).includes(slot.categoryName)){
              categoryCountbooked[slot.categoryName] += slot.bookedSlots;
            }else{
              categoryCountbooked[slot.categoryName] = slot.bookedSlots || 0;
            }
            if(Object.keys(categoryCounttotal).includes(slot.categoryName)){
              categoryCounttotal[slot.categoryName] += slot.totalSlots;
            }else{
              categoryCounttotal[slot.categoryName] = slot.totalSlots || 0;
            }
            row.dbslots += slot.bookedSlots;
            row.dtslots += slot.totalSlots;
          }
          const categoryName = slot.categoryName;
          const bookedSlots = slot.bookedSlots;
          const totalSlots = slot.totalSlots;
          categoriesSet.add(categoryName);
          if (!categories.includes(categoryName)) {
            setCategories((prevCategories) => [...prevCategories, categoryName]);
          }
          row[categoryName] = { bookedSlots, totalSlots };
        });
        if (isInternational) {
          internationalSlots.push(row);
        } else {
          domesticSlots.push(row);
        }
      });
    });

    setICCBooked(intCategoryCountbooked);
    setICCTotal(intCategoryCounttotal);
    setCCBooked(categoryCountbooked);
    setCCTotal(categoryCounttotal);
    setIACBooked(intAllCountBooked);
    setIACTotal(intAllCountTotal);
    setACBooked(allCountBooked);
    setACTotal(allCountTotal);
    const mergedSlots = [];
    const maxLength = Math.max(internationalSlots.length, domesticSlots.length);
    for (let i = 0; i < maxLength; i++) {
      const mergedSlot = { ...(internationalSlots[i] || {}) };
      if (domesticSlots[i]) {
        Object.keys(domesticSlots[i]).forEach((key) => {
          mergedSlot[`d-${key}`] = domesticSlots[i][key];
        });
      }
      mergedSlots.push(mergedSlot);
    }
    
    console.log(mergedSlots, "mergedSlots");
    setCategories(Array.from(categoriesSet));
  
    return mergedSlots;
  };
  
  
  const getColorByPercentage = (percentage) => {
    if (percentage < 80) {
      return "lightgreen";
    } else if (percentage >= 80 && percentage < 100) {
      return "orange";
    } else {
      return "red";
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    form.resetFields();
 
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: <span style={{ fontWeight: 800,display:"flex", justifyContent:"center"}}>Date</span>,
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: 150,
      render: (text, record) => {
        const date = dayjs(text);
      const formattedDate = date.format("DD MMMM YYYY");
      const dayOfWeek = date.format("dddd");
      const internationalTotalSlots = record.isInternational
    ? `${record.ibslots}/${record.itslots}`
    : "";
    const domesticTotalSlots = record.isInternational
    ? ""
    : `${record["d-dbslots"]}/${record["d-dtslots"]}`;
    console.log(record.isInternational,"record.isInternational")
    console.log(record["d-dbslots"],"138")
    console.log(record["d-dtslots"],"139")
          return (
            <Link  to={{
              pathname: '/dateView', 
              state: {dateViewDetails},
              search : '?date='+record.date
            }}
            style={{ cursor: 'pointer',color:'black' }}>
          <div style={{ display: "flex", alignItems: "center" }}>
          <div>
          <span style={{ fontSize: "12px" }}>
              <b>({dayOfWeek})</b>
            </span>
            <br />
            <b>{formattedDate}</b>
          
            <br />
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            <img
            src={airplane}
            alt="Airplane"
            style={{ width: "16px", marginRight: "4px" }}
          />
          Total Slots: 
          <span style={{fontSize:"16px"}}>{internationalTotalSlots}</span>
             
            </span>
            <br />
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
            <img
            src={home}
            alt="Home"
            style={{ width: "16px", marginRight: "4px" }}
          />
               Total Slots:  
               <span style={{fontSize:"16px"}}>{record["d-dbslots"]}/{record["d-dtslots"]}</span>
              
            </span>
          </div>
        </div>
        </Link>
          );
       
      },
     
    },
    {
      title: () => (
        <div style={{ alignItems: "center" }}>
          <img
            src={airplane}
            alt="Airplane"
            style={{ width: "16px", marginRight: "4px" }}
          />
          International {iACBooked || 0}/{iACTotal || 0}
        </div>
      ),
      children: categories.map((category) => ({
        title: () => <><span style={{ fontWeight: 800 }}>{category}</span> <span>{iCCBooked[category] || 0}/{iCCTotal[category] || 0}</span></>,
        dataIndex: category,
        key: `international_${category}`,
        render: (text, record) => {
          const categoryData = record[category];
          if (!categoryData) {
            return {
              props: {
                style: { textAlign: "center" },
              },
              children: (
                <span style={{ display: "inline-block", width: "100%" }}>-</span>
              ),
            };
          }
          const { bookedSlots, totalSlots } = categoryData;
          const percentage = totalSlots !== 0 ? ((bookedSlots / totalSlots) * 100).toFixed(2) : 0;
        
          const color = getColorByPercentage(percentage);
        
          const cellContent = (
     
    <span>
      <span style={{ fontSize: '12px' }}>
        <b>{bookedSlots}/{totalSlots}</b>
      </span>
      <br />
      ({Math.round(percentage)}%)
    </span>
  
          );
          const cellStyle = {
            background: color,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "visible",
            textAlign: "center",
            borderRight: "1px solid black",
          };
        
          return {
            props: {
              style: cellStyle,
            },
            children: (
              <div style={{ maxWidth: "100%", overflow: "visible" }}>
                {cellContent}
              </div>
            ),
          };
        },
        
      })),
    },
    {
      title: () => (
        <div style={{alignItems: "center" }}>
          <img
            src={home}
            alt="Home"
            style={{ width: "16px", marginRight: "4px" }}
          />
          Domestic {ACBooked || 0}/{ACTotal || 0}
        </div>
      ),
      children: categories.map((category) => ({
        title: () => <><span style={{ fontWeight: 800 }}>{category}</span> <span>{CCBooked[category] || 0}/{CCTotal[category] || 0}</span></>,
        dataIndex: `d-${category}`,
        key: `domestic_${category}`,
        render: (text, record) => {
          const domesticCategory = `d-${category}`;
          const categoryData = record[domesticCategory];
          if (!categoryData) {
            return {
              props: {
                style: { textAlign: "center" },
              },
              children: (
                <span style={{ display: "inline-block", width: "100%" }}>-</span>
              ),
            };
          }
          const { bookedSlots, totalSlots } = categoryData;
          const percentage = totalSlots !== 0 ? ((bookedSlots / totalSlots) * 100).toFixed(2) : 0;
    
          const color = getColorByPercentage(percentage);
    
          const cellContent = (
       
            <span>
              <span style={{ fontSize: '12px' }}>
                <b>{bookedSlots}/{totalSlots}</b>
              </span>
              <br />
              ({Math.round(percentage)}%)
            </span>
       
          );
          const cellStyle = {
            background: color,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "visible",
            textAlign: "center",
            borderRight: "1px solid black",
          };
    
          return {
            props: {
              style: cellStyle,
            },
            children: (
              <div style={{ maxWidth: "100%", overflow: "visible" }}>
                {cellContent}
              </div>
            ),
          };
        },
      })),
    }
    
  ];
  
  
  
  return (
    <div className="report-filter-container">
      <span>Demo Status Details</span>
      <Form form={form} onFinish={onFinish}>
        <div className="" style={{ maxWidth: "100%" }}>
          <Form.Item
            label="Date"
            name="fromDate"
            rules={[
              {
                required: true,
                message: "Please input your date range!",
              },
            ]}
          
          >
     <RangePicker
  value={[dayjs(startDate), dayjs(endDate)]}
  disabled={loading}
  // onChange={(dates) => {
  //   setStartDate(dates[0].format("YYYY-MM-DD"));
  //   setEndDate(dates[1].format("YYYY-MM-DD"));
  // }}
/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>


          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin
                size="large"
                tip="Loading..."
                style={{ textAlign: "center" }}
              />
            </div>
          ) : (
            tableData.length > 0 && (
              <>
              <Table
                dataSource={tableData}
                columns={columns}
                scroll={{ x: 150 * categories.length }}
                pagination={false}
              />
             
            </>
             
  
            )
          )}
        </div>
      </Form>
      <Modal
        title="Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      ></Modal>
    </div>
  );
}
const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
});
const mapDispatchToProps = (dispatch) => ({
  demoStatus: (details) =>
    dispatch(global.redux.action.teachers.demoStatus(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Status);