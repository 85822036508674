import _ from 'lodash';
import axios from './axios-config';
import global from '../config/global'
import * as SecureLS from "secure-ls";

export default async (requestOptions) => {
    // component request can have the following properties notification , callback, params, body
    var ls = new SecureLS({ encodingType: 'aes' });
    const token = ls.get('isLogin')
    const callback = _.get(requestOptions, 'componentRequest.callback', () => { });
    const actionType = _.get(requestOptions, 'actionType', "");
    const reducerName = _.get(requestOptions, 'reducerName', "");
    const dispatch = _.get(requestOptions, 'dispatch', () => { });
    let axiosParams = {
        // withCredentials: true,
        params: _.get(requestOptions, 'componentRequest.params', {}),
        data: _.get(requestOptions, 'componentRequest.body', {}),
        ..._.get(requestOptions, 'axiosParams', {}),
    };
    axiosParams.headers = _.get(axiosParams, 'headers', {}) ? { ..._.get(axiosParams, 'headers', {}), 'Authorization': _.get(token, 'authorization') } : { 'Content-Type': 'application/json', 'Authorization': _.get(token, 'authorization') };
    if (_.has(axiosParams, 'baseURI') && _.isEqual(_.get(axiosParams, 'baseURI'),`https://api.artiumacademy.com`)) {
        delete axiosParams.headers
    }
    //Notification
    const notification = (data, type) => {
        let notification_data = { type }
        if (type == 'success') {
            notification_data.title = 'Success'
        } else if (type == 'error') {
            if (_.get(data, 'error.message')) {
                notification_data.title = 'Error'
                notification_data.message = _.get(data, 'error.message')
            }
        }

    }
    // start loader for the property
    global.redux.utils.startActionLoader(dispatch, reducerName, actionType);
    if (_.has(axiosParams, 'baseURI') && _.isEqual(_.get(axiosParams, 'baseURI'),`https://api.artiumacademy.com`)) {
        axios.apiinstance(axiosParams)
            .then((response) => {
                if (_.isEmpty(_.get(token, 'isLoggedIn')) || _.isEqual(_.get(token, 'isLoggedIn'), false)) {
                    if (_.has(response.headers, 'authorization')) {
                        dispatch({
                            type: 'reduxUpdateAccountState',
                            data: { ['isLogin']: { ..._.get(response, 'data', {}), ..._.get(response, 'headers') } }
                        });
                    }
                }
                dispatch({
                    type: reducerName,
                    data: { [actionType]: _.get(response, 'data', {}) }
                });
                global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
                notification(_.get(response, 'data', {}), 'success')
                callback(true, _.get(response, 'data', {}));


            })
            .catch(error => {
                if (error.response && (error.response.status === 401 || error.response.data == 'UnAuthorized')) {
                    window.location.href = '/login';
                }
                notification(_.get(error, 'response.data', {}), 'error')
                callback(false, _.get(error, 'response.data', {}));
                global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
            })
            .finally(() => {
                // stop loader for the property

            });
    } else  if (_.has(axiosParams, 'baseURI') && _.isEqual(_.get(axiosParams, 'baseURI'),`https://58eh80x2x6.execute-api.ap-south-1.amazonaws.com`)) {
        axios.paymentinstance(axiosParams)
            .then((response) => {
                if (_.isEmpty(_.get(token, 'isLoggedIn')) || _.isEqual(_.get(token, 'isLoggedIn'), false)) {
                    if (_.has(response.headers, 'authorization')) {
                        dispatch({
                            type: 'reduxUpdateAccountState',
                            data: { ['isLogin']: { ..._.get(response, 'data', {}), ..._.get(response, 'headers') } }
                        });
                        ls.set('isLogin', {..._.get(response, 'data'), isLoggedIn:true, ..._.get(response, 'headers')})
                    }
                }
                dispatch({
                    type: reducerName,
                    data: { [actionType]: _.get(response, 'data', {}) }
                });
                global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
                notification(_.get(response, 'data', {}), 'success')
                callback(true, _.get(response, 'data', {}));


            })
            .catch(error => {
                if (error.response && (error.response.status === 401 || error.response.data == 'UnAuthorized')) {
                    window.location.href = '/login';
                }
                notification(_.get(error, 'response.data', {}), 'error')
                callback(false, _.get(error, 'response.data', {}));
                global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
            })
            .finally(() => {
                // stop loader for the property

            });
    } else {
        axios.instance(axiosParams)
            .then((response) => {
               
                if (_.isEmpty(_.get(token, 'isLoggedIn')) || _.isEqual(_.get(token, 'isLoggedIn'), false)) {
                    if (_.has(response.headers, 'authorization')) {
                        dispatch({
                            type: 'reduxUpdateAccountState',
                            data: { ['isLogin']: { ..._.get(response, 'data', {}), ..._.get(response, 'headers') } },
                            // data: { ['login']: { ..._.get(response, 'data', {}), ..._.get(response, 'headers') } }
                        });
                        ls.set('isLogin', {..._.get(response, 'data'), isLoggedIn:true, ..._.get(response, 'headers')})

                    }
                }
                // if(_.has(response.headers, 'authorization')) {
                //     dispatch({
                //         type: reducerName,
                //         data: { [actionType]: {..._.get(response, 'data', {}), ..._.get(response, 'headers')} }
                //     });
                // }else{
                    dispatch({
                        type: reducerName,
                        data: { [actionType]: _.get(response, 'data', {}) }
                    });
                // }
             
                global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
                notification(_.get(response, 'data', {}), 'success')
                callback(true, _.get(response, 'data', {}));
            })
            .catch(error => {
                if (error.response && (error.response.status === 401 || error.response.data == 'UnAuthorized')) {
                    window.location.href = '/login';
                  }
                notification(_.get(error, 'response.data', {}), 'error')
                callback(false, _.get(error, 'response.data', {}));
                global.redux.utils.stopActionLoader(dispatch, reducerName, actionType);
            })
            .finally(() => {
                // stop loader for the property

            });
    }

}
