import { useEffect } from "react"
import { collection, query, onSnapshot, orderBy, limit } from "firebase/firestore"
import { db } from "../../../firebase"

const useLoadChatOnConversationSelect = ({ selectedChatData, setNoMoreChatsAvailable, initialFetchChat, setAllChats, setLastChat, CHAT_LENGTH }) => {

  // Load chat messages when a conversation is selected from the chat sidebar
  useEffect(() => {
    // console.log(selectedChatData)
    if (selectedChatData && selectedChatData?.id) {
      const chatHistoryWrapper = document.querySelector('.single-chat__body')
      chatHistoryWrapper.scrollTo(0, chatHistoryWrapper.scrollHeight)
      setNoMoreChatsAvailable(false)

      let q = query(collection(db, "chats", selectedChatData.id, "messages"),
        orderBy("timestamp", "desc"), limit(CHAT_LENGTH)
      )

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (initialFetchChat) {
          // console.log("initialFetchChat true")
          let newData = snapshot.docs.map(doc => doc.data()).reverse()

          setAllChats(newData)
          setLastChat(snapshot.docs[snapshot.size - 1])
          // console.log(newData)

          initialFetchChat = false
        } else {
          // console.log("initialFetchChat false")
          snapshot.docChanges().reverse().forEach(change => {
            // console.log(change, "change")
            let timeCheck = true

            if (change.type === 'added') {
              // console.log(change.doc.data(), "history added")
              if (change.doc.data().timestamp) {
                timeCheck = false
                setAllChats((prev) => {
                  // console.log(prev, "prev added")
                  return [...prev, change.doc.data()]
                })
              }
            }

            if (timeCheck && change.type === 'modified') {
              // console.log(change.doc.data(), "history modified")
              setAllChats((prev) => {
                // console.log(prev, "prev modified")
                return [...prev, change.doc.data()]
              })
            }
          })
        }
      })

      return () => unsubscribe()
    } else {
      setAllChats([])
    }
  }, [selectedChatData])

}

export default useLoadChatOnConversationSelect