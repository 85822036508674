import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Menu, Dropdown } from 'antd';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import _ from 'lodash';
import proimg from '../../assets/img/300-1.jpg';
import defaultImg from '../../assets/img/default-image.png';
import "./header.css";
import * as SecureLS from "secure-ls";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase"
import axios from "axios";
import appEnvirontVariables from "../../core_units/config/env-variables";


const PageHeader = (props) => {
	console.log(props,"out===")
	let history = useNavigate();
	var ls = new SecureLS({ encodingType: 'aes' });
	const token = ls.get("isLogin");
	console.log(token,"token==")
const [adminprofile, setadminprofile] = useState();
const [userdetails, setuserdetails] = useState()
	useEffect(()=>{
		profileapi();
		setadminprofile(ls.get('isLogin'))
		// console.log('get', ls.get('isLogin'));
	},[])
	const profileapi = () => {
		var cred=adminprofile?adminprofile: ls.get('isLogin')
		props.profile({
			params: {
				"credid": _.get(cred,'credentialId')
			},
			callback: (res, data) => {
				if (res == true) {
					setuserdetails(data)
					// console.log('userdetails',userdetails);

				}

			}
		})	
	}
	const onlogout = () => {

		props.logout({
			body: {
				"userId": _.get(props, 'account.login.userId')
			},
			callback: async (res, data) => {
				if (res == true) {
					await signOut(auth)
					localStorage.removeItem("firebaseUID")
					ls.set('isLogin', { ...data, isLoggedIn: false })
					history('/login');
				}
			}
		})
// if(props.account.isLogin.authorities[0] == "ROLE_ADMIN_DEMOOPS_AGENT"){
// 	onStatusChange();
// }
	}
	// const onStatusChange = () => {
	
	// 	const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/status`;
	// 	axios.post(apiUrl, {
	// 	  agentId: token.uId,
	// 	  status: "inactive"
	// 	}, {
	// 	  headers: {
	// 		Authorization: token["authorization"]
	// 	  }
	// 	})
	// 	.then(response => {
		 
	
	// 	})
	// 	.catch(error => {
	// 	  console.error("Error changing status:", error);
		
	// 	});
	//   };
	const onreset = () => {
	
		history('/reset');
	}
	const menuToggle = () => {
		document.body.classList.toggle('menu-close');
	}
	const menu = (
		<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded py-3">
			<div className="menu-item px-3">
				<div className="menu-content d-flex align-items-center px-3 mr-4">
					<div className="symbol symbol-50px me-5">
						<img src={defaultImg} alt="user" />
					</div>
					<div className="d-flex flex-column">
						<div className="fw-bolder d-flex align-items-center fs-16">{_.startCase(_.get(userdetails, 'firstName', 'No-name')) + ' ' + _.startCase(_.get(userdetails, 'lastName', ''))}</div>
						<a href="" className="fw-bold text-muted text-hover-primary fs-12">{_.get(userdetails, 'emailId')}</a>
					</div>
				</div>
			</div>
			{/* <div className="separator my-2"></div> */}
			{/* <div className="menu-item px-3">
				<Link to={'/profile'} className="menu-link">My Profile</Link>
			</div>
			<div className="menu-item px-3">
				<a href="" className="menu-link">
					<span className="menu-text">My Projects</span>
				</a>
			</div> */}
			<div className="separator my-2"></div>
			<div className="menu-item px-3" onClick={onlogout}>
				<Link to='/login' className="menu-link">Sign Out</Link>
			</div>
			{/* <div className="menu-item px-3" onClick={onreset}>
      <Link to='/reset' className="menu-link">Change Password</Link>
    </div> */}
		</div>
	);
	return (
		<div className="header align-items-stretch page-header-container">
			<div className="container-fluid d-flex align-items-stretch justify-content-between">
				<div className="d-flex align-items-stretch">
					<div className="header-menu align-items-stretch">
						<div className="menu-item Header-menu-Icon">
							<span className="SideMenuIcon" onClick={menuToggle}>
								<MenuOutlined />
							</span>
						</div>
					</div>
				</div>
				{/* <div className="menu-item px-3" onClick={onlogout}>
					<Link to='/login' className="menu-link">Sign Out</Link>
				</div> */}
				<Dropdown overlay={menu} overlayClassName="header-dropdown-menu">
					<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
						<img src={defaultImg} alt="user" />
					</a>
				</Dropdown>
			</div>
		</div>
	);
};
const mapStateToProps = state => (
	{

		account: _.get(state, `app.${global.redux.actionTypes.account.name}`, {}),

	});

const mapDispatchToProps = dispatch => ({
	logout: details => dispatch(global.redux.action.account.logout(details)),
	profile: details => dispatch(global.redux.action.account.profile(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)