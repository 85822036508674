import React, { useState, useEffect } from 'react'
import { getInitials, setInitialBackgroundColor, formatDateTime } from './helper';
import { getStudents } from "./services"
import { collection, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore"
import { db } from "../../firebase"
import "./style.css"

// COMPONENTS
import ChatListCard from './ChatListCard'
import ChatHistory from './ChatHistory';
import useLoadConversations from "./hooks/useLoadConversations"
import useLoadChatOnConversationSelect from "./hooks/useLoadChatOnConversationSelect"

function Chat() {
  const currentTeacherData = (typeof window !== 'undefined') ? JSON.parse(localStorage.getItem('currentTeacherDetails')) : null
  // console.log(currentTeacherData, "currentTeacherData")

  const userId =  currentTeacherData?.teacheruserId
  const credentialId = currentTeacherData?.credentialId

  const [allStudents, setAllStudents] = useState({})
  const [studentList, setStudentList] = useState([])

  // TEACHER CHAT MESSAGES
  const [conv, setConv] = useState([]) // convTestData
  const [convCopy, setConvCopy] = useState([])
  const [lastDoc, setLastDoc] = useState(null)
  const [loading, setLoading] = useState(false) // Loading Converstaions
  const CONVERSATION_LENGTH = 1000
  let initialfetchChatList = true
  const [conversationsLoaded, setConversationsLoaded] = useState(false)

  const [noMoreConversationAvailable, setNoMoreConversationAvailable] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [isConversationNew, setIsConversationNew] = useState(false)

  const [selectedChatData, setSelectedChatData] = useState(null)
  const [allChats, setAllChats] = useState([]) //testChatData
  const [lastChat, setLastChat] = useState(null)
  const [noMoreChatsAvailable, setNoMoreChatsAvailable] = useState(false)
  const [runLoadMoreChats, setRunLoadMoreChats] = useState(false)
  let initialFetchChat = true
  const CHAT_LENGTH = 10

  const [textMessage, setTextMessage] = useState("")
  const [sentMessage, setSentMessage] = useState(false)

  const [newChatId, setNewChatId] = useState("")

  useEffect(() => {
    getAllStudents()
  }, [])

// List of paid student for the loggedin teacher
const getAllStudents = async () => {
  const data = await getStudents(credentialId)
  setAllStudents(data)
  setStudentList(Object.values(data))
}

// 1. Load existing conversation list to chat sidebar
useLoadConversations({ setLoading, CONVERSATION_LENGTH, userId, initialfetchChatList, setLastDoc, setConv, setConvCopy, allStudents })

// Load chat list for the first message in conv or if conv is empty select the first item from student list
useEffect(() => {
  if (conv.length > 0 && !sentMessage && !conversationsLoaded) {
    setSelectedChatData(conv[0])
    setConversationsLoaded(true)
  }
}, [conv, sentMessage])

  // 2. Load chat messages when a conversation is selected from the chat sidebar
  useLoadChatOnConversationSelect({ selectedChatData, setNoMoreChatsAvailable, initialFetchChat, setAllChats, setLastChat, CHAT_LENGTH })

  // CONVERSATION SEARCH
  useEffect(() => {
    handleSearch(searchInput)
  }, [searchInput])
  
  const handleSearch = (query) => {
    const searchQuery = query.toLowerCase()
    if (searchQuery === "") {
      setConv(convCopy)
    } else {
      const filteredConvData = convCopy.filter(item => item?.firstName?.toLowerCase().includes(searchQuery))
      setConv(filteredConvData)
    }
  }

  // LOAD MORE CHATS
  const fetchMoreChatMessages = async () => {
    // console.log("fetchMoreChatMessages called")
    if (selectedChatData) {
      setRunLoadMoreChats(true)
      let q = query(collection(db, "chats", selectedChatData.id, 'messages'), orderBy("timestamp", "desc"), limit(5), startAfter(lastChat))
      let snapshot = await getDocs(q)
      let newData = snapshot.docs.map(doc => doc.data()).reverse()

      // console.log('fetchmore', newData)
      if (newData.length === 0) {
        setNoMoreChatsAvailable(true)
      }
      setLastChat(snapshot.docs[snapshot.size - 1])
      setAllChats(prev => [...newData,...prev])
      
      // scroll 300px bottom after adding new chats are added on top
      newData.length !== 0 && document.querySelector('.single-chat__body').scrollBy(0, 300)
    }
  }

  return (
    <div className='page-wrapper'>
      <div className='teacher-name'>{currentTeacherData?.teacherName}</div>
      <div className='chat-list'>
        <div className="chat-list__header">
          <div className='search-container'>
            <input 
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
              type="text" 
              placeholder='Search students' 
            />
            <div className='search-icon'>
              🔎
            </div>
          </div>
        </div>
        <div className="chat-list__body">
          {loading ? <div className='conversations-loading'>Loading...</div> : (
            conv.map((item) => {
               return (
                <ChatListCard 
                  key={item.id} 
                  item={item} 
                  selectedChatData={selectedChatData}  
                  setSelectedChatData={setSelectedChatData} 
                />
               )
            })
          )}

          {!loading && conv.length === 0 && <div className='no-existing-conv'>No chats available</div>}
        </div>
      </div>

      <div className='single-chat'>
        <div className='single-chat__header'>
          <div className='single-chat__header__left'>
            {selectedChatData && (
              <div className={selectedChatData.profilePhoto ? 'chat-header-image' : 'chat-header-image--initials ' +  setInitialBackgroundColor(selectedChatData.firstName ? selectedChatData.firstName : "NA")}>
                {selectedChatData.profilePhoto ? <img src={selectedChatData.profilePhoto} /> : getInitials(selectedChatData.firstName ? selectedChatData.firstName : "NA")}
              </div>
            )}
            {selectedChatData && (
              <>
                <div>{selectedChatData.firstName ? selectedChatData.firstName  : "No Name"}</div>
                <div className='course-name'>{selectedChatData?.courseName ? selectedChatData.courseName  : ""}</div>
              </>
            )}
          </div>
        </div>

        <ChatHistory
          allChats={allChats} 
          userId={userId} 
          selectedChatData={selectedChatData}
          fetchMoreChatMessages={fetchMoreChatMessages}
          runLoadMoreChats={runLoadMoreChats}
          noMoreChatsAvailable={noMoreChatsAvailable}
        />
      </div>

    </div>
  )
}

export default Chat