import React, { useState, useEffect } from "react";
import { Modal, Spin, Button, List, message } from "antd";
import * as SecureLS from "secure-ls";
import axios from "axios";
import appEnvirontVariables from "../../core_units/config/env-variables";
import dayjs from "dayjs";
import { constant } from "../../core_units/constant";
import mixpanel from 'mixpanel-browser';

const TeacherChange = () => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const userRoles = token?.credentialId;
  let canEdit = [13726, 187236, 372, 430214 ].includes(Number(userRoles));
  //const mixpanel = require('mixpanel-browser');
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [unassignedStudents, setUnassignedStudents] = useState([]);
  const [confirmingTeacher, setConfirmingTeacher] = useState(null);
  const [triggeredTeacherName, setTriggeredTeacherName] = useState(""); // Track triggered teacher name

  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/working/teachers`,
        { headers: { Authorization: token["authorization"] } }
      );

      const fetchedTeachers = response.data.map((teacher) => ({
        id: teacher.teacherId,
        name: teacher.teacherName,
        status: teacher.workingStatus,
        dateOfDeparture: teacher.lastWorkingDate,
        totalStudents: Number(teacher.totalStudents),
        reassigned: Number(teacher.reassigned),
        unassigned: Number(teacher.unassigned),
        flowTriggered: false,
      }));
      setTeachers(fetchedTeachers);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    } finally {
      setLoading(false);
    }
  };

  const triggerChangeTeacher = async (teacherId) => {
    try {
      setModalLoading(true); 
      const response = await axios.post(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/detach/students?teacherId=${teacherId}`,
        {},
        { headers: { Authorization: token["authorization"] } }
      );
      message.success("Teacher detachment is in progress, Please refresh after some time!");

      const triggeredTeacher = teachers.find(teacher => teacher.id === teacherId);
      setTriggeredTeacherName(triggeredTeacher?.name || ""); // Update triggered teacher name

      setTeachers((prevTeachers) =>
        prevTeachers.map((teacher) =>
          teacher.id === teacherId ? { ...teacher, flowTriggered: true } : teacher
        )
      );
      fetchTeachers ();
      console.log("mixpanel", mixpanel.track(constant.MIXPANEL_TEACHER_CHANGE, {
        agentId: token.uId,
        agentName: token.studentName,
        teacherName:triggeredTeacher?.name,
        teacherId: teacherId,
        credentialId: token.credentialId,
      })
  )
      mixpanel.track(constant.MIXPANEL_TEACHER_CHANGE, {
        agentId: token.uId,
        agentName: token.studentName,
        teacherName:triggeredTeacher?.name,
        teacherId: teacherId,
        credentialId: token.credentialId,
      });
  
    } catch (error) {
      console.error("Error triggering teacher change:", error);
      message.error("Failed to trigger teacher change.");
    } finally {
      setModalLoading(false);
      setConfirmingTeacher(null);
    }
  };

  const downloadCSV = () => {
    const csvRows = [
      ["Teacher Name", "Status", "Date of Departure", "Total Students", "Reassigned", "Unassigned"],
      ...teachers.map((teacher) => [
        teacher.name,
        teacher.status,
        `"${teacher.dateOfDeparture ? dayjs(teacher.dateOfDeparture).format("MM/DD/YYYY") : "N/A"}"`,
        teacher.totalStudents,
        teacher.reassigned,
        teacher.unassigned,
      ]),
    ];

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "teachers.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const openUnassignedModal = async (teacher) => {
    setSelectedTeacher(teacher);
    setModalLoading(true); 
    try {
      const response = await axios.get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/working/students?type=unassigned&teacherId=${teacher.id}`,
        { headers: { Authorization: token["authorization"] } }
      );
      setUnassignedStudents(response.data);
    } catch (error) {
      console.error("Error fetching unassigned students:", error);
      setUnassignedStudents([]);
    } finally {
      setModalLoading(false);
    }
  };

  const closeModal = () => {
    setSelectedTeacher(null);
    setConfirmingTeacher(null);
    setUnassignedStudents([]);
    setTriggeredTeacherName(""); // Reset triggered teacher name on modal close
  };

  return (
    <div style={{ padding: "16px" }}>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Button onClick={downloadCSV} type="primary" style={{ marginBottom: "16px" }}>
            Download CSV
          </Button> <div style={{ maxHeight: "70vh", overflowY: "auto", border: "1px solid black" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th style={headerStyle}>Teacher Name</th>
                  <th style={headerStyle}>Status</th>
                  <th style={headerStyle}>Date of Departure</th>
                  <th style={headerStyle}>Total Students</th>
                  <th style={headerStyle}>Change Teacher</th>
                  <th style={headerStyle}>Reassigned</th>
                  <th style={headerStyle}>Unassigned</th>
                </tr>
              </thead>
              <tbody>
                {teachers.map((teacher) => (
                  <tr key={teacher.id}>
                    <td style={cellStyle}>{teacher.name}</td>
                    <td style={cellStyle}>{teacher.status}</td>
                    <td style={cellStyle}>
                      {new Date(teacher.dateOfDeparture).toLocaleDateString()}
                    </td>
                    <td style={cellStyle}>{teacher.totalStudents || 0}</td>
                    <td style={cellStyle}>
                      {teacher.totalStudents > 0 ? (
                        <Button
                          onClick={() => setConfirmingTeacher(teacher.id)}
                          type={teacher.flowTriggered ? "default" : "primary"}
                          disabled={!canEdit || teacher.flowTriggered}
                          style={{
                            backgroundColor: teacher.flowTriggered ? "#d9d9d9" : "white",
                            color: teacher.flowTriggered ? "#000" : "black",
                            borderColor: teacher.flowTriggered ? "#d9d9d9" : "lightgray",
                            cursor: !canEdit ? "not-allowed" : "pointer",
                          }}
                        >
                          {teacher.flowTriggered ? "Flow Triggered" : "Change Teacher"}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td style={cellStyle}>{teacher.reassigned}</td>
                    <td style={cellStyle}>
                      <span
                        onClick={() => openUnassignedModal(teacher)}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        {teacher.unassigned}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      <Modal
        visible={!!confirmingTeacher}
        onCancel={closeModal}
        title="Confirm Teacher Change"
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={() => triggerChangeTeacher(confirmingTeacher)}
            loading={modalLoading}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>
          <b>Are you sure you want to trigger a change?</b>
          <br />
          This action will unlink all students assigned to this teacher &
          Students will receive a reboarding process on their dashboard to get new teacher.
        </p>
      </Modal>

      {/* Unassigned Students Modal */}
      <Modal
        visible={!!selectedTeacher}
        onCancel={closeModal}
        title={`Unassigned Students for ${selectedTeacher?.name}`}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>,
        ]}
        bodyStyle={{
          maxHeight: "60vh",
          overflowY: "auto",
          border: "1px solid #d9d9d9",
        }}
        width="80vw"
      >
        {modalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : unassignedStudents.length > 0 ? (
          <div style={{ border: "1px solid #d9d9d9", overflow: "auto" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th style={headerStyle}>Name</th>
                  <th style={headerStyle}>Courses</th>
                  <th style={headerStyle}>Email</th>
                  <th style={headerStyle}>Phone</th>
                </tr>
              </thead>
              <tbody>
                {unassignedStudents.map((student) => (
                  <tr key={student.emailId}>
                    <td style={cellStyle}>{student.studentName || "-"}</td>
                    <td style={cellStyle}>
                      {student.courses ? student.courses.join(", ") : "-"}
                    </td>
                    <td style={cellStyle}>{student.emailId || "-"}</td>
                    <td style={cellStyle}>{student.phoneNumber || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ padding: "16px" }}>No unassigned students data available</p>
        )}
      </Modal>

      {/* Display Triggered Teacher Name */}
 
    </div>
  );
};

const headerStyle = {
  border: "1px solid black",
  padding: "8px",
  position: "sticky",
  top: 0,
  backgroundColor: "white",
  zIndex: 1,
};

const cellStyle = {
  border: "1px solid black",
  padding: "8px",
};

export default TeacherChange;
