import _ from 'lodash';
import actionTypes from './actionTypes';

const initialState = {
  
  // account state
  [actionTypes.account.name]: {
    [actionTypes.account.isLogin]: {},
    [actionTypes.account.newLogin]: {},
    [actionTypes.account.signin]: {},
    [actionTypes.account.profile]: {},
    
  },
  // Teachers State
  [actionTypes.teachers.name]: {
    [actionTypes.teachers.list]: {},
    [actionTypes.teachers.slotdetails]: {},
    [actionTypes.teachers.createslots]: {},
    [actionTypes.teachers.slotAdd]: {},
    [actionTypes.teachers.deleteslots]: {},
    [actionTypes.teachers.deleteAeSlots]:{},
    [actionTypes.teachers.multipleSlotsCreate]: {},
    [actionTypes.teachers.editteacherprofile]: {},
    [actionTypes.teachers.viewteacherprofile]: {},
    [actionTypes.teachers.bookedslotcancel]: {},
    [actionTypes.teachers.addteacherprofile]: {},
    [actionTypes.teachers.isAddOnClass]: {},
    [actionTypes.teachers.classcount]: {},
    [actionTypes.teachers.completeclass]: {},
    [actionTypes.teachers.completeclassforpenalty]:{},
    [actionTypes.teachers.cancelClassFetch]:{},
    [actionTypes.teachers.classNoShow]:{},
    [actionTypes.teachers.scheduleclass]: {},
    [actionTypes.teachers.activeInactiveClass]:{},
    [actionTypes.teachers.coursetags]: {},
    [actionTypes.teachers.canceladdon]: {},
    [actionTypes.teachers.courselist]: {},
    [actionTypes.teachers.feedbacklist]: {},
    [actionTypes.teachers.teacherstatus]: {},
    [actionTypes.teachers.checkteacherstatus]: {},
    [actionTypes.teachers.teacherlist]: {},
    [actionTypes.teachers.teacherage]: {},
    [actionTypes.teachers.teacherlanguage]: {},
    [actionTypes.teachers.updateCourseteachers]: {},
    [actionTypes.teachers.listteacherforcourse]: {},
    [actionTypes.teachers.planCodeForStudent]: {},
    [actionTypes.teachers.changecourseforstudent]: {},
    [actionTypes.teachers.blockstatus]: {},
    [actionTypes.teachers.webinarLink]:{},
    [actionTypes.teachers.updateBanner]:{},
    [actionTypes.teachers.referral]:{},
    [actionTypes.teachers.approvereferral]:{},
    [actionTypes.teachers.fetchWebinar]:{},
    [actionTypes.teachers.deleteWebinar]: {},
    [actionTypes.teachers.viewPreviousClass]:{},
    [actionTypes.teachers.viewTeacherNoshowClass]:{},
    [actionTypes.teachers.deleteTeacherNoshowClass]:{},
    [actionTypes.teachers.enablePaidClass]: {},
    [actionTypes.teachers.cancelClassForComplete]:{},
[actionTypes.teachers.markJoinClass]:{}
  },
  // Students State
  [actionTypes.students.name]: {
    [actionTypes.students.list]: {},
    [actionTypes.students.classdetails]: {},
    [actionTypes.students.bookedclasscancel]: {},
    [actionTypes.students.rescheduleclass]: {},
    [actionTypes.students.viewprofile]: {},
    [actionTypes.students.addstudentprofile]: {},
    [actionTypes.students.editprofile]: {},
    [actionTypes.students.courselist]: {},
    [actionTypes.students.feedbacklist]: {},
    [actionTypes.students.courseslots]: {},
    [actionTypes.students.courseallocation]: {},
    [actionTypes.students.classcount]: {},
    [actionTypes.students.syncSalesForce]: {},
    [actionTypes.students.refundClass]: {},
    [actionTypes.students.bookDemoStatus]: {},
    [actionTypes.students.ChangeStatusForStudentSlot]:{},
    [actionTypes.students.scheduleclass]: {},
    [actionTypes.students.studenttype]: {},
    [actionTypes.students.enrollnewcourse]: {},
    [actionTypes.students.enrolledcourselist]: {},
    [actionTypes.students.uploadphoto]: {},
    [actionTypes.students.validatemobile]: {},
    [actionTypes.students.validateEmail]: {},
    [actionTypes.students.classDeduct] : {},
[actionTypes.students.expiryDate] : {},
  },
  //Admin State
  [actionTypes.admin.name]: {

    [actionTypes.admin.reports]: {},
    [actionTypes.admin.photoupload]: {},
    [actionTypes.admin.photodelete]: {},
    [actionTypes.admin.addonreports]: {},
    [actionTypes.admin.bulkemail]: {},

  },
  [actionTypes.payment.name]: {
    [actionTypes.payment.createpayment]: {},
    [actionTypes.payment.directpayment]: {},
    [actionTypes.payment.paymentlink]: {},
  }
  
}

const reduxUpdateAccountState = (state, action) => {
  return {
    ...state, [actionTypes.account.name]:
    {
      ...state[actionTypes.account.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateTeacherState = (state, action) => {
  return {
    ...state, [actionTypes.teachers.name]:
    {
      ...state[actionTypes.teachers.name],
      ..._.get(action, 'data', null)
    }
  };
}

const reduxUpdateStudentState = (state, action) => {
  return {
    ...state, [actionTypes.students.name]:
    {
      ...state[actionTypes.students.name],
      ..._.get(action, 'data', null)
    }
  };
}
const reduxUpdatePaymentState = (state, action) => {
  return {
    ...state, [actionTypes.payment.name]:
    {
      ...state[actionTypes.payment.name],
      ..._.get(action, 'data', null)
    }
  };
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
   
    case actionTypes.reduxUpdateAccountState:
      return reduxUpdateAccountState(state, action);
      case actionTypes.reduxUpdateTeacherState:
        return reduxUpdateTeacherState(state, action);
        case actionTypes.reduxUpdateStudentState:
          return reduxUpdateStudentState(state, action);
          case actionTypes.reduxUpdatePaymentState:
            return reduxUpdatePaymentState(state, action);
    default:
      return state
  }
}

export default reducer;
