import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import appEnvirontVariables from "./core_units/config/env-variables"

const FIREBASE_API_KEY ='AIzaSyAOFwj0Pa-hg73SryB6SqumoIqL_y-mQHE'
const FIREBASE_APPID='1:882771629850:web:1df703569a4f1435ddbea3'
const FIREBASE_AUTHDOMAIN='artium-notification.firebaseapp.com'
const FIREBASE_MEASUREMENTID='G-GBNXPEFQKE'
const FIREBASE_MESSAGINGSENDERID='882771629850'
const FIREBASE_PROJECTID='artium-notification'
const FIREBASE_STORAGEBUCKET='artium-notification.appspot.com'

const firebaseConfig = {
    apiKey: appEnvirontVariables.API.FIREBASE_API_KEY,
    authDomain: appEnvirontVariables.API.FIREBASE_AUTHDOMAIN,
    projectId: appEnvirontVariables.API.FIREBASE_PROJECTID,
    storageBucket: appEnvirontVariables.API.FIREBASE_STORAGEBUCKET,
    messagingSenderId: appEnvirontVariables.API.FIREBASE_MESSAGINGSENDERID,
    appId: appEnvirontVariables.API.FIREBASE_APPID,
    measurementId:appEnvirontVariables.API.FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage();
export const db = getFirestore()
