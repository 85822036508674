import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import logo from "../../../assets/gif/aa_loader.gif";
import MultipleSlotsScreen from "./multiple-slot-excel";
import _ from "lodash";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Modal,
  Button,
  Image,
  notification,
  Spin,
  Popover,
  Form,
  Input,
  Checkbox,
} from "antd";
import {
  ExclamationCircleOutlined,
  DiffOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./style.css";
import StudentList from "./student-list";
import { oncheckboxchange } from "./student-list";
import axios from "axios";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import { constant } from "../../../core_units/constant";
import mixpanel from 'mixpanel-browser';
const propTypes = {};
const { confirm } = Modal;
const AeCalendar = (props) => {
  console.log(props, "***");
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  console.log(token, "token==")
  //const mixpanel = require('mixpanel-browser');
  const [teacherId] = useSearchParams();
  const [studentId] = useSearchParams();
  const student = useLocation();
  const [events, setEvents] = useState([]);
  const [details, setDetails] = useState([]);
  const [event, setEvent] = useState({});
  const [datefrom, setdatefrom] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(
    moment(datefrom).add(1, "M").startOf("month").format("YYYY-MM-DD")
  );
  const [multipleslot, setMultipleslot] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const localizer = momentLocalizer(moment);
  const [focusDate, setFocusDate] = useState();
  const [st, setst] = useState();
  const [pageloading, setpageloading] = useState();
  const [scheduleslot, setScheduleslot] = useState(false);
  const [viewProfile, setViewProfile] = useState([]);
  const [courseList, setCourseList] = useState();
  const [selectedAeCourse, setSelectedAeCourse] = useState();
  const [numberOfSlots, setNumberOfSlots] = useState(0);
  const [activeItem, setActiveItem] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [activeItemId, setActiveItemId] = useState();
  const [apiCompleted, setApiCompleted] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCourseCategory, setSelectedCourseCategory] = useState("");
  useEffect(() => {
    configjson();
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  }, []);
  // useEffect(() => {
  //   teacherSlotShown(datefrom, dateTo);
  // }, []);
  const onReset = () => {
    setNumberOfSlots();
  };

  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
          const activeItemList = data?.AirlineCourseCategory?.find(
            (item) => item.categoryId
          );
          setActiveItem(activeItemList);
          console.log(activeItemList, "activeItemList==");
        }
      },
    });
  };
  const handleSelect = ({ start, end }) => {
    const dur = Math.abs(new Date(start) - new Date(end));
    const mins = Math.floor(dur / 60000);
    if(!((mins % 30  == 0 || mins % 30  == 29 )|| (mins % 20 == 0 || mins % 20  == 19))){
      notification.error({
        message: "Please select a slot addition of either 30 minutes or 20 minutes only!",
      });
      return;
    }

    if (moment(start) > moment() && mins >= 19) {
      const confirmDialog = Modal.confirm({
        title: `Confirm selected date and time`,
        icon: <DiffOutlined />,
        content: (
          <div>
            <span>
              Date along with range:{" "}
              {`${moment(start).format("DD-MM-YYYY")} ${moment(start).format(
                "HH:mm:ss"
              )} - ${moment(end).format("HH:mm:ss")}`}
            </span>
            <div>
              <b>
                Max booking per slots:<span style={{ color: "red" }}>*</span>
              </b>
              {console.log(numberOfSlots, "555")}
              <select
                id="maxSlots"
                onChange={(event) => {
                  console.log("-", event.target.value);
                  setNumberOfSlots(parseInt(event.target.value, 10));
                }}
                defaultValue={
                  numberOfSlots !== undefined ? numberOfSlots.toString() : "0"
                }
                style={{ width: "30%", marginLeft: "10px" }}
              >
                <option value="0">select slots</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div>
              <b>International Slot</b>
              <Checkbox id="slot1" style={{ paddingLeft: "10px" }}></Checkbox>
              {/* <p>{isChecked ? "The checkbox is checked" : "The checkbox is unchecked"}</p> */}
            </div>
          </div>
        ),
        onOk() {
          const isInternationalSlot = document.getElementById("slot1").checked;
          const slotDate = moment(start).format("YYYY-MM-DD");
          const startTime = moment(start);
          const endTime = moment(end);
          const maxSlotsInput = document.getElementById("maxSlots");
          const maxSlotsValue = parseInt(maxSlotsInput.value, 10);
          const maxSlots = isNaN(maxSlotsValue) ? 2 : maxSlotsValue;

          if (maxSlots === 0) {
            Modal.info({
              title: "Error",
              content: "Please select a value for Max booking per slots.",
              onOk() {
                confirmDialog.update({ visible: true });
              },
            });
            return false;
          }

          const slots = [];

          while (startTime < endTime) {
            const slot = {
              isInternationalSlot,
              slotDate,
              startTime: startTime.format("HH:mm:ss"),
              endTime: startTime.add((mins % 30  == 0 || mins % 30  == 29 ) ? 30:20, "minutes").format("HH:mm:ss"),
            };

            slots.push(slot);
          }
          props.slotAdd({
            params: {
              teacherId: teacherId.get("id"),
              clientId: props.profile.academicExpertClientId,
              ishalfHourSlot: true,
              maxNumberOfSlots: maxSlots,
              categoryId: selectedAeCourse,
            },
            body: slots,
            callback: (res, data) => {
              if (_.isEqual(res, true)) {
                mixpanel.track(constant.MIXPANEL_AE_SLOT_ADD, {
                  user: token?.studentName,
                  userName: token?.username,
                  userId: token?.uId,
                  teacherEmail: props?.profile?.emailId,
                  teacherMobile: props?.profile?.mobileNumber,
                  teacherId: teacherId.get("id"),
                  teacherName: props?.profile?.firstName,
                  enableForPaid: props?.profile?.enableForPaid,
                  maxNumberOfSlots: maxSlots,
                  categoryId: selectedAeCourse,
                  clientId: props.profile.academicExpertClientId,
                  slots: slots,
                  api_status: "Success",
                  api_res: data
                });
                teacherSlotShown(datefrom, dateTo);
                if (
                  _.isEqual(
                    _.get(data, "body"),
                    '{"TeacherSlot already exist!":true}'
                  )
                ) {
                  notification.success({
                    message: "Slot already exists.",
                  });
                } else {
                  notification.success({
                    message: "Slots added successfully.",
                  });
                  onReset();
                }
              } else {
                mixpanel.track(constant.MIXPANEL_AE_SLOT_ADD, {
                  user: token?.studentName,
                  userName: token?.username,
                  userId: token?.uId,
                  teacherEmail: props?.profile?.emailId,
                  teacherMobile: props?.profile?.mobileNumber,
                  teacherId: teacherId.get("id"),
                  teacherName: props?.profile?.firstName,
                  enableForPaid: props?.profile?.enableForPaid,
                  maxNumberOfSlots: maxSlots,
                  categoryId: selectedAeCourse,
                  clientId: props.profile.academicExpertClientId,
                  slots: slots,
                  api_status: "failure",
                  api_res: data
                });
                notification.error({
                  message: "Teacher slot info already exists!",
                });
              }
            },
          });
        },
        onCancel() { },
      });
    }
  };

  // freeslot delete
  const onslotdelete = (event) => {
    confirm({
      title: "Do you Want to do this action?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        setpageloading(true);
        props.deleteAeSlots({
          params: {
            airlineTeacherSlotid: _.get(event, "airlineTeacherSlotId"),
          },
          callback: (res, data) => {
            // setpageloading(false);
            setPreviewModalVisible(false);
            if (_.isEqual(res, true)) {
              mixpanel.track(constant.MIXPANEL_AE_SLOT_DELETE, {
                user: token?.studentName,
                userName: token?.username,
                userId: token?.uId,
                teacherEmail: props?.profile?.emailId,
                teacherMobile: props?.profile?.mobileNumber,
                classTimings: event.startTime + " - " + event.endTime,
                teacherName: event.teacherName,
                studentName: event.studentName,
                courseName: event.courseName,
                airlineBookingSlotId: event.airlineBookingSlotId,
                airlineTeacherSlotId: event.airlineTeacherSlotId,
                isInternationalSlot: event.isInternationalSlot,
                slotId: event.id,
                slotDate: event.slotDate,
                slotTeacherId: event.teacherId,
                slotTimeZone: event.timezoneId,
                slotCancelCount: event.cancelCount,
                slotClassBookingStatus: event.classBookingStatus,
                api_status: "success",
                api_res: data
              });
              teacherSlotShown(datefrom, dateTo);
              notification.success({
                message: "Slot deleted successfully.",
              });
            } else {
              mixpanel.track(constant.MIXPANEL_AE_SLOT_DELETE, {
                user: token?.studentName,
                userName: token?.username,
                userId: token?.uId,
                teacherEmail: props?.profile?.emailId,
                teacherMobile: props?.profile?.mobileNumber,
                classTimings: event.startTime + " - " + event.endTime,
                airlineBookingSlotId: event.airlineBookingSlotId,
                airlineTeacherSlotId: event.airlineTeacherSlotId,
                isInternationalSlot: event.isInternationalSlot,
                teacherName: event.teacherName,
                studentName: event.studentName,
                courseName: event.courseName,
                slotId: event.id,
                slotDate: event.slotDate,
                slotTeacherId: event.teacherId,
                slotTimeZone: event.timezoneId,
                slotCancelCount: event.cancelCount,
                slotClassBookingStatus: event.classBookingStatus,
                api_status: "failure",
                api_res: data
              });
              setpageloading(false);
              notification.error({
                message: "Something went wrong",
              });
            }
          },
        });
      },
      onCancel() { },
    });
  };
  const onFileChange = (event) => {
    setSelectedAeCourse(event.target.value);
    console.log(event.target.value, "value");
  };
  let history = useNavigate();
  let allViews = Object.keys(Views).map((k) => Views[k]);

  const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: "#CCE8F1",
      },
    });

  const teacherSlotShown = async (df, dt) => {
    try {
      setLoading(true);

      const activeItemTemp = courseList?.AirlineCourseCategory?.find(
        (course) => course.categoryId == selectedAeCourse
      )?.id;
      if (!activeItemTemp) {
        throw new Error("Active item not found.");
      }
      setActiveItemId(activeItemTemp);
      console.log(activeItemTemp, "=====");
      console.log(df, dt, "$$$$");

      const updatedActiveItemId = activeItemTemp;
      const response = await axios.get(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/airline/slots?teacherId=${props?.profile?.teacherId}&fromDate=${df}&toDate=${dt}&parentTagId=${updatedActiveItemId}`,
        { headers: { Authorization: token["authorization"] } }
      );

      if (response?.data) {
        setpageloading(false);
        setEvents(response.data);
        setShowCalendar(true);
      } else {
        setpageloading(false);
        setEvents([]);
      }
      setApiCompleted(true);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  /**Events list in calendar */
  const onCalendarEvents = (events) => {

    let datas = [];

    _.map(events, (event, id) => {

      datas.push({
        id: `${_.get(event, "id")}`,
        key: `${_.get(event, "id")}`,
        title: (
          <div
            className="createEventBlock"
            onClick={() => {
              setPreviewModalVisible(true);
              setEvent(event);
              console.log(event, "223344");
              setFocusDate(_.get(event, "slotDate"));
            }}
          >
            <span>
              {_.get(event, "maxNumberOfSlots") ==
                _.get(event, "slotsBooked")
                ? "Booked"
                : _.isEqual(_.get(event, "slotStatus"), "freeSlot")
                  ? "Free Slot"
                  : _.isEqual(
                    _.get(
                      event,
                      "slotStatus"
                    ),
                    "booked"
                  )
                    ? "Booked"
                    : _.isEqual(
                      _.get(
                        event,
                        "slotStatus"
                      ),
                      "completed"
                    )
                      ? "completed"
                      : _.isEqual(
                        _.get(event, "slotStatus"),
                        "cancelledAfterJoining"
                      )
                        ? "cancel AfterJoin(Free)"
                        : "Free Slot"}
            </span>
          </div>
        ),
        start: new Date(
          moment(
            `${_.get(event, "slotDate")} ${_.get(
              event,
              "startTime"
            )}`,
            "YYYY-MM-DD HH-mm-ss"
          ).format()
        ),
        end: _.isEqual(_.get(event, "endTime"), "00:00:00")
          ? new Date(
            moment(
              `${_.get(event, "slotDate")} ${"23:59:59"}`,
              "YYYY-MM-DD HH-mm-ss"
            ).format()
          )
          : new Date(
            moment(
              // if classschedule is there
              // event.class ?
              `${_.get(event, "slotDate")} ${_.get(
                event,
                "endTime"
              )}`,
              "YYYY-MM-DD HH-mm-ss"
            ).format()
          ),
        status: _.get(event, "slotStatus"),
        callStatus: _.get(event, "slotStatus"),
        ...event,
      });
    });

    return datas;
  };
  /**Multiple slots style  status*/
  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor =
      _.get(event, "maxNumberOfSlots") ==
        _.get(event, "slotsBooked")
        ? "#ffc306"
        : _.isEqual(_.get(event, "slotStatus"), "freeSlot")
          ? "#68c0af"
          : _.isEqual(
            _.get(event, "slotStatus"),
            "booked"
          )
            ? "#ffc306"
            : _.isEqual(
              _.get(event, "slotStatus"),
              "completed"
            )
              ? "#ec5e63"
              : _.isEqual(
                _.get(event, "slotStatus"),
                "cancelledAfterJoining"
              )
                ? "lightsalmon"
                : null;

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      color: _.isEqual(_.get(event, "slotStatus"), "true") ? "#363D3F" : "#172714",
      border: "1px solid #fff",
      display: "block",
    };
    return {
      style: style,
    };
  };
  /**Next and previous function call */
  const calenderChange = (changeDate) => {
    let date = moment(new Date(changeDate)).format("YYYY-MM");
    let monthInDays = moment(new Date(changeDate)).daysInMonth();
    let start_date = moment(`${date}-01`, "YYYY-MM-DD").format("YYYY-MM-DD");
    // let end_date = moment(`${date}-${monthInDays}`, "YYYY-MM-DD").format('YYYY-MM-DD')
    let end_date = moment(date)
      .add(1, "M")
      .startOf("month")
      .format("YYYY-MM-DD");
    setdatefrom(start_date);
    console.log(start_date, "22==");
    console.log(end_date, "333===");
    setDateTo(end_date);
    teacherSlotShown(start_date, end_date);
    // setpageloading(true);
  };

  const ViewModel = () => {
    return (
      <div
        className={`modal-container ${_.isEqual(previewModalVisible, false) ? "d-none" : ""
          }`}
      >
        <div className="cookiesContent" id="cookiesPopup">
          <button
            className="close"
            onClick={() => setPreviewModalVisible(false)}
          >
            ✖
          </button>
          {_.isEqual(
            _.get(event, "slotStatus"),
            "class_scheduled"
          ) ? (
            <React.Fragment>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Course title:</b>
                  {_.get(
                    event,
                    "courseName"
                  )}
                </span>
              </div>

              <div className="viewmodaldescription mb-2">

                <span>
                  <b>Description:</b>{" "}
                  {_.get(
                    event,
                    "teacher.userDetails.descriptionDetails.description"
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">

                <span>
                  <b>Session Number:</b>{" "}
                  {_.get(
                    event,
                    "classSchedule.studentClassDetails.courseClassDetails.classNumber"
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">

                <span>
                  <b>Class Date: </b>{" "}
                  {_.upperCase(
                    moment(_.get(event, "slotDate")).format(
                      "DD MMM YYYY"
                    )
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">

                <span>
                  <b>Class Start-End Time: </b>{" "}
                  {_.get(event, "startTime")} -{" "}
                  {_.get(event, "endTime")}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">

                <span>
                  <b>Student name:</b>{" "}
                  {_.startCase(
                    _.get(
                      event,
                      "studentName"
                    )
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">

                <span>
                  <b>Student Mobile Number:</b>
                  {_.get(
                    event,
                    "studentContact"
                  )}
                  {/* -
                  {_.startCase(
                    _.get(
                      event,
                      "classSchedule.studentClassDetails.studentEnrollmentDetails.student.userDetails.credential.mobileNumber"
                    )
                  )} */}
                </span>
              </div>
              <div className="viewmodalstatus mb-2">
                <span>
                  <b>Call status:</b>{" "}
                  {_.startCase(
                    _.get(
                      event,
                      "slotStatus"
                    )
                  )}
                </span>
              </div>
            </React.Fragment>
          ) : _.isEqual(
            _.get(event, "slotStatus"),
            "class_completed"
          ) ? (
            <React.Fragment>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Course title:</b>
                  {_.get(
                    event,
                    "courseName"
                  )}
                </span>
              </div>

              <div className="viewmodaldescription mb-2">
                <span>
                  <b>Description:</b>{" "}
                  {_.get(
                    event,
                    "teacher.userDetails.descriptionDetails.description"
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">
                <span>
                  <b>Session Number:</b>{" "}
                  {_.get(
                    event,
                    "classSchedule.studentClassDetails.courseClassDetails.classNumber"
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">
                <span>
                  <b>Class Date: </b>{" "}
                  {_.upperCase(
                    moment(_.get(event, "slotDate")).format(
                      "DD MMM YYYY"
                    )
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">
                <span>
                  <b>Class Start-End Time: </b>{" "}
                  {_.get(event, "startTime")} -{" "}
                  {_.get(event, "endTime")}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">
                <span>
                  <b>Student name:</b>{" "}
                  {_.startCase(
                    _.get(
                      event,
                      "studentName"
                    )
                  )}
                </span>
              </div>
              <div className="viewmodaldescription mb-2">
                {/* not coming */}
                <span>
                  <b>Student Mobile Number:</b>
                  {_.get(
                    event,
                    "studentContact"
                  )}
                  {/* -
                  {_.startCase(
                    _.get(
                      event,
                      "classSchedule.studentClassDetails.studentEnrollmentDetails.student.userDetails.credential.mobileNumber"
                    )
                  )} */}
                </span>
              </div>
              <div className="viewmodalstatus mb-2">
                <span>
                  <b>Call status:</b>{" "}
                  {_.startCase(
                    _.get(
                      event,
                      "slotStatus"
                    )
                  )}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Course category:</b>
                  {selectedCourseCategory}
                </span>
              </div>
              {/* <div className="viewmodaltitle mb-2">
                <span>
                  <b>Teacher:</b>
                  {_.get(event, "teacherName")}
                </span>
              </div> */}
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Max No.slots:</b>
                  {_.get(event, "maxNumberOfSlots")}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Slots Booked:</b>
                  {_.get(event, "slotsBooked")}
                </span>
              </div>
              {console.log(
                event,
                "event.isInternationalSlot",
                "ghvb===="
              )}
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>International Slot:</b>
                  {/* {_.get(event, "event.airlineBookingSlots.isInternationalSlot")} */}

                  {event.isInternationalSlot}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Students Participated:</b>
                  {_.get(event, "studentsParticipated")}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Student Name:</b>
                  {_.get(event, "studentName")}
                  {/* changed */}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Student Email:</b>
                  {_.get(event, "studentEmail")}
                  {/* changed */}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Student Contact:</b>
                  {_.get(event, "studentContact")}
                  {/* changed */}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Timing:</b>
                  {_.get(event, "startTime")} -{" "}
                  {_.get(event, "endTime")}
                </span>
              </div>
              <div className="viewmodaltitle mb-2">
                <span>
                  <b>Date:</b>
                  {_.upperCase(
                    moment(_.get(event, "slotDate")).format(
                      "DD MMM YYYY"
                    )
                  )}
                </span>
              </div>


              <React.Fragment>
                {_.isEqual(_.get(event, "slotStatus"), "freeSlot") &&
                  (_.get(event, "maxNumberOfSlots") ==
                    _.get(event, "booked"),
                    "booked") && (
                    <button
                      className="accept mt-2"
                      disabled={_.get(
                        props,
                        "teachers.deleteslotsLoadingInProgress"
                      )}
                      onClick={() => onslotdelete(event)}
                    >
                      {_.get(props, "teachers.deleteslotsLoadingInProgress")
                        ? "Loading..."
                        : "Delete"}
                    </button>
                  )}
              </React.Fragment>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };
  /**Addon class delete */

  const content = () => {
    return (
      <div>
        <div
          style={{
            width: "460px",
            float: "right",
          }}
        ></div>

        <h4>AE Slot Search</h4>

        <p>
          <div>
            <Form>
              <Form.Item
                name="course"
                rules={[
                  {
                    required: true,
                    message: "Please Select Course!",
                  },
                ]}
              >
                <select
                  id="course"
                  onChange={(e) => {
                    setSelectedAeCourse(e.target.value);
                    setSelectedCourseCategory(e.target.options[e.target.selectedIndex].text);
                  }}
                  // onChange={onFileChange}
                  value={selectedAeCourse}
                  className="form-control"
                  style={{
                    appearance: "auto",
                    marginLeft: "-10px",
                    width: "202px",
                  }}
                  placeholder="Select Course"
                >
                  {console.log(selectedAeCourse, "666")}
                  <option value="">Select course</option>
                  {courseList &&
                    courseList.AirlineCourseCategory.map((e) => {
                      return <option value={e.categoryId}>{e.name}</option>;
                    })}
                </select>
                <Button
                  className="form-control"
                  style={{
                    width: "73px",
                    marginLeft: "199px",
                    marginTop: "-40px",
                    borderRadius: "9px",
                  }}
                  type="primary"
                  onClick={() => {
                    teacherSlotShown(datefrom, dateTo);
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </p>

        {selectedAeCourse && showCalendar && (
          <div className="calendar-legendset-conatiner">
            <div className="calendar-legend-set">
              <span
                className="legend-square"
                style={{ backgroundColor: "#ec5e63" }}
              ></span>
              <span className="legend-text">Completed &emsp;</span>
              <span
                className="legend-square"
                style={{ backgroundColor: "#ffc306" }}
              ></span>
              <span className="legend-text">Booked &emsp;</span>
              <span
                className="legend-square"
                style={{ backgroundColor: "#68c0af" }}
              ></span>
              <span className="legend-text">Free &emsp;</span>
              <span
                className="legend-square"
                style={{ backgroundColor: "lightsalmon" }}
              ></span>
              <span className="legend-text">Cancel After Join(Free) &emsp;</span>
            </div>
          </div>
        )}
      </div>
    );
  };
  const render = () => {
    return (
      <div className="teachers-slots-container">
        <div className="row">
          {!_.isEqual(teacherId.get("from"), "student") && (
            <React.Fragment>
              <div className="col-12  mb-2">{content()}</div>
            </React.Fragment>
          )}
          {_.isEqual(teacherId.get("from"), "student") && (
            <React.Fragment>
              <div className="col-12  mb-2">
                <div
                  className="slot-btn"
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <span>Teacher Calendar</span>
                </div>
              </div>
              <div className="col-12  mb-2">
                <span>{_.get(events[0], "teacherName")}</span>
              </div>
              <div className="calendar-legendset-conatiner">
                <div className="calendar-legend-set">
                  <span
                    className="legend-square"
                    style={{ backgroundColor: "#ec5e63" }}
                  ></span>
                  <span className="legend-text">Completed &emsp;</span>
                  <span
                    className="legend-square"
                    style={{ backgroundColor: "#ffc306" }}
                  ></span>
                  <span className="legend-text">Booked &emsp;</span>

                  <span
                    className="legend-square"
                    style={{ backgroundColor: "#68c0af" }}
                  ></span>
                  <span className="legend-text">Free &emsp;</span>
                </div>
              </div>
            </React.Fragment>
          )}

          {loading && <Spin></Spin>}
          {selectedAeCourse && showCalendar ? (
            <div className="row">
              <div className="col-12">
                <Spin spinning={pageloading}>
                  {apiCompleted &&
                    events.length === 0 &&
                    !pageloading &&
                    !loading && (
                      <div className="no-slots-message">
                        <b>No slots available.</b>
                      </div>
                    )}
                  <Calendar
                    selectable
                    tooltipAccessor={null}
                    onNavigate={(focusDate, flipUnit, prevOrNext) =>
                      calenderChange(focusDate)
                    }
                    onSelectSlot={handleSelect}
                    localizer={localizer}
                    events={onCalendarEvents(events)}
                    views={["month", "week", "day"]}
                    showMultiDayTimes
                    onSelectEvent={(event) => {
                      setPreviewModalVisible(true);
                      setEvent(event);
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    components={{
                      timeSlotWrapper: ColoredDateCellWrapper,
                    }}
                    eventPropGetter={eventStyleGetter}
                    defaultDate={moment()}
                    timeslots={1}
                    step={10}
                  />

                  {ViewModel()}
                </Spin>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
    // }
  };
  return render();
};

AeCalendar.propTypes = propTypes;

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  slotAdd: (details) => dispatch(global.redux.action.teachers.slotAdd(details)),
  deleteAeSlots: (details) =>
    dispatch(global.redux.action.teachers.deleteAeSlots(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AeCalendar);
