import actionType from '../actionTypes';
import _ from 'lodash';
const pathUrl = `/api/admin`
const courseUrl = `/api/course`

const reducerName = actionType.reduxUpdateStudentState;
// account actions

export default {
    
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `${pathUrl}/students`
                },
                actionType: actionType.students.list,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    // class list
    classdetails: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/api/admin/studentclass/${_.get(componentRequest,'params.studentId')}?size=100&page=0`
                },
                actionType: actionType.students.classdetails,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    classDeduct: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/api/admin/student/classes/deduct`
                },
                actionType: actionType.students.classDeduct,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },

// booked classcancel
scheduledcancel: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `${courseUrl}/cancelClass/${_.get(componentRequest,'params.scheduleid')}`
            },
            actionType: actionType.students.bookedclasscancel,
            reducerName,
            dispatch,
            componentRequest

        };
        global.api.requestAPI(requestOptions);
    }
},
// unscheduled cancel
rescheduleclass: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `${pathUrl}/rescheduleClass`
            },
            actionType: actionType.students.rescheduleclass,
            reducerName,
            dispatch,
            componentRequest

        };
        global.api.requestAPI(requestOptions);
    }
},
//refund class for student
refundClass: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `${courseUrl}/refund/class`
            },
            actionType: actionType.students.refundClass,
            reducerName,
            dispatch,
            componentRequest

        };
        global.api.requestAPI(requestOptions);
    }
},
//   add profile
addstudentprofile: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `${pathUrl}/addstudents`,
        },
        actionType: actionType.students.addstudentprofile,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

   // profile view
   viewprofile: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'get',
                url: `${pathUrl}/student/${_.get(componentRequest,'params.studentId')}/profileAPI`
            },
            actionType: actionType.students.viewprofile,
            reducerName,
            dispatch,
            componentRequest

        };
        global.api.requestAPI(requestOptions);
    }
},

// edit profile
editprofile: (componentRequest) => {
return dispatch => {
    const requestOptions = {
        axiosParams: {
            method: 'put',
            url: `/api/users/profile`
        },
        actionType: actionType.students.editprofile,
        reducerName,
        dispatch,
        componentRequest

    };
    global.api.requestAPI(requestOptions);
}
},

feedbackList: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                baseURI:`https://api.artiumacademy.com`,
                method: 'get',
                url: `/feedback.json`
            },
            actionType: actionType.students.feedbacklist,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
},

// Course list
courseList: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                baseURI:`https://api.artiumacademy.com`,
                method: 'get',
                url: `/config.json`
            },
            actionType: actionType.students.courselist,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    },



    
    

 // Course slots
courseslots: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'get',
                // url: `${pathUrl}/List/freeslots/${_.get(componentRequest,'params.studentId')}`
                url: `${courseUrl}/listFreeSlots/${_.get(componentRequest,'params.studentId')}`
            },
            actionType: actionType.students.courseslots,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    },   
     // Course languages
courselanguages: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'get',
                url: `${pathUrl}/language/${_.get(componentRequest,'params.interestId')}`
            },
            actionType: actionType.students.courseslots,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    }, 
    // Course allocation
    courseallocation: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/api/course/allocateTeacherForBooking`
                },
                actionType: actionType.students.courseallocation,
                reducerName,
                dispatch,
                componentRequest
        
            };
            global.api.requestAPI(requestOptions);
        }
        }, 
        // class count
        viewclasscount: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'get',
                url: `api/admin/studentClassesCount/${_.get(componentRequest,'params.studentId')}`
            },
            actionType: actionType.students.classcount,
            reducerName,
            dispatch,
            componentRequest

        };
        global.api.requestAPI(requestOptions);
    }
},
//Book demo API
// bookDemoStatus: (componentRequest) => {
//     return dispatch => {
//         const requestOptions = {
//             axiosParams: {
//                 method: 'get',
//                 url: `api/course/studentdemocomplete/${_.get(componentRequest,'params.studentId')}`
//             },
//             actionType: actionType.students.bookDemoStatus,
//             reducerName,
//             dispatch,
//             componentRequest

//         };
//         global.api.requestAPI(requestOptions);
//     }
// },
// Schedule new class
scheduleclass: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `${courseUrl}/scheduleClass`
            },
            actionType: actionType.students.scheduleclass,
            reducerName,
            dispatch,
            componentRequest

        };
        global.api.requestAPI(requestOptions);
    }
},
 // Course allocation
 usertypechange: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `${pathUrl}/activateCoursePlan/student/${_.get(componentRequest,'params.studentId')}`
            },
            actionType: actionType.students.studenttype,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    }, 
     // Enroll New Course 
 enrollnewcourse: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `/api/admin/enrolnewcourse`
            },
            actionType: actionType.students.enrollnewcourse,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    }, 
         // Student enrolled  Course 
 enrolledcourselist: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'get',
                url: `/api/admin/courseListForStudentDashboard/${_.get(componentRequest,'params.credid')}`
            },
            actionType: actionType.students.enrolledcourselist,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    }, 
    classRearrangement: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/api/admin/classRearrangement`,
                    params: componentRequest.params
                    
                },
                actionType: actionType.students.classRearrangement,
                reducerName,
                dispatch,
                componentRequest
        
            };
            global.api.requestAPI(requestOptions);
        }
        },
        syncSalesForce:(componentRequest) => {
            return dispatch => {
                const requestOptions = {
                    axiosParams: {
                        method: 'post',
                        url: `/api/course/salesforce/sync`,
                        params: componentRequest.params
                        
                    },
                    actionType: actionType.students.syncSalesForce,
                    reducerName,
                    dispatch,
                    componentRequest
            
                };
                global.api.requestAPI(requestOptions);
            }
            },
            // change status API 
            ChangeStatusForStudentSlot:(componentRequest) => {
                return dispatch => {
                    const requestOptions = {
                        axiosParams: {
                            method: 'post',
                            url: `/api/course/admin/changeClassStatus`
                           
                            
                        },
                        actionType: actionType.students.ChangeStatusForStudentSlot,
                        reducerName,
                        dispatch,
                        componentRequest
                       
                
                    };
                    global.api.requestAPI(requestOptions);
                }
            },
     // Photo update
 studentphotoupload: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `/api/users/upload/profilephoto`
            },
            actionType: actionType.students.uploadphoto,
            reducerName,
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    }, 
     // updateTag 
     updateTag: (componentRequest) => {
    return dispatch => {
        const requestOptions = {
            axiosParams: {
                method: 'post',
                url: `/api/users/updateTag/${_.get(componentRequest,'params.studentId')}/${_.get(componentRequest,'params.tagId')}`
            },
            actionType: actionType.students.enrollnewcourse,
            reducerName, 
            dispatch,
            componentRequest
    
        };
        global.api.requestAPI(requestOptions);
    }
    }, 
    //Add-mobile
    validatemobile: (componentRequest) => {
        const countryCode = _.get(componentRequest, 'params.countryCode');
        const mobile = _.get(componentRequest, 'params.mobile');
        
        if (!countryCode || !mobile) {
          // Handle missing countryCode or mobile
          console.error('Missing countryCode or mobile in componentRequest params');
          return;
        }
        const decodedCountryCode = decodeURIComponent(countryCode);
        const decodedMobile = decodeURIComponent(mobile);

        return dispatch => {
          const requestOptions = {
            axiosParams: {
              method: 'get',
              url: `/api/users/ValidateNewUser/${encodeURIComponent(decodedCountryCode)}/${encodeURIComponent(decodedMobile)}`,
              params: {}
            },
            actionType: actionType.students.validatemobile,
            reducerName,
            dispatch,
            componentRequest,
          };
          global.api.requestAPI(requestOptions);
        };
      },
      
    //Add-email
    validateEmail: (componentRequest) => {
        const { email } = componentRequest.params;
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/api/users/ValidateNewUser1/${encodeURIComponent(email)}`,
                    params: {}
                },
                actionType: actionType.students.validateEmail,
                reducerName,
                dispatch,
                componentRequest
    
            };
            global.api.requestAPI(requestOptions);
        }
    },
    expiryDate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/api/admin/extend/expiry`
                },
                actionType: actionType.students.expiryDate,
                reducerName,
                dispatch,
                componentRequest
        
            };
            global.api.requestAPI(requestOptions);
        }
    }
}


