import React, { useState, useEffect } from "react";
import { Form, DatePicker, Button, Table, Spin, Tag, Select, Modal, Empty} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { connect } from "react-redux";
import "./style.css";
import axios from 'axios';
import * as SecureLS from "secure-ls";
import appEnvirontVariables from "../../../core_units/config/env-variables";
function PaidSlots(props) {
      var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedCellContent, setSelectedCellContent] = useState("");
  const [courseList, setCourseList] = useState();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [mergedData, setMergedData] = useState([]);
  const [teacherData,setTeacherData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [myMap, setMyMap] = useState(new Map());
  useEffect(() => {
 
    if (startDate && endDate) {
        fetchAPIResponse(startDate, endDate);
      }
      
      configjson();
  }, [startDate, endDate]);
  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
          setCourseList(data);
        }
      },
    });
  };
   const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchAPIResponse = (startDate, endDate) => {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/getpaidslotsforadmin?fromDate=${startDate}&toDate=${endDate}&courseId=${selectedCourseId}`;
  
    setLoading(true);
    setData([]);
  
    const params = {
      fromDate: startDate,
      toDate: endDate,
      courseId: selectedCourseId
    };
  
    axios.post(apiUrl, params, {
      headers: {
        Authorization: token["authorization"],
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const responseData = response.data;
        if (responseData && responseData.length > 0) {
          const mergedData = mergeResponseData(responseData);
          setData(mergedData);
        }
        setLoading(false);
        // form.resetFields();
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const mergeResponseData = (responseData) => {
    const mergedData = [];
    const dateMap = new Map();
    const dateTime = new Map();
    for (const response of responseData) {
      const { slotDate, startTime, bookedSlots, totalSlots,endTime } = response;
      console.log(`Slot Date: ${slotDate}, StartTime: ${startTime}, Booked Slots: ${bookedSlots}, Total Slots: ${totalSlots}`);
  if (!dateTime.has(startTime)) {
    dateTime.set(startTime, {
      [`${startTime}-bookedSlots`]: +bookedSlots,
      [`${startTime}-totalSlots`]: +totalSlots,
    });
  } else {
    const timeSlot = dateTime.get(startTime);
    console.log(timeSlot,"timeSlot")
    timeSlot[`${startTime}-bookedSlots`] += +bookedSlots;
    console.log(bookedSlots,"bookedSlots")
    timeSlot[`${startTime}-totalSlots`] += +totalSlots;
    console.log(totalSlots,"totalSlots")
  }
    
      if (!dateMap.has(slotDate)) {
        dateMap.set(slotDate, {
          slotDate: slotDate,
          [startTime]: `${bookedSlots}/${totalSlots}`,
          booked: +bookedSlots,
          total: +totalSlots,
          [`${startTime}-percentage`]: `${((bookedSlots / totalSlots) * 100).toFixed(2)}%`,
        });
      } else {
        const dateData = dateMap.get(slotDate);
        dateData[startTime] = `${bookedSlots}/${totalSlots}`;
        dateData["booked"] += +bookedSlots;
        dateData["total"] += +totalSlots;
        dateData[`${startTime}-percentage`] = `${((bookedSlots / totalSlots) * 100).toFixed(2)}%`;
      }
    }
    console.log(dateTime,"dateTime")
    setMyMap(dateTime);
  
    const sortedDates = Array.from(dateMap.keys()).sort((a, b) => dayjs(b) - dayjs(a));
  

    for (const date of sortedDates) {
      mergedData.push(dateMap.get(date));
    }
    console.log(mergedData,"mergedData")
    return mergedData;
   
  };  

  const getPercentageColor = (percentage) => {
    if (percentage >= 86 && percentage <= 100) {
      return "red";
    } else if (percentage >= 55 && percentage <= 85) {
      return "orange";
    } else {
      return "lightgreen";
    }
  };
  const handleCellContentClick = (text, slotDate, startTime,endTime,selectedCourseId) => {
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/paidslotdetails?slotdate=${slotDate}&starttime=${startTime}&endtime=${endTime}&courseId=${selectedCourseId}`;
  
    fetch(apiUrl, {
      headers: {
        Authorization: token["authorization"]
      }
    })
      .then((response) => response.json())
      .then((data) => {
      setTeacherData(data);
        console.log(data,"data==");
        showModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  const renderPercentage = (text, record, startTime, endTime) => {
    
    const percentage = Math.round(parseFloat(record[`${startTime}-percentage`]));
    const color = getPercentageColor(percentage);
    if (isNaN(percentage)) {
      return ""; 
    }

  console.log(text,"record")
    const cellContent = (
      <span style={{ cursor: "pointer" }} onClick={() => handleCellContentClick(text, record.slotDate, startTime,endTime,selectedCourseId)}>
      <span>
        <span style={{ fontSize: "12px" }}>
          <b>{text}</b>                                           
        </span>
        <br />
        ({percentage}%)
      </span>
      </span>
    );
  
    const cellStyle = {
      background: color,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "visible",
      textAlign: "center",
      borderRight: "1px solid black",
    };
  
    return {
      props: {
        style: cellStyle,
      },
      children: (
        <div style={{ maxWidth: "100%", overflow: "visible" }}>
          {cellContent}
        </div>
      ),
    };
  };
  
  const columns = [
    {
      title: "Date",
      dataIndex: "slotDate",
      key: "slotDate",
      render: (slotDate, record) => {
        const date = new Date(slotDate);
        const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString(undefined, options);
        const { booked, total } = record;
        return (
          <div>
            <span>{formattedDate}</span><br/>
            <span style={{fontWeight:"bold"}}>{booked}/{total}</span>
          </div>
        );
      }
    },
    {
      title: `00:00:00 (${myMap.get("00:00:00") ? `${myMap.get("00:00:00")["00:00:00-bookedSlots"]}/${myMap.get("00:00:00")["00:00:00-totalSlots"]}` : "0/0"})`,
      dataIndex: "00:00:00",
      key: "startTime",
      align: "center",
      render: (text, record) => renderPercentage(text, record, "00:00:00", "01:00:00"),
   
    },
 
    {
      title: `01:00:00 (${myMap.get("01:00:00") ? `${myMap.get("01:00:00")["01:00:00-bookedSlots"]}/${myMap.get("01:00:00")["01:00:00-totalSlots"]}` : "0/0"})`,
      dataIndex: "01:00:00",
      key: "startTime",
      align: "center",
      render: (text, record) => renderPercentage(text, record, "01:00:00", "02:00:00"),
    },
    {
      title: `05:00:00 (${myMap.get("05:00:00") ? `${myMap.get("05:00:00")["05:00:00-bookedSlots"]}/${myMap.get("05:00:00")["05:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "05:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "05:00:00","06:00:00"),
      },
    
      {
        title: `06:00:00 (${myMap.get("06:00:00") ? `${myMap.get("06:00:00")["06:00:00-bookedSlots"]}/${myMap.get("06:00:00")["06:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "06:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "06:00:00","07:00:00"),
      },
   
      {
        title: `07:00:00 (${myMap.get("07:00:00") ? `${myMap.get("07:00:00")["07:00:00-bookedSlots"]}/${myMap.get("07:00:00")["07:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "07:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "07:00:00","08:00:00"),
      },
    
      {
        title: `08:00:00 (${myMap.get("08:00:00") ? `${myMap.get("08:00:00")["08:00:00-bookedSlots"]}/${myMap.get("08:00:00")["08:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "08:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "08:00:00","09:00:00"),
      },
   
      {
        title: `09:00:00 (${myMap.get("09:00:00") ? `${myMap.get("09:00:00")["09:00:00-bookedSlots"]}/${myMap.get("09:00:00")["09:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "09:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "09:00:00","10:00:00"),
      },
    
      {
        title: `10:00:00 (${myMap.get("10:00:00") ? `${myMap.get("10:00:00")["10:00:00-bookedSlots"]}/${myMap.get("10:00:00")["10:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "10:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "10:00:00", "11:00:00"),
      },
    
      {
        title: `11:00:00 (${myMap.get("11:00:00") ? `${myMap.get("11:00:00")["11:00:00-bookedSlots"]}/${myMap.get("11:00:00")["11:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "11:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "11:00:00", "12:00:00"),
      },
  
      {
        title: `12:00:00 (${myMap.get("12:00:00") ? `${myMap.get("12:00:00")["12:00:00-bookedSlots"]}/${myMap.get("12:00:00")["12:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "12:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "12:00:00", "13:00:00"),
      },
   
      {
        title: `13:00:00 (${myMap.get("13:00:00") ? `${myMap.get("13:00:00")["13:00:00-bookedSlots"]}/${myMap.get("13:00:00")["13:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "13:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "13:00:00", "14:00:00"),
      },
      
   
      {
        title: `14:00:00 (${myMap.get("14:00:00") ? `${myMap.get("14:00:00")["14:00:00-bookedSlots"]}/${myMap.get("14:00:00")["14:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "14:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "14:00:00","15:00:00"),
      },
    
      {
        title: `15:00:00 (${myMap.get("15:00:00") ? `${myMap.get("15:00:00")["15:00:00-bookedSlots"]}/${myMap.get("15:00:00")["15:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "15:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "15:00:00","16:00:00"),
      },
   
      {
        title: `16:00:00 (${myMap.get("16:00:00") ? `${myMap.get("16:00:00")["16:00:00-bookedSlots"]}/${myMap.get("16:00:00")["16:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "16:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "16:00:00", "17:00:00"),
      },
    
      {
        title: `17:00:00 (${myMap.get("17:00:00") ? `${myMap.get("17:00:00")["17:00:00-bookedSlots"]}/${myMap.get("17:00:00")["17:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "17:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "17:00:00","18:00:00"),
      },
    
      {
        title: `18:00:00 (${myMap.get("18:00:00") ? `${myMap.get("18:00:00")["18:00:00-bookedSlots"]}/${myMap.get("18:00:00")["18:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "18:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "18:00:00", "19:00:00"),
      },
   
      {
        title: `19:00:00 (${myMap.get("19:00:00") ? `${myMap.get("19:00:00")["19:00:00-bookedSlots"]}/${myMap.get("19:00:00")["19:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "19:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "19:00:00", "20:00:00"),
      },
   
      {
        title: `20:00:00 (${myMap.get("20:00:00") ? `${myMap.get("20:00:00")["20:00:00-bookedSlots"]}/${myMap.get("20:00:00")["20:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "20:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "20:00:00", "21:00:00"),
      },
   
      {
        title: `21:00:00 (${myMap.get("21:00:00") ? `${myMap.get("21:00:00")["21:00:00-bookedSlots"]}/${myMap.get("21:00:00")["21:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "21:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "21:00:00", "22:00:00"),
      },
   
      {
        title: `22:00:00 (${myMap.get("22:00:00") ? `${myMap.get("22:00:00")["22:00:00-bookedSlots"]}/${myMap.get("22:00:00")["22:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "22:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "22:00:00","23:00:00"),
      },
   
      {
        title: `23:00:00 (${myMap.get("23:00:00") ? `${myMap.get("23:00:00")["23:00:00-bookedSlots"]}/${myMap.get("23:00:00")["23:00:00-totalSlots"]}` : "0/0"})`,
        dataIndex: "23:00:00",
        key: "startTime",
        align: "center",
        render: (text, record) => renderPercentage(text, record, "23:00:00","00:00:00"),
      },
   
      
    
    
  ];
  const onFinish = (values) => {
    const startDate = values.fromDate[0].format("YYYY-MM-DD");
    const endDate = values.fromDate[1].format("YYYY-MM-DD");
    setLoading(true);
    fetchAPIResponse(startDate, endDate);
  };
  const exportToExcel = () => {
    if (!Array.isArray(data) || data.length === 0) {
        console.log("No data to export");
        return;
    }
console.log(data,"data===")
    // Hardcoded headers
    const headers = [
        'Date',
        '00:00:00',
        '01:00:00',
        '05:00:00',
        '06:00:00',
        '07:00:00',
        '08:00:00',
        '09:00:00',
        '10:00:00',
        '11:00:00',
        '12:00:00',
        '13:00:00',
        '14:00:00',
        '15:00:00',
        '16:00:00',
        '17:00:00',
        '18:00:00',
        '19:00:00',
        '20:00:00',
        '21:00:00',
        '22:00:00',
        '23:00:00',
       
    ];

    let csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n";
    csvContent += data.map(item => {
        return headers.map(header => {
            if (header === 'Date') {
                return item.slotDate || '';
            } else {
                return item[header] || '';
            }
        }).join(",");
    }).join("\n");
console.log(csvContent,"csvContent==")
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "PaidSlotsData.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  return (
    <div className="report-filter-container">
      <span>PaidSlot Details</span>
      <Form form={form} onFinish={onFinish}>
        <div className="" style={{ maxWidth: "100%" }}>
          <Form.Item
            label="Date"
            name="fromDate"
            rules={[
              {
                required: true,
                message: "Please input your date range!",
              },
            ]}
          >
            <RangePicker/>
          </Form.Item>
          <Form.Item
            name="courseId"
            label="Course"
            rules={[
              {
                required: true,
                message: "Please Select Course!",
              },
            ]}
          >
            <Select placeholder="Select the Course" onChange={(value) => setSelectedCourseId(value)}
  value={selectedCourseId} style={{width:"255px"}}>
            {_.map(
                              _.get(courseList, "courses"),
                              (course, eachParent) => {
                                {
                                  return _.map(
                                    _.get(course, "tags"),
                                    (tag, each) => {
                                      console.log("tags", tag);
                                      console.log("course", course);
                                      return (
                                        <Option
                                          value={_.get(tag, "paidCourseId")}
                                          key={eachParent + "-" + each}
                                        >
                                          {_.get(course, "name")}-
                                          {_.get(tag, "name")}
                                        </Option>
                                      );
                                    }
                                  );
                                }
                              }
                            )}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Button onClick={exportToExcel} style={{ marginTop: '20px', marginBottom:"20px" }}>Export to CSV</Button>

          {loading ? (
            <div style={{ textAlign: "center" }}>
             <Spin
                size="large"
                tip="Loading..."
                style={{ textAlign: "center" }}
              />
            </div>

          ) : data.length === 0 ? (
           <div style={{ display: "flex", justifyContent: "center" }}>No Data Found</div>
          ) : (
           <div></div>
          )}
          <div className="" style={{width:"fit-content"}}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              {/* <Spin
                size="large"
                tip="Loading..."
                style={{ textAlign: "center" }}
              /> */}
            </div>
          ) : data.length === 0 ? (
           <div></div>
          ) : (
            <Table columns={columns} dataSource={data} pagination={false} />
          )}
          </div>
        </div>
      </Form>
      <Modal
  title="Teacher Details"
  visible={isModalVisible}
  onOk={handleOk}
  onCancel={handleCancel}
  footer={false}
  className="teacherDetails"

>
<table style={{width:"100%"}}>
  <thead>
    <tr>
      <th style={{ border: "1px solid black", padding: "8px" }}>S.No</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Teacher Name</th>                     
      <th style={{ border: "1px solid black", padding: "8px" }}>Student Name</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Course Name</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>CountryCode</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Is Booked</th>
    </tr>
  </thead>
  <tbody>
  
  {teacherData
    .sort((a, b) => a.teacherName.localeCompare(b.teacherName))
    .map((data, index) => (
        <tr key={index}>
          <td style={{ border: "1px solid black", padding: "8px" }}>{index + 1}</td>
<td style={{ border: "1px solid black", padding: "8px" }}>{data.teacherName || "-"}</td>
      <td style={{ border: "1px solid black", padding: "8px" }}>{data.studentName || "-"}</td>
          <td style={{ border: "1px solid black", padding: "8px" }}>{data.courseName || "-"}</td>
          <td style={{ border: "1px solid black", padding: "8px" }}>{data.countryCode || "-"}</td>
          <td style={{ border: "1px solid black", padding: "8px" }}>{data.isBooked ? "true" : "false"}</td>
        </tr>
      ))}
  </tbody>
</table>
</Modal>
    </div>  
    );
}

const mapStateToProps = (state) => ({
    teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  });
  
  const mapDispatchToProps = (dispatch) => ({
    courseList: (details) =>
      dispatch(global.redux.action.students.courseList(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(PaidSlots);

