import _ from 'lodash';
import {  notification} from 'antd';

export default (criteria)=>{
    const title = _.get(criteria, 'title', 'Alert Title');
    const message = _.get(criteria, 'message', 'My Alert Msg');
    const type = _.get(criteria, 'type', 'Info')
    notification[type]({
        message: title,
        description:message
      });
    
};