import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ShoppingOutlined,WalletOutlined } from '@ant-design/icons';
import _ from "lodash";
import SecureLS from "secure-ls";
import moment from "moment"; 
import { Col, Row } from 'antd';
import axios from "axios";
import './style.css';
import { Modal, Form, Button, DatePicker, Input,notification } from "antd";
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { ExclamationCircleOutlined } from '@ant-design/icons';
function Cashback(props) {
    console.log(props,"props==")
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const [form] = Form.useForm();
    const [cashDetail, setCashDetail] = useState();
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    console.log("Render Selected Date:", selectedDateTime);
    useEffect(() => {
        console.log("useEffect is running");
        walletDetails();
   
    }, []);
    const walletDetails = () => {
        const userId = props.students.viewprofile.userId;
        const userType = props.students.viewprofile.userType;
        axios.get(`${appEnvirontVariables.API.REFERRAL_URL}/api/artium-cash/${userId}?userType=${userType}`, {
        headers: {
            Authorization: token["authorization"]
        }
    })
            .then(response => {
                const data = response.data; 
                console.log("API Response Data:", data);
                setCashDetail(data);
            })
            .catch(error => {
                console.error("API Error:", error);
            });
    };
    const handleDateChange = (date) => {
        console.log("Selected Date:", date); 
        setSelectedDateTime(date);
    };
    const handleSubmit = (values) => {
        const userId = props.students.viewprofile.userId;
        const formattedDateTime = selectedDateTime
        ? selectedDateTime.toISOString() 
        : null;
        const points = parseInt(values.points, 10);
        const postData = {
            type: values.type,
            userId: userId,
            expiryDate: formattedDateTime,
            points: points 
        };
    
      
    
        Modal.confirm({
            title: 'Do you want to add the points?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                axios.post(`${appEnvirontVariables.API.REFERRAL_URL}/api/artium-cash/${userId}`, postData, {
                    headers: {
                        Authorization: token["authorization"]
                    }
                })
                .then(response => {
                    console.log("API Response:", response.data);
                    notification.success({
                        message: 'Points added successfully.',
                    });
                    walletDetails();
                    form.resetFields();
                })
                .catch(error => {
                    console.error("API Error:", error);
                    notification.error({
                        message: 'Something went wrong',
                    });
                });
            },
        });
    };
    
    
  return (
    <div>
      <Row>
    <Col span={4} className="cash-column" style={{border:"0px solid black", paddingTop:"10px", borderRadius:"30px", boxShadow:"0px 0px 14px rgb(0 0 0 / 10%)"}}>
        <ShoppingOutlined />
        <div>My Cash Points</div>
       <b>{cashDetail && cashDetail.myCash}</b> 
    </Col>
    <Col span={4} className="cash-column" style={{border:"0px solid black", marginLeft:"10px", paddingTop:"10px", borderRadius:"30px", boxShadow:"0px 0px 14px rgb(0 0 0 / 10%)"}}>
        <WalletOutlined />
        <div>Artium Cash Points</div>
       <b>{cashDetail && <div>{cashDetail.totalArtiumCash}</div>}</b> 
    </Col>
</Row>
<div className="report-card"style={{width:"100%",marginTop:"18px"}}>
<Form form={form} onFinish={handleSubmit} style={{paddingTop:"30px",marginLeft:"50px"}}>
                <Form.Item label="Event" name="type"  rules={[{ required: true ,pattern: /^[^\s]+$/, message: "Space is not allowed",}]}>
                    <Input  style={{width:"45%"}}/>
                </Form.Item>
                <Form.Item label="Expiry Date" name="expiryDate"  rules={[{ required: true }]}>
                <DatePicker
    showTime
    onChange={handleDateChange}
    disabledDate={(currentDate) =>
      currentDate && currentDate < moment().endOf("day")
    }
  />
                </Form.Item>
                <Form.Item label="Artium Cash Points" name="points"  rules={[{ required: true }]}
                
                onKeyPress={(e) => {
                    const keyCode = e.which || e.keyCode;
                    if (keyCode < 48 || keyCode > 57) {
                        e.preventDefault();
                    }
                }}
                >
                    <Input style={{width:"45%"}}/>
                </Form.Item>
                <Form.Item>
        <Button type="primary" htmlType="submit" style={{float:"right"}}>Add Points</Button>
    </Form.Item>
            </Form>
</div>

</div>

  )
}



export default Cashback;
