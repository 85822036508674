import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import envVariables from '../../core_units/config/env-variables';
import * as SecureLS from "secure-ls";
import dayjs from 'dayjs';
import './style.css';
import FilterControls from './FilterControls';
import { Button, Select, Pagination, Input } from 'antd';
import {
  FilterOutlined,
  
} from "@ant-design/icons";


const LiveDemoTable = (props) => {
  const ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [allDemoData, setAllDemoData] = useState([]); // Store all data here
  const [demoData, setDemoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [country, setCountry] = useState(null);
  const [status, setStatus] = useState(null);
  const [aeFound, setAeFound] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [timezone, setTimezone] = useState(null); // Timezone filter state
  const [uniqueTimezones, setUniqueTimezones] = useState([]);
  const intervalId = useRef(null);
  useEffect(() => {
    if (props.activeTab == 3 && !intervalId.current) {
      intervalId.current = setInterval(() => fetchLiveDemo(currentPage), 5000);
    } else if (props.activeTab != 3 && intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, [props.activeTab]);
  const fetchLiveDemo = () => {
    setLoading(true);
    const apiUrl = `${envVariables.API.BASE_URL}/api/admin/leads`;

    axios.post(apiUrl, { page: 0, size: 500, type: "liveDemo", fromDate: "" }, {
      headers: { Authorization: token["authorization"] }
    })
      .then(response => {
        if (response.data.content) {
          const fetchedData = response.data.content;
          setAllDemoData(fetchedData);
          setDemoData(fetchedData);
          setTotalPages(response.data.totalPages);

          // Extract unique timezones for dropdown
          const timezones = [...new Set(fetchedData.map(demo => demo.studentTimezone))];
          setUniqueTimezones(timezones);
        } else {
          setAllDemoData([]);
          setDemoData([]);
          setTotalPages(0);
          setUniqueTimezones([]); // Clear timezones if no data
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching lead data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    let filteredData = [...allDemoData];

    if (country) {
      filteredData = filteredData.filter(demo => demo.country === country);
    }
    if (status) {
      filteredData = filteredData.filter(demo => demo.demoStatus === status);
    }
    if (aeFound) {
      filteredData = aeFound === "NO_AE"
        ? filteredData.filter(demo => !demo.teacherFirstName)
        : filteredData.filter(demo => demo.teacherFirstName);
    }
    if (timezone) {
      filteredData = filteredData.filter(demo => demo.studentTimezone && demo.studentTimezone.trim() === timezone.trim());
    }

    setDemoData(filteredData);
  }, [country, status, aeFound, timezone, allDemoData]);

  useEffect(() => {
    fetchLiveDemo(currentPage);
  }, [currentPage])
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const applyFilters = () => {
    let filteredData = [...allDemoData];

    // Filter by country
    if (country) {
        filteredData = filteredData.filter(demo => demo.country === country);
    }

    // Filter by status
    if (status) {
        filteredData = filteredData.filter(demo => {
            switch (status) {
              case "SCHEDULED":
                if (
                    demo.demoStatus === "SCHEDULED" &&
                    (
                        (demo.studentJoinTime && !demo.teacherJoinTime) || 
                        (!demo.studentJoinTime && demo.teacherJoinTime) || 
                        (!demo.studentJoinTime && !demo.teacherJoinTime)
                    )
                ) {
                    // if (demo.actionPerformed === "teacher_demo_cancelclass") {
                    //     return demo.demoStatus === "NOT CONDUCTED";
                    // } else if (demo.actionPerformed === "student_trial_cancelclass") {
                    //     return demo.demoStatus === "CANCELLED";
                    // }
                    return true; 
                }
                return false;
            
            
                case "RESCHEDULED":
                    return demo.demoStatus === "Class_Ready_For_Scheduling" && demo.classDeleted;

                case "ONGOING":
                    return (
                        demo.demoStatus !== "COMPLETED" &&
                        demo.studentJoinTime &&
                        demo.teacherJoinTime &&
                        !demo.actionPerformed &&
                        demo.meetType !== "deleted" &&
                        !demo.classDeleted
                    );

                case "COMPLETED":
                    return demo.demoStatus === "COMPLETED";

                case "CANCELLED":
                    return (
                        demo.actionPerformed === "student_trial_cancelclass" &&
                        demo.demoStatus !== "Class_Ready_For_Scheduling"
                    );

                case "NOT CONDUCTED":
                    return demo.actionPerformed === "teacher_demo_cancelclass" && demo.demoStatus !== "Class_Ready_For_Scheduling";

                case "NOSHOW":
                    return demo.meetType?.includes("NoShow");

                default:
                    return true; // No status filter applied if no match
            }
        });
    }

    // Filter by AE (teacher) presence
    if (aeFound) {
        filteredData = aeFound === "NO_AE"
            ? filteredData.filter(demo => !demo.teacherFirstName)
            : filteredData.filter(demo => demo.teacherFirstName);
    }

    // Filter by timezone
    if (timezone) {
        filteredData = filteredData.filter(
            demo => demo.studentTimezone?.trim() === timezone.trim()
        );
    }

    // Update the filtered data state
    setDemoData(filteredData);
};


  
  

  // Apply filters automatically whenever dependencies change
  useEffect(() => {
    applyFilters();
  }, [country, status, aeFound, timezone, allDemoData]);
 
  const calculateElapsedTime = (latestJoinTime) => {
    if (!latestJoinTime) return null;
  
    const now = dayjs();
    const join = dayjs(latestJoinTime);
  
    const diff = now.diff(join, 'second');
    const minutes = Math.floor(diff / 60);
    const seconds = diff % 60;
  
    return `${minutes < 10 ? '0' : ''}${minutes}m ${seconds < 10 ? '0' : ''}${seconds}s`;
  };
  
  const renderStatusBadge = (demo) => {
    const { demoStatus, classDeleted, actionPerformed, studentJoinTime, teacherJoinTime, meetType } = demo;
    const studentJoined = !!studentJoinTime;
    const aeJoined = !!teacherJoinTime;

    // Check for ONGOING status first
    if (studentJoined && aeJoined && demoStatus !== "COMPLETED" && !actionPerformed && meetType !== "deleted" && !classDeleted ) {
        const latestJoinTime = dayjs(Math.max(dayjs(studentJoinTime).valueOf(), dayjs(teacherJoinTime).valueOf()));
        const elapsedTime = calculateElapsedTime(latestJoinTime.toISOString());
  
        return (
            <div style={{
                backgroundColor: '#b2c888',
                color: 'black',
                padding: '8px',
                borderRadius: '4px',
                height: '50px', 
                width: '120px',  
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center', 
            }}>
                ONGOING <br />
                {elapsedTime || 'Calculating...'}
            </div>
        );
    }

 
  
    if (meetType?.includes("NoShow")) {
        return createBadge("NOSHOW", '#fa5775');
    }

 
    switch (demoStatus) {
        case "COMPLETED":
            return createBadge("COMPLETED", '#4caf50');
        case "Class_Ready_For_Scheduling":
            if (classDeleted) {
                return createBadge("RESCHEDULED", 'lightblue');
            }
            return createBadge("RESCHEDULED", 'lightblue');
        case "SCHEDULED":
            if (actionPerformed === "teacher_demo_cancelclass") {
                return createBadge("NOT CONDUCTED", '#fcaefc');
            }
            if (actionPerformed === "student_trial_cancelclass") {
                return createBadge("CANCELLED", '#fa9237');
            }
            break;
        case "AE not found":
            return createBadge("AE not found", 'red');
        case "Student No Show":
            return createBadge("Student No Show", 'orange');
        default:
            break;
    }

    return createBadge(demoStatus, '#fbcc68');
};

// Helper function to create badge with consistent styling
const createBadge = (status, color) => (
    <div style={{
        backgroundColor: color,
        color: 'black',
        padding: '8px',
        borderRadius: '4px',
        height: '40px',  
        width: '120px',  
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center', 
    }}>
        {status}
    </div>
);

  
  
  

  return (
    <div>
     <FilterControls
        country={country} setCountry={setCountry}
        status={status} setStatus={setStatus}
        aeFound={aeFound} setAeFound={setAeFound}
        timezone={timezone} setTimezone={setTimezone}
        uniqueTimezones={uniqueTimezones} // Pass unique timezones to FilterControls
        applyFilters={applyFilters}
      />
      {/* <div className="leads-section" >  <Button id="filterButton" >
        
        
        Filter  <FilterOutlined></FilterOutlined> </Button></div> */}
 
    

        <div style={{ maxHeight: '550px', overflowY: 'auto', border: '1px solid #ddd', marginTop:"10px" }}>

        <table  style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'auto', alignItems:"center" }}>
            <thead>
              <tr>
              <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>            
                <span style={{ display: "flex", justifyContent: "center" }}>Demo Time</span></th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>        
                <span style={{ display: "flex", justifyContent: "center" }}> Demo Status</span></th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>        <span className='center' >Student Name</span></th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>        <span className='center' >Student Joined</span></th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>         <span className='center' >AE Name</span></th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>        <span className='center' >AE Joined</span></th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>       < span className='center' >Timezone</span> </th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>       < span className='center' >Course Name</span> </th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#f1f1f1', border: '1px solid #ddd', padding: '8px', }}>       < span className='center' >Contact</span> </th>
              </tr>
            </thead>
            <tbody>
            {demoData.length > 0 ? (
           demoData.map((demo, index) => (
         <tr key={index}>
<td style={{ border: '1px solid #ddd', padding: '8px' }}>
        <span style={{ display: "flex", justifyContent: "center" }}>
          {(() => {
            if (demo.startTime) {
              const [hours, minutes] = demo.startTime.split(':');
              let hoursInt = parseInt(hours, 10);
              const ampm = hoursInt >= 12 ? 'PM' : 'AM';
              hoursInt = hoursInt % 12;
              hoursInt = hoursInt ? hoursInt : 12;
              return `${hoursInt}:${minutes} ${ampm}`;
            }
            return 'N/A';
          })()}
        </span>
      </td>         
      <td style={{ border: '1px solid #ddd', padding: '8px', textAlign:"center" }}><span >{renderStatusBadge(demo)}</span></td>
         <td style={{ border: '1px solid #ddd', padding: '8px', textAlign:"center" }}><span >
          {demo.firstName} {demo.lastName}</span></td>
         
         {/* Parse demo.startTime with today's date */}
         <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
    <div style={{ display: "flex", justifyContent: "center" }}>
        {dayjs(`${dayjs().format("YYYY-MM-DD")} ${demo.startTime}`).isAfter(dayjs())
            ? <span> - </span>
            : demo.studentJoinTime 
                ? dayjs(demo.studentJoinTime, "YYYY-MM-DD HH:mm:ss").format("hh:mm A") // Parses and formats to 12-hour with AM/PM
                : (
                    <span>  
                        Student has not joined
                    </span>
                  )}
    </div>
</td>


         
<td style={{ border: '1px solid #ddd', padding: '8px', textAlign:"center" }}>
  <span >
    {demo.teacherFirstName === "Academic Expert" 
      ? "-" // If teacherFirstName exists, display it
      : demo.teacherFirstName   // Otherwise, display alternative text
    }
  </span>
</td>

         
         {/* Teacher Joined Column */}
         <td style={{ border: '1px solid #ddd', padding: '8px', textAlign:"center" }}>
         <div style={{ display: "flex", justifyContent: "center" }}>
    {demo.classStatus && demo.classStatus.includes("Found")
      ? <span style={{ color: 'red', fontWeight: 'bold', border: '1px solid red', padding: '4px', borderRadius: '4px' }}>
          AE not found
        </span>
      : dayjs(`${dayjs().format("YYYY-MM-DD")} ${demo.startTime}`).isAfter(dayjs())
        ? <span> - </span>
        : demo.teacherJoinTime 
          ? (() => {
              const studentJoin = dayjs(demo.studentJoinTime);
              const teacherJoin = dayjs(demo.teacherJoinTime);
              const diffInMinutes = teacherJoin.diff(studentJoin, 'minute');

              return diffInMinutes >= 2
                ? <div><span style={{ display: "flex", justifyContent: "center", alignContent:"center" }}>{teacherJoin.format("hh:mm A")} </span><span style={{ color: 'red', display: "flex", justifyContent: "center",  }}>AE joined late </span></div>
                // {teacherJoin.format("hh:mm A")}
                : teacherJoin.format("hh:mm A");
            })()
          : <span  >AE has not joined</span>}
</div>

</td>
  <td style={{ border: '1px solid #ddd', padding: '8px',textAlign:"center" }}> <span  >  {demo.studentTimezone}</span>    </td>
  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign:"center" }}> <span  >  {demo.courseName}</span>    </td>
  <td style={{ border: '1px solid #ddd', padding: '8px',textAlign:"center" }}> <span  >  {demo.mobileNumber}</span>    </td>


       </tr>

       
       
           
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>No data available</td>
              </tr>
            )}
              
            
            </tbody>
          </table>

          
</div>

    </div>
  );
};

export default LiveDemoTable;
