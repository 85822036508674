import React, { useState, useEffect } from 'react';
import { DatePicker, Modal, Button, Form, Select, notification,Tooltip } from 'antd';
import { connect } from "react-redux";
import _ from "lodash";
import dayjs from 'dayjs'
import formatDate from '../../../core_units/utils/dayjs/index';
import '../../students/payment/styles.css'
import appEnvirontVariables from "../../../core_units/config/env-variables";
import * as SecureLS from "secure-ls";
import axios from "axios";
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const TeacherStatusModal = (props) => {
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [otpValue,setOtpValue] = useState("")
    const [loading, setLoading] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onFinish = (values) => {
        console.log('Success:', values, _.get(props, 'teachers.viewteacherprofile'), _.get(props, 'teachers.viewteacherprofile.mobileNumber'));
        let paramsreq = {
            mobileNumber: _.get(props, 'teachers.viewteacherprofile.mobileNumber'),
            ...values,
            lastDate:  formatDate(_.get(values, 'lastDate'), true, "YYYY-MM-DD HH:mm:ss")
     

        }
        if (values.status !== 'RESIGNED') {
            delete paramsreq.lastDate
        }
        props.teacherblockstatus({
            params: paramsreq,
            callback: (response, data) => {
                setIsModalVisible(false);
                if (response == true) {
                    notification.success({
                        message: 'Status updated successfully.',
                    });
                } else {
                    notification.error({
                        message: _.get(data, 'message', 'Something went wrong'),
                    });
                }
            },
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const handleGetLoginOTP = () => {
        setLoading(true);
        setDisabledBtn(true);
        const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/temp/otp?credentialId=${props.teacherDetails.credentialId}`;
        const requestBody = {
          credentialId: props.teacherDetails.credentialId
        };
        axios
        .post(apiUrl, requestBody, {
          headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status === 200) {
              const otpValue = response.data?.OTP;
    
             
              setIsLoginModalVisible(true);
              setOtpValue(otpValue);
                  } else {
                    notification.error({
                        message: 'Something went wrong',
                    });
                    console.error('API Error:', response.data);
                  }
          })
          .catch((error) => {
              setLoading(false);
            console.error("API Error:", error);
          });
      };
      const copyToClipboard = (otpValue) => {
        navigator.clipboard
            .writeText(otpValue)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch(() => {
                notification.error({
                    message: 'Error while copying',
                });
            });
    };
      const DigitBox = ({ digit }) => (
        <div
          style={{
            width: '54px',
            height: '40px',
            borderRadius: '30px',
            border: '6px solid #009688',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '5px',
            fontWeight: 'bold',
            fontSize: '18px', 
          }}
        >
          {digit}
        </div>
      );
      const renderOtpDigits = () => {
        return otpValue.split('').map((digit, index) => (
          <DigitBox key={index} digit={digit} />
        ));
      };

    const render = () => {
        return (
            <div className='col-lg-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={showModal}>
                    Change Status</Button>
                    <Button type="primary" style={{marginLeft:"10px"}} onClick={handleGetLoginOTP}>Get Login OTP</Button>
                    <Modal
        title="Login OTP"
        visible={isLoginModalVisible}
        onOk={() => setIsLoginModalVisible(false)}
        onCancel={() => setIsLoginModalVisible(false)}
        footer={false}
        className="custom-modal"
      >
        <div className="grid-container" style={{ display: 'flex', alignItems: 'center', justifyContent:"center" }}>
       {renderOtpDigits()} 
       {isCopied &&
        <Button
        style={{ marginLeft: "20px" }}
        onClick={() => copyToClipboard(otpValue)}
        icon={<CheckCircleOutlined />}
        title="Copied"
      />
       }
       {
        !isCopied &&
        <Button
        style={{ marginLeft: "20px" }}
        onClick={() => copyToClipboard(otpValue)}
        icon={<CopyOutlined />}
        title="Copy"
      />
       }
              
          
        </div>
      </Modal>
                <Modal title="Change Status" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                    footer={false}>
                    <Form
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your status!',
                                },
                            ]}
                        >
                            <Select dropdownClassName='course-list-dropdown' onSelect={(value) => form.setFieldsValue({ status: value })}>

                                <Option key={1} value='ACTIVE'
                                > Active </Option>
                                <Option key={2} value='TERMINATED'
                                > Terminate </Option>
                                <Option key={3} value='RESIGNED'
                                > Resign </Option>
                            </Select>                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('status') === 'RESIGNED' ? (
                                    <Form.Item
                                        label="Date"
                                        name="lastDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input last date!',
                                            },
                                        ]}
                                    >
                                        <DatePicker dropdownClassName='course-list-dropdown' />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
    return render();
}
const mapStateToProps = (state) => (
    {
        teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
    }
);

const mapDispatchToProps = (dispatch) => ({
    teacherblockstatus: (details) =>
        dispatch(global.redux.action.teachers.teacherblockstatus(details)),
    view: (details) =>
        dispatch(global.redux.action.teachers.viewteacherprofile(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherStatusModal);