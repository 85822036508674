import React from 'react';
import appPages from './app-pages';

export default [
    {
        key: "login",
        element: <appPages.loginPage />,
        path:'/login',
        exact:true,
        header:false
    },
    
];
