import React from 'react'
import { getInitials, setInitialBackgroundColor, formatDateTime } from './helper';

function ChatListCard({ item, selectedChatData, setSelectedChatData }) {
  // console.log(item, "conversation item")
  const { id, lastMessage, lastMsgTimestamp, firstName, profilePhoto } = item

  const handleConversationClick = () => {
    // console.log(item)
    setSelectedChatData(item) 
  }
  
  return (
    <div 
      className={selectedChatData?.id === id ? 'chat-list__card selected-chat-card' : 'chat-list__card'} 
      onClick={handleConversationClick}
    >
      <div className={profilePhoto ? 'chat-list__card__image' : 'chat-list__card__image--initials ' +  setInitialBackgroundColor(firstName ? firstName : "NA")}>
        {profilePhoto ? <img src={profilePhoto} /> : getInitials(firstName ? firstName : "NA")}
      </div>
      <div className={'chat-list__card__content'}>
        <div className='chat-list__card__row-one'>
          <div className={'conv-student-name'}>
            {firstName ? firstName : "No Name"}
          </div>
          {lastMsgTimestamp && <div className='date-time'>{formatDateTime(lastMsgTimestamp.seconds)}</div>}
        </div>
        <div className='chat-list__card__row-two'>
          {lastMessage?.length > 20 ? lastMessage.substring(0, 20) + " ..." : lastMessage}
        </div>
    
        {/* {!isStudentRow && showConvBadge && <div className='unread-count'></div>} */}
      </div>
    </div>
  )
}

export default ChatListCard