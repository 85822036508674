import React, { useEffect, useState } from "react";
import { Tree } from 'antd';
import formatDate from "../../../core_units/utils/dayjs/index"
import * as SecureLS from "secure-ls";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import appEnvirontVariables from "../../../core_units/config/env-variables";


const { TreeNode } = Tree;

const StudentHistory = (props) => {
    var ls = new SecureLS({ encodingType: "aes" });
    const token = ls.get("isLogin");
    const [historyDetails, setHistoryDetails] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);

    useEffect(() => {
        StudentDetails();
    }, []);

    const StudentDetails = async () => {
        try {
            const res = await axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/get/user/perform/action?studentId=${props.profileDetails.studentId}`, {
                headers: { Authorization: token["authorization"] }
            });
            console.log(res.data, "res.data");
            setHistoryDetails(res.data.body);
            expandAllNodes(res.data.body);
        } catch (error) {
            console.error("Error fetching student history:", error);
        }
    };

    const expandAllNodes = (data) => {
        const keys = [];
        data.forEach((item, index) => {
            keys.push(`${index}`);
        });
        setExpandedKeys(keys);
    };
    const getTitle = (scenario) => {
        switch (scenario) {
            case "change_teacher":
                return { title: "Change Teacher", color: "blue" };
            case "change_course":
                return { title: "Change Course", color: "green" };
            case "one_account_to_another":
                return { title: "One account To Another", color: "red" };
            default:
                return { title: scenario, color: "black" };
        }
    };
    
    const renderHistoryDetails = (data) => {
        return data.map((item, index) => (
            <div key={index}>
                <div style={{textDecoration:"underline", color: getTitle(item.userPerformScenario).color}}>
                <b>{`${getTitle(item.userPerformScenario).title} - ${formatDate(item.createdOn, true)}`}</b>
            </div>
                <ul>
                    {item.userPerformScenario === "change_teacher" && (
                        <>
                            <li>Course Name: {item.oldCourseName}</li>
                            <li>Old Teacher Name: {item.oldTeacherName}</li>
                            <li>New Teacher Name: {item.newTeacherName}</li>
                            <li>Performed By: {item.actionPerformBy}</li>
                        </>
                    )}
                    {item.userPerformScenario === "change_course" && (
                        <>
                            <li>Old Course Name: {item.oldCourseName}</li>
                            <li>New Course Name: {item.newCourseName}</li>
                            <li>Old Teacher Name: {item.oldTeacherName}</li>
                            <li>New Teacher Name: {item.newTeacherName}</li>
                            <li>No. of Classes Added: {item.noOfClassToAdd}</li>
                            <li>No. of Classes Deducted: {item.noOfClassDeducted}</li>
                            <li>Performed By: {item.actionPerformBy}</li>
                        </>
                    )}
                    {item.userPerformScenario === "one_account_to_another" && (
                        <>
                            <li>Student Name: {item.toUserName}</li>
                            <li>Mobile Number: {item.toMobileNumber}</li>
                            <li>From Course Name: {item.fromCourseName}</li>
                            <li>To Course Name: {item.toCourseName}</li>
                            <li>No. of Classes Added: {item.noOfClassToAdd}</li>
                            <li>No. of Classes Deducted: {item.noOfClassDeducted}</li>
                            <li>Reason: {item.reason}</li>
                            <li>Performed By: {item.actionPerformBy}</li>
                        </>
                    )}
                </ul>
            </div>
        ));
    };
    
    
    return (
        <div>
        {historyDetails.length === 0 ? (
            <p style={{ display: "flex", justifyContent: "center" }}>No data found</p>
        ) : (
            renderHistoryDetails(historyDetails)
        )}
    </div>
    );
};

export default StudentHistory;
