import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import "./style.css";
import * as SecureLS from "secure-ls";
import { Link } from "react-router-dom";
import { Form, DatePicker, Button, Select, Modal, Empty, Input, Radio, notification, Spin } from "antd";
import _ from "lodash";
import axios from "axios";
import { CloseCircleOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import appEnvirontVariables from "../../core_units/config/env-variables"
import { constant } from "../../core_units/constant";
import Icon from "@ant-design/icons/lib/components/Icon";
import mixpanel from 'mixpanel-browser';

const { TextArea } = Input;
const BatchCreationOffline = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [form] = Form.useForm();
  const loginDetails = ls.get('isLogin');
  //const mixpanel = require('mixpanel-browser');
  const [studentName, setStudentName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const { Option } = Select;
  const [onlineGroupCourseList, setOnlineGroupCourseList] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("Preparatory");
  const [loading, setLoading] = useState(false);
  const [sLoading,setSLoading] = useState(false);
  const [batchTags, setBatchTags] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("MMMM"));
  const [endDate, setEndDate] = useState(moment().format("YYYY"));
  const [courseList, setCourseList] = useState();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [allSelectedCourse,setAllSelectedCourse ]= useState("");
  const [studentsList, setStudentsList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  console.log(selectedBranch,"selectedBranch");
  const[branchList, setBranchList] = useState();
  const [dayTime, setDayTime]= useState("");
  const [ startTimePicker, setStartTimePicker]= useState("");
  const [showBatchDetails, setShowBatchDetails] = useState(false);
  const [showExistingDetails, setShowExistingDetails] = useState(true);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [batchDetails, setBatchDetails] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [selectedStudentCred, setSelectedStudentCred] = useState("");
  const [showStudentExistErr, setShowStudentExisterr] = useState(false);
  const [showStudentAddErr, setShowStudentAdderr] = useState(false);
  const [showCourseErr, setShowCourseErr] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editBatchName, setEditBatchName] = useState("");
  const [editDesc, setEditDesc] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [queryvalue, setqueryvalue] = useState();
  const [fromNewLink, setFromNewLink] = useState(false);
  const [selectedOnlineGroupCourse, setSelectedOnlineGroupCourse] = useState();
  const [selectedOnlineGroupCourseIndex, setSelectedOnlineGroupCourseIndex] = useState();
  const [centerList, setCenterList] = useState();
  console.log(selectedOnlineGroupCourseIndex,"selectedOnlineGroupCourseIndex===")
  useEffect(() => {
    configjson();
  }, []);

  const getCenterList= (uId) => {
    return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/counsellor/centre?uId=${uId}`,
      { headers: { "Authorization": token["authorization"] } });
  }
  const handleSearch = () => {
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);
    const isQueryEmpty = searchQuery.replace(/\s+/g, '') === '';
    setErrorMessage(isQueryEmpty);

    if (isQueryEmpty) {

    } else {
      setSearchPerformed(true);

      let query = {};


      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(searchQuery)) {
        query.emailId = searchQuery;
      }

      else if (new RegExp(/^[0-9\b]+$/).test(searchQuery)) {
        query.mobileNumber = searchQuery;
      }

      else {
        query.name = searchQuery;
      }


      query.page = 0;
      query.size = 100;


      apicall(query);

      console.log(query);
    }
  };
  const apicall = (query = {}, page = 0) => {
    setqueryvalue(query)
    setSLoading(true);
    props.list({
      body: {
        page: page,
        size: 100,
        typeOfStudent: "offline",
        offlineCenterId: centerList.find(e => e.centreName == selectedBranch).centreId,
        ...query
      },
      callback: (response, data) => {


        if (_.isEqual(response, true)) {
          let arr = [];
          if (_.isEmpty(query) && !_.isEmpty(_.get(data, "content"))) {
            arr = _.uniqBy(
              [...studentsList, ..._.get(data, "content")],
              function (e) {
                return e.studentId;
              }
            );
          } else if (!_.isEmpty(query)) {
            if (
              _.size(studentsList) < _.get(data, "totalElements") &&
              _.isEqual(queryvalue, query)
            ) {
              arr = _.uniqBy(
                [...studentsList, ..._.get(data, "content")],
                function (e) {
                  return e.studentId;
                }
              );
            } else {
              arr = _.get(data, "content");
            }
          }
          setStudentsList(arr);
          setSelectedStudent("");
          console.log(arr, "arr")
        }
        setSLoading(false);
      }
    })

  }
  // const configjson = () => {
  //   props.courseList({
  //     callback: (res, data) => {
  //       if (_.isEqual(res, true)) {
  //         console.log("courselist", data);
  //         data.onlineGroupCourses = data.onlineGroupCourses.filter(e => e.enabled);
  //         setCourseList(data);
  //         setBranchList(data);
          
  //         setSelectedCourse(data.onlineGroupCourses[0].name);
  //         const cList = [];
          
  //         setOnlineGroupCourseList(data.onlineGroupCourses);
  //       }
  //     },
  //   });
  // };

  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          console.log("courselist", data);
         
          // const offlineCourses = data.offlineCourses.flatMap(offlineCourse => 
          //   offlineCourse.offlineCentres.flatMap(offlineCentre => 
          //     offlineCentre.courses
          //   )
          // );
          setCourseList(data);
          setBranchList(data);
          let offlineCourses;
          getCenterList(token.uId).then(result => {
            if(result.data && result.data.length){
              setSelectedBranch(result.data[0].centreName)
              setCenterList(result.data);
              offlineCourses = data.offlineCourseCentres.filter(e => e.centreName == result.data[0].centreName).flatMap(offlineCourseCentre => offlineCourseCentre.courses)
              const filteredOfflineCourses = offlineCourses.filter(course => course.enabled);
              setSelectedCourse(filteredOfflineCourses.length > 0 ? filteredOfflineCourses[0].name : '');
              setOnlineGroupCourseList(filteredOfflineCourses);
            }else{
              setSelectedBranch(data.offlineCentres[0].centreName)
              setCenterList(data.offlineCentres);
              offlineCourses = data.offlineCourseCentres.filter(e => e.centreName == data.offlineCentres[0].centreName).flatMap(offlineCourseCentre => offlineCourseCentre.courses)
              const filteredOfflineCourses = offlineCourses.filter(course => course.enabled);
              setSelectedCourse(filteredOfflineCourses.length > 0 ? filteredOfflineCourses[0].name : '');
              setOnlineGroupCourseList(filteredOfflineCourses);
            }
          })
        }
      },
    });
  };
  

  const formatMonthYearToDates = (selectedMonth, selectedYear) => {
    const startOfMonth = moment(`${selectedYear}-${moment().month(selectedMonth).format("MM")}-01`, "YYYY-MM-DD");
    const endOfMonth = startOfMonth.clone().endOf("month");

    const startDate = startOfMonth.format("YYYY-MM-DD");
    const endDate = endOfMonth.format("YYYY-MM-DD");

    return { startDate, endDate };
  };

  const handleSubmit = () => {
    console.log("handleSubmit invoked");
    setLoading(true);
    const selectedMonth = document.getElementById("startDate").value;
    const selectedYear = document.getElementById("endDate").value;
    const { startDate, endDate } = formatMonthYearToDates(selectedMonth, selectedYear);
  
    const selectedCourseObject = courseList.offlineCourseCentres.filter(e => e.centreName == selectedBranch)
    .flatMap(offlineCourseCentre => offlineCourseCentre.courses)
    .find(course => course.name === selectedCourse);
    if (selectedCourseObject) {
      console.log(selectedCourseObject, "selectedCourseObject===")
        let courseIds = [];
        
      
        if (selectedCourseObject.tags && selectedCourseObject.tags.length > 0) {
            const selectedTag = selectedCourseObject.tags.find(tag => tag.localizedName === selectedLevel || tag.localizedName === "Tamil");
            if (selectedTag) {
                courseIds.push(selectedTag.paidCourseId);
            } 
        } 

        console.log(selectedCourseObject.paidCourseId, "selectedCourseObject.paidCourseId===");
        const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/fetch/batches`;
        const requestBody = {
            branchName: selectedBranch,
            batchType: "Offline",
            courseIds,
            startDate,
            endDate,
        };

        if (dayTime || startTimePicker) {
            requestBody.batchTimings = [];
            if (dayTime && startTimePicker) {
                requestBody.batchTimings.push({
                    dayofWeek: dayTime,
                    startTime: startTimePicker
                });
            } else if (dayTime) {
                requestBody.batchTimings.push({
                    dayofWeek: dayTime,
                });
            } else if (startTimePicker) {
                requestBody.batchTimings.push({
                    startTime: startTimePicker
                });
            }
        }
       
        axios.post(apiUrl, requestBody, {
            headers: {
                Authorization: token["authorization"],
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            setLoading(false);
            if (response.status === 204) {
              setLoading(false);
                setBatchTags([]);
                notification.error({
                    message: "",
                    description: "No Batches Found",
                });
            } else {
                const batchData = response.data;
                const uniqueBatchTags = [];
                batchData.forEach((batch) => {
                    const { batchTimings, batchStudents } = batch;
                    (batchTimings || []).forEach((timing) => {
                        const { dayofWeek, startTime } = timing;
                        const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mmA");
                        const tag = {
                            dayofWeek: dayofWeek,
                            startTime: formattedStartTime,
                            batchId: batch.id,
                            batchStartDate: batch.batchStartDate,
                            batchName: batch.batchName,
                            teacherName: batch.teacherName,
                            batchTimings: batchTimings,
                            batchStudents: batchStudents,
                            studentsInBatch: batch.studentsInBatch,
                            maxStudentsInBatch: batch.maxStudentsInBatch,
                            description: batch.description
                        };
      
                        const existingTag = uniqueBatchTags.find((t) => t.batchId === batch.id);
                        if (!existingTag) {
                            uniqueBatchTags.push(tag);
                        }
                    });
                });
                if (uniqueBatchTags.length === 0) {
                  setLoading(false);
                  setBatchTags([]);
                  notification.error({
                    message: "",
                    description: "No Batches Found",
                  });
                }
                setBatchTags(uniqueBatchTags);
                console.log(uniqueBatchTags, "uniqueBatchTags");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error("Error:", error);
            notification.error({
                message: "Error",
                description: "Failed to fetch batches. Please try again later.",
            });
        });
    }else {
      console.error("Selected course not found in courseList");
    }
}


  const handleLevelChange = (e) => {
    setSelectedLevel(e.target.value);
  };


  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  function getTagColor(studentList, maxcount) {
    if (studentList.filter(e => e.studentStatus != "Expired").length < maxcount) {
      return "green";
    } else if (studentList.filter(e => e.studentStatus == "Confirmed").length < maxcount) {
      return "orange";
    } else {
      return "red";
    }
  }
  const handleBackClick = () => {
    console.log("handleBackClick invoked");
    setShowExistingDetails(true);
    setShowBatchDetails(false);
    setTimeout(handleSubmit);
  };

  const handleTagClick = (batchId) => {
    setSelectedBatchId(batchId);
   
    setLoading(true);

    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/batch/details?batchId=${batchId}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {

        console.log("Group Details API Response:", response.data);
       
       
        //setBatchDetails(response.data);
        if (response.data?.studentDetails) {
          const updatedStudentData = [];
          for (const student of response.data?.studentDetails) {
            await fetchStudentDetails(student, response.data);
            updatedStudentData.push(student);
          }
          response.data.studentDetails = updatedStudentData;
          setShowBatchDetails(true);
          setShowExistingDetails(false);
          setLoading(false);
        }
        setBatchDetails(response.data);
        console.log(response.data, "res==")

      })
      .catch((error) => {
        setLoading(false);
        console.error("API Error:", error);
        notification.error({
          message: "",
          description: "An error occurred while fetching batch details.",
        });
      });
  };
  const fetchStudentDetails = async (student, rdata) => {
    try {
      const response = await new Promise((resolve, reject) => {
        props.getpaymentlink({
          params: {
            studentId: student.studentId
          },
          callback: (response, data) => {
            if (response === true && data.length > 0) {
              const filteredData = data.filter(e => e.paymentFor === "directpayment")
                .sort((a, b) => b.created_at - a.created_at);
              resolve(filteredData);
            } else {
              resolve([]);
            }
          }
        });
      });
      const externalData = await getExternalPayment(student.studentId);
      const aggregateresult = [...(externalData.data || []), ...response];
      //const courseData = await getCourseDetails(student.studentId, rdata.batchDetails.batchCourseDetials[0].id);
      student.paymentData = aggregateresult.sort((a, b) => b.created_at - a.created_at);
      console.log(student.paymentData, "responseppppp")
    } catch (error) {
      console.error(`Error fetching details for student ID ${student.studentId}:`, error);
      student.paymentData = null;
    }
  }
  const getExternalPayment = async (id) => {
    return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/external-payment/${id}`,
      { headers: { "Authorization": token['authorization'] } });
  }
  const deleteBatch = () => {
    const confirmCreate = window.confirm("Are you sure you want to delete batch?");
    if (!confirmCreate) {
      return;
    }
  
    axios.delete(`${appEnvirontVariables.API.BASE_URL}/api/course/batch?batchId=${selectedBatchId}`, {
      headers: { "Authorization": token['authorization'] }
    })
    .then(response => {
      console.log("Batch deleted successfully:", response);
      notification.success({
        message: "Success",
        description: "Batch deleted successfully.",
      });
  
      const {
        batchEndDate,
        batchStartDate,
        teacherName,
        branchName,
        batchName,
        batchCourseDetials
      } = batchDetails?.batchDetails || {};
  
      const courseName = batchCourseDetials && batchCourseDetials.length > 0 ? batchCourseDetials[0].name : undefined;
  
      const batchDelete = {
        LoginUser: loginDetails?.studentName,
        batchName: batchName,
        batchEndDate: batchEndDate ? moment(batchEndDate).format("DD MMM YYYY") : undefined,
        batchStartDate: batchStartDate ? moment(batchStartDate).format("DD MMM YYYY") : undefined,
        teacherName: teacherName,
        branchName: branchName,
        batchCourseDetails: courseName,
        batchId: selectedBatchId
      };
  
      console.log("Tracking Data:", batchDelete); 
  
      mixpanel.track(constant.MIXPANEL_OFFLINE_BATCH_DELETION,batchDelete);
  
      handleBackClick();
    })
    .catch(error => {
      console.error('There was an error deleting the Batch:', error);
  
      notification.error({
        message: "Error",
        description: "There was an error deleting the Batch.",
      });
    });
  };
  
    

  const showDeleteModal = (studentId) => {

    deleteStudent(batchDetails.batchDetails.id, studentId)

  };
  const deleteStudent = (batchId, studentId) => {
    Modal.confirm({
      title: '',
      content: 'Do you want to delete this student?',
      onOk() {

        fetch(`${appEnvirontVariables.API.BASE_URL}/api/admin/delete/batch/student?batchId=${batchId}&studentId=${studentId}`, {
          method: 'post',
          headers: {
            Authorization: token["authorization"],
          },
        })
          .then(response => {
            if (response.status === 200) {
              notification.success({
                message: '',
                description: 'Student Deleted Successfully.',
              });
              setBatchDetails(prevBatchDetails => ({
                ...prevBatchDetails,
                studentDetails: prevBatchDetails.studentDetails.filter(student => student.studentId !== studentId),
              }));
            } else {
              console.error('Error deleting student');
            }
          })
          .catch(error => {
            console.error('API call error:', error);
          });
      },
      onCancel() {

      },
    });
  };
  const addStudentToBatch = (batchId, studentId) => {
 
    setLoading(true);
    const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/batch/student`;
    
   
    let courseList = '';

    if (batchDetails && batchDetails?.batchDetails && batchDetails?.batchDetails?.batchCourseDetials && Array.isArray(batchDetails?.batchDetails?.batchCourseDetials) && batchDetails?.batchDetails?.batchCourseDetials.length > 0) {
      
        courseList = batchDetails.batchDetails.batchCourseDetials.map(detail => detail.id).join(',');
    } else {
       
        courseList = allSelectedCourse ? allSelectedCourse.tags.map(tag => tag.paidCourseId).join(',') : '';
    }
  
    console.log(courseList, "courseList=====");

    const payload = {
        batchId: batchId,
        studentId: studentId,
        centreId: centerList.find(e => e.centreName == selectedBranch).centreId,
        addedBy: loginDetails?.studentName,
        courseList: courseList,
        linkExpiryDate: moment().format('YYYY-MM-DD'),
        studentStatus: "Confirmed"
    };
  
    axios.post(apiUrl, payload, {
        headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        setLoading(false);
        console.log("API Response:", response.data);
        handleTagClick(batchId);
        form.resetFields();
        notification.success({
            message: "",
            description: `Student ${selectedStudentName} has been added to the batch.`,
        });
        closeModal();
    })
    .catch((error) => {
        setLoading(false);
        console.error("API Error:", error);
        notification.error({
            message: "Error",
            description: "Failed to add the student to the batch.",
        });
    });
};

  const handleAllCourseChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedOnlineGroupCourseIndex(selectedIndex);
    const selectedCourse = onlineGroupCourseList[selectedIndex];
    setAllSelectedCourse(selectedCourse);
    setShowCourseErr(false);
   
  };
  const onEditFinish = () => {
    setLoading(true);
    if(editBatchName.trim()){
      let requestBody = {
        batchId : selectedBatchId,
        description : editDesc || "",
        batchName : editBatchName
      }
      const apiUrl = `${appEnvirontVariables.API.BASE_URL}/api/admin/update/batch/details`;
      axios.put(apiUrl, requestBody, {
        headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
      mixpanel.track(constant.MIXPANEL_OFFLINE_GROUP_DETAIL_UPDATE_API, {
        LoginUser: loginDetails?.studentName,
        ...requestBody,
        old_description : batchDetails?.batchDetails?.description || "",
        old_batchName : batchDetails?.batchDetails?.batchName
      });
      notification.success({
        message: "Success",
        description: response.data,
      });
      batchDetails.batchDetails.batchName = editBatchName;
      batchDetails.batchDetails.description = editDesc;
      setBatchDetails(batchDetails);
      closeEditModal();
      setLoading(false);
     }).catch((error) => {
      setLoading(false);
      console.error("Error:", error);
      notification.error({
          message: "Error",
          description: "Failed to update batch. Please try again later.",
      });
  });
    }else{
      notification.error({
        message: "Error",
        description: "Group Name should not be empty.",
      });
    }
  }
  const onFinish = () => {
    if (selectedStudent && selectedBatchId) {
      if (!batchDetails.studentDetails.some(e => e.studentId === selectedStudent)) {
        setFromNewLink(false);
        addStudentToBatch(selectedBatchId, selectedStudent);
      } else {
        setShowStudentExisterr(true);
      }
    }
    if (!selectedStudent) {
      setShowStudentAdderr(true);
    }
    if (!selectedOnlineGroupCourse && !(batchDetails && batchDetails?.batchDetails?.batchCourseDetials && batchDetails?.batchDetails?.batchCourseDetials.length)) {
      setShowCourseErr(true);
    }
  };
  
  const handleRadioChange = (studentId, studentName, credId) => {
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);
    setSelectedStudent(studentId);
    console.log(studentId,"studentId")
    setSelectedStudentName(studentName);
    console.log(studentName,"studentName")
    setSelectedStudentCred(credId);
    console.log(credId,"credId")
  };
  const openModal = () => {
    setSearchPerformed(false);
    setStudentsList([]);
    setSearchQuery("");
    setSelectedStudentCred("");
    setSelectedStudent("");
    setShowStudentExisterr(false);
    setShowStudentAdderr(false);
    setSelectedOnlineGroupCourse("");
    setSelectedOnlineGroupCourseIndex("");

      form.resetFields();
      setIsModalVisible(true);
    
  };
  const openEditModal = () => {
    setEditDesc(batchDetails?.batchDetails?.description);
    setEditBatchName(batchDetails?.batchDetails?.batchName);
    setIsEditModalVisible(true);
  };
  const closeEditModal = () => {
    setEditDesc("");
    setEditBatchName("");
    setIsEditModalVisible(false);

  };
  const closeModal = () => {
    setSelectedOnlineGroupCourse("");
    setSelectedOnlineGroupCourseIndex("");
    form.resetFields();
    setIsModalVisible(false);
  };
  const handleDayClear = () => {
    setDayTime('');
  };
  const handleDelete = (student) => {
    console.log("student", student);
    const confirmCreate = window.confirm("Are you sure you want to remove this student from the batch?");
    if (!confirmCreate) {
      return;
    }
  
    const url = `${appEnvirontVariables.API.BASE_URL}/api/admin/offline/delete/batch/student?batchId=${selectedBatchId}&studentId=${student.studentId}`;
  
    axios.delete(url, {
      headers: { "Authorization": token['authorization'] }
    })
    .then(response => {
      console.log("Student deleted successfully:", response);
      notification.success({
        message: "Success",
        description: "Student deleted successfully.",
      });
      handleBackClick();
      const {
        batchDetails: {
          batchEndDate,
          batchStartDate,
          teacherName,
          branchName,
          batchName,
          batchCourseDetials
        }
      } = batchDetails;
      const courseName = batchCourseDetials && batchCourseDetials.length > 0 ? batchCourseDetials[0].name : undefined;  
      const {
        studentName,
        mobileNumber,
        emailId,
        studentStatus
      } = student;
  
      const studentDelete = {
        LoginUser: loginDetails?.studentName,
        ...student,
        ...batchCourseDetials,
        batchEndDate: moment(batchEndDate).format("DD MMM YYYY"),
        batchStartDate: moment(batchStartDate).format("DD MMM YYYY"),
        teacherName: teacherName,
        courseName: courseName,
       
      };
      console.log("Tracking Data:", studentDelete);
      mixpanel.track(constant.MIXPANEL_OFFLINE_STUDENT_DELETION, studentDelete);
    })
    .catch(error => {
      console.error("There was an error deleting the student:", error);
      notification.error({
        message: "Error",
        description: "There was an error deleting the student.",
      });
    });
  };
  
    


 
  return (
    <div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, 
          }}
        >
          <Spin />
        </div>
      )}
      {showExistingDetails && (
      <div>
      <div style={{ marginTop: "10px" }}>
        <Link
          to={{
            pathname: "/CreateOffline",
          }}
          style={{ cursor: "pointer", color: "black" }}
        >
          <Button type="primary">Create Group</Button>
        </Link>
        {/* <Link
          to={{
            pathname: "/RegForm",
          }}
          style={{ cursor: "pointer", color: "black" }}
        >
          <Button type="primary" style={{marginLeft:"20px"}}>Registration Form</Button>
        </Link> */}
      </div>
      <div>
              <div className="calendar-legendset-conatiner" style={{ float: "right" }}>
                <div className="calendar-legend-set">
                  <span className="legend-square" style={{ backgroundColor: "green", cursor: "pointer" }}></span>
                  <span className="legend-text">FREE &emsp; </span>
                  <span className="legend-square" style={{ backgroundColor: "red", cursor: "pointer" }}></span>
                  <span className="legend-text">FILLED &emsp; </span>
                
                </div>
              </div>
            </div>
      <div style={{ marginTop: "10px",fontSize:"15px" }} className="row batchDetail">
      <div style={{ width: "auto" }}>
  <label htmlFor="courseSelect" style={{ fontWeight: 800 }}>
    Course Name:
  </label>
  {courseList && courseList.offlineCourseCentres && courseList.offlineCourseCentres.length > 0 && (
  <select
    id="courseSelect"
    value={selectedCourse}
    onChange={handleCourseChange}
    style={{ marginLeft: "15px", width: "180px", cursor: "pointer" }}
  >
    {courseList.offlineCourseCentres.filter(e => e.centreName == selectedBranch).map((offlineCourseCentre, index1) => (
      offlineCourseCentre.courses.map((course, index2) => {
       
        if (course.name === "Karaoke Offline" && course.tags && course.tags.length > 0) {
          return course.tags.map((tag, index3) => (
            <option
              value={course.name}
              key={`${index1}-${index2}-${tag.id}`}
            >
              {`${course.name} - ${tag.localizedName}`}
            </option>
          ));
        } else {
          return (
            <option
              value={course.name}
              key={`${index1}-${index2}-${course.id}`}
            >
              {course.name}
            </option>
          );
        }
      })
    ))}
  </select>
)}

  
  
</div>


              <div style={{ width: "auto" }}>
                <label htmlFor="levelSelect" style={{ fontWeight: 800 }}>
                Course Level:
                </label>
                <select
                  id="courseIds"
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                 disabled={selectedCourse == "Karaoke Offline"}
                >
                   <option value="" disabled>Select Teacher Level</option>
                  <option value="Preparatory">Preparatory</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                  
                </select>
              </div>

              <div style={{ width: "auto" }}>
                <label htmlFor="startDatePicker" style={{ fontWeight: 800 }}>
                  Month:
                </label>

                <select
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>

              <div style={{ width: "auto" }}>
                <label htmlFor="endDatePicker" style={{ fontWeight: 800 }}>
                  Year:
                </label>

                <select
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>
                
              <div style={{ width: "auto" }}>
  <label htmlFor="branchname" style={{ fontWeight: 800 }}>
    Branch Name:
  </label>
  <select
    id="branchname"
    value={selectedBranch}
    onChange={(e) => setSelectedBranch(e.target.value)}
    style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
  >
    {centerList?.map((centre, index) => (
      <option value={centre.centreName} key={index}>
        {centre.centreName}
      </option>
    ))}
  </select>
</div>
<div style={{ width: "auto" }}>
                <label htmlFor="endDatePicker" style={{ fontWeight: 800 }}>
                  Day:
                </label>

                <select
                  id="endDate"
                  value={dayTime}
                  onChange={(e) => setDayTime(e.target.value)}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                   <option value="">Select Day</option>
                  <option value="MONDAY">MONDAY</option>
                  <option value="TUESDAY">TUESDAY</option>
                  <option value="WEDNESDAY">WEDNESDAY</option>
                  <option value="THURSDAY">THURSDAY</option>
                  <option value="FRIDAY">FRIDAY</option>
                  <option value="SATURDAY">SATURDAY</option>
                  <option value="SUNDAY">SUNDAY</option>
                </select>
         
              </div>

              <div style={{ width: "auto" }}>
                <label htmlFor="startTimePicker" style={{ fontWeight: 800 }}>
                  Time:
                </label>

                <select
                  id="endDate"
                  value={startTimePicker}
                  onChange={(e) => setStartTimePicker(e.target.value)}
                  style={{ marginLeft: "15px", width: "140px", cursor: "pointer" }}
                >
                   <option value="">Select Time</option>
              <option value="07:00:00">07:00AM</option>
              <option value="08:00:00">08:00AM</option>
              <option value="09:00:00">09:00AM</option>
              <option value="10:00:00">10:00AM</option>
              <option value="11:00:00">11:00AM</option>
              <option value="12:00:00">12:00PM</option>
              <option value="13:00:00">01:00PM</option>
              <option value="14:00:00">02:00PM</option>
              <option value="15:00:00">03:00PM</option>
              <option value="16:00:00">04:00PM</option>
              <option value="17:00:00">05:00PM</option>
              <option value="18:00:00">06:00PM</option>
              <option value="19:00:00">07:00PM</option>
              <option value="20:00:00">08:00PM</option>
              <option value="21:00:00">09:00PM</option>
              <option value="22:00:00">10:00PM</option>
              <option value="23:00:00">11:00PM</option>
     </select>
              </div>
              <div style={{ width: "auto" }}>
                <button
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer",
                    // borderRadius: "30px",
                    backgroundColor: "lightblue",
                    border: "none",
                    width: "97px",
                    padding: "4px",
                    marginTop: "-3px",
                  }}
                >
                  Find Group
                </button>
              </div>
            </div>


      <div style={{ marginTop: "10px" ,fontSize:"15px" }}>
{batchTags.length > 0 &&
        <table className="custom-table">
          <thead>
            <tr>
              <th style={{ fontSize: "14px" }}>Batch Name </th>
              <th style={{ fontSize: "14px" }}>Batch Id </th>
              <th style={{ fontSize: "14px" }}>Start Date</th>
              <th style={{ fontSize: "14px" }}>Teacher Name</th>
              <th style={{ fontSize: "14px" }}>Day/Slot Time</th>
              <th style={{ fontSize: "14px" }}>Student Status</th>
              <th style={{ fontSize: "14px" }}>Desc</th>        
            </tr>
            <tr>
            </tr>
          </thead>

          <tbody>
  {batchTags.map((batch, index) => {
    console.log("Batch:", batch);
    const nonExpiredStudents = (batch.batchStudents || []).filter(
      (student) => student.studentStatus !== "Expired"
    );

    return (
      <tr key={index}  style={{cursor:"pointer"}} onClick={() => handleTagClick(batch.batchId)} className="hover-effect">
        <td>{batch.batchName}</td>
        <td>{batch.batchId}</td>
        <td>{moment(batch.batchStartDate).format("DD MMM YYYY")}</td>
        <td>{batch.teacherName}</td>
        <td>
          {batch.batchTimings.map((timing, timingIndex) => {
            console.log("Timing:", timing); 
            return (
              <span
                key={timingIndex}
                className="legend-square"
                style={{
                  fontSize: "small",
                  borderRadius: "30px",
                  padding: "8px",
                  color: "white",
                  backgroundColor: "lightslategray",
                  width: "155px",
                  display: "inline-flex",
                  height: "37px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5px",
                  marginTop: timingIndex > 0 ? "10px" : "0",
                }}
              
              >
                {timing.dayofWeek}/{timing.startTime}
              </span>
            );
          })}
        </td>
        <td>
          <span
            style={{
              backgroundColor: getTagColor(batch.batchStudents || [],batch.maxStudentsInBatch),
              borderRadius: '4px',
              padding: '8px',
              color: 'white',
              fontWeight: 800
            }}
          
          >
            {nonExpiredStudents.length}/{batch.maxStudentsInBatch}
          </span>
        </td>
        <td>
        {batch.description || ""}
        </td>
      </tr>
    );
  })}
</tbody>





        </table>
}
      </div>

</div>
)}
{showBatchDetails && batchDetails && (
  <div className="full-page-view-loader">
<div className="batch-details-content">
<div className="batchTableView">
<div>
                    {batchDetails.studentDetails
                              .filter(student =>
                                student.studentStatus === "Payment Link Shared" || student.studentStatus === "Confirmed"
                              ).length < 1 ? 
                              <button
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "114px",
                        padding: "4px",
                        marginTop: "-42px",
                        marginRight: "250px",
                        float: "right"
                      }}
                      onClick={() => deleteBatch()}
                    >
                      Delete Batch
                    </button> : ""}

                    <button
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "114px",
                        padding: "4px",
                        marginTop: "-42px",
                        marginRight: "125px",
                        float: "right"
                      }}
                       onClick={() => openEditModal()}
                    >
                      Edit Batch
                    </button>
                   
                    <button
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "114px",
                        padding: "4px",
                        marginTop: "-42px",
                        float: "right"
                      }}
                       onClick={() => openModal()}
                     

                    >
                      Add Student
                    </button>

                  </div>
<div style={{ marginTop: "-112px" }}>
                    <button
                      onClick={handleBackClick}
                      style={{
                        cursor: "pointer",
                        borderRadius: "30px",
                        backgroundColor: "lightblue",
                        border: "none",
                        width: "97px",
                        padding: "4px",
                        marginTop: "70px",
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div
                    style={{
                      fontWeight: 800,
                      color: "deeppink",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "large",
                    }}
                  >
                    GROUP DETAILS
                  </div>
                  <div style={{ marginTop: "5px",padding:"104px", paddingBottom:"4px",paddingTop:"9px" }} className="row batchDetail">
                    <div style={{ width: "auto" }}>
                      <label htmlFor="courseSelect" style={{ fontWeight: 800 }}>
                        Group Name:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{batchDetails.batchDetails.batchName}</label>
                    </div>
                    <div style={{ width: "auto" }}>
                      <label htmlFor="levelSelect" style={{ fontWeight: 800 }}>
                        Start Date:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{moment(batchDetails.batchDetails.batchStartDate).format("DD MMM YYYY")}</label>
                     
                    </div>
                    <div style={{ width: "auto" }}>
                      <label htmlFor="levelSelect" style={{ fontWeight: 800 }}>
                        End Date:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{moment(batchDetails.batchDetails.batchEndDate).format("DD MMM YYYY")}</label>
                    </div>
                    {batchDetails.batchDetails.batchCourseDetials && batchDetails.batchDetails.batchCourseDetials.length ? <div style={{ width: "auto" }}>
                      <label htmlFor="monthSelect" style={{ fontWeight: 800 }}>
                        Course:
                      </label>
                      <label style={{ marginLeft: "5px" }}>

                        {batchDetails.batchDetails.batchCourseDetials[0].localizedName ? `${batchDetails.batchDetails.batchCourseDetials[0].localizedName.split("-")[0]}${batchDetails.batchDetails.batchCourseDetials[0].localizedName.split("-")[1] ? "-" + batchDetails.batchDetails.batchCourseDetials[0].localizedName.split("-")[2] : ""}` : ""}             </label>
                    </div> : ''}
                    <div style={{ width: "auto" }}>
                      <label htmlFor="yearSelect" style={{ fontWeight: 800 }}>
                        Teacher Name:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{batchDetails.batchDetails.teacherName}</label>
                    </div>
                    <div style={{ width: "auto" }}>
                      <label htmlFor="yearSelect" style={{ fontWeight: 800 }}>
                        Branch Name:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{batchDetails.batchDetails.branchName}</label>
                    </div>
                    {batchDetails?.batchDetails?.description && <div style={{ width: "auto" }}>
                      <label htmlFor="yearSelect" style={{ fontWeight: 800 }}>
                        Description:
                      </label>
                      <label style={{ marginLeft: "5px" }}>{batchDetails.batchDetails.description}</label>
                    </div>}
                  </div>

                  {batchDetails.studentDetails.length > 0 ? (
                    <div style={{ marginTop: "10px" }}>
                      {batchDetails.studentDetails.some(student => student.studentStatus !== "Expired") && (
                        <table className="custom-table">

                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Student Name</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Student Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {batchDetails.studentDetails
                              .filter(student =>
                                student.studentStatus === "Payment Link Shared" || student.studentStatus === "Confirmed"
                              )
                              .map((student, index) => (
                                <tr key={student.studentId}>
                                  <td>{index + 1}</td>
                                  <td>{student.studentName}</td>
                                  <td>{student.emailId}</td>
                                  <td>{student.mobileNumber}</td>
                                  <td>{student.studentStatus}</td>
                                  <td>  <Button 
          icon={<DeleteOutlined />} 
          onClick={() => handleDelete(student)}
        /></td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {batchDetails.studentDetails.some(student => student.studentStatus === "Expired") && (
                        <div>
                          <div style={{ marginTop: "20px", fontWeight: "800" }}> Expired Students</div>
                          <table className="custom-table" style={{ marginTop: "20px" }}>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Student Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {batchDetails.studentDetails
                                .filter(student => student.studentStatus === "Expired")
                                .map((student, index) => (
                                  <tr key={student.studentId}>
                                    <td>{index + 1}</td>
                                    <td>{student.studentName}</td>
                                    <td>{student.emailId}</td>
                                    <td>{student.mobileNumber}</td>
                                    <td>{student.studentStatus}</td>
                                    <td>
                                      <button style={{ borderRadius: "30px" }} 
                                      onClick={() => showDeleteModal(student.studentId, batchDetails.batchDetails.id)}>
                                        Delete
                                        </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                    </div>


                  ) : (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", fontWeight: "800" }}>No students in this batch please add student.</div>
                  )}








                  </div>
</div>
</div>
)}
  <Modal
        title="Edit Batch"
        visible={isEditModalVisible}
        onOk={onEditFinish}
        onCancel={closeEditModal}
      >
        <Form form={form} onFinish={onEditFinish}>
        
        <Form.Item
                label="Group Name"
                name="editBatchName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Group Name!",
                  },
                ]}
              >
                <Input
                defaultValue={editBatchName}
                  value={editBatchName}
                  onChange={(e) => setEditBatchName(e.target.value)}
                  style={{ width: "255px" }}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                name="desc"
                rules={[
                  {
                    required: false,
                    message: "Group description",
                  },
                ]}
              >
                <TextArea defaultValue={editDesc} value={editDesc} rows={3} onChange={(e) => setEditDesc(e.target.value)} placeholder="" maxLength={256} />
              </Form.Item>
          
        </Form>      
      </Modal>
  <Modal
        title="Add Student"
        visible={isModalVisible}
        onOk={onFinish}
        onCancel={closeModal}
      >
        <Form form={form} onFinish={onFinish}>
        {!(batchDetails && batchDetails?.batchDetails && batchDetails?.batchDetails?.batchCourseDetials?.length) ? <select className="form-control mb-2" style={{ width: '100%', appearance: 'auto' }}
            dropdownClassName='course-list-dropdown'
            placeholder='Select Course'
            value={selectedOnlineGroupCourseIndex}
            id="course-list"
            onChange={handleAllCourseChange}
          >
            <option disabled value="">Select Course</option>
            {onlineGroupCourseList.length > 0 && onlineGroupCourseList.map((course, index) => {
              return <option value={index} >{course['name']}</option>
            })}
          </select> : ""}
          <Form.Item label="Search Student" style={{ marginTop: "27px" }}>
            <Input
              value={searchQuery}
              onChange={(e) => { setSearchQuery(e.target.value); setShowStudentExisterr(false); setShowStudentAdderr(false); }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSearch();
                }
              }}
              placeholder="Enter Student name"
              style={{ width: "77%" }}
              allowClear
            />

            <Button disabled={!searchQuery.trim()} type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Form.Item>

          {errorMessage &&
            <span style={{ color: "red", marginLeft: "152px" }}>Space is not Allowed.</span>
          }
          {sLoading ? (
            <Spin tip="Loading...">
              <div style={{ minHeight: '200px' }} />
            </Spin>
          ) : (

            <div className="modal-body-scrollable">
              {searchPerformed && studentsList.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center", color: "red" }}>No Student Found</div>
              ) : (
                <Form.Item>
                  <Radio.Group style={{ display: "inline" }}>
                    {studentsList.map((student) => (
                      <div key={student.studentId} className="student-card">
                        <Radio
                          value={student.studentId}
                          onClick={() => handleRadioChange(student.studentId, student.studentName, student.credentialId)}
                        >
                          {student.studentName} -  {student.mobileNumber} - {student.emailId}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
            </div>
          )}
        </Form>
        {showStudentAddErr ? <p style={{ color: "red" }}>Please select student.</p> : ""}
        {showStudentExistErr ? <p style={{ color: "red" }}>Student already added.</p> : ""}
        {/* {showCourseErr ? <p style={{ color: "red" }}>Please select course.</p> : ""} */}
      
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  teachers: state.app[global.redux.actionTypes.teachers.name] || {},
});

const mapDispatchToProps = (dispatch) => ({
  getpaymentlink: details => dispatch(global.redux.action.payment.paymentlink(details)),
  view: (details) =>
    dispatch(global.redux.action.students.viewprofile(details)),
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
    enrolledcourselist: details => dispatch(global.redux.action.students.enrolledcourselist(details)),
  list: (details) => dispatch(global.redux.action.students.list(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchCreationOffline);