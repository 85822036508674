import dayjs from "dayjs"

export const getInitials = (fullName = '') => {
  if (fullName.length > 0) {
    const trimName = fullName?.trim();
    return trimName.trim()
      .replace(/[^A-Za-z0-9À-ÿ ]/ig, '')        // taking care of accented characters as well
      .replace(/ +/ig, ' ')                     // replace multiple spaces to one
      .split(/ /)                               // break the name into parts
      .reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from the parts
      .concat(trimName.substring(1))                   // what if the name consist only one part
      .concat(trimName)                             // what if the name is only one character
      .substring(0, 2)                             // get the first two characters an initials
      .toUpperCase();
  }
  return '';
}

// add background color to initials:
export const setInitialBackgroundColor = (fullName = '') => {
  const fname = fullName.charAt(0).toUpperCase();
  if (fullName.length > 0) {
    let arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    if ((arr.slice(0, 5)).includes(fname)) {
      return 'initialBackColor4CBE73';
    } else if ((arr.slice(5, 10)).includes(fname)) {
      return 'initialBackColor4C79BE';
    } else if ((arr.slice(10, 15)).includes(fname)) {
      return 'initialBackColor7E4CBE';
    } else if ((arr.slice(15, 20)).includes(fname)) {
      return 'initialBackColorBE4C89';
    } else if ((arr.slice(20, 26)).includes(fname)) {
      return 'initialBackColorBE4C4C';
    }
    else {
      return 'initialBackColor7E4CBE';
    }
  }
}

export const formatDateTime = (date) => {
  const lastMsgDate = new Date(date * 1000)
  const currentDay = new Date()

  if (dayjs(currentDay).format("DD/MM/YYYY") === dayjs(lastMsgDate).format("DD/MM/YYYY")) { // Today Check
    return `${dayjs(lastMsgDate).format("h:mm A")}`
  } else if (Number(dayjs(currentDay).format("DD")) - Number(dayjs(lastMsgDate).format("DD")) === 1) { // Yesterday Check
    return "Yesterday"
  } else {
    return `${dayjs(lastMsgDate).format("DD/MM/YYYY")}`
  }
}